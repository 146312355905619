export const Cards = {
  Identifier: 'Identifier',
  Worker: 'Worker',
  CardIdentifier: 'Card identifier',
  MCID: 'MPTS ID',
  PAN: 'Card number',
  Status: 'Status',
  Company: 'Company',
  ActivationDate: 'Activation date',
};
