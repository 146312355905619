// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class WorkerMessageViewDto extends APIModel {
    /**
    * MessageRead identifier
    */
    id: number;
    /**
    * Message identifier
    */
    messageId: number;
    /**
    * Message caption
    */
    caption: string;
    /**
    * Message preview
    */
    preview: string;
    /**
    * True if message was read
    */
    read: boolean;
    /**
    * True if message was answered
    */
    answered: boolean;
    /**
    * Date of creation of message
    */
    created: string;

    constructor(options?: APIModelOptions) {
        super(WorkerMessageViewDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "messageId", type: "number" },
            { name: "caption", type: "string" },
            { name: "preview", type: "string" },
            { name: "read", type: "boolean" },
            { name: "answered", type: "boolean" },
            { name: "created", type: "string" }
        ];
    }
}




