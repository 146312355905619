import { InputField, Grid, FormComponentProps } from '@/components';

export const formBasicFields = [
  { name: 'firstName', label: 'FirstName', required: true },
  { name: 'lastName', label: 'LastName', required: true },
  { name: 'username', label: 'Username', required: true },
];

export const FormBasic = ({ disabled, t }: FormComponentProps) => {
  return (
    <Grid container>
      {formBasicFields.map((field) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 12, md: 6 }}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
