import { DatePickerField, Grid, FormComponentProps } from '@/components';
import { useMemo } from 'react';

const datePickers = [
  {
    name: 'excludedPeriodFrom',
    label: 'ExcludedPeriodFrom',
    nonTouchedError: true,
    required: true,
  },
  { name: 'excludedPeriodTo', label: 'ExcludedPeriodTo', nonTouchedError: true },
];

export const formExcludedPeriodFields = [...datePickers];

export const FormExcludedPeriod = ({ disabled, t, values }: FormComponentProps) => {
  if (!values.excludedPeriod) {
    values = { ...values, excludedPeriod: {} };
  }

  const excludedPeriodText = useMemo(() => t('ExcludedPeriodText'), [t]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <>{`${excludedPeriodText}`}</>
      </Grid>
      {datePickers.map((field) => (
        <Grid key={field.name} item xs={12} sm={6} md={3}>
          <DatePickerField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              dateOnly: true,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
