import { useCallback } from 'react';
import { useSettingGet, useSettingUpdate } from '@/api';
import {
  Button,
  Form,
  FormValues,
  InputField,
  CheckboxField,
  Spinner,
  useLoading,
  useSnackbar,
  Grid,
} from '@/components';
import { ApiKey } from '@/enums';
import { useValidationSchema } from './validation';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { removeStyledSmallScreenProp, useSmallScreen } from '@/utils';
import { useQueryClient } from 'react-query';
import { isEqual } from 'lodash';

export const Setting = () => {
  const { t } = useTranslation('Setting');
  const { data, isFetching } = useSettingGet();
  const { mutateAsync } = useSettingUpdate();
  const { showSuccessSnackbar } = useSnackbar();
  const { loading, showLoading, hideLoading } = useLoading();
  const validationSchema = useValidationSchema();
  const smallScreen = useSmallScreen();
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      showLoading();
      await mutateAsync(values);
      queryClient.invalidateQueries(ApiKey.Setting);
      showSuccessSnackbar(t('SettingUpdated'));
      hideLoading();
    },
    [queryClient, mutateAsync, showSuccessSnackbar, showLoading, hideLoading, t],
  );

  return loading || isFetching ? (
    <Spinner fullSize={true} />
  ) : data ? (
    <Form
      {...{
        key: JSON.stringify(data),
        initialValues: data,
        validationSchema,
        onSubmit,
        withLoading: false,
      }}
    >
      {({ isValid, values }) => (
        <Container {...{ smallScreen }}>
          <Title variant='h1'>{t('Title')}</Title>
          {[{ name: 'clientVersionMin', label: 'ClientVersionMin' }].map((field) => (
            <StyledInputField key={field.name} {...field} label={t(field.label)} />
          ))}
          <Grid container>
            {[{ name: 'maintenanceInProgress', label: 'MaintenanceInProgress' }].map((field) => (
              <Grid key={field.name} {...{ item: true, xs: 12, sm: 6, md: 6 }}>
                <CheckboxField {...{ ...field, label: t(field.label) }} />
              </Grid>
            ))}
          </Grid>

          <StyledButton
            type='submit'
            label={t('Submit')}
            disabled={!isValid || isEqual(data, values)}
            data-cy='form-submit'
          />
        </Container>
      )}
    </Form>
  ) : (
    <></>
  );
};

const Container = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  padding: smallScreen ? `${theme.spacing(6)} ${theme.spacing(2)}` : theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 500,
  margin: '0 auto',
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '1.1em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
}));

const StyledInputField = styled(InputField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));
