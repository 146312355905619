// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class ApiMetadata extends APIModel {
    /**
    * Items Offset
    */
    offset: number;
    /**
    * Page Size
    */
    limit: number;
    /**
    * Total numbers of items
    */
    totalItems: number;
    /**
    * Dynamic metadata
    */
    payload: object;

    constructor(options?: APIModelOptions) {
        super(ApiMetadata.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "offset", type: "number" },
            { name: "limit", type: "number" },
            { name: "totalItems", type: "number" },
            { name: "payload", type: "object" }
        ];
    }
}




