import React from 'react';
import { LocalizationProvider as XLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLocale } from '.';

export const LocalizationProvider = ({ children }: React.PropsWithChildren) => {
  const locale = useLocale();

  return (
    <XLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </XLocalizationProvider>
  );
};
