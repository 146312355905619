export const Messages = {
  AddButton: 'Pridať tlačidlo',
  Button: 'Tlačidlo',
  Buttons: 'Tlačidlá',
  ButtonLabel: 'Text tlačidla',
  Cancel: 'Zavrieť',
  Caption: 'Nadpis',
  Color: 'Farba',
  Company: 'Spoločnosť',
  CompanyCode: 'Kód spoločnosti',
  Content: 'Obsah',
  Created: 'Vytvorené',
  DateValidFrom: 'Platnosť od',
  DateValidTo: 'Platnosť do',
  EditValues: 'Hodnoty',
  FullWidth: 'Na celú šírku',
  ImageError: 'Obsahom správy je pravdepodobne zmazaný obrázok!',
  Message: 'Správa',
  Name: 'Názov',
  NoActionsText: 'Sú zobrazené predvolené tlačidlá.',
  Preview: 'Náhľad',
  Read: 'Prečítané',
  Type: 'Typ',
};
