export const Images = {
  CopyUrl: 'Kopírovať URL',
  FileName: 'Názov súboru',
  FileSize: 'Veľkosť',
  Image: 'Obrázok',
  Created: 'Vytvorené',
  LastModified: 'Editované',
  Type: 'Typ',
  UrlCopied: 'URL skopírovaná',
  Delete: 'Zmazať',
  DeleteConfirmText: 'Naozaj chcete zmazať obrázok?',
  DeleteConfirmTextEmpty: 'Obrázok nie je nikde používaný, je možné ho bezpečne zmazať.',
  DeleteConfirmTextUsed: 'Obrázok je používaný v nasledujúcich správach:',
  DeleteSuccess: 'Obrázok zmazaný',
};
