// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class BalanceDto extends APIModel {
    /**
    * Balance identifier
    */
    id: number;
    /**
    * Total number of payments
    */
    totalPaymentsCount: number;
    /**
    * Total number of workers
    */
    totalWorkersCount: number;
    /**
    * Total number of registered workers
    */
    totalWorkersRegistered: number;
    /**
    * Total number of active workers
    */
    totalWorkersActive: number;
    /**
    * Total number of paid workers
    */
    totalWorkersPaid: number;
    /**
    * Currency
    */
    currency: string;
    /**
    * Paid in total
    */
    totalPaid: number;
    /**
    * Bonus in total
    */
    totalBonus: number;
    /**
    * Fee in total
    */
    totalFee: number;
    /**
    * Company fee in total
    */
    totalCompanyFee: number;
    /**
    * Worker fee in total
    */
    totalWorkerFee: number;
    /**
    * Advance in total
    */
    totalAdvance: number;
    /**
    * Balance month
    */
    payMonth: string;
    /**
    * Date of calculation
    */
    dateTimeCalculated: string;
    /**
    * Export date
    */
    dateTimeDownloaded: string;
    /**
    * Code of company
    */
    companyCode: string;
    /**
    * Name of company
    */
    companyName: string;
    /**
    * ERP system of company
    */
    erpSystem: string;
    companyRegionCode: string;

    constructor(options?: APIModelOptions) {
        super(BalanceDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "totalPaymentsCount", type: "number" },
            { name: "totalWorkersCount", type: "number" },
            { name: "totalWorkersRegistered", type: "number" },
            { name: "totalWorkersActive", type: "number" },
            { name: "totalWorkersPaid", type: "number" },
            { name: "currency", type: "string" },
            { name: "totalPaid", type: "number" },
            { name: "totalBonus", type: "number" },
            { name: "totalFee", type: "number" },
            { name: "totalCompanyFee", type: "number" },
            { name: "totalWorkerFee", type: "number" },
            { name: "totalAdvance", type: "number" },
            { name: "payMonth", type: "string" },
            { name: "dateTimeCalculated", type: "string" },
            { name: "dateTimeDownloaded", type: "string" },
            { name: "companyCode", type: "string" },
            { name: "companyName", type: "string" },
            { name: "erpSystem", type: "string" },
            { name: "companyRegionCode", type: "string" }
        ];
    }
}




