export const appHeaderHeight = 80;
export const appSidebarWidth = 260;
export const tableCellHeight = 48;
export const smallScreenSize = 800;

export const borderRadius = 36;

export const fontFamily = ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(',');

export const colorBlack = '#262939';
export const colorBlue = '#0047BB';
export const colorBlueLight = '#3dc2ff';
export const colorGreen = '#00E285';
export const colorGreenLight = '#66DE8E';
export const colorGrey = '#ADADAD';
export const colorGreyDark = '#818181';
export const colorGreyLight = '#F5F5F5';
export const colorGreyApp = '#92949c';
export const colorOrange = '#FFA726';
export const colorPeach = '#FFCFBE';
export const colorRed = '#EE622E';

export const colorMobilePrimary = '#00B06F';
export const colorMobileSecondary = '#00dd8d';
export const colorMobileTertiary = '#0047bb';
export const colorMobileSuccess = '#affacd';
export const colorMobileWarning = '#eb445a';
export const colorMobileDanger = '#ffacbe';
export const colorMobileDark = '#242a38';
export const colorMobileMedium = '#eeeeee';
export const colorMobileLight = '#ffffff';
export const colorMobileAccent = '#ffcfbe';
export const colorMobilePinpoints = colorMobileAccent;
export const colorMobileBlack = '#000';
export const colorMobileWhite = '#fff';
