import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isCurrencyMin, isString }) => ({
    amount: isCurrencyMin(1),
    bonus: isCurrencyMin(0),
    companyFee: isCurrencyMin(0),
    workerFee: isCurrencyMin(0),
    note: isString(),
  }));
};
