import { CalendarPickerView, DatePicker as XDatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from '../Input';
import { DateValue, Props } from './types';
import {
  formatDateString,
  formatMonthString,
  isValidDate,
  isValidDateOptional,
  usePrevious,
} from '@/utils';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMonths, isDialogOpened } from './utils';

export const DatePicker = ({
  value: outterValue,
  onChange: onChangeOutter = () => null,
  onChangeMapper = (v) => v,
  error,
  helperText,
  dateOnly,
  monthOnly,
  closeOnMonthSelect,
  withArrows,
  minDate = new Date('1970-01-01'),
  ...props
}: Props) => {
  const dateTime = !dateOnly && !monthOnly;

  const { t } = useTranslation('Validation');
  const [value, setValue] = useState<DateValue>(null);
  const [stateKey, setStateKey] = useState(false);
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const transformDate = useCallback(
    (dateValue: DateValue) => {
      let newValue: string | null = null;

      if (dateValue) {
        newValue = dateTime
          ? dateValue.toISOString()
          : monthOnly
          ? formatMonthString(dateValue)
          : formatDateString(dateValue);
      }

      return onChangeMapper(newValue);
    },
    [dateTime, monthOnly, onChangeMapper],
  );

  const onChange = useCallback(
    (dateValue: DateValue) => {
      setValue(dateValue);

      if (!dateValue || (isValidDate(dateValue) && dateValue >= minDate)) {
        onChangeOutter(transformDate(dateValue));
      }
    },
    [minDate, onChangeOutter, transformDate],
  );

  const prevOutterValue = usePrevious(outterValue);

  const dateValue = useMemo(() => {
    const dateValue = typeof outterValue === 'string' ? new Date(outterValue) : null;
    return dateValue && isValidDate(dateValue) ? dateValue : null;
  }, [outterValue]);

  useEffect(() => {
    if (prevOutterValue !== outterValue) {
      if (dateValue && value !== dateValue) {
        setValue(dateValue);
      }
    }
  }, [value, prevOutterValue, outterValue, dateValue]);

  const Component = useMemo(() => (dateTime ? DateTimePicker : XDatePicker), [dateTime]);

  const prevDateValue = dateValue ? addMonths(dateValue, -1) : null;
  const nextDateValue = dateValue ? addMonths(dateValue, 1) : null;
  const transformedDate = transformDate(dateValue);
  const prevDisabled =
    transformDate(prevDateValue) === transformedDate || !prevDateValue || prevDateValue < minDate;
  const nextDisabled = transformDate(nextDateValue) === transformedDate;

  const invalidDate = useMemo(() => !focused && !isValidDateOptional(value), [focused, value]);

  return (
    <>
      {withArrows && (
        <IconButton onClick={() => onChange(prevDateValue)} disabled={prevDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
      )}
      <Component
        {...{
          ...props,
          key: `${stateKey}`,
          value,
          onChange,
          minDate,
          views: [
            ...(closeOnMonthSelect ? ['month', 'year'] : ['year', 'month']),
            ...(!monthOnly ? ['day'] : []),
            ...(dateTime ? ['hours', 'minutes', 'seconds'] : []),
          ] as CalendarPickerView[],
          onMonthChange: () => {
            if (closeOnMonthSelect && isDialogOpened()) {
              setStateKey((key) => !key);
            }
          },
          renderInput: (params) => (
            <Input
              {...{
                ...params,
                error: invalidDate || error,
                helperText: invalidDate ? t('ValidDate') : helperText,
                onFocus,
                onBlur,
                'data-cy': props['data-cy'],
              }}
            />
          ),
          ...(monthOnly
            ? {
                inputFormat: 'MM/yyyy',
              }
            : {}),
        }}
      />
      {withArrows && (
        <IconButton onClick={() => onChange(nextDateValue)} disabled={nextDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      )}
    </>
  );
};
