export enum TableColumnType {
  Text = 'Text',
  Number = 'Number',
  Boolean = 'Boolean',
  Enum = 'Enum',
  Date = 'Date',
  DateTime = 'DateTime',
  Month = 'Month',
  Component = 'Component',
  ApiEnum = 'ApiEnum',
  Currency = 'Currency',
}

export enum Order {
  asc = 'asc',
  desc = 'desc',
}
