import { useCallback } from 'react';
import { useLogin } from '@/api';
import { Button, Form, InputField, LanguageDropdown } from '@/components';
import { useValidationSchema } from './validation';
import { Box, styled, Typography } from '@mui/material';
import { Route } from '@/enums';
import { useAppContext } from '@/context';
import { useTranslation } from 'react-i18next';
import { removeStyledSmallScreenProp, useSmallScreen } from '@/utils';

const initialValues = { username: '', password: '' };

type FormValues = typeof initialValues;

export const Login = () => {
  const { t } = useTranslation('Login');
  const { updateLoggedUser } = useAppContext();
  const { mutateAsync } = useLogin();
  const validationSchema = useValidationSchema();
  const smallScreen = useSmallScreen();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const loggedUser = await mutateAsync(values);
      updateLoggedUser(loggedUser);
    },
    [mutateAsync, updateLoggedUser],
  );

  return (
    <Form {...{ initialValues, validationSchema, onSubmit, afterSubmitRoute: Route.Dashboard }}>
      {() => (
        <Container {...{ smallScreen }}>
          <Title variant='h1'>{t('Title')}</Title>
          {[
            { name: 'username', label: 'Username' },
            { name: 'password', label: 'Password', type: 'password' },
          ].map((field) => (
            <StyledInputField key={field.name} {...field} label={t(field.label)} />
          ))}
          <StyledButton type='submit' label={t('Submit')} data-cy='form-submit' />
          {smallScreen && <StyledLanguageDropdown />}
        </Container>
      )}
    </Form>
  );
};

const Container = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...(smallScreen
    ? {
        padding: `${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(5)}`,
        width: `calc(100% - ${theme.spacing(4)})`,
        maxWidth: 350,
        margin: '0 auto',
      }
    : {
        padding: theme.spacing(8),
        width: `calc(100% - ${theme.spacing(16)})`,
      }),
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '1.1em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
}));

const StyledInputField = styled(InputField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

const StyledLanguageDropdown = styled(LanguageDropdown)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
