export const CardTransactions = {
  Identifier: 'Identifikátor',
  TransactionProcessingDate: 'Datum transakce',
  Amount: 'Částka',
  Status: 'Stav',
  Worker: 'Pracovník',
  Company: 'Společnost',
  PayMonth: 'Období',
  Region: 'Region',
  CashbackAmount: 'Cashback',
  CashbackPayoutId: 'Id výplaty cashbacku',
  CashbackPayoutDate: 'Cashback vyplacen',
  Debited: 'Inkasováno',
  CardNumber: 'Číslo karty',
};
