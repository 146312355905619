import { useRegionLookupsList } from '@/api';
import { RegistrationType } from '@/api/base/model';
import { CheckboxField, InputField, Grid, FormComponentProps, ApiSelectField } from '@/components';
import { getCurrencySymbol } from '@/utils';
import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useRegistrationTypesList } from './registrationTypes';

const inputs = [
  { name: 'code', label: 'Code', required: true },
  { name: 'name', label: 'Name', required: true, sm: 8, md: 8 },
  { name: 'erpSystem', label: 'ErpSystem' },
  { name: 'documentInfo', label: 'DocumentInfo', multiline: true, rows: 5, sm: 8, md: 8 },
  { name: 'city', label: 'City', required: true, sm: 8, md: 8 },
  {
    name: 'payLimitPercentage',
    label: 'PayLimitPercentage',
    type: 'number',
    required: true,
    sm: 4,
  },
  {
    name: 'payLimitAmount',
    label: 'PayLimitAmount',
    type: 'number',
    required: true,
    sm: 3,
    md: 3,
    getEndAdornment: getCurrencySymbol,
  },
  { name: 'balanceDay', label: 'BalanceDay', type: 'number', required: true, sm: 3, md: 3 },
];

const region = {
  name: 'regionId',
  label: 'Region',
};

const registrationType = {
  name: 'registrationType',
  label: 'RegistrationType',
};

const checkboxes = [
  { name: 'demo', label: 'Demo', md: 3 },
  { name: 'regularAttendance', label: 'RegularAttendance', md: 3 },
  { name: 'paysInExecutionOrInsolvency', label: 'PaysInExecutionOrInsolvency', md: 6, sm: 12 },
  {
    name: 'balanceDayInSameMonth',
    label: 'BalanceDayInSameMonth',
    tooltip: 'BalanceDayInSameMonthTooltip',
    md: 3,
  },
  { name: 'enrolledInCardProgram', label: 'EnrolledInCardProgram', md: 3 },
  { name: 'excludeFromBatch', label: 'ExcludeFromBatch', md: 6, sm: 12 },
];

export const formBasicFields = [...inputs, region, ...checkboxes];

export const FormBasic = ({ disabled, t, values, initialValues }: FormComponentProps) => {
  const tRegions = useMemo(
    () => ({
      ...region,
      label: t(region.label),
      disabled,
      defaultValue: initialValues.regionId,
      useApiList: useRegionLookupsList,
      getOptionLabel: (r: any) => r.code,
      getOptionValue: (r: any) => `${r.id}`,
      selectFirst: true,
    }),
    [t, disabled, initialValues.regionId],
  );

  const tRegistrationTypes = useMemo(
    () => ({
      ...registrationType,
      label: t(registrationType.label),
      disabled,
      defaultValue: RegistrationType.Default,
      useApiList: useRegistrationTypesList,
      getOptionLabel: (r: any) => r.name,
      getOptionValue: (r: any) => `${r.id}`,
      selectFirst: true,
    }),
    [t, disabled],
  );

  return (
    <>
      <TopContainer container spacing={2}>
        {inputs.map(({ sm = 4, md = 4, getEndAdornment = () => undefined, ...field }) => (
          <Grid key={field.name} {...{ item: true, xs: 12, sm, md }}>
            <InputField
              {...{
                ...field,
                label: t(field.label),
                disabled,
                endAdornmentString: getEndAdornment(values.currency),
              }}
            />
          </Grid>
        ))}
        <Grid key={region.name} {...{ item: true, xs: 12, sm: 3 }}>
          <ApiSelectField {...tRegions} />
        </Grid>
        <Grid key={registrationType.name} {...{ item: true, xs: 12, sm: 3 }}>
          <ApiSelectField {...tRegistrationTypes} />
        </Grid>
        <Grid key='ddenab' {...{ item: true, xs: 12, sm: 3 }}>
          <CheckboxField
            name='directDebitsEnabled'
            label='Inkasa povolena'
            tooltipTextol='Inkasa povolena'
            disabled
          />
        </Grid>
        <Grid key='ddlimit' {...{ item: true, xs: 12, sm: 3 }}>
          <InputField
            name='directDebitLimit'
            label='Inkasní limit'
            tooltipTextol='Inkasní limit'
            type='number'
            disabled
            endAdornmentString={getCurrencySymbol(values.currency)}
          />
        </Grid>
        <Grid key='ddacct' {...{ item: true, xs: 12, sm: 3 }}>
          <InputField
            name='directDebitsAccount'
            label='Inkasní účet'
            tooltipTextol='Inkasní účet'
            disabled
          />
        </Grid>
        <Grid key='ddaRec' {...{ item: true, xs: 12, sm: 3 }}>
          <InputField
            name='getDirectDebitNotificationsRecipients'
            label='Příjemci inkas'
            tooltipTextol='Příjemci inkas'
            disabled
          />
        </Grid>
      </TopContainer>
      <Grid container>
        {checkboxes.map(({ sm = 4, md = 6, ...field }) => (
          <Grid key={field.name} {...{ item: true, xs: 12, sm, md }}>
            <CheckboxField
              {...{ ...field, label: t(field.label), tooltipText: t(field.tooltip), disabled }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const TopContainer = styled(Grid)(({ theme }) => ({ marginBottom: theme.spacing(2) }));
