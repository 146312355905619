import { useEffect } from 'react';
import { TableExportProps } from './types';
import { useModule } from './useModule';
import { EXPORT_LIMIT } from '@/constants';

export const TableExport = ({ useApiExport, apiOptions }: TableExportProps) => {
  const { mutateAsync } = useApiExport();
  const { updateModuleOptions } = useModule();

  useEffect(() => {
    updateModuleOptions({
      exportCallback: () => mutateAsync({ ...apiOptions, limit: EXPORT_LIMIT }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateAsync, apiOptions]);

  return <></>;
};
