// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CreatedEntityDtoApiDataResponse, SaveUserDto, UserDetailDtoApiDataResponse, UserDtoListApiDataResponse } from "../model";

export class UsersApi extends APIHandler {
    static urls: APIURLTypes.UsersApi = {
        apiV1AdminUsersAdminGet: "/api/v1/admin/users/admin",
        apiV1AdminUsersAdminXlsxGet: "/api/v1/admin/users/admin/xlsx",
        apiV1AdminUsersIdDelete: "/api/v1/admin/users/{id}",
        apiV1AdminUsersIdGet: "/api/v1/admin/users/{id}",
        apiV1AdminUsersIdPut: "/api/v1/admin/users/{id}",
        apiV1AdminUsersOtherGet: "/api/v1/admin/users/other",
        apiV1AdminUsersOtherXlsxGet: "/api/v1/admin/users/other/xlsx",
        apiV1AdminUsersPost: "/api/v1/admin/users"
    };
    
    constructor() {
        super("UsersApi");
    }
    
    /**
     * Get admin users
     * @param username 
     * @param firstName 
     * @param lastName 
     * @param companyId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminUsersAdminGet(params: APIParams & {
        username?: string;
        firstName?: string;
        lastName?: string;
        companyId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<UserDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersAdminGet, null);
        params.options = this.initOptions(params.options, { username: params.username, firstName: params.firstName, lastName: params.lastName, companyId: params.companyId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<UserDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminUsersAdminGet", params.options, body, "apiV1AdminUsersAdminGet");
    }

    /**
     * Get admin user export in XLSX format.
     * @param username 
     * @param firstName 
     * @param lastName 
     * @param companyId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminUsersAdminXlsxGet(params: APIParams & {
        username?: string;
        firstName?: string;
        lastName?: string;
        companyId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersAdminXlsxGet, null);
        params.options = this.initOptions(params.options, { username: params.username, firstName: params.firstName, lastName: params.lastName, companyId: params.companyId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminUsersAdminXlsxGet", params.options, body, "apiV1AdminUsersAdminXlsxGet");
    }

    /**
     * Delete user
     * @param id 
     */
    public apiV1AdminUsersIdDelete(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersIdDelete, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("DELETE".toLowerCase(), apiURL, "apiV1AdminUsersIdDelete", params.options, body, "apiV1AdminUsersIdDelete");
    }

    /**
     * Get user detail
     * @param id 
     */
    public apiV1AdminUsersIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<UserDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<UserDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminUsersIdGet", params.options, body, "apiV1AdminUsersIdGet");
    }

    /**
     * Update user
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **USERNAME_TAKEN** - User with provided email already exists. + **COMPANY_INVALID** - Company is invalid. + **COMPANY_ADMIN_MUST_HAVE_COMPANY_ASSIGNED** - Company admin must have company assigned 
     * @param id 
     * @param saveUserDto 
     */
    public apiV1AdminUsersIdPut(params: APIParams & {
        id: number;
        saveUserDto?: SaveUserDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveUserDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminUsersIdPut", params.options, body, "apiV1AdminUsersIdPut");
    }

    /**
     * Get other users
     * @param username 
     * @param firstName 
     * @param lastName 
     * @param companyId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminUsersOtherGet(params: APIParams & {
        username?: string;
        firstName?: string;
        lastName?: string;
        companyId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<UserDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersOtherGet, null);
        params.options = this.initOptions(params.options, { username: params.username, firstName: params.firstName, lastName: params.lastName, companyId: params.companyId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<UserDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminUsersOtherGet", params.options, body, "apiV1AdminUsersOtherGet");
    }

    /**
     * Get other user export in XLSX format.
     * @param username 
     * @param firstName 
     * @param lastName 
     * @param companyId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminUsersOtherXlsxGet(params: APIParams & {
        username?: string;
        firstName?: string;
        lastName?: string;
        companyId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersOtherXlsxGet, null);
        params.options = this.initOptions(params.options, { username: params.username, firstName: params.firstName, lastName: params.lastName, companyId: params.companyId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminUsersOtherXlsxGet", params.options, body, "apiV1AdminUsersOtherXlsxGet");
    }

    /**
     * Create user
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **USERNAME_TAKEN** - User with provided email already exists. + **USER_HAS_NO_ROLES** - Admin user needs to have role assigned. + **COMPANY_INVALID** - Company is invalid. + **COMPANY_ADMIN_MUST_HAVE_COMPANY_ASSIGNED** - Company admin must have company assigned 
     * @param saveUserDto 
     */
    public apiV1AdminUsersPost(params: APIParams & {
        saveUserDto?: SaveUserDto;
    } = {}): AxiosPromise<CreatedEntityDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(UsersApi.urls.apiV1AdminUsersPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveUserDto;
        return this.makeCall<CreatedEntityDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminUsersPost", params.options, body, "apiV1AdminUsersPost");
    }

}
