import { Grid as MuiGrid, styled } from '@mui/material';
import { Props } from './types';

export const Grid = (props: Props) => {
  return <StyledGrid {...{ ...(props.container ? { spacing: 3 } : {}), ...props }} />;
};

const StyledGrid = styled(MuiGrid)(({ item }) =>
  item ? { display: 'flex', alignItems: 'center' } : {},
);
