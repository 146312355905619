// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { TransactionHistoryResponse } from './transaction-history-response';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class TransactionHistoryResponseQueryResult extends APIModel {
    totalItems: number;
    offset: number;
    limit: number;
    items: Array<TransactionHistoryResponse>;

    constructor(options?: APIModelOptions) {
        super(TransactionHistoryResponseQueryResult.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "totalItems", type: "number" },
            { name: "offset", type: "number" },
            { name: "limit", type: "number" },
            { name: "items", type: "Array<TransactionHistoryResponse>" }
        ];
    }
}




