export const Routes = {
  Dashboard: 'Dashboard',
  Workers: 'Pracovníci',
  AttendanceCurrent: 'Dochádzka aktuálna',
  AttendanceAll: 'Dochádzka celkovo',
  AbsenceCurrent: 'Neprítomnosť aktuálna',
  AbsenceAll: 'Neprítomnosť celkovo',
  Payments: 'Žiadosti',
  PaymentsDemo: 'Demo žiadosti',
  PaymentsVerifiedCzech: 'Verifikované žiadosti v ČR',
  PaymentsVerifiedSlovak: 'Verifikované žiadosti v SR',
  Messages: 'Správy',
  Actions: 'Akcie',
  Images: 'Obrázky',
  Answers: 'Odpovede',
  Companies: 'Spoločnosti',
  Themes: 'Témy',
  Connectors: 'Konektory',
  Balances: 'Uzávierky',
  Users: 'Používatelia',
  AdminUsers: 'Admin používatelia',
  OtherUsers: 'Používatelia aplikacie',
  Roles: 'Úlohy',
  UserRoles: 'Používateľské úlohy',
  UserViews: 'Používateľské pohľady',
  Helper: 'Pomocník',
  LogToday: 'Log dnes',
  LogFull: 'Log všetko',
  ActiveUser: 'Aktívny používateľ',
  Help: 'Nápoveda',
  News: 'Novinky',
  PasswordChange: 'Zmena hesla',
  Setting: 'Nastavenia',
  Regions: 'Regióny',
  DirectDebits: 'Inkasá',
  BankAuthorizations: 'Potvrzování CZ plateb',
  Cards: 'Karty',
  CardTransactions: 'Karetní transakce',
};
