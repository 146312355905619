export const Regions = {
  Id: 'Identifier',
  Region: 'Region',
  Name: 'Name',
  Code: 'Code',
  MinimalNetSalary: 'Minimal net salary',
  DirectDebitLimit: 'Direct debit limit',
  Delete: 'Delete',
  DeleteSuccess: 'Region deleted',
  DeleteConfirmText: 'Are you sure you want to delete region?',
  HunterReferralBonus: 'Referral bonus - Hunter',
  TargetReferralBonus: 'Referral bonus - Target',
};
