export const Validation = {
  Required: 'Required',
  MinLength: 'Must have at least {{value}} characters',
  MaxLength: 'Must have no more than {{value}} characters',
  Length: 'Must have {{value}} characters',
  MinValue: 'Must be greater than or equal to {{value}}',
  MaxValue: 'Must be lower than or equal to {{value}}',
  CurrencyValue: 'Must have no more than {{value}} digits after decimal point',
  ValidDate: 'Must be valid date',
  PasswordsNotSame: 'Passwords are not same',
  BonusToIsFilled: "Must not be empty when 'Event duration to' is filled",
  BonusToGreaterThanBonusFrom: "Must be later than 'Event duration from'",
  BonusFromAndToFilled:
    "Must be greater than 0 when 'Event duration from' and 'Event duration to' are filled",
  DateValidToGreaterThanDateValidFrom: "Must be same or later than 'Valid from'",
  DateValidFromGreaterThanToday: 'Must be later than today',
  CompanyAdmin: 'Company admin must have a company assigned',
};
