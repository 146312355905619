import {
  Button,
  Grid,
  FormComponentProps,
  List,
  Modal,
  Form,
  InputField,
  SelectField,
  CheckboxField,
} from '@/components';
import { phoneHeight, Preview } from './Preview';
import { Box, styled, Typography } from '@mui/material';
import { removeStyledSmallScreenProp, useSmallScreen } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { EditableAction } from './types';
import { compareActions, newActionId } from './utils';
import { useFormikContext } from 'formik';
import { ActionColor } from './enums';
import { Delete } from '@mui/icons-material';

export const FormActions = ({ disabled, values, t }: FormComponentProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Preview {...{ values, component: <Actions {...{ values, disabled, t }} /> }} />
      </Grid>
    </Grid>
  );
};

const Actions = ({
  values,
  disabled,
  t,
}: Pick<FormComponentProps, 'values' | 'disabled' | 't'>) => {
  const actions = values.messageActions as EditableAction[];
  const smallScreen = useSmallScreen();
  const { setFieldValue } = useFormikContext();
  const [currentAction, setCurrentAction] = useState<EditableAction>();

  const closeForm = () => setCurrentAction(undefined);

  const updateActions = useCallback(
    (actions: EditableAction[]) => {
      setFieldValue('messageActions', actions);
    },
    [setFieldValue],
  );

  const onActionEdit = useCallback(
    (action: EditableAction) => {
      updateActions(
        actions.find((a) => compareActions(a, action))
          ? actions.map((a) => (compareActions(a, action) ? action : a))
          : [...actions, action],
      );
      closeForm();
    },
    [actions, updateActions],
  );

  return (
    <Container {...{ smallScreen }}>
      <Toolbar {...{ smallScreen }}>
        <Button
          {...{
            label: t('AddButton'),
            disabled,
            onClick: () =>
              setCurrentAction({
                newId: newActionId(),
                color: 'primary',
              }),
            'data-cy': 'add-button',
          }}
        />
      </Toolbar>
      {actions && actions.length ? (
        <Box>
          <List
            {...{
              items: actions,
              getId: (item) => item.id ?? item.newId,
              getLabel: (item) => `${item.caption} (${item.name})`,
              onClick: setCurrentAction,
              action: {
                Icon: Delete,
                onClick: (item) => updateActions(actions.filter((a) => !compareActions(a, item))),
              },
            }}
          />
        </Box>
      ) : (
        <NoActionsText>{t('NoActionsText')}</NoActionsText>
      )}
      <ActionForm {...{ disabled, data: currentAction, onChange: onActionEdit, closeForm }} />
    </Container>
  );
};

const ActionForm = ({
  disabled,
  data,
  onChange,
  closeForm,
}: {
  disabled: boolean;
  data: EditableAction | undefined;
  onChange: (actions: EditableAction) => void;
  closeForm: () => void;
}) => {
  const { t } = useTranslation(['Messages', 'Form']);
  const smallScreen = useSmallScreen();

  return data ? (
    <Modal {...{ open: true, maxWidth: 'lg', onClick: (e) => e.stopPropagation() }}>
      <ActionModalContent {...{ smallScreen }}>
        <ActionModalTitle>{t('Button')}</ActionModalTitle>
        <Form withLoading={false} initialValues={data} onSubmit={onChange}>
          {() => (
            <>
              <Grid container>
                {[
                  { name: 'name', label: 'Name', sm: 6 },
                  { name: 'type', label: 'Type', sm: 6 },
                  { name: 'caption', label: 'ButtonLabel' },
                  { name: 'url', label: 'Url' },
                  { name: 'editValues', label: 'EditValues' },
                ].map(({ sm = 12, ...field }) => (
                  <Grid key={field.name} {...{ item: true, xs: 12, sm }}>
                    <InputField
                      {...{
                        ...field,
                        label: t(field.label),
                        disabled,
                        required: true,
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={6}>
                  <SelectField
                    {...{
                      name: 'color',
                      label: t('Color'),
                      disabled,
                      required: true,
                      items: Object.values(ActionColor).map((id) => ({ id, name: id })),
                    }}
                  />
                </Grid>
                <Grid {...{ item: true, xs: 12, sm: 6 }}>
                  <CheckboxField {...{ name: 'fullWidth', label: t('FullWidth'), disabled }} />
                </Grid>
              </Grid>
              <ActionFormButtons>
                {!disabled && (
                  <ActionFormButton
                    {...{
                      type: 'submit',
                      label: t('Save', { ns: 'Form' }),
                      'data-cy': 'message-action-submit',
                    }}
                  />
                )}
                <ActionFormButton
                  color='inherit'
                  label={t('Close', { ns: 'Form' })}
                  onClick={closeForm}
                />
              </ActionFormButtons>
            </>
          )}
        </Form>
      </ActionModalContent>
    </Modal>
  ) : (
    <></>
  );
};

const Container = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ smallScreen }) => ({
  overflowY: 'auto',
  ...(!smallScreen ? { height: phoneHeight, minHeight: phoneHeight, maxHeight: phoneHeight } : {}),
}));

const Toolbar = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  ...(smallScreen ? { justifyContent: 'center' } : {}),
}));

const NoActionsText = styled(Typography)({
  width: '100%',
  textAlign: 'center',
});

const ActionModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textTransform: 'uppercase',
}));

const ActionModalContent = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  width: `calc(100% - ${theme.spacing(smallScreen ? 2 : 8)})`,
  padding: theme.spacing(smallScreen ? 1 : 4),
}));

const ActionFormButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}));

const ActionFormButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));
