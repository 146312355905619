// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DirectDebitStatus } from './direct-debit-status';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class DirectDebitDto extends APIModel {
    /**
    * Direct debit identifier
    */
    id: number;
    /**
    * Code of company
    */
    companyCode: string;
    /**
    * Name of company
    */
    companyName: string;
    /**
    * Amount of direct debit
    */
    amount: number;
    /**
    * Currency
    */
    currency: string;
    /**
    * Indicate date when report was generated.
    */
    created: string;
    status: DirectDebitStatus;
    /**
    * The accounting period for which the debit is generated
    */
    payMonth: string;
    regionCode: string;

    constructor(options?: APIModelOptions) {
        super(DirectDebitDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "companyCode", type: "string" },
            { name: "companyName", type: "string" },
            { name: "amount", type: "number" },
            { name: "currency", type: "string" },
            { name: "created", type: "string" },
            { name: "status", type: "DirectDebitStatus" },
            { name: "payMonth", type: "string" },
            { name: "regionCode", type: "string" }
        ];
    }
}




