export const Routes = {
  Dashboard: 'Dashboard',
  Workers: 'Pracovníci',
  AttendanceCurrent: 'Docházka aktuální',
  AttendanceAll: 'Docházka vše',
  AbsenceCurrent: 'Nepřítomnost aktuální',
  AbsenceAll: 'Nepřítomnost vše',
  Payments: 'Žádosti',
  PaymentsDemo: 'Demo žádosti',
  PaymentsVerifiedCzech: 'Verifikované žádosti v ČR',
  PaymentsVerifiedSlovak: 'Verifikované žádosti v SR',
  Messages: 'Zprávy',
  Actions: 'Akce',
  Images: 'Obrázky',
  Answers: 'Odpovědi',
  Companies: 'Společnosti',
  Themes: 'Témata',
  Connectors: 'Konektory',
  Balances: 'Závěrky',
  Users: 'Uživatelé',
  AdminUsers: 'Admin uživatelé',
  OtherUsers: 'Uživatelé aplikace',
  Roles: 'Role',
  UserRoles: 'Uživatelské role',
  UserViews: 'Uživatelské pohledy',
  Helper: 'Pomocník',
  LogToday: 'Log dnes',
  LogFull: 'Log vše',
  ActiveUser: 'Aktivní uživatel',
  Help: 'Nápověda',
  News: 'Novinky',
  PasswordChange: 'Změna hesla',
  Setting: 'Nastavení',
  Regions: 'Regiony',
  DirectDebits: 'Inkasa',
  BankAuthorizations: 'Potvrzování CZ plateb',
  Cards: 'Karty',
  CardTransactions: 'Karetní transakce',
};
