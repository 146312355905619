import { imagePublicUrlPath } from '@/api';
import { escapeRegExp } from 'lodash';
import { ActionColor, actionColors } from './enums';
import { EditableAction } from './types';

export const getActionColor = (name: string) => {
  const color = actionColors[name as ActionColor];

  return color ?? actionColors[ActionColor.Primary];
};

export const compareActions = (action1: EditableAction, action2: EditableAction) =>
  (action1.id && action2.id && action1.id === action2.id) ||
  (action1.newId && action2.newId && action1.newId === action2.newId);

export const newActionId = () => new Date().getTime();

export const prepareMarkdownPreview = (markdownData: string | null | undefined) =>
  markdownData
    ? markdownData.replaceAll(
        new RegExp(`${escapeRegExp(`${imagePublicUrlPath}/`).replaceAll('/', '\\/')}\\d+`, 'g'),
        (value) => `${value}?t=${new Date().getTime()}`,
      )
    : '';
