import { Select, SelectProps } from '../../Select';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { SelectItem } from '@/components';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';

export const SelectComponent = (props: SelectProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: SelectItem) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <Select {...{ ...props, ...field, onChange: handleChange }} />;
};

export const SelectField = (fieldProps: FormFieldProps<Omit<SelectProps, 'value'>>) => {
  const restProps = splitProps(fieldProps, [
    'className',
    'disabled',
    'items',
    'getOptionValue',
    'getOptionLabel',
  ]) as SelectProps;

  return (
    <Field {...fieldProps}>
      <SelectComponent {...restProps} />
    </Field>
  );
};
