import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { Modal } from '../Modal';
import { Props } from './types';
import { Close, Edit } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from '../Snackbar';
import { removeStyledProps } from '@/utils';

export const ImageUpload = ({ value, type, disabled, onChange }: Props) => {
  const { t } = useTranslation('Form');
  const { showErrorSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const showFailSnackbar = useCallback(
    () => showErrorSnackbar(t('FailedToUploadImage')),
    [showErrorSnackbar, t],
  );

  const toBase64 = useCallback(
    async (file: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const { result } = reader;
          if (result) {
            resolve((result as string).replace(/[^,]+,/, ''));
          } else {
            showFailSnackbar();
            reject();
          }
        };
        reader.onerror = (error) => {
          showFailSnackbar();
          reject(error);
        };
      }) as Promise<string>,
    [showFailSnackbar],
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
    },
    onDrop: async (files) => {
      const file = files[0];
      const data = await toBase64(file);
      onChange(data, file.name, file.type);
      onClose();
    },
  });

  return (
    <>
      <Container>
        <Image>
          <img src={`data:${type};base64,${value}`} />
          <EditIcon {...{ disabled, onClick: () => !disabled && onOpen() }} />
        </Image>
      </Container>
      <Modal {...{ open, maxWidth: 'md', onClick: (e) => e.stopPropagation() }}>
        <Content>
          <Toolbar>
            <Typography variant='h6'>{t('UploadImage')}</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Toolbar>
          <Dropzone {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{t('UploadImageDropzone')}</p>
          </Dropzone>
        </Content>
      </Modal>
    </>
  );
};

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Image = styled(Box)({
  position: 'relative',
  width: 'fit-content',
  img: {
    maxHeight: 300,
    maxWidth: '100%',
  },
});

const EditIcon = styled(
  Edit,
  removeStyledProps('disabled'),
)<{ disabled?: boolean }>(({ disabled }) => ({
  position: 'absolute',
  bottom: -20,
  right: 0,
  ...(!disabled ? { cursor: 'pointer' } : {}),
}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const Toolbar = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

const Dropzone = styled(Box)(({ theme }) => ({
  height: 200,
  width: '100%',
  backgroundColor: theme.palette.grey[100],
  border: `2px dashed ${theme.palette.grey[500]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));
