import React, { useCallback, useMemo, useState } from 'react';
import { LoadingContext as TLoadingContext } from './types';
import { LoadingContext } from './LoadingContext';
import { Spinner } from './Spinner';

export const LoadingProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState(false);

  const showLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const hideLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const context: TLoadingContext = useMemo(
    () => ({ loading, showLoading, hideLoading }),
    [loading, showLoading, hideLoading],
  );

  return (
    <LoadingContext.Provider value={context}>
      {children}
      <Spinner {...{ fullScreen: true, visible: loading }} />
    </LoadingContext.Provider>
  );
};
