// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CardStatus } from './card-status';
import { ReferralBonusDetails } from './referral-bonus-details';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class WorkerOverviewDto extends APIModel {
    /**
    * First name of worker
    */
    firstName: string;
    /**
    * Last name of worker
    */
    lastName: string;
    /**
    * Bank account consists of account number and bank code
    */
    bankAccount: string;
    /**
    * Already withdrawn money
    */
    withdrawRealized: number;
    /**
    * Available money for withdrawal
    */
    withdrawAvailable: number;
    /**
    * Current month
    */
    currentMonth: string;
    /**
    * Code of worker
    */
    code: string;
    /**
    * Worker referral code
    */
    referralCode: string;
    /**
    * True if worker is active
    */
    active: boolean;
    /**
    * Name of company
    */
    companyName: string;
    /**
    * Code of company
    */
    companyCode: string;
    /**
    * Company currency code using ISO 4217
    */
    companyCurrency: string;
    /**
    * Company country code using ISO 3166
    */
    companyLegislation: string;
    /**
    * Balance day of company
    */
    balanceDay: number;
    /**
    * First withdrawal fee for company
    */
    firstCompanyFee: number;
    /**
    * First withdrawal fee for worker
    */
    firstWorkerFee: number;
    /**
    * Second withdrawal fee for company
    */
    secondCompanyFee: number;
    /**
    * Second withdrawal fee for worker
    */
    secondWorkerFee: number;
    /**
    * Third withdrawal fee for company
    */
    thirdCompanyFee: number;
    /**
    * Third withdrawal fee for worker
    */
    thirdWorkerFee: number;
    /**
    * Fourth withdrawal fee for company
    */
    fourthPlusCompanyFee: number;
    /**
    * Fourth withdrawal fee for worker
    */
    fourthPlusWorkerFee: number;
    paymentCardCompanyFee: number;
    paymentCardWorkerFee: number;
    /**
    * Number of unread messages
    */
    unreadMessagesCount: number;
    /**
    * Id of the newest unread message
    */
    newestUnreadMessageId: number;
    /**
    * Preview of the newest unread message
    */
    newestUnreadMessagePreview: string;
    /**
    * Sequence of next payment in current month
    */
    nextMonthPaymentSequence: number;
    /**
    * Bonus amount
    */
    bonus: number;
    /**
    * Localized bonus message
    */
    bonusMessage: string;
    /**
    * Indicates that company is participating in referral program and registration step 2 can have referral code.
    */
    enrolledInReferralProgram: boolean;
    /**
    * Referral bonus for worker
    */
    referralBonus: number;
    referral: ReferralBonusDetails;
    /**
    * Indicates that payroll consolidation is in process.
    */
    payrollConsolidationInProcess: boolean;
    /**
    * Indicates if worker already made a payment.
    */
    isNew: boolean;
    paymentCardStatus: CardStatus;

    constructor(options?: APIModelOptions) {
        super(WorkerOverviewDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "bankAccount", type: "string" },
            { name: "withdrawRealized", type: "number" },
            { name: "withdrawAvailable", type: "number" },
            { name: "currentMonth", type: "string" },
            { name: "code", type: "string" },
            { name: "referralCode", type: "string" },
            { name: "active", type: "boolean" },
            { name: "companyName", type: "string" },
            { name: "companyCode", type: "string" },
            { name: "companyCurrency", type: "string" },
            { name: "companyLegislation", type: "string" },
            { name: "balanceDay", type: "number" },
            { name: "firstCompanyFee", type: "number" },
            { name: "firstWorkerFee", type: "number" },
            { name: "secondCompanyFee", type: "number" },
            { name: "secondWorkerFee", type: "number" },
            { name: "thirdCompanyFee", type: "number" },
            { name: "thirdWorkerFee", type: "number" },
            { name: "fourthPlusCompanyFee", type: "number" },
            { name: "fourthPlusWorkerFee", type: "number" },
            { name: "paymentCardCompanyFee", type: "number" },
            { name: "paymentCardWorkerFee", type: "number" },
            { name: "unreadMessagesCount", type: "number" },
            { name: "newestUnreadMessageId", type: "number" },
            { name: "newestUnreadMessagePreview", type: "string" },
            { name: "nextMonthPaymentSequence", type: "number" },
            { name: "bonus", type: "number" },
            { name: "bonusMessage", type: "string" },
            { name: "enrolledInReferralProgram", type: "boolean" },
            { name: "referralBonus", type: "number" },
            { name: "referral", type: "ReferralBonusDetails" },
            { name: "payrollConsolidationInProcess", type: "boolean" },
            { name: "isNew", type: "boolean" },
            { name: "paymentCardStatus", type: "CardStatus" }
        ];
    }
}




