// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, ConnectorDetailDtoApiDataResponse, ConnectorDtoListApiDataResponse, CreatedEntityDtoApiDataResponse, SaveConnectorDto } from "../model";

export class ConnectorsApi extends APIHandler {
    static urls: APIURLTypes.ConnectorsApi = {
        apiV1AdminConnectorsGet: "/api/v1/admin/connectors",
        apiV1AdminConnectorsIdGet: "/api/v1/admin/connectors/{id}",
        apiV1AdminConnectorsIdPut: "/api/v1/admin/connectors/{id}",
        apiV1AdminConnectorsPost: "/api/v1/admin/connectors",
        apiV1AdminConnectorsXlsxGet: "/api/v1/admin/connectors/xlsx"
    };
    
    constructor() {
        super("ConnectorsApi");
    }
    
    /**
     * Get connectors
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminConnectorsGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<ConnectorDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(ConnectorsApi.urls.apiV1AdminConnectorsGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ConnectorDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminConnectorsGet", params.options, body, "apiV1AdminConnectorsGet");
    }

    /**
     * Get connector detail
     * @param id 
     */
    public apiV1AdminConnectorsIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<ConnectorDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(ConnectorsApi.urls.apiV1AdminConnectorsIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ConnectorDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminConnectorsIdGet", params.options, body, "apiV1AdminConnectorsIdGet");
    }

    /**
     * Update connector
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param id 
     * @param saveConnectorDto 
     */
    public apiV1AdminConnectorsIdPut(params: APIParams & {
        id: number;
        saveConnectorDto?: SaveConnectorDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(ConnectorsApi.urls.apiV1AdminConnectorsIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveConnectorDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminConnectorsIdPut", params.options, body, "apiV1AdminConnectorsIdPut");
    }

    /**
     * Create connector
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param saveConnectorDto 
     */
    public apiV1AdminConnectorsPost(params: APIParams & {
        saveConnectorDto?: SaveConnectorDto;
    } = {}): AxiosPromise<CreatedEntityDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(ConnectorsApi.urls.apiV1AdminConnectorsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveConnectorDto;
        return this.makeCall<CreatedEntityDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminConnectorsPost", params.options, body, "apiV1AdminConnectorsPost");
    }

    /**
     * Get connector export in XLSX format.
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminConnectorsXlsxGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(ConnectorsApi.urls.apiV1AdminConnectorsXlsxGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminConnectorsXlsxGet", params.options, body, "apiV1AdminConnectorsXlsxGet");
    }

}
