// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, DirectDebitDtoApiDataResponse, DirectDebitDtoListApiDataResponse, DirectDebitStatus } from "../model";

export class DirectDebitsApi extends APIHandler {
    static urls: APIURLTypes.DirectDebitsApi = {
        apiV1AdminDirectDebitsGet: "/api/v1/admin/direct-debits",
        apiV1AdminDirectDebitsIdGet: "/api/v1/admin/direct-debits/{id}",
        apiV1AdminDirectDebitsIdPerformPost: "/api/v1/admin/direct-debits/{id}/perform",
        apiV1AdminDirectDebitsIdRequestPost: "/api/v1/admin/direct-debits/{id}/request",
        apiV1AdminDirectDebitsIdXlsxPaymentsGet: "/api/v1/admin/direct-debits/{id}/xlsx-payments",
        apiV1AdminDirectDebitsRequestSkPost: "/api/v1/admin/direct-debits/request/sk"
    };
    
    constructor() {
        super("DirectDebitsApi");
    }
    
    /**
     * Get direct debits list
     * @param companyId 
     * @param status 
     * @param amountFrom 
     * @param amountTo 
     * @param createdFrom 
     * @param createdTo 
     * @param payMonthFrom 
     * @param payMonthTo 
     * @param regionId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminDirectDebitsGet(params: APIParams & {
        companyId?: number;
        status?: DirectDebitStatus;
        amountFrom?: number;
        amountTo?: number;
        createdFrom?: string;
        createdTo?: string;
        payMonthFrom?: string;
        payMonthTo?: string;
        regionId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<DirectDebitDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(DirectDebitsApi.urls.apiV1AdminDirectDebitsGet, null);
        params.options = this.initOptions(params.options, { companyId: params.companyId, status: params.status, amountFrom: params.amountFrom, amountTo: params.amountTo, createdFrom: params.createdFrom, createdTo: params.createdTo, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, regionId: params.regionId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<DirectDebitDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminDirectDebitsGet", params.options, body, "apiV1AdminDirectDebitsGet");
    }

    /**
     * Get direct debit detail
     * @param id 
     */
    public apiV1AdminDirectDebitsIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<DirectDebitDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(DirectDebitsApi.urls.apiV1AdminDirectDebitsIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<DirectDebitDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminDirectDebitsIdGet", params.options, body, "apiV1AdminDirectDebitsIdGet");
    }

    /**
     * Perform direct debit
     * @param id 
     */
    public apiV1AdminDirectDebitsIdPerformPost(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(DirectDebitsApi.urls.apiV1AdminDirectDebitsIdPerformPost, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminDirectDebitsIdPerformPost", params.options, body, "apiV1AdminDirectDebitsIdPerformPost");
    }

    /**
     * Request direct debit
     * @param id 
     */
    public apiV1AdminDirectDebitsIdRequestPost(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(DirectDebitsApi.urls.apiV1AdminDirectDebitsIdRequestPost, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminDirectDebitsIdRequestPost", params.options, body, "apiV1AdminDirectDebitsIdRequestPost");
    }

    /**
     * Get direct debit payments export in XLSX format.
     * @param id 
     */
    public apiV1AdminDirectDebitsIdXlsxPaymentsGet(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(DirectDebitsApi.urls.apiV1AdminDirectDebitsIdXlsxPaymentsGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminDirectDebitsIdXlsxPaymentsGet", params.options, body, "apiV1AdminDirectDebitsIdXlsxPaymentsGet");
    }

    /**
     * Generates a SEPA direct debit mandate file for all pending SK debits and marks them as requested.
     */
    public apiV1AdminDirectDebitsRequestSkPost(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(DirectDebitsApi.urls.apiV1AdminDirectDebitsRequestSkPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminDirectDebitsRequestSkPost", params.options, body, "apiV1AdminDirectDebitsRequestSkPost");
    }

}
