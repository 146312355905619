import {
  useMessageCreate,
  useMessageGet,
  useMessageList,
  useMessageExport,
  useMessageUpdate,
  useMessageCreateBulk,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { useAppContext } from '@/context';
import { FormActions } from './FormActions';
import { FormBasic, formBasicFields } from './FormBasic';
import { FormBasicBulk, formBasicFieldsBulk } from './FormBasicBulk';
import { FormContent, formContentFields } from './FormContent';
import { useValidationSchema } from './validation';
import { useValidationSchemaBulk } from './validationBulk';

export const Messages = () => {
  const { companyId } = useAppContext();

  return (
    <Module
      {...{
        apiKey: ApiKey.Messages,
        translationKey: 'Messages',
        useApiList: useMessageList,
        useApiGet: useMessageGet,
        useApiCreate: useMessageCreate,
        useApiCreateBulk: useMessageCreateBulk,
        useApiUpdate: useMessageUpdate,
        useApiExport: useMessageExport,
        useValidationSchema,
        useValidationSchemaBulk,
        formComponentHeight: 630,
        columns: [
          { dataKey: 'id', label: 'Id', type: TableColumnType.Number },
          { dataKey: 'created', label: 'Created', type: TableColumnType.Date },
          { dataKey: 'dateValidFrom', label: 'DateValidFrom', type: TableColumnType.Date },
          { dataKey: 'dateValidTo', label: 'DateValidTo', type: TableColumnType.Date },
          { dataKey: 'caption', label: 'Caption' },
          { dataKey: 'type', label: 'Type' },
          { dataKey: 'preview', label: 'Preview' },
          { dataKey: 'companyName', label: 'Company', visible: !companyId },
          { dataKey: 'companyCode', label: 'CompanyCode', visible: !companyId },
        ],
        initialValues: {
          caption: '',
          type: '',
          preview: '',
          markdownData: '',
          dateValidFrom: null,
          dateValidTo: null,
          company: companyId
            ? {
                id: companyId,
              }
            : null,
          messageActions: [],
        },
        initialValuesBulk: {
          caption: '',
          type: '',
          preview: '',
          markdownData: '',
          dateValidFrom: null,
          dateValidTo: null,
          company: companyId
            ? [
                {
                  id: companyId,
                },
              ]
            : [],
          messageActions: [],
          nps: true,
        },
        formComponents: [
          {
            label: 'Message',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'Content',
            component: FormContent,
            fields: formContentFields,
            smallScreenNoPadding: true,
          },
          {
            label: 'Buttons',
            component: FormActions,
            smallScreenNoPadding: true,
          },
        ],
        formComponentsBulk: [
          {
            label: 'Message',
            component: FormBasicBulk,
            fields: formBasicFieldsBulk,
          },
          {
            label: 'Content',
            component: FormContent,
            fields: formContentFields,
            smallScreenNoPadding: true,
          },
          {
            label: 'Buttons',
            component: FormActions,
            smallScreenNoPadding: true,
          },
        ],
      }}
    />
  );
};
