export const Regions = {
  Region: 'Región',
  Name: 'Názov',
  Code: 'Kód',
  MinimalNetSalary: 'Minimálna čístá mzda',
  DirectDebitLimit: 'Limit inkasa',
  Delete: 'Vymazať',
  DeleteSuccess: 'Region vymazaný',
  DeleteConfirmText: 'Naozaj chcete vymazať región?',
  HunterReferralBonus: 'Referal bonus - Lovec',
  TargetReferralBonus: 'Referal bonus - Cieľ',
};
