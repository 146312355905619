// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, SettingDto, SettingDtoApiDataResponse } from "../model";

export class SettingApi extends APIHandler {
    static urls: APIURLTypes.SettingApi = {
        apiV1AdminSettingGet: "/api/v1/admin/setting",
        apiV1AdminSettingPut: "/api/v1/admin/setting"
    };
    
    constructor() {
        super("SettingApi");
    }
    
    /**
     * Get setting detail
     */
    public apiV1AdminSettingGet(params: APIParams & {
        
    } = {}): AxiosPromise<SettingDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(SettingApi.urls.apiV1AdminSettingGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<SettingDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminSettingGet", params.options, body, "apiV1AdminSettingGet");
    }

    /**
     * Update setting
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. 
     * @param settingDto 
     */
    public apiV1AdminSettingPut(params: APIParams & {
        settingDto?: SettingDto;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(SettingApi.urls.apiV1AdminSettingPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.settingDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminSettingPut", params.options, body, "apiV1AdminSettingPut");
    }

}
