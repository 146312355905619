import { ApiListOptions, useSnackbar } from '@/components';
import { useQuery, useMutation } from 'react-query';
import { ApiKey } from '@/enums';
import { PaymentsApi } from '../base';
import { params, fileParams, downloadFileFromResponse } from '../utils';
import { RegionEnum } from '../base/model/region-enum';
import { ReconcilliationRequestModel } from '../base/model';
import { ReconciliatePaymentsParams } from '@/components/Module/TableVerifiedConnect';
import { useTranslation } from 'react-i18next';

const api = new PaymentsApi();

interface UsePaymentListOptions extends ApiListOptions {
  demo?: boolean;
}

const usePaymentList = (options: UsePaymentListOptions) => {
  return useQuery([ApiKey.Payments, options], async () => {
    const response = await api.apiV1AdminPaymentsGet(params(options));

    return response.data;
  });
};

export const useNonDemoPaymentList = (options: ApiListOptions) => {
  return usePaymentList({ ...options, demo: false });
};

export const useDemoPaymentList = (options: ApiListOptions) => {
  return usePaymentList({ ...options, demo: true });
};

export const useVerifiedCzechPaymentList = (options: ApiListOptions) => {
  const options2 = { ...options, regionId: RegionEnum.CzechRepublic };
  return useQuery([ApiKey.PaymentsVerified, options2], async () => {
    const response = await api.apiV1AdminPaymentsVerifiedRegionIdGet(params(options2));

    return response.data;
  });
};

export const useVerifiedSlovakPaymentList = (options: ApiListOptions) => {
  const options2 = { ...options, regionId: RegionEnum.Slovakia };
  return useQuery([ApiKey.PaymentsVerified, options2], async () => {
    const response = await api.apiV1AdminPaymentsVerifiedRegionIdGet(params(options2));

    return response.data;
  });
};

const usePaymentExport = (demo: boolean) => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminPaymentsXlsxGet(fileParams({ ...options, demo }));
    downloadFileFromResponse(response);
  });
};

export const useNonDemoPaymentExport = () => {
  return usePaymentExport(false);
};

export const useDemoPaymentExport = () => {
  return usePaymentExport(true);
};

export const useVerifiedPaymentSlovakExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminPaymentsSlovakiaBatchExportGet(fileParams({ ...options }));
    downloadFileFromResponse(response);
  });
};

export const useVerifiedPaymentCzechExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminPaymentsCzechRepublicBatchExportGet(
      fileParams({ ...options }),
    );
    downloadFileFromResponse(response);
  });
};

export const usePaymentGet = (id: number) => {
  return useQuery([ApiKey.Payments, id], async () => {
    const response = await api.apiV1AdminPaymentsIdGet(params({ id }));

    return response.data.data;
  });
};

export const usePaymentProcess = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminPaymentsIdProcessPut(params({ id }));
  });
};

export const useVerifiedCzechPaymentConnect = () => {
  return useMutation(async (options: ApiListOptions) => {
    return undefined; // api.apiV1AdminPaymentsIdProcessPut(params({}));
  });
};

export const useReconciliatePayments = () => {
  const { showSuccessSnackbar } = useSnackbar();
  const { t } = useTranslation('Payments');

  return useMutation(async (options: ReconciliatePaymentsParams) => {
    const request = new ReconcilliationRequestModel();
    request.regionId = RegionEnum.CzechRepublic;
    request.reconcilliationPeriodStart = options.startDate;
    request.reconcilliationPeriodEnd = options.endDate;
    const response = await api.apiV1AdminPaymentsReconcilliatePaymentsPost(
      params({ reconcilliationRequestModel: request }),
    );
    if (response.status === 204) {
      showSuccessSnackbar(t('ReconciliatePaymentsOK'));
    }
  });
};
