import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isNumberMin, isStringMaxLength }) => ({
    name: isStringMaxLength(50),
    code: isStringMaxLength(50),
    minimalNetSalary: isNumberMin(0),
    directDebitLimit: isNumberMin(0),
    hunterReferralBonus: isNumberMin(0),
    targetReferralBonus: isNumberMin(0),
  }));
};
