import { InputField, Grid, FormComponentProps, DatePickerField } from '@/components';
import { getCurrencySymbol } from '@/utils';

const fields = [
  { name: 'id', label: 'Id' },
  { name: 'companyName', label: 'CompanyName' },
  { name: 'companyCode', label: 'CompanyCode' },
  { name: 'amount', label: 'Amount', getEndAdornment: getCurrencySymbol },
  { name: 'status', label: 'Status' },
];
const dateField = { name: 'created', label: 'Created' };

export const formBasicFields = [...fields, dateField];

export const FormBasic = ({ disabled, t, values }: FormComponentProps) => {
  return (
    <Grid container>
      {fields.map(({ getEndAdornment = () => undefined, ...field }) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 6 }}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              endAdornmentString: getEndAdornment(values.currency),
            }}
          />
        </Grid>
      ))}
      <Grid key={dateField.name} {...{ item: true, xs: 12, sm: 6 }}>
        <DatePickerField {...{ ...dateField, label: t(dateField.label), disabled }} />
      </Grid>
    </Grid>
  );
};
