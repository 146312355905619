import { ConnectorState } from '@/api/base/model';
import { colorBlack, colorBlue, colorGreen, colorGreyDark, colorRed } from '@/styles';

export const getConnectorStateStyle = (state: ConnectorState) => {
  let color = colorGreyDark;

  switch (state) {
    case ConnectorState.Active:
      color = colorGreen;
      break;
    case ConnectorState.Connecting:
      color = colorBlue;
      break;
    case ConnectorState.CompilationError:
      color = colorRed;
      break;
    case ConnectorState.Disabled:
      color = colorBlack;
      break;
    default:
      break;
  }

  return { color, fontWeight: 'bold' };
};
