import { FormValues, ApiListOptions } from '@/components';
import { useQuery, useMutation } from 'react-query';
import { ApiKey } from '@/enums';
import { CompanyThemesApi } from '../base';
import { SaveCompanyThemeDto } from '../base/model';
import { params, dto, fileParams, downloadFileFromResponse, withCompanyId } from '../utils';

const api = new CompanyThemesApi();

export const useCompanyThemeList = (options: ApiListOptions) => {
  return useQuery([ApiKey.CompanyThemes, options], async () => {
    const response = await api.apiV1AdminCompanyThemesGet(params(options));

    return response.data;
  });
};

export const useCompanyThemeExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminCompanyThemesXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useCompanyThemeGet = (id: number) => {
  return useQuery([ApiKey.CompanyThemes, id], async () => {
    const response = await api.apiV1AdminCompanyThemesIdGet(params({ id }));

    return response.data.data;
  });
};

export const useCompanyThemeCreate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminCompanyThemesPost(
      params({
        saveCompanyThemeDto: createSaveDto(values),
      }),
    );
  });
};

export const useCompanyThemeUpdate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminCompanyThemesIdPut(
      params({
        id: values.id,
        saveCompanyThemeDto: createSaveDto(values),
      }),
    );
  });
};

const createSaveDto = (values: FormValues) => dto(SaveCompanyThemeDto, withCompanyId(values));
