export const Errors = {
  DEFAULT: 'Akce nebyla úspěšná. Došlo k neznámé chybě.',
  ERROR_401: 'Vypršela platnost Vaší relace. Znovu se přihlašte, prosím.',
  ERROR_403: 'Nemáte oprávnění provést danou akci.',
  ERROR_404: 'Záznam nenalezen.',
  ERROR_500: 'Interní chyba serveru.',
  VALIDATION_FAILED: 'Nebyly zadány validní hodnoty.',
  COMPANY_INVALID: 'Společnost je neplatná.',
  COMPANY_CODE_INVALID: 'Kód společnosti nebyl nalezen.',
  COMPANY_CODE_EXISTS: 'Společnost s poskytnutým kódem již existuje.',
  CONNECTOR_NO_CONNECTION:
    'Neexistuje žádné připojení k žádnému ze serverů zaměstnavatele. Prosím zkuste to znovu později.',
  CONNECTOR_NO_REPLY: 'Server zaměstnavatele neodpověděl včas. Prosím zkuste to znovu později.',
  USER_IDENTIFIER_OR_PASSWORD_INVALID: 'Uživatelské jméno nebo heslo je neplatné.',
  USER_OLD_PASSWORD_INVALID: 'Aktuální heslo je neplatné.',
  BALANCE_EXISTS: 'Platbu nelze upravit - pro tento měsíc již byla vytvořena závěrka.',
  CONECTOR_REQUEST_FAILED:
    'Požadavek na konektor se nezdařil. Zkuste to znovu později nebo kontaktujte podporu.',
  PAYMENT_ALREADY_PROCESSED: 'Žádost je již zpracována.',
  PAYMENT_NOT_VERIFIED: 'Žádost není ověřena.',
  USER_HAS_NO_ROLES: 'Admin uživatel musí mít definovanou roli.',
  USERNAME_TAKEN: 'Uživatel se stejným uživatelským jménem již existuje.',
  COMPANY_ADMIN_MUST_HAVE_COMPANY_ASSIGNED: 'Company admin musí mít přiřazenou společnost',
  INVALID_BALANCE_DAY: 'Den závěrky společnosti má neplatnou hodnotu',
  NO_PAYMENT_BATCHES_CREATED:
    'Nebyly vytvořeny žádné hromadné platby. Všechny platby jsou již zpracovávány v bance.',
  PAYMENTS_ALREADY_AUTHORIZED: 'Platby jsou již autorizovány v bance.',
};
