import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { t } = useTranslation('Dashboard');

  return (
    <HomeContainer>
      <Typography variant='h3'>{t('Home')}</Typography>
    </HomeContainer>
  );
};

const HomeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});
