import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { Language } from '@mui/icons-material';
import { Dropdown } from '@/components';
import { languages, parseLanguage } from '@/translation';
import { Props } from './types';

const LanguageDropdownComponent = (props: Props) => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation('Languages');

  return (
    <Dropdown
      {...props}
      data-cy='language'
      label={t(parseLanguage(language))}
      startIcon={<Language />}
      items={languages.map((lng) => {
        const label = t(lng);

        return {
          label,
          onClick: () => changeLanguage(lng),
          'data-cy': `language-${label}`,
        };
      })}
    />
  );
};

export const LanguageDropdown = styled(LanguageDropdownComponent)(({ theme }) => ({
  borderWidth: 0,
  color: theme.palette.common.black,
  '&:hover': {
    borderWidth: 0,
  },
}));
