import { TablePagination as MuiTablePagination } from '@mui/material';
import { TablePaginationProps } from './types';

export const TablePagination = ({
  count,
  offset,
  limit,
  onOffsetChange,
  onLimitChange,
}: TablePaginationProps) => {
  return (
    <MuiTablePagination
      rowsPerPageOptions={[25, 50, 100, 250, 1000]}
      component='div'
      count={count}
      rowsPerPage={limit}
      page={Math.ceil(offset / limit)}
      onPageChange={(_, page) => onOffsetChange(page * limit)}
      onRowsPerPageChange={(e) => {
        const value = e.target.value;
        const numberValue = Number(value);
        if (!isNaN(numberValue)) {
          onLimitChange(numberValue);
        }
      }}
    />
  );
};
