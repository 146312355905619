import { useMemo, useState } from 'react';
import { useModule } from './useModule';
import { useSmallScreen } from '@/utils';
import { Dropdown } from '../Dropdown';
import { MoreVert } from '@mui/icons-material';
import { TableAction } from './enums';
import { Toolbar } from './Toolbar';
import { styled } from '@mui/material';
import { ConfirmModal } from '../ConfirmModal';

export const TableToolbar = () => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const { tableActions } = useModule();
  const smallScreen = useSmallScreen();

  const exportAction = useMemo(
    () => tableActions.find(({ key }) => key === TableAction.Export),
    [tableActions],
  );

  return smallScreen ? (
    <StyledToolbar
      {...{
        action: (
          <>
            <Dropdown
              icon={MoreVert}
              items={tableActions
                .filter(({ key }) => key !== TableAction.Reload)
                .map((action) =>
                  action.key === TableAction.Export
                    ? { ...action, onClick: () => setExportModalOpen(true) }
                    : action,
                )}
            />
            {exportAction && (
              <ConfirmModal
                {...{
                  ...exportAction,
                  open: exportModalOpen,
                  onClose: () => setExportModalOpen(false),
                }}
              />
            )}
          </>
        ),
      }}
    />
  ) : (
    <></>
  );
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({ paddingTop: theme.spacing(1) }));
