import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ComposedChart } from './ComposedChart';
import { BarChart } from './BarChart';
import { PieChart } from './PieChart';
import { ChartProps } from './types';
import { ApiAutocomplete, DatePicker, Grid } from '@/components';
import { useCompanyLookupsList } from '@/api';
import { useEffect, useMemo, useState } from 'react';
import { CompanyLookupDto } from '@/api/base/model';
import { useSmallScreen } from '@/utils';

export const DashboardView = () => {
  const { t } = useTranslation('Dashboard');
  const [month, setMonth] = useState<string | null | undefined>();
  const [company, setCompany] = useState<CompanyLookupDto>();
  const [initialized, setInitialized] = useState(false);
  const smallScreen = useSmallScreen();

  const companyId = company?.id;
  const currentMonth = company?.currentMonth;
  const currentMonthDate = useMemo(
    () => (currentMonth ? new Date(currentMonth) : null),
    [currentMonth],
  );
  const realMonth = month ?? currentMonth;
  const maxDate = useMemo(
    () => (currentMonthDate ? new Date(`${currentMonthDate.getFullYear()}-12-31`) : undefined),
    [currentMonthDate],
  );

  useEffect(() => {
    if (!initialized && company) {
      setInitialized(true);
      setMonth(currentMonth);
    }
  }, [initialized, company, currentMonth]);

  return (
    <DashboardContainer>
      <Filters container>
        {!smallScreen && <Grid {...{ item: true, md: 6, lg: 2, xl: 4 }} />}
        <Grid {...{ item: true, xs: 12, md: 6, lg: 4, xl: 3 }}>
          <DatePicker
            {...{
              label: t('Month'),
              monthOnly: true,
              value: month,
              onChange: (value) => setMonth(value),
              disableHighlightToday: true,
              disableFuture: true,
              closeOnMonthSelect: true,
              withArrows: true,
              minDate: new Date('2000-01-01'),
              maxDate,
              shouldDisableMonth: (date: Date) => {
                return currentMonthDate
                  ? date.getFullYear() === currentMonthDate.getFullYear() &&
                      date.getMonth() > currentMonthDate.getMonth()
                  : false;
              },
              onChangeMapper: (value) =>
                value
                  ? currentMonthDate && new Date(value) > currentMonthDate
                    ? currentMonth ?? null
                    : value
                  : null,
            }}
          />
        </Grid>
        <Grid {...{ item: true, xs: 12, sm: 9, md: 11, lg: 5, xl: 4 }}>
          <ApiAutocomplete
            {...{
              key: `${!company}`,
              label: t('Company'),
              useApiList: useCompanyLookupsList,
              value: company,
              onChange: setCompany,
              selectFirst: !initialized,
            }}
          />
        </Grid>
        <Grid {...{ item: true, xs: 12, sm: 3, md: 1 }}>
          {company?.currency && <Currency>{company.currency}</Currency>}
        </Grid>
      </Filters>
      {companyId && (
        <>
          {realMonth && (
            <Chart
              {...{
                title: 'GlobalOverview',
                content: <ComposedChart {...{ companyId, month: realMonth }} />,
              }}
            />
          )}
          {(!month || month === currentMonth) && (
            <BottomContainer>
              <Chart
                {...{
                  title: 'NumbersOfWorkers',
                  content: <BarChart {...{ companyId }} />,
                }}
              />
              <Chart
                {...{
                  title: 'TotalBudget',
                  content: <PieChart {...{ companyId }} />,
                }}
              />
            </BottomContainer>
          )}
        </>
      )}
    </DashboardContainer>
  );
};

const Chart = ({ title, content }: ChartProps) => {
  const { t } = useTranslation('Dashboard');

  return (
    <ChartContainer>
      <Title variant='h6'>{t(title)}</Title>
      {content}
    </ChartContainer>
  );
};

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(4)})`,
  height: `calc(100% - ${theme.spacing(5)})`,
  padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  overflow: 'auto',
}));

const Filters = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  left: -24,
  marginBottom: theme.spacing(3),
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: theme.spacing(3),
}));

const ChartContainer = styled(Box)({ flex: 1 });

const Title = styled(Typography)({ fontSize: '1.1rem' });

const Currency = styled(Typography)({ width: '100%', textAlign: 'center' });
