import { useLocale } from '@/translation';
import { format as fnsFormat } from 'date-fns';
import { useCallback } from 'react';

export const isValidDate = (value: Date) => value && value.toString() !== 'Invalid Date';

export function isValidDateOptional(value: Date | null | undefined) {
  return value === undefined || value === null || isValidDate(value);
}

const formatDatePart = (value: number) => `0${value}`.slice(-2);

const formatYearMonthString = (value: Date) =>
  `${value.getFullYear()}-${formatDatePart(value.getMonth() + 1)}`;

export const formatMonthString = (value: Date) => `${formatYearMonthString(value)}-01`;

export const formatDateString = (value: Date) =>
  `${formatYearMonthString(value)}-${formatDatePart(value.getDate())}`;

const dateFormat = 'P';
const dateTimeFormat = 'Pp';
const monthFormat = 'MM/yyyy';
const dayFormat = 'dd.MM';

export const useDateFormat = () => {
  const locale = useLocale();

  const format = useCallback(
    (value: Date, pattern: string) => {
      if (isValidDate(value)) {
        return fnsFormat(value, pattern, {
          locale,
        });
      }

      return '';
    },
    [locale],
  );

  const formatString = useCallback(
    (value: string | null | undefined, pattern: string) => {
      if (!value) {
        return '';
      }

      return format(new Date(value), pattern);
    },
    [format],
  );

  const formatDateObject = useCallback((value: Date) => format(value, dateFormat), [format]);

  const formatDate = useCallback(
    (value: string) => formatString(value, dateFormat),
    [formatString],
  );

  const formatDateTime = useCallback(
    (value: string) => formatString(value, dateTimeFormat),
    [formatString],
  );

  const formatMonth = useCallback(
    (value: string) => formatString(value, monthFormat),
    [formatString],
  );

  const formatDay = useCallback((value: string) => formatString(value, dayFormat), [formatString]);

  return { formatDateObject, formatDate, formatDateTime, formatMonth, formatDay };
};
