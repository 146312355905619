import { ApiKey } from '@/enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { TableVerifiedConnectProps } from './types';
import { useModule } from './useModule';
import { useLoading } from '../Loading';
import { ConfirmModal } from '../ConfirmModal';
import { LoadingContext } from '../Loading/types';
import { createDateRangeConfirm } from './DateRangeConfirm';
import { formatDate, getLocalDatePart } from './utils';

export interface BatchProcessParams {
  loadingContext: LoadingContext;
  setAuthorizeUrls: (urls: string[]) => void;
}

export interface ReconciliatePaymentsParams {
  startDate: string;
  endDate: string;
}

export const TableVerifiedConnect = ({
  t,
  useApiVerifyConnection,
  useApiVerifiedConnectionConnect,
  useApiVerifiedConnectionBatchProcess,
  useReconciliatePayments,
  useApiPollBatches,
}: TableVerifiedConnectProps) => {
  const { updateModuleOptions } = useModule();
  const queryClient = useQueryClient();
  const { mutateAsync: connect } = useApiVerifiedConnectionConnect();
  const { mutateAsync: batchProcess } = useApiVerifiedConnectionBatchProcess();
  const { mutateAsync: reconciliatePayments } = useReconciliatePayments();
  const { mutateAsync: pollBatches } = useApiPollBatches();
  const { data, isLoading } = useApiVerifyConnection();
  const loadingContext = useLoading();
  const [authorizeModalOpen, setAuthorizeModalOpen] = useState(false);
  const [authorizeUrls, setAuthorizeUrls] = useState<string[]>([]);
  const [reconciliatePaymentsModalOpen, setReconciliatePaymentsModalOpen] = useState(false);
  const [range, setRange] = useState<ReconciliatePaymentsParams>({ startDate: '', endDate: '' });

  if (authorizeUrls.length > 0 && !authorizeModalOpen) {
    setAuthorizeModalOpen(true);
  }

  useEffect(() => {
    queryClient.invalidateQueries([ApiKey.VerifyConnectionGet]);
  }, [queryClient]);

  useEffect(() => {
    updateModuleOptions({
      connectCallback: !data?.isConnected && !isLoading ? () => connect({}) : undefined,
      batchProcessCallback:
        data?.isConnected && !isLoading
          ? () => batchProcess({ loadingContext, setAuthorizeUrls })
          : undefined,
      reconciliatePaymentsCallback:
        data?.isConnected && !isLoading
          ? () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setDate(endDate.getDate() - 7);
              const range: ReconciliatePaymentsParams = {
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
              };
              setRange(range);
              setReconciliatePaymentsModalOpen(true);
            }
          : undefined,
      pollBatchesCallback:
        data?.isConnected && !isLoading ? () => pollBatches(loadingContext) : undefined,
    });
  }, [
    connect,
    batchProcess,
    data,
    isLoading,
    updateModuleOptions,
    loadingContext,
    reconciliatePayments,
    pollBatches,
  ]);

  const handleAuthorizedUrls = useCallback(() => {
    setAuthorizeUrls([]);
  }, [authorizeUrls]);

  const authorizeText = useMemo(() => {
    return t('AuthorizeOpen');
  }, [authorizeUrls, t]);

  const handleReconciliatePayments = useCallback(() => {
    reconciliatePayments({ ...range });
  }, [range, reconciliatePayments]);

  const handleRangeChange = useCallback(
    (dataKey: string, value: string) => {
      const newRange = { ...range, [dataKey]: getLocalDatePart(value) };
      setRange(newRange);
    },
    [range],
  );

  return (
    <>
      <ConfirmModal
        {...{
          label: t('Authorization'),
          text: authorizeText,
          open: authorizeModalOpen,
          onClick: handleAuthorizedUrls,
          onClose: () => {
            setAuthorizeModalOpen(false);
            setAuthorizeUrls([]);
          },
        }}
      />
      <ConfirmModal
        {...{
          label: t('ReconciliatePayments'),
          Content: createDateRangeConfirm(
            range,
            handleRangeChange,
            t('ReconciliatePaymentsConfirm'),
          ),
          open: reconciliatePaymentsModalOpen,
          onClick: handleReconciliatePayments,
          onClose: () => setReconciliatePaymentsModalOpen(false),
        }}
      />
    </>
  );
};
