import { useMemo } from 'react';
import { InputField, Grid, FormComponentProps } from '@/components';

const input = { name: 'script', label: 'Script', required: true, multiline: true, rows: 20 };

export const formScriptFields = [input];

export const FormScript = ({ disabled, t }: FormComponentProps) => {
  const tInput = useMemo(() => ({ ...input, label: t(input.label), disabled }), [t, disabled]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputField {...tInput} />
      </Grid>
    </Grid>
  );
};
