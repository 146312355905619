import { EditorMarkdown, EditorMarkdownProps } from '../../EditorMarkdown';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';

export const EditorMarkdownComponent = (props: EditorMarkdownProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: string) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <EditorMarkdown {...{ ...props, ...field, onChange: handleChange }} />;
};

export const EditorMarkdownField = (fieldProps: FormFieldProps<EditorMarkdownProps>) => {
  const restProps = splitProps(fieldProps, [
    'className',
    'disabled',
    'onImageSave',
  ]) as EditorMarkdownProps;

  return (
    <Field {...{ ...fieldProps, nonTouchedError: true }}>
      <EditorMarkdownComponent {...restProps} />
    </Field>
  );
};
