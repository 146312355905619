// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { KeyValuePairDto } from './key-value-pair-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class ConnectorDetailDto extends APIModel {
    /**
    * Connector identifier
    */
    id: number;
    /**
    * Connector name
    */
    name: string;
    /**
    * Connector code
    */
    code: string;
    /**
    * Connector description
    */
    description: string;
    /**
    * Connector script
    */
    script: string;
    /**
    * Connector version
    */
    version: string;
    /**
    * Connector is active
    */
    active: boolean;
    company: KeyValuePairDto;

    constructor(options?: APIModelOptions) {
        super(ConnectorDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "name", type: "string" },
            { name: "code", type: "string" },
            { name: "description", type: "string" },
            { name: "script", type: "string" },
            { name: "version", type: "string" },
            { name: "active", type: "boolean" },
            { name: "company", type: "KeyValuePairDto" }
        ];
    }
}




