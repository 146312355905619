export const Balances = {
  Id: 'Identifier',
  Balance: 'Balance',
  PayMonth: 'Pay month',
  CompanyCode: 'Company code',
  CompanyName: 'Company name',
  DownloadErpSystem: 'Download for {{erpSystem}}',
  DownloadErpSystemText: 'Do you wish to generate a file for import into the payroll system?',
  DownloadXls: 'Download XLS',
  DownloadXlsText: 'Do you wish to generate an XLS file?',
  DownloadCompleted: 'Download completed',
  TotalPaid: 'Total paid',
  TotalBonus: 'Total bonus',
  TotalFee: 'Total fee',
  TotalAdvance: 'Total advance',
  TotalCompanyFee: 'Total company fee',
  TotalWorkerFee: 'Total worker fee',
  TotalPaymentsCount: 'Total payments count',
  TotalWorkersCount: 'Total workers count',
  TotalWorkersRegistered: 'Total registered workers count',
  TotalWorkersActive: 'Total active workers count',
  TotalWorkersPaid: 'Total paid workers count',
  DateTimeCalculated: 'Balance date',
  DateTimeDownloaded: 'Date of export',
};
