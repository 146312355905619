import { ApiListOptions } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { BalancesApi } from '../base';
import { params, fileParams, downloadFileFromResponse } from '../utils';

const api = new BalancesApi();

export const useBalanceList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Balances, options], async () => {
    const response = await api.apiV1AdminBalancesGet(params(options));

    return response.data;
  });
};

export const useBalanceErpPaymentsExport = () => {
  return useMutation(async (id: number) => {
    const response = await api.apiV1AdminBalancesIdErpGet(fileParams({ id }));
    downloadFileFromResponse(response);
  });
};

export const useBalancePaymentsExport = () => {
  return useMutation(async (id: number) => {
    const response = await api.apiV1AdminBalancesIdXlsxPaymentsGet(fileParams({ id }));
    downloadFileFromResponse(response);
  });
};

export const useBalanceExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminBalancesXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useBalanceGet = (id: number) => {
  return useQuery([ApiKey.Balances, id], async () => {
    const response = await api.apiV1AdminBalancesIdGet(params({ id }));

    return response.data.data;
  });
};
