import { TableColumnType } from './enums';
import { useTranslation } from 'react-i18next';
import { FilterValue, TableFilterProps } from './types';
import { Select } from '../Select';
import { Box, styled } from '@mui/material';
import { useCallback } from 'react';
import { DatePicker } from '../DatePicker';
import { AutocompleteItem } from '../Autocomplete';
import { ApiAutocomplete } from '../ApiAutocomplete';
import { createRangeFromKey, createRangeToKey, toUTCDateTime } from './utils';
import { removeStyledProps } from '@/utils';
import { Input } from '../Input';
import { debounce } from 'lodash';

const stringValue = (value: FilterValue) => (value ? `${value}` : undefined);

export const TableFilter = ({
  type,
  dataKey,
  filter,
  enumName,
  enumObject,
  useApiList,
  onChange,
}: TableFilterProps) => {
  const dateFilterProps = { dataKey, filter, onChange };
  const props = { value: filter[dataKey], onChange: (value: string) => onChange(dataKey, value) };

  switch (type) {
    case TableColumnType.Date:
      return <DateFilter {...dateFilterProps} />;
    case TableColumnType.DateTime:
      return <DateTimeFilter {...dateFilterProps} />;
    case TableColumnType.Month:
      return <MonthFilter {...dateFilterProps} />;
    case TableColumnType.ApiEnum:
      return <ApiEnumFilter {...{ ...props, useApiList }} />;
    case TableColumnType.Enum:
      return <EnumFilter {...{ ...props, enumName, enumObject }} />;
    case TableColumnType.Text:
    case TableColumnType.Number:
      return <TextFilter {...props} />;
    default:
      return <></>;
  }
};

type Props = { value: FilterValue; onChange: (value: string) => void };
type DateFilterProps = Pick<TableFilterProps, 'onChange' | 'dataKey' | 'filter'>;

const DateFilter = (props: DateFilterProps) => {
  return <DateTimeFilter {...{ ...props, dateOnly: true }} />;
};

const MonthFilter = (props: DateFilterProps) => {
  return <DateTimeFilter {...{ ...props, monthOnly: true }} />;
};

const DateTimeFilter = ({
  dataKey,
  ...props
}: DateFilterProps & { dateOnly?: boolean; monthOnly?: boolean }) => {
  return (
    <DateTimeContainer width={props.monthOnly ? 150 : props.dateOnly ? 180 : 230}>
      <DateTimeFilterComponentContainer data-cy='table-date-time-filter-from'>
        <DateTimeFilterComponent
          {...{
            ...props,
            columnDataKey: dataKey,
            dataKey: createRangeFromKey(dataKey),
          }}
        />
      </DateTimeFilterComponentContainer>
      <Box data-cy='table-date-time-filter-to'>
        <DateTimeFilterComponent
          {...{
            ...props,
            columnDataKey: dataKey,
            dataKey: createRangeToKey(dataKey),
          }}
        />
      </Box>
    </DateTimeContainer>
  );
};

const DateTimeFilterComponent = ({
  dataKey,
  filter,
  onChange,
  dateOnly,
  columnDataKey,
  ...props
}: DateFilterProps & { dateOnly?: boolean; columnDataKey?: string }) => {
  const value = filter[dataKey];

  return (
    <DatePicker
      {...{
        ...props,
        dateOnly,
        value: stringValue(value),
        onChange: (newValue) =>
          onChange(
            dataKey,
            !!dateOnly
              ? toUTCDateTime(newValue ?? '', dataKey === createRangeToKey(columnDataKey ?? ''))
              : newValue,
          ),
      }}
    />
  );
};

const ApiEnumFilter = ({
  value,
  useApiList,
  onChange,
}: Props & Pick<TableFilterProps, 'useApiList'>) => {
  const { t } = useTranslation('Common');

  const handleChange = useCallback(
    (item: AutocompleteItem | null) => onChange(item ? item.id : null),
    [onChange],
  );

  return useApiList ? (
    <ApiEnumFilterContainer>
      <ApiAutocomplete
        {...{
          value: stringValue(value),
          placeholder: t('Choose'),
          useApiList,
          onChange: handleChange,
          'data-cy': 'table-api-enum-filter',
        }}
      />
    </ApiEnumFilterContainer>
  ) : (
    <></>
  );
};

const TextFilter = ({ onChange }: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce((e) => {
      onChange?.(e.target.value);
    }, 300),
    [onChange],
  );

  return (
    <TextFilterContainer>
      <Input
        {...{
          onChange: debouncedChange,
          variant: 'outlined',
        }}
      />
    </TextFilterContainer>
  );
};

const EnumFilter = ({
  value,
  enumName,
  enumObject,
  onChange,
}: Props & Pick<TableFilterProps, 'enumName' | 'enumObject'>) => {
  const { t } = useTranslation(enumName);
  const { t: tCommon } = useTranslation('Common');

  return enumObject ? (
    <EnumFilterContainer>
      <Select
        {...{
          value: stringValue(value),
          placeholder: tCommon('Choose'),
          items: Object.keys(enumObject).map((id) => ({ id, name: t(id) })),
          onChange,
          'data-cy': 'table-enum-filter',
        }}
      />
    </EnumFilterContainer>
  ) : (
    <></>
  );
};

const DateTimeContainer = styled(
  Box,
  removeStyledProps('width'),
)<{ width: number }>(({ width }) => ({
  width,
  minWidth: width,
  maxWidth: width,
}));

const DateTimeFilterComponentContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

const ApiEnumFilterContainer = styled(Box)({
  width: '100%',
  minWidth: 200,
});

const EnumFilterContainer = styled(Box)({
  width: '100%',
  minWidth: 150,
});

const TextFilterContainer = styled(Box)({
  maxWidth: 300,
});
