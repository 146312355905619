import MuiButton from '@mui/material/Button';
import { Props } from './types';

export const Button = ({ label, ...props }: Props) => {
  return (
    <MuiButton {...{ disableElevation: true, variant: 'contained', size: 'large', ...props }}>
      {label}
    </MuiButton>
  );
};
