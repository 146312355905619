// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CurrentWorkersDto extends APIModel {
    /**
    * Number of registered workers
    */
    registered: number;
    /**
    * Number of logged in workers
    */
    loggedIn: number;
    /**
    * Number of workers who requested payment
    */
    paymentRequested: number;

    constructor(options?: APIModelOptions) {
        super(CurrentWorkersDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "registered", type: "number" },
            { name: "loggedIn", type: "number" },
            { name: "paymentRequested", type: "number" }
        ];
    }
}




