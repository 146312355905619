// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CreatePaymentDto, CreatedPaymentDtoApiDataResponse, PaymentDetailDtoApiDataResponse, PaymentDtoListApiDataResponse, PaymentState, PaymentStateDtoApiDataResponse, PaymentViewDtoListApiDataResponse, ReconcilliationRequestModel, RegionEnum } from "../model";

export class PaymentsApi extends APIHandler {
    static urls: APIURLTypes.PaymentsApi = {
        apiV1AdminPaymentsCzechRepublicBatchExportGet: "/api/v1/admin/payments/czech-republic/batch-export",
        apiV1AdminPaymentsGet: "/api/v1/admin/payments",
        apiV1AdminPaymentsIdGet: "/api/v1/admin/payments/{id}",
        apiV1AdminPaymentsIdProcessPut: "/api/v1/admin/payments/{id}/process",
        apiV1AdminPaymentsIdVerifyPut: "/api/v1/admin/payments/{id}/verify",
        apiV1AdminPaymentsNotifyUnprocessedPut: "/api/v1/admin/payments/notify-unprocessed",
        apiV1AdminPaymentsReconcilliatePaymentsPost: "/api/v1/admin/payments/reconcilliate-payments",
        apiV1AdminPaymentsSlovakiaBatchExportGet: "/api/v1/admin/payments/slovakia/batch-export",
        apiV1AdminPaymentsVerifiedRegionIdGet: "/api/v1/admin/payments/verified/{regionId}",
        apiV1AdminPaymentsXlsxGet: "/api/v1/admin/payments/xlsx",
        apiV1ClientPaymentsGet: "/api/v1/client/payments",
        apiV1ClientPaymentsIdStateGet: "/api/v1/client/payments/{id}/state",
        apiV1ClientPaymentsPost: "/api/v1/client/payments"
    };
    
    constructor() {
        super("PaymentsApi");
    }
    
    /**
     * Generates payments batch files for verified payments in the Czech republic
     */
    public apiV1AdminPaymentsCzechRepublicBatchExportGet(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsCzechRepublicBatchExportGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminPaymentsCzechRepublicBatchExportGet", params.options, body, "apiV1AdminPaymentsCzechRepublicBatchExportGet");
    }

    /**
     * Get payments
     * @param demo Demo payment
     * @param id The id of the payment within PalmApp
     * @param companyId Company identifier
     * @param state Payment state
     * @param name Worker name
     * @param payMonthFrom Pay month from
     * @param payMonthTo Pay month to
     * @param dateRequestedFrom Date when payment was requested
     * @param dateRequestedTo Date when payment was requested
     * @param account The account number the payment will be sent to
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminPaymentsGet(params: APIParams & {
        demo?: boolean;
        id?: number;
        companyId?: number;
        state?: PaymentState;
        name?: string;
        payMonthFrom?: string;
        payMonthTo?: string;
        dateRequestedFrom?: string;
        dateRequestedTo?: string;
        account?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<PaymentDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsGet, null);
        params.options = this.initOptions(params.options, { demo: params.demo, id: params.id, companyId: params.companyId, state: params.state, name: params.name, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, dateRequestedFrom: params.dateRequestedFrom, dateRequestedTo: params.dateRequestedTo, account: params.account, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<PaymentDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminPaymentsGet", params.options, body, "apiV1AdminPaymentsGet");
    }

    /**
     * Get payment detail
     * @param id 
     */
    public apiV1AdminPaymentsIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<PaymentDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<PaymentDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminPaymentsIdGet", params.options, body, "apiV1AdminPaymentsIdGet");
    }

    /**
     * Process payment
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **PAYMENT_ALREADY_PROCESSED** - Payment is already processed. + **PAYMENT_NOT_VERIFIED** - Payment is not verified. 
     * @param id 
     */
    public apiV1AdminPaymentsIdProcessPut(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsIdProcessPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminPaymentsIdProcessPut", params.options, body, "apiV1AdminPaymentsIdProcessPut");
    }

    /**
     * Verify payment manually. Used only when automatic verification fails.
     * @param id 
     */
    public apiV1AdminPaymentsIdVerifyPut(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsIdVerifyPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminPaymentsIdVerifyPut", params.options, body, "apiV1AdminPaymentsIdVerifyPut");
    }

    /**
     * Notify unprocessed payments
     */
    public apiV1AdminPaymentsNotifyUnprocessedPut(params: APIParams & {
        
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsNotifyUnprocessedPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminPaymentsNotifyUnprocessedPut", params.options, body, "apiV1AdminPaymentsNotifyUnprocessedPut");
    }

    /**
     * @param reconcilliationRequestModel 
     */
    public apiV1AdminPaymentsReconcilliatePaymentsPost(params: APIParams & {
        reconcilliationRequestModel?: ReconcilliationRequestModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsReconcilliatePaymentsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.reconcilliationRequestModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminPaymentsReconcilliatePaymentsPost", params.options, body, "apiV1AdminPaymentsReconcilliatePaymentsPost");
    }

    /**
     * Generates payments batch files for verified payments in Slovakia
     */
    public apiV1AdminPaymentsSlovakiaBatchExportGet(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsSlovakiaBatchExportGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminPaymentsSlovakiaBatchExportGet", params.options, body, "apiV1AdminPaymentsSlovakiaBatchExportGet");
    }

    /**
     * Get payments for a given region in a given status
     * @param regionId 
     * @param demo Demo payment
     * @param id The id of the payment within PalmApp
     * @param companyId Company identifier
     * @param state Payment state
     * @param name Worker name
     * @param payMonthFrom Pay month from
     * @param payMonthTo Pay month to
     * @param dateRequestedFrom Date when payment was requested
     * @param dateRequestedTo Date when payment was requested
     * @param account The account number the payment will be sent to
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminPaymentsVerifiedRegionIdGet(params: APIParams & {
        regionId: RegionEnum;
        demo?: boolean;
        id?: number;
        companyId?: number;
        state?: PaymentState;
        name?: string;
        payMonthFrom?: string;
        payMonthTo?: string;
        dateRequestedFrom?: string;
        dateRequestedTo?: string;
        account?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    }): AxiosPromise<PaymentDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsVerifiedRegionIdGet, { regionId: params.regionId } );
        params.options = this.initOptions(params.options, { demo: params.demo, id: params.id, companyId: params.companyId, state: params.state, name: params.name, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, dateRequestedFrom: params.dateRequestedFrom, dateRequestedTo: params.dateRequestedTo, account: params.account, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<PaymentDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminPaymentsVerifiedRegionIdGet", params.options, body, "apiV1AdminPaymentsVerifiedRegionIdGet");
    }

    /**
     * Get payment export in XLSX format.
     * @param demo Demo payment
     * @param id The id of the payment within PalmApp
     * @param companyId Company identifier
     * @param state Payment state
     * @param name Worker name
     * @param payMonthFrom Pay month from
     * @param payMonthTo Pay month to
     * @param dateRequestedFrom Date when payment was requested
     * @param dateRequestedTo Date when payment was requested
     * @param account The account number the payment will be sent to
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminPaymentsXlsxGet(params: APIParams & {
        demo?: boolean;
        id?: number;
        companyId?: number;
        state?: PaymentState;
        name?: string;
        payMonthFrom?: string;
        payMonthTo?: string;
        dateRequestedFrom?: string;
        dateRequestedTo?: string;
        account?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1AdminPaymentsXlsxGet, null);
        params.options = this.initOptions(params.options, { demo: params.demo, id: params.id, companyId: params.companyId, state: params.state, name: params.name, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, dateRequestedFrom: params.dateRequestedFrom, dateRequestedTo: params.dateRequestedTo, account: params.account, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminPaymentsXlsxGet", params.options, body, "apiV1AdminPaymentsXlsxGet");
    }

    /**
     * Get payments mobile view.
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1ClientPaymentsGet(params: APIParams & {
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<PaymentViewDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1ClientPaymentsGet, null);
        params.options = this.initOptions(params.options, { offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<PaymentViewDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientPaymentsGet", params.options, body, "apiV1ClientPaymentsGet");
    }

    /**
     * Get payment state
     * @param id 
     */
    public apiV1ClientPaymentsIdStateGet(params: APIParams & {
        id: number;
    }): AxiosPromise<PaymentStateDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1ClientPaymentsIdStateGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<PaymentStateDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientPaymentsIdStateGet", params.options, body, "apiV1ClientPaymentsIdStateGet");
    }

    /**
     * Create payment
     * @param createPaymentDto 
     */
    public apiV1ClientPaymentsPost(params: APIParams & {
        createPaymentDto?: CreatePaymentDto;
    } = {}): AxiosPromise<CreatedPaymentDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(PaymentsApi.urls.apiV1ClientPaymentsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.createPaymentDto;
        return this.makeCall<CreatedPaymentDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1ClientPaymentsPost", params.options, body, "apiV1ClientPaymentsPost");
    }

}
