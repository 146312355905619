import {
  colorMobileAccent,
  colorMobileBlack,
  colorMobileDanger,
  colorMobileDark,
  colorMobileLight,
  colorMobileMedium,
  colorMobilePinpoints,
  colorMobilePrimary,
  colorMobileSecondary,
  colorMobileSuccess,
  colorMobileTertiary,
  colorMobileWarning,
  colorMobileWhite,
} from '@/styles';

export enum ActionColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Dark = 'dark',
  Medium = 'medium',
  Light = 'light',
  Accent = 'accent',
  Pinpoints = 'pinpoints',
}

export const actionColors = {
  [ActionColor.Primary]: { backgroundColor: colorMobilePrimary, color: colorMobileWhite },
  [ActionColor.Secondary]: { backgroundColor: colorMobileSecondary, color: colorMobileBlack },
  [ActionColor.Tertiary]: { backgroundColor: colorMobileTertiary, color: colorMobileWhite },
  [ActionColor.Success]: { backgroundColor: colorMobileSuccess, color: colorMobileBlack },
  [ActionColor.Warning]: { backgroundColor: colorMobileWarning, color: colorMobileWhite },
  [ActionColor.Danger]: { backgroundColor: colorMobileDanger, color: colorMobileBlack },
  [ActionColor.Dark]: { backgroundColor: colorMobileDark, color: colorMobileWhite },
  [ActionColor.Medium]: { backgroundColor: colorMobileMedium, color: colorMobileBlack },
  [ActionColor.Light]: { backgroundColor: colorMobileLight, color: colorMobileBlack },
  [ActionColor.Accent]: { backgroundColor: colorMobileAccent, color: colorMobileBlack },
  [ActionColor.Pinpoints]: { backgroundColor: colorMobilePinpoints, color: colorMobileBlack },
};
