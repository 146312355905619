// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CardsResponse, ChangeCashbankAccountModel, ChangePasswordModel, KycResponse, OverviewResponse, ResetPasswordModel, TransactionHistoryResponseQueryResult } from "../model";

export class UserApi extends APIHandler {
    static urls: APIURLTypes.UserApi = {
        apiV1ClientUserCardsGet: "/api/v1/client/user/cards",
        apiV1ClientUserChangeCashbackAccountPut: "/api/v1/client/user/change-cashback-account",
        apiV1ClientUserChangePasswordPut: "/api/v1/client/user/change-password",
        apiV1ClientUserKycGet: "/api/v1/client/user/kyc",
        apiV1ClientUserOverviewGet: "/api/v1/client/user/overview",
        apiV1ClientUserResetPasswordPut: "/api/v1/client/user/reset-password",
        apiV1ClientUserSalaryDeductionAgreementGet: "/api/v1/client/user/salary-deduction-agreement",
        apiV1ClientUserTransactionHistoryGet: "/api/v1/client/user/transaction-history"
    };
    
    constructor() {
        super("UserApi");
    }
    
    /**
     */
    public apiV1ClientUserCardsGet(params: APIParams & {
        
    } = {}): AxiosPromise<CardsResponse> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserCardsGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CardsResponse>("GET".toLowerCase(), apiURL, "apiV1ClientUserCardsGet", params.options, body, "apiV1ClientUserCardsGet");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **INVALID_ACCOUNT** - Invalid account. 
     * @param changeCashbankAccountModel 
     */
    public apiV1ClientUserChangeCashbackAccountPut(params: APIParams & {
        changeCashbankAccountModel?: ChangeCashbankAccountModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserChangeCashbackAccountPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.changeCashbankAccountModel;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1ClientUserChangeCashbackAccountPut", params.options, body, "apiV1ClientUserChangeCashbackAccountPut");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **PASSWORD_MISMATCH** - Entered passwords are not the same. 
     * @param changePasswordModel 
     */
    public apiV1ClientUserChangePasswordPut(params: APIParams & {
        changePasswordModel?: ChangePasswordModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserChangePasswordPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.changePasswordModel;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1ClientUserChangePasswordPut", params.options, body, "apiV1ClientUserChangePasswordPut");
    }

    /**
     */
    public apiV1ClientUserKycGet(params: APIParams & {
        
    } = {}): AxiosPromise<KycResponse> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserKycGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<KycResponse>("GET".toLowerCase(), apiURL, "apiV1ClientUserKycGet", params.options, body, "apiV1ClientUserKycGet");
    }

    /**
     */
    public apiV1ClientUserOverviewGet(params: APIParams & {
        
    } = {}): AxiosPromise<OverviewResponse> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserOverviewGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<OverviewResponse>("GET".toLowerCase(), apiURL, "apiV1ClientUserOverviewGet", params.options, body, "apiV1ClientUserOverviewGet");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. 
     * @param resetPasswordModel 
     */
    public apiV1ClientUserResetPasswordPut(params: APIParams & {
        resetPasswordModel?: ResetPasswordModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserResetPasswordPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.resetPasswordModel;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1ClientUserResetPasswordPut", params.options, body, "apiV1ClientUserResetPasswordPut");
    }

    /**
     */
    public apiV1ClientUserSalaryDeductionAgreementGet(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserSalaryDeductionAgreementGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1ClientUserSalaryDeductionAgreementGet", params.options, body, "apiV1ClientUserSalaryDeductionAgreementGet");
    }

    /**
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1ClientUserTransactionHistoryGet(params: APIParams & {
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<TransactionHistoryResponseQueryResult> {
        const apiURL: string = this.initAPIURL(UserApi.urls.apiV1ClientUserTransactionHistoryGet, null);
        params.options = this.initOptions(params.options, { offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<TransactionHistoryResponseQueryResult>("GET".toLowerCase(), apiURL, "apiV1ClientUserTransactionHistoryGet", params.options, body, "apiV1ClientUserTransactionHistoryGet");
    }

}
