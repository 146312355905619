// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { KeyValuePairDto } from './key-value-pair-dto';
import { RoleDto } from './role-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class UserDetailDto extends APIModel {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    identifier: string;
    userRoles: Array<RoleDto>;
    isCurrentUser: boolean;
    company: KeyValuePairDto;
    role: RoleDto;

    constructor(options?: APIModelOptions) {
        super(UserDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "username", type: "string" },
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "identifier", type: "string" },
            { name: "userRoles", type: "Array<RoleDto>" },
            { name: "isCurrentUser", type: "boolean" },
            { name: "company", type: "KeyValuePairDto" },
            { name: "role", type: "RoleDto" }
        ];
    }
}




