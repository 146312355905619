import { ApiListOptions } from '@/components';
import { useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { RolesApi } from '../base';
import { params } from '../utils';

const api = new RolesApi();

export const useRolesList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Roles, options], async () => {
    const response = await api.apiV1AdminRolesGet(params(options));

    return response.data;
  });
};
