import { LabeledRouteItem, useRoutes } from '@/routes';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useActiveRoute = () => {
  const location = useLocation();
  const routes = useRoutes();

  return useMemo(() => {
    let item: (LabeledRouteItem & { parent: string }) | undefined;

    routes.forEach((route) => {
      if (route?.items) {
        const found = route.items.find(({ path }) => path === location.pathname);
        if (found) {
          item = { ...found, parent: route.label };
        }
      }
      if (route?.path === location.pathname) {
        item = { ...(route as LabeledRouteItem), parent: route.label };
      }
    });

    return item;
  }, [routes, location.pathname]);
};
