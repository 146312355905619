export const Enums = {
  ConnectorState: {
    Unknown: 'Neznámy',
    Active: 'Aktívny',
    Connecting: 'Pripája sa',
    Disabled: 'Zakázaný',
    CompilationError: 'Chyba kompilácie',
  },
  PaymentState: {
    None: 'Neznámy',
    Requested: 'Požiadavka',
    Verified: 'Overená',
    Processed: 'Spracovaná',
    Error: 'Chyba',
    InBankPendingAuth: 'V bance - čeká na autorizaci',
    InBankLocked: 'V bance - uzamčeno',
    InBankAuthExpired: 'V bance - vypršela platnost',
  },
  Currencies: {
    CZK: 'Česká koruna',
    EUR: 'Euro',
  },
  MessagesAnswersName: {
    read: 'Prečítané',
  },
  RegistrationType: {
    Default: 'základná',
    OneTimeEmailCode: 'emailovým kódom',
  },
  WorkerState: {
    Active: 'Aktívny',
    Inactive: 'Neaktívny',
    Disabled: 'Vypnutý',
    NotFound: 'Nenájdený',
    RefreshFailure: 'Obnovenie zlyhalo',
  },
  DirectDebitStatus: {
    Pending: 'Nové',
    Collected: 'Proplaceno',
    Requested: 'V bance',
  },
  ExternalPaymentState: {
    AUTHORIZATION_NOT_STARTED: 'Autorizace nebyla zahájena',
    DONE: 'Potvrzeno',
    PROCESSING: 'Zpracovává se',
    REJECTED: 'Zamítnuto',
    EXPIRED: 'Autorizace vypršela',
    ORDER_PROCESSING_FAILED: 'Chyba zpracování',
  },
  CardStatus: {
    Active: 'Aktivní',
    Inactive: 'Neaktivní',
    TemporaryBlocked: 'Dočasná blokace',
    Fraud: 'Fraud',
    Closed: 'Uzavřena',
    Lost: 'Ztracena',
    Stolen: 'Ukradena',
  },
  CardTransactionStatus: {
    Pending: 'Čekající',
    Authorized: 'Schváleno',
    Reversed: 'Vráceno',
    Error: 'Chyba',
  },
};
