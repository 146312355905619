import { useMemo } from 'react';
import { InputField, Grid, FormComponentProps } from '@/components';

const input = { name: 'note', label: 'Note', multiline: true, rows: 15 };

export const formNoteFields = [input];

export const FormNote = ({ disabled, t }: FormComponentProps) => {
  const tInput = useMemo(() => ({ ...input, label: t(input.label), disabled }), [t, disabled]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputField {...tInput} />
      </Grid>
    </Grid>
  );
};
