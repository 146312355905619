export enum Permission {
  DashboardView = 'DASHBOARD_VIEW',
  WorkersView = 'WORKERS_VIEW',
  WorkersSave = 'WORKERS_SAVE',
  WorkersRefresh = 'WORKERS_REFRESH',
  PaymentsView = 'PAYMENTS_VIEW',
  PaymentsEdit = 'PAYMENTS_EDIT',
  PaymentsProcess = 'PAYMENTS_PROCESS',
  MessagesView = 'MESSAGES_VIEW',
  MessagesSave = 'MESSAGES_SAVE',
  CompaniesView = 'COMPANIES_VIEW',
  CompaniesSave = 'COMPANIES_SAVE',
  CompaniesRefresh = 'COMPANIES_REFRESH',
  CompanyThemesView = 'COMPANY-THEMES_VIEW',
  CompanyThemesSave = 'COMPANY-THEMES_SAVE',
  ConnectorsView = 'CONNECTORS_VIEW',
  ConnectorsSave = 'CONNECTORS_SAVE',
  BalancesView = 'BALANCES_VIEW',
  BalancesCreate = 'BALANCES_CREATE',
  UsersView = 'USERS_VIEW',
  UsersSave = 'USERS_SAVE',
  RolesView = 'ROLES_VIEW',
  RolesSave = 'ROLES_SAVE',
  RegionsView = 'REGIONS_VIEW',
  RegionsSave = 'REGIONS_SAVE',
  SettingView = 'SETTING_VIEW',
  SettingSave = 'SETTING_SAVE',
  DirectDebitsView = 'DIRECT-DEBITS_VIEW',
  DirectDebitsSave = 'DIRECT-DEBITS_SAVE',
  CeskaSporitelnaApi = 'CESKA_SPORITELNA_API',
  CardsView = 'CARDS_VIEW',
  CardTransactionsView = 'CARD_TRANSACTIONS_VIEW',
}
