import { InputField, Grid, FormComponentProps } from '@/components';

export const formErrorFields = [
  { name: 'error', label: 'Error', multiline: true, rows: 14, disabled: true },
];

export const FormError = ({ t }: FormComponentProps) => {
  return (
    <Grid container>
      {formErrorFields.map((field) => (
        <Grid key={field.name} item xs={12}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled: true,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
