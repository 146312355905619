// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class UpdateWorkerDto extends APIModel {
    payLimitPercentage: number;
    hourSalaryAvg: number;
    monthSalary: number;
    wageDeduction: number;
    workingTime: number;
    active: boolean;
    inExecution: boolean;
    inInsolvency: boolean;
    dismissDate: string;
    excludedPeriodFrom: string;
    excludedPeriodTo: string;

    constructor(options?: APIModelOptions) {
        super(UpdateWorkerDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "payLimitPercentage", type: "number" },
            { name: "hourSalaryAvg", type: "number" },
            { name: "monthSalary", type: "number" },
            { name: "wageDeduction", type: "number" },
            { name: "workingTime", type: "number" },
            { name: "active", type: "boolean" },
            { name: "inExecution", type: "boolean" },
            { name: "inInsolvency", type: "boolean" },
            { name: "dismissDate", type: "string" },
            { name: "excludedPeriodFrom", type: "string" },
            { name: "excludedPeriodTo", type: "string" }
        ];
    }
}




