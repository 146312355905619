import { InputField, Grid, FormComponentProps } from '@/components';

export const formPasswordFields = [
  { name: 'password', label: 'NewPassword' },
  { name: 'passwordConfirm', label: 'ConfirmNewPassword', nonTouchedError: true },
];

export const FormPassword = ({ disabled, t }: FormComponentProps) => {
  return (
    <Grid container>
      {formPasswordFields.map((field) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 12, md: 6 }}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              type: 'password',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
