import { useRegionExport, useRegionGet, useRegionList } from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey, Permission } from '@/enums';
import { Delete } from '@mui/icons-material';
import { FormBasic, formBasicFields } from './FormBasic';
import { useValidationSchema } from './validation';

export const Regions = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Regions,
        translationKey: 'Regions',
        useApiList: useRegionList,
        useApiGet: useRegionGet,
        useApiExport: useRegionExport,
        useValidationSchema,
        formComponentHeight: 280,
        columns: [
          { dataKey: 'id', label: 'Identifier', type: TableColumnType.Number },
          { dataKey: 'name', label: 'Name' },
          { dataKey: 'code', label: 'Code' },
          { dataKey: 'minimalNetSalary', label: 'MinimalNetSalary', type: TableColumnType.Number },
          { dataKey: 'directDebitLimit', label: 'DirectDebitLimit', type: TableColumnType.Number },
          {
            dataKey: 'hunterReferralBonus',
            label: 'HunterReferralBonus',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'targetReferralBonus',
            label: 'TargetReferralBonus',
            type: TableColumnType.Number,
          },
        ],
        initialValues: {
          name: '',
          code: '',
          minimalNetSalary: 0,
          directDebitLimit: 0,
          hunterReferralBonus: 0,
          targetReferralBonus: 0,
        },
        formComponents: [
          {
            label: 'Region',
            component: FormBasic,
            fields: formBasicFields,
          },
        ],
        actions: [],
      }}
    />
  );
};
