export const Connectors = {
  Active: 'Aktívny',
  Code: 'Kód',
  Company: 'Spoločnosť',
  Connector: 'Konektor',
  Description: 'Opis',
  Identifier: 'Identifikátor',
  Name: 'Názov',
  Script: 'Skript',
  State: 'Stav',
  Version: 'Verzia',
};
