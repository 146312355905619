// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CreateRegistrationDto, EmailModel, FinishedRegistrationDtoApiDataResponse, RegistrationVerificationDtoApiDataResponse, VerifyQuestionRegistrationDto, VerifyQuestionRegistrationWithOneTimeCodeDto } from "../model";

export class RegistrationsApi extends APIHandler {
    static urls: APIURLTypes.RegistrationsApi = {
        apiV1ClientRegistrationsIdQuestionVerificationOneTimeCodePut: "/api/v1/client/registrations/{id}/question-verification/one-time-code",
        apiV1ClientRegistrationsIdQuestionVerificationPut: "/api/v1/client/registrations/{id}/question-verification",
        apiV1ClientRegistrationsPost: "/api/v1/client/registrations",
        apiV1ClientRegistrationsRegistrationIdRequestEmailCodePost: "/api/v1/client/registrations/{registrationId}/request-email-code"
    };
    
    constructor() {
        super("RegistrationsApi");
    }
    
    /**
     * Registrations step 2 with 2fa
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **REGISTRATION_STATE_INVALID** - Registration must be \&#39;IN_PROGRESS\&#39; or \&#39;FAILED\&#39; state. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. + **CONNECTOR_NO_CONNECTION** - There is no connection to any of the employer\&#39;s servers. Please try again later. + **CONNECTOR_NO_REPLY** - The employer\&#39;s server did not respond in a timely manner. Please try again later. + **REGISTRATION_FAILED** - Registration failed. 
     * @param id 
     * @param verifyQuestionRegistrationWithOneTimeCodeDto 
     */
    public apiV1ClientRegistrationsIdQuestionVerificationOneTimeCodePut(params: APIParams & {
        id: number;
        verifyQuestionRegistrationWithOneTimeCodeDto?: VerifyQuestionRegistrationWithOneTimeCodeDto;
    }): AxiosPromise<FinishedRegistrationDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(RegistrationsApi.urls.apiV1ClientRegistrationsIdQuestionVerificationOneTimeCodePut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.verifyQuestionRegistrationWithOneTimeCodeDto;
        return this.makeCall<FinishedRegistrationDtoApiDataResponse>("PUT".toLowerCase(), apiURL, "apiV1ClientRegistrationsIdQuestionVerificationOneTimeCodePut", params.options, body, "apiV1ClientRegistrationsIdQuestionVerificationOneTimeCodePut");
    }

    /**
     * Registrations step 2
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **REGISTRATION_STATE_INVALID** - Registration must be \&#39;IN_PROGRESS\&#39; or \&#39;FAILED\&#39; state. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. + **CONNECTOR_NO_CONNECTION** - There is no connection to any of the employer\&#39;s servers. Please try again later. + **CONNECTOR_NO_REPLY** - The employer\&#39;s server did not respond in a timely manner. Please try again later. + **REGISTRATION_FAILED** - Registration failed. 
     * @param id 
     * @param verifyQuestionRegistrationDto 
     */
    public apiV1ClientRegistrationsIdQuestionVerificationPut(params: APIParams & {
        id: number;
        verifyQuestionRegistrationDto?: VerifyQuestionRegistrationDto;
    }): AxiosPromise<FinishedRegistrationDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(RegistrationsApi.urls.apiV1ClientRegistrationsIdQuestionVerificationPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.verifyQuestionRegistrationDto;
        return this.makeCall<FinishedRegistrationDtoApiDataResponse>("PUT".toLowerCase(), apiURL, "apiV1ClientRegistrationsIdQuestionVerificationPut", params.options, body, "apiV1ClientRegistrationsIdQuestionVerificationPut");
    }

    /**
     * Registrations step 1
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_CODE_INVALID** - Company code was not found. 
     * @param createRegistrationDto 
     */
    public apiV1ClientRegistrationsPost(params: APIParams & {
        createRegistrationDto?: CreateRegistrationDto;
    } = {}): AxiosPromise<RegistrationVerificationDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(RegistrationsApi.urls.apiV1ClientRegistrationsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.createRegistrationDto;
        return this.makeCall<RegistrationVerificationDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationsPost", params.options, body, "apiV1ClientRegistrationsPost");
    }

    /**
     * When company has 2fa enabled, this endpoint allows to request a code to be send to email.
     *  ### Error names for HTTP Status 400 BadRequest: + **EMAIL_DELIVERY_FAILED** - Email notification delivery has failed. 
     * @param registrationId 
     * @param emailModel 
     */
    public apiV1ClientRegistrationsRegistrationIdRequestEmailCodePost(params: APIParams & {
        registrationId: number;
        emailModel?: EmailModel;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(RegistrationsApi.urls.apiV1ClientRegistrationsRegistrationIdRequestEmailCodePost, { registrationId: params.registrationId } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.emailModel;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationsRegistrationIdRequestEmailCodePost", params.options, body, "apiV1ClientRegistrationsRegistrationIdRequestEmailCodePost");
    }

}
