import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoading } from '@/components';
import { userMenuRoutes } from '@/routes';
import { useLogout } from '@/api';
import { Route } from '@/enums';
import { useAppContext } from '@/context';

export const useUserAccount = () => {
  const { t } = useTranslation('UserAccount');
  const navigate = useNavigate();
  const { removeLoggedUser } = useAppContext();
  const { mutateAsync } = useLogout();
  const { showLoading, hideLoading } = useLoading();

  const handleLogout = useCallback(async () => {
    showLoading();
    await mutateAsync();
    removeLoggedUser();
    hideLoading();
    navigate(Route.Login);
  }, [mutateAsync, removeLoggedUser, showLoading, hideLoading, navigate]);

  return [
    ...userMenuRoutes.map(({ label, path }) => ({
      label: t(label),
      onClick: () => navigate(path),
      'data-cy': `user-account-${label}`,
    })),
    {
      label: t('Logout'),
      onClick: handleLogout,
      'data-cy': 'user-account-logout',
    },
  ];
};
