import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ t, isStringRequired, isStringMinMaxLength }) => ({
    oldPassword: isStringRequired(),
    newPassword: isStringMinMaxLength(8, 200),
    newPasswordConfirm: isStringRequired().test(
      'match',
      t('PasswordsNotSame'),
      function (newPasswordConfirm) {
        return newPasswordConfirm === this.parent.newPassword;
      },
    ),
  }));
};
