// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Sex } from './sex';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class KycModel extends APIModel {
    name: string;
    birthnumber: string;
    birthDate: string;
    sex: Sex;
    permanentResidence: string;
    citizenship: string;
    phoneNumber: string;
    isPoliticallyExposedPerson: boolean;

    constructor(options?: APIModelOptions) {
        super(KycModel.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "name", type: "string" },
            { name: "birthnumber", type: "string" },
            { name: "birthDate", type: "string" },
            { name: "sex", type: "Sex" },
            { name: "permanentResidence", type: "string" },
            { name: "citizenship", type: "string" },
            { name: "phoneNumber", type: "string" },
            { name: "isPoliticallyExposedPerson", type: "boolean" }
        ];
    }
}




