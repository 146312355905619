import { AuthApi } from '../base';
import { ChangePasswordDto, LoginUserDto } from '../base/model';
import { useMutation } from 'react-query';
import { dto, params } from '../utils';
import { FormValues } from '@/components';

const api = new AuthApi();

export const useLogin = () => {
  return useMutation(async (values: FormValues) => {
    const response = await api.apiV1AdminAuthLoginPost(
      params({
        loginUserDto: dto(LoginUserDto, values),
      }),
    );

    return response.data.data;
  });
};

export const useLogout = () => {
  return useMutation(() => {
    return api.apiV1AdminAuthLogoutPut(params({}));
  });
};

export const useChangePassword = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminAuthPasswordPut(
      params({
        changePasswordDto: dto(ChangePasswordDto, values),
      }),
    );
  });
};
