import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { Tooltip } from '../Tooltip';
import { LabelWrapperProps, Props } from './types';

export const Checkbox = ({ value, onChange, label, tooltipText, ...props }: Props) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e, e.target.checked);
    },
    [onChange],
  );

  return (
    <LabelWrapper
      {...{
        label,
        tooltipText,
        component: (
          <MuiCheckbox
            {...{
              checked: value,
              onChange: handleChange,
              ...props,
            }}
          />
        ),
      }}
    />
  );
};

const LabelWrapper = ({ label, tooltipText, component }: LabelWrapperProps) => {
  const labeledComponent = tooltipText ? (
    <Tooltip {...{ title: tooltipText }}>
      <FormControlLabel {...{ label, control: component }} />
    </Tooltip>
  ) : (
    <FormControlLabel {...{ label, control: component }} />
  );

  return label ? labeledComponent : component;
};
