import { FormValues, ApiListOptions } from '@/components';
import { useQuery, useMutation } from 'react-query';
import { ApiKey } from '@/enums';
import { ConnectorsApi } from '../base';
import { SaveConnectorDto } from '../base/model';
import { params, dto, fileParams, downloadFileFromResponse, withCompanyId } from '../utils';

const api = new ConnectorsApi();

export const useConnectorList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Connectors, options], async () => {
    const response = await api.apiV1AdminConnectorsGet(params(options));

    return response.data;
  });
};

export const useConnectorExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminConnectorsXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useConnectorGet = (id: number) => {
  return useQuery([ApiKey.Connectors, id], async () => {
    const response = await api.apiV1AdminConnectorsIdGet(params({ id }));

    return response.data.data;
  });
};

export const useConnectorCreate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminConnectorsPost(
      params({
        saveConnectorDto: createSaveDto(values),
      }),
    );
  });
};

export const useConnectorUpdate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminConnectorsIdPut(
      params({
        id: values.id,
        saveConnectorDto: createSaveDto(values),
      }),
    );
  });
};

const createSaveDto = (values: FormValues) => dto(SaveConnectorDto, withCompanyId(values));
