// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class ChangePasswordDto extends APIModel {
    /**
    * Old password
    */
    oldPassword: string;
    /**
    * New password
    */
    newPassword: string;

    constructor(options?: APIModelOptions) {
        super(ChangePasswordDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "oldPassword", type: "string" },
            { name: "newPassword", type: "string" }
        ];
    }
}




