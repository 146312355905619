import { removeStyledProps } from '@/utils';
import { TableCell, TableBody as MuiTableBody, TableRow, styled } from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { useModule } from '../Module';
import { TableCellValue } from './TableCellValue';
import { TableBodyProps } from './types';

export const TableBody = ({
  data,
  columns,
  componentKey,
  rowClickEnabled = true,
  onRowClick,
}: TableBodyProps) => {
  const [lastSelectedId, setLastSelectedId] = useState<number | null>(null);
  const { itemId } = useModule();
  if (itemId) {
    if (lastSelectedId !== itemId) {
      setLastSelectedId(itemId);
    }
  }

  return (
    <MuiTableBody>
      {data.map((item) => {
        return (
          <StyledTableRow
            key={item.id}
            rowClickEnabled={rowClickEnabled}
            customSelected={item.id == lastSelectedId}
          >
            {columns.map(({ dataKey, ...rest }) => {
              const value = get(item, dataKey);

              return (
                <StyledTableCell
                  key={dataKey}
                  onClick={() => rowClickEnabled && onRowClick?.(item)}
                >
                  <TableCellValue {...{ ...rest, value, item, componentKey }} />
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        );
      })}
    </MuiTableBody>
  );
};

const StyledTableRow = styled(
  TableRow,
  removeStyledProps(['rowClickEnabled', 'customSelected']),
)<{ rowClickEnabled: boolean; customSelected: boolean }>(
  ({ theme, rowClickEnabled, customSelected }) => ({
    borderRadius: theme.shape.borderRadius,
    '&:hover > td > div': {
      backgroundColor: theme.palette.secondary.main,
    },
    'td > div': customSelected
      ? {
          backgroundColor: theme.palette.grey[500],
        }
      : {},
    ...(rowClickEnabled
      ? {
          cursor: 'pointer',
        }
      : {}),
  }),
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomWidth: 0,
  padding: 0,
  '&:first-of-type > div': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  '&:last-of-type > div': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));
