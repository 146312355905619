// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { QuestionResponse } from './question-response';
import { RegistrationType } from './registration-type';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class InitialRegistrationResponse extends APIModel {
    registrationType: RegistrationType;
    firstQuestion: QuestionResponse;
    secondQuestion: QuestionResponse;
    registrationToken: string;
    companyId: number;
    companyName: string;
    companyCity: string;
    companyCurrency: string;
    companyLegislation: string;
    legislationLinkPrefix: string;
    enrolledInReferralProgram: boolean;

    constructor(options?: APIModelOptions) {
        super(InitialRegistrationResponse.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "registrationType", type: "RegistrationType" },
            { name: "firstQuestion", type: "QuestionResponse" },
            { name: "secondQuestion", type: "QuestionResponse" },
            { name: "registrationToken", type: "string" },
            { name: "companyId", type: "number" },
            { name: "companyName", type: "string" },
            { name: "companyCity", type: "string" },
            { name: "companyCurrency", type: "string" },
            { name: "companyLegislation", type: "string" },
            { name: "legislationLinkPrefix", type: "string" },
            { name: "enrolledInReferralProgram", type: "boolean" }
        ];
    }
}




