import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import { Context } from 'react';
import { FieldContext } from './FieldContext';
import { FieldProps, FieldContext as TFieldContext } from './types';

export function Field({
  children,
  nonTouchedError,
  includeError = true,
  label,
  required,
  ...props
}: FieldProps) {
  const FC = FieldContext as Context<TFieldContext>;

  return (
    <FormikField {...props}>
      {({ field, meta: { error, touched } }: FormikFieldProps) => {
        const errorText =
          nonTouchedError || touched
            ? typeof error === 'object'
              ? error['id']
              : error
            : undefined;

        return (
          <FC.Provider
            value={{
              ...field,
              ...(includeError
                ? {
                    error: !!errorText,
                    helperText: errorText,
                  }
                : {}),
              label: required ? `${label} *` : label,
              'data-cy': `field-${props.name}`,
            }}
          >
            {children}
          </FC.Provider>
        );
      }}
    </FormikField>
  );
}
