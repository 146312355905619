export enum Route {
  AbsenceAll = '/absence-all',
  AbsenceCurrent = '/absence-current',
  ActiveUser = '/active-user',
  Answers = '/answers',
  AttendanceAll = '/attendance-all',
  AttendanceCurrent = '/attendance-current',
  Balances = '/balances',
  BankAuthorizations = '/bank-authorizations',
  Cards = '/cards',
  CardTransactions = '/card-transactions',
  Companies = '/companies',
  CompanyThemes = '/company-themes',
  Connectors = '/connectors',
  Dashboard = '/',
  DirectDebits = '/direct-debits',
  Help = '/help',
  Images = '/images',
  Login = '/login',
  LogFull = '/log-full',
  LogToday = '/log-today',
  Messages = '/messages',
  News = '/news',
  Payments = '/payments',
  PaymentsDemo = '/payments-demo',
  PaymentsVerifiedCzech = '/payments-verified-czech',
  PaymentsVerifiedSlovak = '/payments-verified-slovak',
  PasswordChange = '/change-password',
  Roles = '/roles',
  Regions = '/regions',
  Setting = '/setting',
  Users = '/users',
  OtherUsers = '/other-users',
  UserRoles = '/user-roles',
  UserViews = '/user-views',
  Workers = '/workers',
}
