// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ApiMetadata } from './api-metadata';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class ApiErrorResponse extends APIModel {
    message: string;
    metadata: ApiMetadata;
    errors: Array<string>;
    errorName: string;

    constructor(options?: APIModelOptions) {
        super(ApiErrorResponse.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "message", type: "string" },
            { name: "metadata", type: "ApiMetadata" },
            { name: "errors", type: "Array<string>" },
            { name: "errorName", type: "string" }
        ];
    }
}




