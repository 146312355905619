export const Balances = {
  Balance: 'Uzávierka',
  PayMonth: 'Mesiac',
  CompanyCode: 'Kód spoločnosti',
  CompanyName: 'Názov spoločnosti',
  DownloadErpSystem: 'Stiahnuť pre {{erpSystem}}',
  DownloadErpSystemText: 'Prajete si vygenerovať súbor na import do mzdového systému?',
  DownloadXls: 'Stiahnuť XLS',
  DownloadXlsText: 'Prajete si vygenerovať XLS súbor?',
  DownloadCompleted: 'Sťahovanie dokončené',
  TotalPaid: 'Suma platieb',
  TotalBonus: 'Suma bonusov',
  TotalFee: 'Suma poplatkov',
  TotalAdvance: 'Suma záloh',
  TotalCompanyFee: 'Suma poplatkov zaplatených spoločnosťou',
  TotalWorkerFee: 'Suma poplatkov zaplatených zamestnancom',
  TotalPaymentsCount: 'Počet platieb',
  TotalWorkersCount: 'Počet zamestnancov',
  TotalWorkersRegistered: 'Počet registrovaných',
  TotalWorkersActive: 'Počet aktívnych',
  TotalWorkersPaid: 'Vybrali',
  DateTimeCalculated: 'Dátum uzávierky',
  DateTimeDownloaded: 'Dátum exportu',
};
