import { useCompanyLookupsList } from '@/api/methods';
import {
  CheckboxField,
  InputField,
  Grid,
  FormComponentProps,
  ApiAutocompleteField,
} from '@/components';
import { useMemo } from 'react';

const company = {
  name: 'company',
  label: 'Company',
  required: true,
};

const version = { name: 'version', label: 'Version', required: true };

const active = { name: 'active', label: 'Active' };

export const formBasicFields = [company, version, active];

export const FormBasic = ({ disabled, t }: FormComponentProps) => {
  const tCompany = useMemo(
    () => ({ ...company, label: t(company.label), disabled, useApiList: useCompanyLookupsList }),
    [t, disabled],
  );
  const tVersion = useMemo(
    () => ({ ...version, label: t(version.label), disabled }),
    [t, disabled],
  );
  const tActive = useMemo(() => ({ ...active, label: t(active.label), disabled }), [t, disabled]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <ApiAutocompleteField {...tCompany} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InputField {...tVersion} />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CheckboxField {...tActive} />
      </Grid>
    </Grid>
  );
};
