import { useCompanyLookupsList } from '@/api';
import {
  InputField,
  Grid,
  FormComponentProps,
  DatePickerField,
  ApiAutocompleteField,
} from '@/components';
import { useAppContext } from '@/context';
import { useMemo } from 'react';

const company = {
  name: 'company',
  label: 'Company',
  required: true,
  multiple: true,
};
const datePickers = [
  { name: 'dateValidFrom', label: 'DateValidFrom', required: true },
  { name: 'dateValidTo', label: 'DateValidTo', required: true, nonTouchedError: true },
];
const inputs = [
  { name: 'type', label: 'Type', required: false, sm: 6, md: 4 },
  { name: 'caption', label: 'Caption', required: true, sm: 6, md: 8 },
  { name: 'preview', label: 'Preview', required: false, multiline: true, rows: 3 },
];

export const formBasicFieldsBulk = [company, ...datePickers, ...inputs];

export const FormBasicBulk = ({ disabled, t }: FormComponentProps) => {
  const { companyId } = useAppContext();

  const tCompany = useMemo(
    () => ({ ...company, label: t(company.label), disabled, useApiList: useCompanyLookupsList }),
    [t, disabled],
  );

  return (
    <Grid container>
      {datePickers.map((field) => (
        <Grid key={field.name} item xs={12} sm={6} md={3}>
          <DatePickerField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              dateOnly: true,
            }}
          />
        </Grid>
      ))}
      {!companyId && (
        <Grid item xs={12} md={6}>
          <ApiAutocompleteField {...tCompany} />
        </Grid>
      )}
      {inputs.map(({ sm = 12, md = 12, ...field }) => (
        <Grid
          key={field.name}
          {...{ item: true, xs: 12, sm, md: companyId ? (md === 4 ? 6 : 12) : md }}
        >
          <InputField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
    </Grid>
  );
};
