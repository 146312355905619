export const Dashboard = {
  Amount: 'Čiastka',
  Available: 'Na čerpanie',
  Company: 'Spoločnosť',
  Count: 'Počet',
  GlobalOverview: 'Globálny prehľad',
  Home: 'Vitajte',
  LoggedIn: 'Prihlásené',
  Month: 'Mesiac',
  NoData: 'Žiadne údaje na zobrazenie',
  NumberOfIndividualWithdrawals: 'Počet jednotlivých výberov',
  NumbersOfWorkers: 'Počet pracovníkov',
  PaymentRequested: 'Požiadolo',
  Realized: 'Vyčerpané',
  Registered: 'Registrované',
  TotalAmountOfWithdrawals: 'Celková čiastka výberov',
  TotalBudget: 'Celkový rozpočet',
};
