export const Form = {
  Close: 'Close',
  Errors: 'Check the form fields',
  FailedToUploadImage: 'Failed to upload image',
  Save: 'Save',
  Saved: 'Saved',
  SaveAndContinue: 'Save and continue',
  UploadImage: 'Upload image',
  UploadImageDropzone: "Drag 'n' drop some file here, or click to select file",
};
