// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class SaveCompanyThemeDto extends APIModel {
    /**
    * Theme is active
    */
    active: boolean;
    /**
    * Company identifier
    */
    companyId: number;
    /**
    * Version
    */
    version: string;
    /**
    * Metadata
    */
    metadata: string;

    constructor(options?: APIModelOptions) {
        super(SaveCompanyThemeDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "active", type: "boolean" },
            { name: "companyId", type: "number" },
            { name: "version", type: "string" },
            { name: "metadata", type: "string" }
        ];
    }
}




