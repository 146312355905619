import {
  useCompanyThemeCreate,
  useCompanyThemeExport,
  useCompanyThemeGet,
  useCompanyThemeList,
  useCompanyThemeUpdate,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { FormBasic, formBasicFields } from './FormBasic';
import { FormMetadata, formMetadataFields } from './FormMetadata';
import { useValidationSchema } from './validation';

export const CompanyThemes = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.CompanyThemes,
        translationKey: 'CompanyThemes',
        useApiList: useCompanyThemeList,
        useApiGet: useCompanyThemeGet,
        useApiCreate: useCompanyThemeCreate,
        useApiUpdate: useCompanyThemeUpdate,
        useApiExport: useCompanyThemeExport,
        useValidationSchema,
        formComponentHeight: 540,
        nonCloseSubmit: true,
        columns: [
          { dataKey: 'id', label: 'Id', type: TableColumnType.Number },
          { dataKey: 'company.name', sortKey: 'company', label: 'Company' },
          { dataKey: 'version', label: 'Version' },
          { dataKey: 'active', label: 'Active', type: TableColumnType.Boolean },
          { dataKey: 'size', label: 'Size', type: TableColumnType.Number, sortEnabled: false },
        ],
        initialValues: {
          version: '',
          metadata: '{}',
          active: false,
          company: null,
        },
        formComponents: [
          {
            label: 'Theme',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'Metadata',
            component: FormMetadata,
            fields: formMetadataFields,
          },
        ],
      }}
    />
  );
};
