import { RegistrationType } from '@/api/base/model';
import { ApiKey } from '@/enums';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const useRegistrationTypesList = () => {
  const { t } = useTranslation('RegistrationType');
  return useQuery([ApiKey.RegistrationTypes], async () => {
    return {
      data: [
        { id: RegistrationType.Default, name: t(RegistrationType.Default) },
        { id: RegistrationType.OneTimeEmailCode, name: t(RegistrationType.OneTimeEmailCode) },
      ],
      message: '',
      metadata: {
        totalItems: 0,
        offset: 0,
        limit: 0,
        payload: {},
      },
    };
  });
};
