export const Users = {
  Company: 'Spoločnosť',
  FirstName: 'Meno',
  LastName: 'Priezvisko',
  Username: 'Používateľský účet',
  Identifier: 'Identifikátor',
  CompanyName: 'Spoločnosť',
  CompanyCode: 'Kód spoločnosti',
  User: 'Používateľ',
  Password: 'Heslo',
  NewPassword: 'Nové heslo',
  ConfirmNewPassword: 'Potvrďte nové heslo',
  Roles: 'Roly',
  Warning: 'Upozornenie',
  WarningText:
    'Ak editujete svoje role, tak uložením dôjde k odhláseniu a môžete prísť o nejaké prístupy v aplikácii.',
};
