import { FormValues } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { SettingApi } from '../base';
import { SettingDto } from '../base/model';
import { dto, params } from '../utils';

const api = new SettingApi();

export const useSettingGet = () => {
  return useQuery([ApiKey.Setting], async () => {
    const response = await api.apiV1AdminSettingGet(params());

    return response.data.data;
  });
};

export const useSettingUpdate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminSettingPut(
      params({
        settingDto: dto(SettingDto, values),
      }),
    );
  });
};
