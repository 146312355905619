// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class RegionDetailDto extends APIModel {
    /**
    * Region identifier
    */
    id: number;
    /**
    * Region name
    */
    name: string;
    /**
    * Region code
    */
    code: string;
    /**
    * Region minimal net salary
    */
    minimalNetSalary: number;
    /**
    * Regional limit of direct debit
    */
    directDebitLimit: number;
    /**
    * Regional amount for hunter referral bonus
    */
    hunterReferralBonus: number;
    /**
    * Regional amount for target referral bonus
    */
    targetReferralBonus: number;

    constructor(options?: APIModelOptions) {
        super(RegionDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "name", type: "string" },
            { name: "code", type: "string" },
            { name: "minimalNetSalary", type: "number" },
            { name: "directDebitLimit", type: "number" },
            { name: "hunterReferralBonus", type: "number" },
            { name: "targetReferralBonus", type: "number" }
        ];
    }
}




