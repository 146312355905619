// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class WorkerDetails extends APIModel {
    idws: string;
    idwa: string;
    code: string;
    roleId: string;
    firstName: string;
    lastName: string;
    accountNumber: string;
    accountBankCode: string;
    accountIban: string;
    dismissDate: string;
    salaryChanged: string;
    salaryPercentLimit: number;
    hourSalaryAvg: number;
    monthSalary: number;
    wageDeduction: number;
    workingTime: number;
    active: boolean;
    draftBudget: number;
    data: { [key: string]: string; };

    constructor(options?: APIModelOptions) {
        super(WorkerDetails.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "idws", type: "string" },
            { name: "idwa", type: "string" },
            { name: "code", type: "string" },
            { name: "roleId", type: "string" },
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "accountNumber", type: "string" },
            { name: "accountBankCode", type: "string" },
            { name: "accountIban", type: "string" },
            { name: "dismissDate", type: "string" },
            { name: "salaryChanged", type: "string" },
            { name: "salaryPercentLimit", type: "number" },
            { name: "hourSalaryAvg", type: "number" },
            { name: "monthSalary", type: "number" },
            { name: "wageDeduction", type: "number" },
            { name: "workingTime", type: "number" },
            { name: "active", type: "boolean" },
            { name: "draftBudget", type: "number" },
            { name: "data", type: "{ [key: string]: string; }" }
        ];
    }
}




