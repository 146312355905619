// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CreateImageDto, CreatedEntityDtoApiDataResponse, ImageDetailDtoApiDataResponse, ImageDtoListApiDataResponse, ImageUsedDtoApiDataResponse, UpdateImageDto } from "../model";

export class ImagesApi extends APIHandler {
    static urls: APIURLTypes.ImagesApi = {
        apiV1AdminImagesGet: "/api/v1/admin/images",
        apiV1AdminImagesIdDelete: "/api/v1/admin/images/{id}",
        apiV1AdminImagesIdGet: "/api/v1/admin/images/{id}",
        apiV1AdminImagesIdPut: "/api/v1/admin/images/{id}",
        apiV1AdminImagesIdUsedGet: "/api/v1/admin/images/{id}/used",
        apiV1AdminImagesPost: "/api/v1/admin/images",
        apiV1AdminImagesXlsxGet: "/api/v1/admin/images/xlsx",
        apiV1ClientImagesIdGet: "/api/v1/client/images/{id}"
    };
    
    constructor() {
        super("ImagesApi");
    }
    
    /**
     * Get images
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminImagesGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<ImageDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ImageDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminImagesGet", params.options, body, "apiV1AdminImagesGet");
    }

    /**
     * Delete image
     * @param id 
     */
    public apiV1AdminImagesIdDelete(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesIdDelete, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("DELETE".toLowerCase(), apiURL, "apiV1AdminImagesIdDelete", params.options, body, "apiV1AdminImagesIdDelete");
    }

    /**
     * Get image detail
     * @param id 
     */
    public apiV1AdminImagesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<ImageDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ImageDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminImagesIdGet", params.options, body, "apiV1AdminImagesIdGet");
    }

    /**
     * Update image
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. 
     * @param id 
     * @param updateImageDto 
     */
    public apiV1AdminImagesIdPut(params: APIParams & {
        id: number;
        updateImageDto?: UpdateImageDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.updateImageDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminImagesIdPut", params.options, body, "apiV1AdminImagesIdPut");
    }

    /**
     * Check if image is used
     * @param id 
     */
    public apiV1AdminImagesIdUsedGet(params: APIParams & {
        id: number;
    }): AxiosPromise<ImageUsedDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesIdUsedGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ImageUsedDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminImagesIdUsedGet", params.options, body, "apiV1AdminImagesIdUsedGet");
    }

    /**
     * Create image
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param createImageDto 
     */
    public apiV1AdminImagesPost(params: APIParams & {
        createImageDto?: CreateImageDto;
    } = {}): AxiosPromise<CreatedEntityDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.createImageDto;
        return this.makeCall<CreatedEntityDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminImagesPost", params.options, body, "apiV1AdminImagesPost");
    }

    /**
     * Get images export in XLSX format.
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminImagesXlsxGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1AdminImagesXlsxGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminImagesXlsxGet", params.options, body, "apiV1AdminImagesXlsxGet");
    }

    /**
     * Get image public
     * @param id 
     */
    public apiV1ClientImagesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(ImagesApi.urls.apiV1ClientImagesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("GET".toLowerCase(), apiURL, "apiV1ClientImagesIdGet", params.options, body, "apiV1ClientImagesIdGet");
    }

}
