import { DatePicker, DatePickerProps, DatePickerValue } from '../../DatePicker';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';

export const DatePickerComponent = (props: DatePickerProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: DatePickerValue) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <DatePicker {...{ ...props, ...field, onChange: handleChange }} />;
};

export const DatePickerField = (
  fieldProps: FormFieldProps<Omit<DatePickerProps, 'value' | 'onChange' | 'error' | 'helperText'>>,
) => {
  const restProps = splitProps(fieldProps, [
    'className',
    'disabled',
    'dateOnly',
    'monthOnly',
  ]) as DatePickerProps;

  return (
    <Field {...fieldProps}>
      <DatePickerComponent {...restProps} />
    </Field>
  );
};
