import MuiGlobalStyles from '@mui/material/GlobalStyles';
import { colorBlack, fontFamily } from './constants';

const disabledInputColor = `${colorBlack} !important`;

const disabled = {
  '&.Mui-disabled': {
    opacity: '0.85 !important',
    color: disabledInputColor,
    '-webkit-text-fill-color': disabledInputColor,
  },
};

export const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={{
        body: { margin: 0, fontFamily },
        input: {
          ...disabled,
        },
        label: {
          ...disabled,
        },
      }}
    />
  );
};
