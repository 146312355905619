// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CardStatus } from './card-status';
import { KeyValuePairDto } from './key-value-pair-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CardDto extends APIModel {
    /**
    * Card id within PalmApp
    */
    id: number;
    /**
    * Card identifier assigned by card provider
    */
    cardIdentifier: string;
    /**
    * Card identifier within card scheme
    */
    mcid: string;
    /**
    * Card number (masked)
    */
    pan: string;
    status: CardStatus;
    /**
    * Card activation date
    */
    activationDate: string;
    worker: KeyValuePairDto;
    company: KeyValuePairDto;

    constructor(options?: APIModelOptions) {
        super(CardDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "cardIdentifier", type: "string" },
            { name: "mcid", type: "string" },
            { name: "pan", type: "string" },
            { name: "status", type: "CardStatus" },
            { name: "activationDate", type: "string" },
            { name: "worker", type: "KeyValuePairDto" },
            { name: "company", type: "KeyValuePairDto" }
        ];
    }
}




