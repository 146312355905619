import { QueryClient } from 'react-query';

type OnErrorCallback = (error: unknown) => void;

export const createApiClient = (onError: OnErrorCallback) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        onError,
      },
      mutations: {
        retry: false,
        onError,
      },
    },
  });
