import { useState } from 'react';
import { Props } from './types';
import { Button, Menu } from '@/components';

export const Dropdown = ({ items, icon: Icon, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonProps = { onClick: handleClick };

  return (
    <>
      {Icon ? (
        <Icon {...buttonProps} />
      ) : (
        <Button {...{ ...buttonProps, variant: 'outlined', ...props }} />
      )}
      <Menu {...{ items, anchorEl, onClose: handleClose, open: true }} />
    </>
  );
};
