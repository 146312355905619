// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class ImageDetailDto extends APIModel {
    /**
    * Image identifier
    */
    id: number;
    /**
    * Image type
    */
    type: string;
    /**
    * Filename
    */
    fileName: string;
    /**
    * File type
    */
    fileType: string;
    /**
    * Image in base64
    */
    fileData: string;
    /**
    * Date of creation
    */
    created: string;
    /**
    * Date of modification
    */
    lastModified: string;

    constructor(options?: APIModelOptions) {
        super(ImageDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "type", type: "string" },
            { name: "fileName", type: "string" },
            { name: "fileType", type: "string" },
            { name: "fileData", type: "string" },
            { name: "created", type: "string" },
            { name: "lastModified", type: "string" }
        ];
    }
}




