// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { KeyValuePairDto } from './key-value-pair-dto';
import { MessageActionDto } from './message-action-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class MessageDetailDto extends APIModel {
    /**
    * Message identifier
    */
    id: number;
    company: KeyValuePairDto;
    /**
    * Message caption
    */
    caption: string;
    /**
    * Message type
    */
    type: string;
    /**
    * Message preview
    */
    preview: string;
    /**
    * Message content
    */
    markdownData: string;
    /**
    * Date of creation of message
    */
    created: string;
    /**
    * Date from which the message is valid
    */
    dateValidFrom: string;
    /**
    * Date until which the message is valid
    */
    dateValidTo: string;
    /**
    * Message actions
    */
    messageActions: Array<MessageActionDto>;

    constructor(options?: APIModelOptions) {
        super(MessageDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "company", type: "KeyValuePairDto" },
            { name: "caption", type: "string" },
            { name: "type", type: "string" },
            { name: "preview", type: "string" },
            { name: "markdownData", type: "string" },
            { name: "created", type: "string" },
            { name: "dateValidFrom", type: "string" },
            { name: "dateValidTo", type: "string" },
            { name: "messageActions", type: "Array<MessageActionDto>" }
        ];
    }
}




