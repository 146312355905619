// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, LoginModel, LoginResponse } from "../model";

export class AuthenticationApi extends APIHandler {
    static urls: APIURLTypes.AuthenticationApi = {
        apiV1ClientAuthenticationLoginPost: "/api/v1/client/authentication/login",
        apiV1ClientAuthenticationLogoutPut: "/api/v1/client/authentication/logout"
    };
    
    constructor() {
        super("AuthenticationApi");
    }
    
    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **PASSWORD_MISMATCH** - Entered passwords are not the same. 
     * @param loginModel 
     */
    public apiV1ClientAuthenticationLoginPost(params: APIParams & {
        loginModel?: LoginModel;
    } = {}): AxiosPromise<LoginResponse> {
        const apiURL: string = this.initAPIURL(AuthenticationApi.urls.apiV1ClientAuthenticationLoginPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.loginModel;
        return this.makeCall<LoginResponse>("POST".toLowerCase(), apiURL, "apiV1ClientAuthenticationLoginPost", params.options, body, "apiV1ClientAuthenticationLoginPost");
    }

    /**
     */
    public apiV1ClientAuthenticationLogoutPut(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(AuthenticationApi.urls.apiV1ClientAuthenticationLogoutPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("PUT".toLowerCase(), apiURL, "apiV1ClientAuthenticationLogoutPut", params.options, body, "apiV1ClientAuthenticationLogoutPut");
    }

}
