import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isStringRequired, isStringMaxLength, isEntity }) => ({
    version: isStringMaxLength(100),
    metadata: isStringRequired(),
    company: isEntity('Company'),
  }));
};
