// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class MonthOverviewDto extends APIModel {
    /**
    * Date when payment was requested
    */
    dateRequested: string;
    /**
    * Sum of amounts of payments
    */
    amount: number;
    /**
    * Counts of payments
    */
    count: number;

    constructor(options?: APIModelOptions) {
        super(MonthOverviewDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "dateRequested", type: "string" },
            { name: "amount", type: "number" },
            { name: "count", type: "number" }
        ];
    }
}




