import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
  ThemeProvider,
  createTheme,
  styled,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import {
  DrawerProps,
  ItemsProps,
  LinkButtonProps,
  ListItemButtonProps,
  ListItemLabelProps,
  ListItemTextProps,
  Props,
} from './types';
import { appHeaderHeight, appSidebarWidth } from '@/styles';
import { FOOTER_PART_1, FOOTER_PART_2 } from '@/constants';
import { useTranslation } from 'react-i18next';
import { removeStyledProps, useSmallScreen } from '@/utils';
import { useCallback } from 'react';
import { LanguageDropdown } from '@/components';
import { useUserAccount } from '../useUserAccount';
import { Logo } from '../Logo';

export const Sidebar = ({ items, open, openedItem, onToggleItem, onClose }: Props) => {
  const location = useLocation();
  const smallScreen = useSmallScreen();
  const userAccountItems = useUserAccount();

  const onClickCallback = useCallback(() => smallScreen && onClose(), [smallScreen, onClose]);

  return (
    <ThemeProvider
      theme={(theme) =>
        createTheme({
          ...theme,
          components: {
            MuiButtonBase: {
              defaultProps: {
                disableRipple: true,
              },
            },
          },
        })
      }
    >
      <DrawerWrapper
        {...{
          open,
          collapsed: !open,
          variant: smallScreen ? 'temporary' : 'permanent',
          onClose,
          'data-cy': 'sidebar',
        }}
      >
        {smallScreen && (
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        )}
        {mapItems({
          items,
          openedItem,
          onToggleItem,
          onClickCallback,
          activePath: location.pathname,
        })}
        {smallScreen ? (
          <>
            <SmallScreenVerticalSpace />
            <StyledLanguageDropdown />
            {mapItems({ items: userAccountItems, onClickCallback })}
          </>
        ) : (
          <Footer>
            <div>{FOOTER_PART_1}</div>
            <div>{FOOTER_PART_2}</div>
          </Footer>
        )}
      </DrawerWrapper>
    </ThemeProvider>
  );
};

const mapItems = ({
  items: outterItems,
  openedItem,
  onToggleItem,
  onClickCallback,
  isNested = false,
  activePath,
}: ItemsProps) => {
  return (
    <List>
      {outterItems.map(({ path, label, items, onClick }) => {
        const isOpened = openedItem === label;
        const isActive = !!(activePath && path === activePath);
        const hasActiveChild = !!items?.find((item) => item.path === activePath);

        return (
          <div key={label}>
            <ListItem disablePadding>
              <LinkButton {...{ path, onClick: onClickCallback }}>
                <StyledListItemButton
                  {...{
                    isActive,
                    isNested,
                    onClick: () => {
                      if (onClick) {
                        if (onClickCallback) {
                          onClickCallback();
                        }
                        onClick();
                      }
                      onToggleItem?.(items ? label : undefined);
                    },
                    'data-cy': path ?? label,
                  }}
                >
                  <ListItemLabel {...{ label, isActive, isBold: isActive || hasActiveChild }} />
                  {items ? isOpened ? <ExpandLess /> : <ExpandMore /> : <></>}
                </StyledListItemButton>
              </LinkButton>
            </ListItem>
            {items && (
              <Collapse in={isOpened}>
                {mapItems({ items, activePath, isNested: true, onClickCallback })}
              </Collapse>
            )}
          </div>
        );
      })}
    </List>
  );
};

const LinkButton = ({ path, children, onClick }: LinkButtonProps) =>
  path ? (
    <LinkContainer {...{ onClick }}>
      <StyledLink to={path}>{children}</StyledLink>
    </LinkContainer>
  ) : (
    <>{children}</>
  );

const ListItemLabel = ({ label, ...props }: ListItemLabelProps) => {
  const { t } = useTranslation('Routes');

  return <StyledListItemText {...{ primary: t(label), ...props }} />;
};

const DrawerWrapper = styled(
  Drawer,
  removeStyledProps('collapsed'),
)<DrawerProps>(({ theme, variant, collapsed }) =>
  variant == 'temporary'
    ? {}
    : {
        width: collapsed ? theme.spacing(3) : appSidebarWidth,
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        flexShrink: 0,
        transition: theme.transitions.create(['width', 'transform']),
        ['& .MuiDrawer-paper']: {
          width: collapsed ? 0 : appSidebarWidth,
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
          boxSizing: 'border-box',
          position: 'static',
          borderRightWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
);

const Footer = styled(Box)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(2)})`,
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  fontSize: '0.8em',
  textAlign: 'center',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  width: '100%',
}));

const LinkContainer = styled(Box)({
  width: '100%',
});

const StyledListItemButton = styled(
  ListItemButton,
  removeStyledProps(['isActive', 'isNested']),
)<ListItemButtonProps>(({ theme, isActive, isNested }) =>
  isActive
    ? {
        padding: `0 0 0 ${theme.spacing(isNested ? 3 : 1)} !important`,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      }
    : { paddingLeft: theme.spacing(isNested ? 5 : 3) },
);

const StyledListItemText = styled(
  ListItemText,
  removeStyledProps(['isActive', 'isBold']),
)<ListItemTextProps>(({ theme, isActive, isBold }) => ({
  ...(isActive
    ? {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 20,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        flex: '0 1 auto',
      }
    : {}),
  ...(isBold ? { '& > span': { fontWeight: '600' } } : {}),
}));

const StyledLanguageDropdown = styled(LanguageDropdown)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}`,
  borderRadius: 0,
  justifyContent: 'flex-start',
}));

const SmallScreenVerticalSpace = styled(Box)({ flex: 1 });

const LogoWrapper = styled(Box)(({ theme }) => ({
  width: appSidebarWidth,
  minWidth: appSidebarWidth,
  height: appHeaderHeight,
  minHeight: appHeaderHeight,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
}));
