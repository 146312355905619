import { IconButton, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import MuiList from '@mui/material/List';
import { Props } from './types';

export const List = ({
  items,
  action,
  onClick,
  getId = (item) => item.id,
  getLabel = (item) => item.label,
}: Props) => {
  const ActionIcon = action?.Icon;

  return (
    <StyledList>
      {items.map((item) => {
        const id = getId(item);

        return (
          <ListItem
            key={id}
            secondaryAction={
              action && (
                <IconButton {...{ onClick: () => action.onClick(item) }}>
                  <ActionIcon />
                </IconButton>
              )
            }
            disablePadding
          >
            <StyledListItemButton onClick={() => onClick?.(item)}>
              <ListItemText primary={getLabel(item)} />
            </StyledListItemButton>
          </ListItem>
        );
      })}
    </StyledList>
  );
};

const StyledList = styled(MuiList)({
  maxWidth: 'none',
  width: '100%',
});

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[100],
  margin: `${theme.spacing(0.5)} 0`,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
