import { InputField, Grid, FormComponentProps, DatePickerField } from '@/components';
import { getCurrencySymbol } from '@/utils';

const inputs = [
  { name: 'companyCode', label: 'CompanyCode' },
  { name: 'companyName', label: 'CompanyName', sm: 8 },
];

const datePickers = [
  { name: 'payMonth', label: 'PayMonth', monthOnly: true },
  { name: 'dateTimeCalculated', label: 'DateTimeCalculated' },
  { name: 'dateTimeDownloaded', label: 'DateTimeDownloaded' },
];

const inputValues = [
  {
    name: 'totalPaid',
    label: 'TotalPaid',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'totalBonus',
    label: 'TotalBonus',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'totalAdvance',
    label: 'TotalAdvance',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'totalFee',
    label: 'TotalFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'totalCompanyFee',
    label: 'TotalCompanyFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'totalWorkerFee',
    label: 'TotalWorkerFee',
    getEndAdornment: getCurrencySymbol,
  },
  { name: 'totalWorkersCount', label: 'TotalWorkersCount' },
  { name: 'totalWorkersRegistered', label: 'TotalWorkersRegistered' },
  { name: 'totalWorkersActive', label: 'TotalWorkersActive' },
  { name: 'totalWorkersPaid', label: 'TotalWorkersPaid' },
  { name: 'totalPaymentsCount', label: 'TotalPaymentsCount' },
];

export const formBasicFields = [...inputs, ...datePickers, ...inputValues];

export const FormBasic = ({ disabled, t, values }: FormComponentProps) => {
  return (
    <Grid container>
      {inputs.map(({ sm = 4, ...field }) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm }}>
          <InputField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
      {datePickers.map((field) => (
        <Grid key={field.name} item xs={12} sm={4}>
          <DatePickerField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
      {inputValues.map(({ getEndAdornment = () => undefined, ...field }) => (
        <Grid key={field.name} item xs={12} sm={4}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              endAdornmentString: getEndAdornment(values.currency),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
