// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionState {
    None = 'None',
    Requested = 'Requested',
    Verified = 'Verified',
    Processed = 'Processed',
    SendForProcessing = 'SendForProcessing',
    Pending = 'Pending',
    Authorized = 'Authorized',
    Reversed = 'Reversed',
    InBankPendingAuth = 'InBankPendingAuth',
    InBankLocked = 'InBankLocked',
    InBankAuthExpired = 'InBankAuthExpired',
    Error = 'Error'
}



