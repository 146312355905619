// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse } from "../model";

export class Version1MigrationApi extends APIHandler {
    static urls: APIURLTypes.Version1MigrationApi = {
        apiLoginPinPost: "/api/login/pin",
        apiRegistrationStep1Post: "/api/registration/step1"
    };
    
    constructor() {
        super("Version1MigrationApi");
    }
    
    /**
     */
    public apiLoginPinPost(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(Version1MigrationApi.urls.apiLoginPinPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiLoginPinPost", params.options, body, "apiLoginPinPost");
    }

    /**
     */
    public apiRegistrationStep1Post(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(Version1MigrationApi.urls.apiRegistrationStep1Post, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiRegistrationStep1Post", params.options, body, "apiRegistrationStep1Post");
    }

}
