import { useTranslation } from 'react-i18next';
import { cs, enUS as en, sk } from 'date-fns/locale';
import { languages } from './enums';
import { Language } from './types';

export const parseLanguage = (language: string) => {
  const parsedLanguage = language.replace(/-.+$/, '').toLowerCase();

  return (languages.includes(parsedLanguage) ? parsedLanguage : 'en') as Language;
};

export const toLocale = (language: Language) => {
  switch (language) {
    case 'cs':
      return 'csCZ';
    case 'sk':
      return 'skSK';
    default:
      return 'enUS';
  }
};

export const useLanguage = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return parseLanguage(language);
};

const locales = { cs, en, sk };

export const useLocale = () => {
  const language = useLanguage();

  return locales[language];
};
