import { useLoading, useSnackbar } from '@/components';
import { AxiosError } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { QueryClientProvider } from 'react-query';
import { createApiClient } from './ApiClient';
import { BusinessError } from './types';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@/context';
import { useNavigate } from 'react-router-dom';
import { Route } from '@/enums';

export const ApiClientProvider = ({ children }: React.PropsWithChildren) => {
  const { hideLoading } = useLoading();
  const { showErrorSnackbar } = useSnackbar();
  const { removeLoggedUser } = useAppContext();
  const { t } = useTranslation('Errors');
  const navigate = useNavigate();

  const onErrorApiClientCallback = useCallback(
    (error: unknown) => {
      hideLoading();

      let message = t('DEFAULT');

      if (error && error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        const data = axiosError.response?.data;
        const status = axiosError.response?.status;

        if (status === 400 && data && typeof data === 'object' && 'errorName' in data) {
          message = t((data as BusinessError).errorName);
        } else if (status && [401, 403, 404, 500].includes(status)) {
          message = t(`ERROR_${status}`);
          if (status === 401) {
            removeLoggedUser();
            navigate(Route.Login);
          }
        } else if (axiosError.message) {
          message = axiosError.message;
        }
      }

      showErrorSnackbar(message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const client = useMemo(
    () => createApiClient(onErrorApiClientCallback),
    [onErrorApiClientCallback],
  );

  return <QueryClientProvider {...{ client }}>{children}</QueryClientProvider>;
};
