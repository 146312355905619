import { InputField, Grid, FormComponentProps } from '@/components';
import { removeStyledProps } from '@/utils';
import { styled, Typography } from '@mui/material';
import { flatten } from 'lodash';

const groups = [
  {
    groupName: 'firstVerification',
    title: 'FirstVerification',
    required: true,
  },
  {
    groupName: 'secondVerification',
    title: 'SecondVerification',
    withMargin: true,
  },
];

const getFields = (groupName: string) =>
  [
    { name: 'caption', label: 'Caption' },
    { name: 'toolTip', label: 'Description', md: 8 },
    { name: 'example', label: 'Example' },
    { name: 'format', label: 'Format', md: 8 },
  ].map((field) => ({ ...field, name: `${groupName}.${field.name}` }));

export const formVerificationFields = flatten(groups.map(({ groupName }) => getFields(groupName)));

export const FormVerification = (props: FormComponentProps) => {
  return (
    <Grid container>
      {groups.map((group) => (
        <Component key={group.groupName} {...{ ...props, ...group }} />
      ))}
    </Grid>
  );
};

const Component = ({
  disabled,
  t,
  groupName,
  title,
  withMargin,
  required = false,
}: FormComponentProps & {
  groupName: string;
  title: string;
  withMargin?: boolean;
  required?: boolean;
}) => {
  return (
    <Container item xs={12} withMargin={withMargin}>
      <div>
        <Title>{t(title)}</Title>
        <Grid container>
          {getFields(groupName).map(({ md = 4, ...field }) => (
            <Grid key={field.name} {...{ item: true, xs: 12, md }}>
              <InputField
                {...{
                  ...field,
                  label: t(field.label),
                  disabled,
                  required,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

const Container = styled(
  Grid,
  removeStyledProps('withMargin'),
)<{ withMargin?: boolean }>(({ theme, withMargin }) => ({
  ...(withMargin ? { marginTop: theme.spacing(2) } : {}),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
