import { useCompanyLookupsList } from '@/api';
import {
  CheckboxField,
  InputField,
  Grid,
  FormComponentProps,
  ApiAutocompleteField,
} from '@/components';
import { useMemo } from 'react';

const inputs = [
  { name: 'name', label: 'Name', required: true, sm: 8 },
  { name: 'code', label: 'Code', required: true },
  { name: 'description', label: 'Description', multiline: true, rows: 10, sm: 12 },
];

const company = {
  name: 'company',
  label: 'Company',
  required: true,
  params: { demo: false },
};

const version = { name: 'version', label: 'Version', required: true };

const active = { name: 'active', label: 'Active' };

export const formBasicFields = [...inputs, company, version, active];

export const FormBasic = ({ disabled, t, initialValues }: FormComponentProps) => {
  const tCompany = useMemo(
    () => ({
      ...company,
      label: t(company.label),
      disabled,
      defaultItem: initialValues.company,
      useApiList: useCompanyLookupsList,
    }),
    [t, disabled, initialValues.company],
  );
  const tVersion = useMemo(
    () => ({ ...version, label: t(version.label), disabled }),
    [t, disabled],
  );
  const tActive = useMemo(() => ({ ...active, label: t(active.label), disabled }), [t, disabled]);

  return (
    <Grid container>
      {inputs.map(({ sm = 4, ...field }) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm }}>
          <InputField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
      <Grid item xs={12} md={6}>
        <ApiAutocompleteField key={initialValues.company?.id} {...tCompany} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InputField {...tVersion} />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CheckboxField {...tActive} />
      </Grid>
    </Grid>
  );
};
