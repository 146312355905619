import { Input, InputProps } from '../../Input';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';

export const InputComponent = (props: InputProps) => {
  const field = useField();

  return <Input {...props} {...field} />;
};

export const InputField = (fieldProps: FormFieldProps<InputProps>) => {
  const restProps = splitProps(fieldProps, [
    'className',
    'type',
    'disabled',
    'multiline',
    'rows',
    'endAdornmentString',
  ]) as InputProps;

  return (
    <Field {...fieldProps}>
      <InputComponent {...restProps} />
    </Field>
  );
};
