export const DirectDebits = {
  Amount: 'Částka',
  Created: 'Vytvořeno',
  DirectDebits: 'Inkasa',
  DirectDebit: 'Inkaso',
  DirectDebitRequest: 'Zadatné do banky',
  DirectDebitRequestConfirmText: 'Označit inkaso jako zadané do banky?',
  DirectDebitRequestSuccess: 'Inkaso bylo označeno jako zadané do banky.',
  DirectDebitProcess: 'Zpracovat',
  DirectDebitProcessConfirmText: 'Opravdu je inkaso odesláno?',
  DirectDebitProcessSuccess: 'Inkaso bylo zpracováno',
  Identifier: 'Identifikátor',
  CompanyCode: 'Kód společnosti',
  CompanyName: 'Název společnosti',
  DownloadXlsx: 'Stáhnout XLSX',
  DownloadXlsxText: 'Přejete si vygenerovat XLSX soubor?',
  DownloadCompleted: 'Stahování dokončeno',
  Status: 'Stav',
  PayMonth: 'K výplatě',
};
