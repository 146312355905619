import { useCompanyMonthOverview } from '@/api';
import { useTranslation } from 'react-i18next';
import { removeStyledProps, useDateFormat } from '@/utils';
import { Box, styled, Typography } from '@mui/material';
import {
  ComposedChart as RechartsComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
  TooltipProps,
} from 'recharts';
import { ComposedChartProps } from './types';
import { getColor } from './utils';

const amountColor = getColor(0);
const countColor = getColor(1);

export const ComposedChart = ({ companyId, month }: ComposedChartProps) => {
  const { data } = useCompanyMonthOverview(companyId, month);

  const { t } = useTranslation('Dashboard');
  const { formatDay } = useDateFormat();

  return (
    <RechartsComposedChart
      data={data ?? []}
      width={1800}
      height={350}
      margin={{
        top: 20,
        left: 50,
        right: 50,
      }}
    >
      <CartesianGrid strokeDasharray='3' />
      <XAxis dataKey='dateRequested' scale='band' tickFormatter={(value) => formatDay(value)} />
      <YAxis
        label={{
          value: t('TotalAmountOfWithdrawals'),
          angle: -90,
          offset: 100,
          position: 'insideTopRight',
        }}
        yAxisId='left'
        dataKey='amount'
        stroke={amountColor}
      />
      <YAxis
        label={{
          value: t('NumberOfIndividualWithdrawals'),
          angle: 90,
          offset: 70,
          position: 'insideTopLeft',
        }}
        yAxisId='right'
        dataKey='count'
        orientation='right'
        stroke={countColor}
        allowDecimals={false}
      />
      <Tooltip content={<TooltipContent />} />
      <Bar yAxisId='left' dataKey='amount' barSize={20} fill={amountColor} />
      <Line yAxisId='right' type='monotone' dataKey='count' stroke={countColor} />
    </RechartsComposedChart>
  );
};

const TooltipContent = ({ active, payload }: TooltipProps<string | number, string>) => {
  const { t } = useTranslation('Dashboard');
  const { formatDate } = useDateFormat();

  if (active && payload && payload.length) {
    const { dateRequested, amount, count } = payload[0].payload;

    return (
      <TooltipContainer>
        <TooltipTitle variant='h6'>{formatDate(dateRequested)}</TooltipTitle>
        <TooltipLabel color={amountColor}>{`${t('Amount')} : ${amount}`}</TooltipLabel>
        <TooltipLabel color={countColor}>{`${t('Count')} : ${count}`}</TooltipLabel>
      </TooltipContainer>
    );
  }

  return null;
};

const TooltipContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[500]}`,
}));

const TooltipTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const TooltipLabel = styled(
  Typography,
  removeStyledProps('color'),
)<{ color: string }>(({ color }) => ({
  color,
}));
