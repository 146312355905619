import { InputField, Grid, FormComponentProps, ImageUpload } from '@/components';
import { useFormikContext } from 'formik';

const inputs = [
  { name: 'type', label: 'Type', required: true },
  { name: 'fileName', label: 'FileName', required: true },
];

export const formBasicFields = [...inputs];

export const FormBasic = ({ disabled, values, t }: FormComponentProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Grid container>
      {inputs.map(({ ...field }) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 6 }}>
          <InputField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
      <Grid {...{ item: true, xs: 12 }}>
        <ImageUpload
          value={values.fileData}
          type={values.fileType}
          onChange={(data, name, type) => {
            setFieldValue('fileData', data);
            setFieldValue('fileName', name);
            setFieldValue('fileType', type);
          }}
        />
      </Grid>
    </Grid>
  );
};
