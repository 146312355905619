// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { RoleEnum } from './role-enum';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class SaveUserDto extends APIModel {
    username: string;
    firstName: string;
    lastName: string;
    password: string;
    roleIds: Array<number>;
    companyId: number;
    roleId: RoleEnum;

    constructor(options?: APIModelOptions) {
        super(SaveUserDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "username", type: "string" },
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "password", type: "string" },
            { name: "roleIds", type: "Array<number>" },
            { name: "companyId", type: "number" },
            { name: "roleId", type: "RoleEnum" }
        ];
    }
}




