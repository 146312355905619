import { InputField, Grid, FormComponentProps, getModuleItemUrl } from '@/components';
import { Route } from '@/enums';
import { getCurrencySymbol } from '@/utils';
import { styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const fields = [
  {
    name: 'amount',
    label: 'Amount',
    type: 'number',
    required: true,
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'bonus',
    label: 'Bonus',
    type: 'number',
    required: true,
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'companyFee',
    label: 'CompanyFee',
    type: 'number',
    required: true,
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'workerFee',
    label: 'WorkerFee',
    type: 'number',
    required: true,
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'referralBonus',
    label: 'ReferralBonus',
    type: 'number',
    required: false,
    getEndAdornment: getCurrencySymbol,
    disabled: true,
  },
  {
    name: 'hunterReferralCode',
    label: 'HunterReferralCode',
    required: false,
    disabled: true,
  },
];

const directDebitId = { name: 'directDebitId', label: 'Debited', sm: 6, md: 6 };
const note = { name: 'note', label: 'Note', multiline: true, rows: 8, sm: 12, md: 12 };

export const formBasicFields = [directDebitId, note, ...fields];

export const FormBasic = ({ disabled: d, t, values }: FormComponentProps) => {
  return (
    <Grid container>
      {fields.map(({ disabled = d, getEndAdornment = () => undefined, ...field }) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 6, md: 3 }}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              endAdornmentString: getEndAdornment(values.currency),
            }}
          />
        </Grid>
      ))}
      {values.directDebitId && (
        <Grid key='link' {...{ item: true, xs: 12, sm: 6, md: 6 }}>
          <StyledLink
            key='link'
            to={getModuleItemUrl(Route.DirectDebits, values.directDebitId)}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Typography>
              {t(directDebitId.label)}: {values.directDebitId}
            </Typography>
          </StyledLink>
        </Grid>
      )}
      <Grid key={note.name} {...{ item: true, xs: 12, sm: note.sm, md: note.md }}>
        <InputField
          {...{
            name: note.name,
            multiline: note.multiline,
            rows: note.rows,
            label: t(note.label),
            disabled: d,
          }}
        />
      </Grid>
    </Grid>
  );
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  margin: 2,
}));
