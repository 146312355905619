import { InputField, Grid, FormComponentProps, CheckboxField, DatePickerField } from '@/components';
import { getCurrencySymbol } from '@/utils';
import { useMemo } from 'react';

const inputs = [
  { name: 'code', label: 'Code', required: true, disabled: true, sm: 6, md: 3 },
  { name: 'idws', label: 'Idws', disabled: true, sm: 6, md: 3 },
  { name: 'idwa', label: 'Idwa', disabled: true, sm: 6, md: 3 },
  { name: 'referralCode', label: 'ReferralCode', disabled: true, sm: 6, md: 3 },
  { name: 'firstName', label: 'FirstName', required: true, disabled: true, md: 6 },
  { name: 'lastName', label: 'LastName', required: true, disabled: true, md: 6 },
  {
    name: 'accountNumber',
    label: 'AccountNumber',
    required: true,
    disabled: true,
    xs: 7,
    sm: 8,
    md: 4,
  },
  {
    name: 'accountBankCode',
    label: 'AccountBankCode',
    required: true,
    disabled: true,
    xs: 5,
    sm: 4,
    md: 2,
  },
  { name: 'accountIban', label: 'AccountIban', disabled: true, md: 6 },
  {
    name: 'payLimitPercentage',
    label: 'PayLimitPercentage',
    required: true,
    type: 'number',
    sm: 6,
    md: 2,
  },
  {
    name: 'hourSalaryAvg',
    label: 'HourSalaryAvg',
    required: true,
    type: 'number',
    sm: 6,
    md: 2,
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'monthSalary',
    label: 'MonthSalary',
    type: 'number',
    sm: 6,
    md: 2,
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'wageDeduction',
    label: 'WageDeduction',
    required: true,
    type: 'number',
    sm: 6,
    md: 3,
    getEndAdornment: getCurrencySymbol,
  },
  { name: 'workingTime', label: 'WorkingTime', required: true, type: 'number', sm: 6, md: 3 },
];

const checkboxes = [
  { name: 'active', label: 'Active' },
  {
    name: 'inExecution',
    label: 'InExecution',
  },
  {
    name: 'inInsolvency',
    label: 'InInsolvency',
  },
];

const dismissDate = { name: 'dismissDate', label: 'DismissDate', dateOnly: true };

export const formBasicFields = [...inputs, dismissDate, ...checkboxes];

export const FormBasic = ({ disabled, t, values }: FormComponentProps) => {
  const tDismissDate = useMemo(
    () => ({ ...dismissDate, label: t(dismissDate.label), disabled }),
    [t, disabled],
  );

  return (
    <Grid container>
      {inputs.map(({ xs = 12, sm = 12, md = 4, getEndAdornment = () => undefined, ...field }) => (
        <Grid key={field.name} {...{ item: true, xs, sm, md }}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled: field.disabled ?? disabled,
              endAdornmentString: getEndAdornment(values.currency),
            }}
          />
        </Grid>
      ))}
      {checkboxes.map(({ ...field }) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 3, md: 3 }}>
          <CheckboxField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={3}>
        <DatePickerField {...tDismissDate} />
      </Grid>
    </Grid>
  );
};
