import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import { Input } from '../Input';
import { AutocompleteItem, Props } from './types';

export const Autocomplete = ({
  label,
  value,
  multiple,
  items,
  placeholder,
  error,
  helperText,
  onChange,
  onInputChange,
  getOptionValue = (item) => item.id,
  getOptionLabel = (item) => item.name,
  ...props
}: Props) => {
  const handleChange = useCallback(
    (_: SyntheticEvent<Element, Event>, value: AutocompleteItem | null) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <MuiAutocomplete
      {...{
        fullWidth: true,
        disablePortal: true,
        options: items,
        value: multiple ? (Array.isArray(value) ? value : [value]) : value,
        onChange: handleChange,
        getOptionLabel,
        isOptionEqualToValue: (option: AutocompleteItem, value: AutocompleteItem) =>
          getOptionValue(option) === getOptionValue(value),
        onInputChange: (_, text) => onInputChange?.(text),
        multiple,
        renderInput: (params) => (
          <Input {...{ ...params, label, placeholder, error, helperText }} />
        ),
        ...props,
      }}
    />
  );
};
