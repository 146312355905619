import { RoleDto } from '@/api/base/model/role-dto';
import { useValidation } from '@/components';
import { isCompanyAdmin } from '@/utils/user';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(
    ({
      t,
      isStringMaxLength,
      isStringMinMaxLengthOptional,
      isString,
      isEntityOptional,
      isArray,
    }) => ({
      firstName: isStringMaxLength(100),
      lastName: isStringMaxLength(100),
      username: isStringMaxLength(100),
      password: isStringMinMaxLengthOptional(8, 200),
      passwordConfirm: isString().test('match', t('PasswordsNotSame'), function (passwordConfirm) {
        if (!this.parent.password) {
          return true;
        }
        return passwordConfirm === this.parent.password;
      }),
      company: isEntityOptional().test('companyAdmin', t('CompanyAdmin'), function (company) {
        return isCompanyAdmin(this.parent?.role ? [this.parent?.role] : [])
          ? company !== null
          : company === null;
      }),
    }),
  );
};
