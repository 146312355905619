// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PaymentState } from './payment-state';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class PaymentDetailDto extends APIModel {
    /**
    * Payment identifier
    */
    id: number;
    /**
    * Currency
    */
    currency: string;
    /**
    * Payment amount
    */
    amount: number;
    /**
    * Bonus amount
    */
    bonus: number;
    /**
    * Company fee
    */
    companyFee: number;
    /**
    * Worker fee
    */
    workerFee: number;
    /**
    * Note
    */
    note: string;
    /**
    * Error
    */
    error: string;
    state: PaymentState;
    /**
    * Referral bonus of payment
    */
    referralBonus: number;
    /**
    * Hunter referral code
    */
    hunterReferralCode: string;
    /**
    * Direct debit id
    */
    directDebitId: number;

    constructor(options?: APIModelOptions) {
        super(PaymentDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "currency", type: "string" },
            { name: "amount", type: "number" },
            { name: "bonus", type: "number" },
            { name: "companyFee", type: "number" },
            { name: "workerFee", type: "number" },
            { name: "note", type: "string" },
            { name: "error", type: "string" },
            { name: "state", type: "PaymentState" },
            { name: "referralBonus", type: "number" },
            { name: "hunterReferralCode", type: "string" },
            { name: "directDebitId", type: "number" }
        ];
    }
}




