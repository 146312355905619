import { Autocomplete, AutocompleteProps } from '../../Autocomplete';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { AutocompleteItem } from '@/components';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';

export const splitPropsList = [
  'className',
  'disabled',
  'items',
  'getOptionValue',
  'getOptionLabel',
];

export const AutocompleteComponent = (props: AutocompleteProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: AutocompleteItem) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <Autocomplete {...{ ...props, ...field, onChange: handleChange }} />;
};

export const AutocompleteField = (fieldProps: FormFieldProps<AutocompleteProps>) => {
  const restProps = splitProps(fieldProps, splitPropsList) as AutocompleteProps;

  return (
    <Field {...fieldProps}>
      <AutocompleteComponent {...restProps} />
    </Field>
  );
};
