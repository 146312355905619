import { Box, FormControl, FormHelperText, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { encode } from 'base64-arraybuffer';
import { Props } from './types';
import { getImagePublicUrl } from '@/api';

export const editorMarkdownHeight = 450;
const previewClass = 'editor-markdown-preview';

export const EditorMarkdown = ({
  disabled,
  error,
  helperText,
  value,
  onImageSave,
  ...props
}: Props) => {
  const { t } = useTranslation('Editor');
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  const handleSave = async function* (data: ArrayBuffer, blob: Blob) {
    const id = await onImageSave?.(encode(data), blob as File);
    yield id ? getImagePublicUrl(id) : '';
    return true;
  };

  return (
    <FormControl fullWidth>
      <Container>
        <ReactMde
          {...{
            ...props,
            value,
            readOnly: disabled,
            minEditorHeight: editorMarkdownHeight,
            maxEditorHeight: editorMarkdownHeight,
            minPreviewHeight: editorMarkdownHeight,
            selectedTab: selectedTab,
            onTabChange: setSelectedTab,
            l18n: {
              write: t('Write'),
              preview: t('Preview'),
              uploadingImage: t('UploadingImage'),
              pasteDropSelect: t('PasteDropSelect'),
            },
            generateMarkdownPreview: (markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>),
            classes: {
              preview: previewClass,
            },
            paste: onImageSave
              ? {
                  saveImage: handleSave,
                }
              : undefined,
          }}
        />
        {helperText ? <FormHelperText {...{ error }}>{helperText}</FormHelperText> : <></>}
      </Container>
    </FormControl>
  );
};

const Container = styled(Box)({
  [`.${previewClass}`]: {
    maxHeight: editorMarkdownHeight,
    overflowY: 'auto',
    wordBreak: 'break-word',
  },
  textarea: {
    resize: 'none !important',
  },
});
