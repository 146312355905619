import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isCurrencyMin, isStringMaxLength, isNumberMin, isDate, t }) => ({
    firstName: isStringMaxLength(100),
    lastName: isStringMaxLength(100),
    payLimitPercentage: isNumberMin(0),
    hourSalaryAvg: isCurrencyMin(0),
    monthSalary: isCurrencyMin(0),
    wageDeduction: isCurrencyMin(0),
    workingTime: isNumberMin(0),
    excludedPeriodTo: isDate().test(
      'DateValidToGreaterThanDateValidFrom',
      t('DateValidToGreaterThanDateValidFrom'),
      function (excludedPeriodTo) {
        const excludedPeriodFrom = this.parent.excludedPeriodFrom;
        if (!!!excludedPeriodFrom || !!!excludedPeriodTo) {
          return true;
        }
        const fromDate = new Date(excludedPeriodFrom);

        return fromDate.setHours(0, 0, 0, 0) <= excludedPeriodTo.setHours(0, 0, 0, 0);
      },
    ),
    // excludedPeriodFrom: isDate().test(
    //   'DateValidFromGreaterThanToday',
    //   t('DateValidFromGreaterThanToday'),
    //   function (excludedPeriodFrom) {
    //     const excludedPeriodTo = this.parent.excludedPeriodTo;
    //     if (!excludedPeriodFrom && !excludedPeriodTo) {
    //       return true;
    //     }
    //     return !!excludedPeriodFrom && new Date(excludedPeriodFrom) >= new Date();
    //   },
    // ),
  }));
};
