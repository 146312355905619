import {
  Balances,
  Companies,
  CompanyThemes,
  Connectors,
  Dashboard,
  Images,
  Login,
  Messages,
  MessagesAnswers,
  PasswordChange,
  Payments,
  PaymentsDemo,
  PaymentsVerifiedCzech,
  PaymentsVerifiedSlovak,
  Setting,
  Users,
  OtherUsers,
  Workers,
  DirectDebits,
} from '@/pages';
import { useAppContext } from '@/context';
import { Permission, Route } from '@/enums';
import { GroupRouteItem, LabeledRouteItem, RouteItem } from './types';
import { Regions } from '@/pages/Regions';
import { BankAuthorizations } from '@/pages/BankAuthorizations';
import { Cards } from '@/pages/Cards';
import { CardTransactions } from '@/pages/CardTransactions';

export const useRoutes = () => {
  const { permissions, hasPermission } = useAppContext();

  if (!permissions.length) {
    return [];
  }

  return (
    [
      {
        path: Route.Dashboard,
        label: 'Dashboard',
        page: Dashboard,
      },
      {
        label: 'Workers',
        permission: Permission.WorkersView,
        items: [
          {
            path: Route.Workers,
            label: 'Workers',
            page: Workers,
            editPermission: Permission.WorkersSave,
          },
          // {
          //   path: Route.AttendanceCurrent,
          //   label: 'AttendanceCurrent',
          //   page: Dashboard,
          // },
          // {
          //   path: Route.AttendanceAll,
          //   label: 'AttendanceAll',
          //   page: Dashboard,
          // },
          // {
          //   path: Route.AbsenceCurrent,
          //   label: 'AbsenceCurrent',
          //   page: Dashboard,
          // },
          // {
          //   path: Route.AbsenceAll,
          //   label: 'AbsenceAll',
          //   page: Dashboard,
          // },
        ],
      },
      {
        label: 'Payments',
        permission: Permission.PaymentsView,
        items: [
          {
            path: Route.Payments,
            label: 'Payments',
            page: Payments,
          },
          {
            path: Route.PaymentsDemo,
            label: 'PaymentsDemo',
            page: PaymentsDemo,
          },
          {
            path: Route.PaymentsVerifiedCzech,
            label: 'PaymentsVerifiedCzech',
            page: PaymentsVerifiedCzech,
            permission: Permission.CeskaSporitelnaApi,
          },
          {
            path: Route.BankAuthorizations,
            label: 'BankAuthorizations',
            page: BankAuthorizations,
            permission: Permission.CeskaSporitelnaApi,
          },
          {
            path: Route.PaymentsVerifiedSlovak,
            label: 'PaymentsVerifiedSlovak',
            page: PaymentsVerifiedSlovak,
            permission: Permission.PaymentsProcess,
          },
        ],
      },
      {
        label: 'Cards',
        items: [
          {
            path: Route.Cards,
            label: 'Cards',
            page: Cards,
            permission: Permission.CardsView,
          },
          {
            path: Route.CardTransactions,
            label: 'CardTransactions',
            page: CardTransactions,
            permission: Permission.CardTransactionsView,
          },
        ],
      },
      {
        label: 'Messages',
        permission: Permission.MessagesView,
        items: [
          {
            path: Route.Messages,
            label: 'Messages',
            page: Messages,
            editPermission: Permission.MessagesSave,
          },
          {
            path: Route.Images,
            label: 'Images',
            page: Images,
            editPermission: Permission.MessagesSave,
          },
          {
            path: Route.Answers,
            label: 'Answers',
            page: MessagesAnswers,
          },
        ],
      },
      {
        label: 'Companies',
        items: [
          {
            path: Route.Companies,
            label: 'Companies',
            page: Companies,
            permission: Permission.CompaniesView,
            editPermission: Permission.CompaniesSave,
          },
          {
            path: Route.CompanyThemes,
            label: 'Themes',
            page: CompanyThemes,
            permission: Permission.CompanyThemesView,
            editPermission: Permission.CompanyThemesSave,
          },
          {
            path: Route.Connectors,
            label: 'Connectors',
            page: Connectors,
            permission: Permission.ConnectorsView,
            editPermission: Permission.ConnectorsSave,
          },
        ],
      },
      {
        path: Route.Balances,
        label: 'Balances',
        page: Balances,
        permission: Permission.BalancesView,
      },
      {
        path: Route.DirectDebits,
        label: 'DirectDebits',
        page: DirectDebits,
        permission: Permission.DirectDebitsView,
        editPermision: Permission.DirectDebitsSave,
      },
      {
        label: 'Users',
        items: [
          {
            path: Route.Users,
            label: 'AdminUsers',
            page: Users,
            permission: Permission.UsersView,
            editPermission: Permission.UsersSave,
          },
          {
            path: Route.OtherUsers,
            label: 'OtherUsers',
            page: OtherUsers,
            permission: Permission.UsersView,
            editPermission: Permission.UsersSave,
          },
          // {
          //   path: Route.Roles,
          //   label: 'Roles',
          //   page: Dashboard,
          //   permission: Permission.RolesView,
          //   editPermission: Permission.RolesSave,
          // },
          // {
          //   path: Route.UserRoles,
          //   label: 'UserRoles',
          //   page: Dashboard,
          //   permission: Permission.RolesView,
          //   editPermission: Permission.RolesSave,
          // },
          // {
          //   path: Route.UserViews,
          //   label: 'UserViews',
          //   page: Dashboard,
          //   permission: Permission.UsersView,
          //   editPermission: Permission.UsersSave,
          // },
        ],
      },
      {
        path: Route.Regions,
        label: 'Regions',
        page: Regions,
        permission: Permission.RegionsView,
      },
      // {
      //   label: 'Helper',
      //   items: [
      //     {
      //       path: Route.LogToday,
      //       label: 'LogToday',
      //       page: Dashboard,
      //     },
      //     {
      //       path: Route.LogFull,
      //       label: 'LogFull',
      //       page: Dashboard,
      //     },
      //     {
      //       path: Route.ActiveUser,
      //       label: 'ActiveUser',
      //       page: Dashboard,
      //     },
      //     {
      //       path: Route.Help,
      //       label: 'Help',
      //       page: Dashboard,
      //     },
      //     {
      //       path: Route.News,
      //       label: 'News',
      //       page: Dashboard,
      //     },
      //   ],
      // },
      {
        path: Route.Setting,
        label: 'Setting',
        page: Setting,
        permission: Permission.SettingView,
      },
    ] as GroupRouteItem[]
  )
    .map((route) =>
      route.items
        ? {
            ...route,
            items: route.items.filter(({ permission }) => hasPermission(permission)),
          }
        : route,
    )
    .filter(({ permission, items }) => hasPermission(permission) && (!items || items.length));
};

export const noAuthRoutes: RouteItem[] = [
  {
    path: Route.Login,
    page: Login,
  },
];

export const userMenuRoutes: LabeledRouteItem[] = [
  {
    path: Route.PasswordChange,
    label: 'PasswordChange',
    page: PasswordChange,
  },
];
