import { useRolesList } from '@/api';
import { RoleDto } from '@/api/base/model';
import { Grid, FormComponentProps, Checkbox, Modal, Button } from '@/components';
import { useApiListData } from '@/utils';
import { useFormikContext } from 'formik';
import { Box, styled, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useAppContext } from '@/context';
import { Permission } from '@/enums';
import { isCompanyAdmin } from '@/utils/user';
import { companyField } from './FormBasic';

export const FormRoles = ({ disabled, values, t }: FormComponentProps) => {
  const { hasPermission } = useAppContext();
  const [open, setOpen] = useState<boolean>(
    !!values?.isCurrentUser && hasPermission(Permission.UsersSave),
  );

  const { data } = useApiListData(useRolesList, {});

  let userRole: RoleDto | undefined = undefined;
  if (values.userRoles) {
    userRole = values.userRoles[0];
  }
  if (values.role) {
    userRole = values.role;
  }

  const allAvailableRoles = useMemo(() => {
    return (data?.data ?? []) as RoleDto[];
  }, [data?.data]);

  const { setFieldValue, validateField, validateForm } = useFormikContext();

  const updateRole = useCallback(
    (role: RoleDto | undefined) => {
      if (values.userRoles) {
        setFieldValue('userRoles', []);
      }
      setFieldValue('role', role);
      // console.log('Roles', roles);
      // console.log('isCompanyAdmin', isCompanyAdmin(roles));
      if (!isCompanyAdmin(role ? [role] : [])) {
        // console.log('Setting company to null');
        setFieldValue(companyField.name, null);
        validateForm();
      }
    },
    [values.userRoles, setFieldValue, validateForm],
  );

  const onRoleEdit = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: boolean, role: RoleDto) => {
      updateRole(value ? role : undefined);
    },
    [updateRole],
  );

  return (
    <Grid container>
      {allAvailableRoles.map((role) => (
        <Grid key={role.id} {...{ item: true, xs: 12, sm: 6, md: 6 }}>
          <Checkbox
            {...{
              value: userRole?.id === role.id,
              label: role.name,
              onChange: (e, c) => onRoleEdit(e, c, role),
              disabled,
            }}
          />
        </Grid>
      ))}
      {open ?? (
        <Modal {...{ open, maxWidth: 'xs', onClick: (e) => e.stopPropagation() }}>
          <Container>
            <Title variant='h6'>{t('Warning')}</Title>
            <p>{t('WarningText')}</p>
            <StyledButton
              {...{
                label: 'OK',
                disabled,
                onClick: () => {
                  setOpen(false);
                },
                'data-cy': 'confirm-warning-button-ok',
              }}
            />
          </Container>
        </Modal>
      )}
    </Grid>
  );
};

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
