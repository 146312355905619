import {
  useCompanyLookupsList,
  usePinReset,
  useWorkerAgreementExport,
  useWorkerDisable,
  useWorkerEnable,
  useWorkerExport,
  useWorkerGet,
  useWorkerList,
  useWorkerRefresh,
  useWorkerUpdate,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey, Permission } from '@/enums';
import { DoNotTouch, DownloadForOffline, LockReset, PanTool, Refresh } from '@mui/icons-material';
import { FormBasic, formBasicFields } from './FormBasic';
import { FormExcludedPeriod, formExcludedPeriodFields } from './FormExcludedPeriod';
import { useValidationSchema } from './validation';
import { WorkerState } from '@/api/base/model';

export const Workers = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Workers,
        translationKey: 'Workers',
        useApiList: useWorkerList,
        useApiGet: useWorkerGet,
        useApiExport: useWorkerExport,
        useApiUpdate: useWorkerUpdate,
        useValidationSchema,
        formComponentHeight: 410,
        columns: [
          {
            dataKey: 'state',
            label: 'State',
            type: TableColumnType.Enum,
            enumName: 'WorkerState',
            enumObject: WorkerState,
            filterKey: 'workerState',
            filterEnabled: true,
          },
          {
            dataKey: 'id',
            label: 'Id',
            type: TableColumnType.Number,
            filterKey: 'workerId',
            filterEnabled: true,
          },
          {
            dataKey: 'lastName',
            label: 'LastName',
            filterEnabled: true,
            type: TableColumnType.Text,
          },
          {
            dataKey: 'firstName',
            label: 'FirstName',
            filterEnabled: true,
            type: TableColumnType.Text,
          },
          {
            dataKey: 'companyName',
            sortKey: 'company',
            filterKey: 'companyId',
            label: 'Company',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'companyCode',
            label: 'CompanyCode',
          },
          {
            dataKey: 'accountNumber',
            label: 'AccountNumber',
            filterEnabled: true,
            type: TableColumnType.Number,
          },
          {
            dataKey: 'accountBankCode',
            label: 'AccountBankCode',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'accountIban',
            label: 'AccountIban',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'code',
            label: 'Code',
          },
          {
            dataKey: 'idws',
            label: 'Idws',
          },
          {
            dataKey: 'idwa',
            label: 'Idwa',
          },
          {
            dataKey: 'currentMonth',
            label: 'CurrentMonth',
            type: TableColumnType.Month,
          },
          {
            dataKey: 'currentTotalHours',
            label: 'CurrentTotalHours',
            type: TableColumnType.Number,
            sortEnabled: false,
          },
          {
            dataKey: 'currentBudget',
            label: 'CurrentBudget',
            type: TableColumnType.Number,
            sortEnabled: false,
          },
          {
            dataKey: 'currentRealized',
            label: 'CurrentRealized',
            type: TableColumnType.Number,
            sortEnabled: false,
          },
          {
            dataKey: 'currentAvailable',
            label: 'CurrentAvailable',
            type: TableColumnType.Number,
            sortEnabled: false,
          },
          {
            dataKey: 'payLimitPercentage',
            label: 'PayLimitPercentage',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'monthSalary',
            label: 'MonthSalary',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'wageDeduction',
            label: 'WageDeduction',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'workingTime',
            label: 'WorkingTime',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'hourSalaryAvg',
            label: 'HourSalaryAvg',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'dismissDate',
            label: 'DismissDate',
            type: TableColumnType.Date,
          },
          {
            dataKey: 'inExecution',
            label: 'InExecution',
            type: TableColumnType.Boolean,
          },
          {
            dataKey: 'inInsolvency',
            label: 'InInsolvency',
            type: TableColumnType.Boolean,
          },
          {
            dataKey: 'referralCode',
            label: 'ReferralCode',
          },
          {
            dataKey: 'dateRegistered',
            label: 'DateRegistered',
            type: TableColumnType.DateTime,
          },
          {
            dataKey: 'dateLastLogin',
            label: 'DateLastLogin',
            type: TableColumnType.DateTime,
          },
          {
            dataKey: 'dateLastPayment',
            label: 'DateLastPayment',
            type: TableColumnType.DateTime,
          },
          {
            dataKey: 'dateLastRefresh',
            label: 'DateLastRefresh',
            type: TableColumnType.DateTime,
          },
        ],
        formComponents: [
          {
            label: 'Worker',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'ExcludedPeriod',
            component: FormExcludedPeriod,
            fields: formExcludedPeriodFields,
          },
        ],
        actions: [
          {
            label: 'ResetPin',
            text: 'ResetPinConfirmText',
            message: 'ResetPinSuccess',
            useAction: usePinReset,
            icon: LockReset,
            permission: Permission.WorkersSave,
          },
          {
            label: 'RefreshWorker',
            text: 'RefreshWorkerConfirmText',
            message: 'RefreshWorkerSuccess',
            isEnabled: (p) => !p.demo,
            useAction: useWorkerRefresh,
            icon: Refresh,
            permission: Permission.WorkersRefresh,
          },
          {
            label: 'EnableWorker',
            text: 'EnableWorkerConfirmText',
            message: 'EnableWorkerSuccess',
            isEnabled: (p) => p.state === WorkerState.Disabled,
            useAction: useWorkerEnable,
            icon: PanTool,
            permission: Permission.WorkersSave,
          },
          {
            label: 'DisableWorker',
            text: 'DisableWorkerConfirmText',
            message: 'DisableWorkerSuccess',
            isEnabled: (p) => p.state !== WorkerState.Disabled,
            useAction: useWorkerDisable,
            icon: PanTool,
            permission: Permission.WorkersSave,
          },
        ],
        trailingActions: [
          {
            key: 'agreement',
            label: 'DownloadAgreement',
            text: 'DownloadAgreementText',
            useAction: useWorkerAgreementExport,
            icon: DownloadForOffline,
            message: 'DownloadCompleted',
            permission: Permission.WorkersSave,
          },
        ],
      }}
    />
  );
};
