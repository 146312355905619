// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CreatedEntityDtoApiDataResponse, MessageAnswerDtoListApiDataResponse, MessageDetailDtoApiDataResponse, MessageDtoListApiDataResponse, SaveBulkMessageDto, SaveMessageDto } from "../model";

export class MessagesApi extends APIHandler {
    static urls: APIURLTypes.MessagesApi = {
        apiV1AdminMessagesAnswersGet: "/api/v1/admin/messages/answers",
        apiV1AdminMessagesAnswersXlsxGet: "/api/v1/admin/messages/answers/xlsx",
        apiV1AdminMessagesBulkPost: "/api/v1/admin/messages/bulk",
        apiV1AdminMessagesGet: "/api/v1/admin/messages",
        apiV1AdminMessagesIdGet: "/api/v1/admin/messages/{id}",
        apiV1AdminMessagesIdPut: "/api/v1/admin/messages/{id}",
        apiV1AdminMessagesPost: "/api/v1/admin/messages",
        apiV1AdminMessagesXlsxGet: "/api/v1/admin/messages/xlsx"
    };
    
    constructor() {
        super("MessagesApi");
    }
    
    /**
     * Get messages answers
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminMessagesAnswersGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<MessageAnswerDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesAnswersGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<MessageAnswerDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminMessagesAnswersGet", params.options, body, "apiV1AdminMessagesAnswersGet");
    }

    /**
     * Get messages answers export in XLSX format.
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminMessagesAnswersXlsxGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesAnswersXlsxGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminMessagesAnswersXlsxGet", params.options, body, "apiV1AdminMessagesAnswersXlsxGet");
    }

    /**
     * Create message for multiple companies
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param saveBulkMessageDto 
     */
    public apiV1AdminMessagesBulkPost(params: APIParams & {
        saveBulkMessageDto?: SaveBulkMessageDto;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesBulkPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveBulkMessageDto;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminMessagesBulkPost", params.options, body, "apiV1AdminMessagesBulkPost");
    }

    /**
     * Get messages
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminMessagesGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<MessageDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<MessageDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminMessagesGet", params.options, body, "apiV1AdminMessagesGet");
    }

    /**
     * Get message detail
     * @param id 
     */
    public apiV1AdminMessagesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<MessageDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<MessageDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminMessagesIdGet", params.options, body, "apiV1AdminMessagesIdGet");
    }

    /**
     * Update message
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param id 
     * @param saveMessageDto 
     */
    public apiV1AdminMessagesIdPut(params: APIParams & {
        id: number;
        saveMessageDto?: SaveMessageDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveMessageDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminMessagesIdPut", params.options, body, "apiV1AdminMessagesIdPut");
    }

    /**
     * Create message
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param saveMessageDto 
     */
    public apiV1AdminMessagesPost(params: APIParams & {
        saveMessageDto?: SaveMessageDto;
    } = {}): AxiosPromise<CreatedEntityDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveMessageDto;
        return this.makeCall<CreatedEntityDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminMessagesPost", params.options, body, "apiV1AdminMessagesPost");
    }

    /**
     * Get messages export in XLSX format.
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminMessagesXlsxGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(MessagesApi.urls.apiV1AdminMessagesXlsxGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminMessagesXlsxGet", params.options, body, "apiV1AdminMessagesXlsxGet");
    }

}
