// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class LoggedWorkerDto extends APIModel {
    /**
    * JWT Token
    */
    token: string;
    /**
    * Minimal version of client
    */
    clientVersionMin: string;
    /**
    * Version of theme
    */
    themeVersion: string;
    /**
    * Flag if maintenance is in progress
    */
    maintenanceInProgress: boolean;
    /**
    * Flag if user is from demo company
    */
    demo: boolean;

    constructor(options?: APIModelOptions) {
        super(LoggedWorkerDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "token", type: "string" },
            { name: "clientVersionMin", type: "string" },
            { name: "themeVersion", type: "string" },
            { name: "maintenanceInProgress", type: "boolean" },
            { name: "demo", type: "boolean" }
        ];
    }
}




