export const Workers = {
  Id: 'Identifikátor',
  AccountBankCode: 'Kód banky',
  AccountIban: 'IBAN',
  AccountNumber: 'Číslo účtu',
  Active: 'Aktivní',
  Code: 'Kód',
  Company: 'Společnost',
  CompanyCode: 'Kód společnosti',
  CurrentAvailable: 'Akt. zbývá',
  CurrentBudget: 'Akt. nárok',
  CurrentMonth: 'Akt. měsíc',
  CurrentRealized: 'Akt. vybráno',
  CurrentTotalHours: 'Akt. hodin',
  DateLastLogin: 'Posl. přihlášení',
  DateLastPayment: 'Posl. žádost',
  DateLastRefresh: 'Posl. aktualizace',
  DateRegistered: 'Registrace',
  InExecution: 'V exekuci',
  InInsolvency: 'V insolvenci',
  DismissDate: 'Ukončen prac. poměr',
  FirstName: 'Jméno',
  HourSalaryAvg: 'Průměrná hod. mzda',
  Idwa: 'IDWA',
  Idws: 'IDWS',
  LastName: 'Příjmení',
  MonthSalary: 'Měsíční plat',
  PayLimitPercentage: 'Limit %',
  ResetPin: 'Resetovat PIN',
  ResetPinConfirmText:
    'Opravdu chcete resetovat PIN, aby si ho musel pracovník zadat znovu při přihlášení?',
  ResetPinSuccess: 'PIN resetován',
  WageDeduction: 'Srážky ze mzdy',
  Worker: 'Pracovník',
  WorkingTime: 'Prac. úvazek v hod/týdně',
  ReferralCode: 'Referal kód',
  RefreshWorker: 'Aktualizace pracovníka',
  RefreshWorkerConfirmText: 'Opravdu chcete aktualizovat data od zaměstnavatele (údaje, docházka)?',
  RefreshWorkerSuccess: 'Aktualizace provedena',
  ExcludedPeriod: 'Vyloučená doba',
  ExcludedPeriodText: 'Vyloučená doba je doba, po kterou se pro uživatele nezapočítává žádná mzda.',
  ExcludedPeriodFrom: 'od (včetně)',
  ExcludedPeriodTo: 'do (včetně)',
  DownloadAgreement: 'Stáhnout Dohodu o srážkách ze mzdy',
  DownloadAgreementText: 'Přejete si stáhnout PDF soubor?',
  EnableWorker: 'Aktivovat pracovníka',
  EnableWorkerConfirmText: 'Jste si jisti, že chcete pracovníka aktivovat?',
  EnableWorkerSuccess: 'Pracovník aktivován',
  DisableWorker: 'Deaktivovat pracovníka',
  DisableWorkerConfirmText: 'Jste si jisti, že chcete pracovníka deaktivovat?',
  DisableWorkerSuccess: 'Pracovník deaktivován',
  State: 'Stav',
};
