import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, editorMarkdownHeight } from '@/components';
import Phone from '@/assets/images/phone.png';
import { Box, Paper, styled, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useCompanyActiveThemeGet } from '@/api';
import { borderRadius, colorMobilePrimary } from '@/styles';
import {
  removeStyledProps,
  removeStyledSmallScreenProp,
  useDateFormat,
  useSmallScreen,
} from '@/utils';
import { CompanyPreviewComponentProps, PreviewComponentProps, PreviewProps } from './types';
import { useTranslation } from 'react-i18next';
import { getActionColor, prepareMarkdownPreview } from './utils';

export const Preview = ({ values, ...props }: PreviewProps) => {
  const companyId = values.company?.id;

  const allProps = { ...props, ...values };

  return companyId ? (
    <CompanyPreviewComponent {...({ ...allProps, companyId } as CompanyPreviewComponentProps)} />
  ) : (
    <PreviewComponent {...allProps} />
  );
};

const CompanyPreviewComponent = ({ companyId, ...props }: CompanyPreviewComponentProps) => {
  const { data } = useCompanyActiveThemeGet(companyId);

  return <PreviewComponent {...{ ...props, companyTheme: data?.settings }} />;
};

const PreviewComponent = ({
  component,
  companyTheme,
  caption,
  markdownData,
  created,
  messageActions,
}: PreviewComponentProps) => {
  const { t } = useTranslation('Messages');
  const { formatDate, formatDateObject } = useDateFormat();
  const smallScreen = useSmallScreen();
  const [imageError, setImageError] = useState(false);
  const markdownRef = useRef<HTMLDivElement | null>(null);

  const findInCompanyTheme = useCallback(
    (name: string) => (companyTheme ?? []).find((v) => v.name === `--ion-color-${name}`)?.value,
    [companyTheme],
  );

  const getButtonColor = useCallback(
    (colorName: string) => {
      const backgroundColor = findInCompanyTheme(colorName);
      const color = findInCompanyTheme(`${colorName}-contrast`);

      return backgroundColor
        ? { backgroundColor, color: color ?? getActionColor(colorName).color }
        : getActionColor(colorName);
    },
    [findInCompanyTheme],
  );

  const titleBackground = useMemo(
    () => findInCompanyTheme('secondary') ?? colorMobilePrimary,
    [findInCompanyTheme],
  );

  const actions = useMemo(
    () =>
      messageActions && messageActions.length
        ? messageActions
        : [
            {
              id: 1,
              caption: t('Read'),
              name: 'read',
              fullWidth: false,
              color: 'tertiary',
            },
            {
              id: 2,
              caption: t('Cancel'),
              name: 'cancel',
              fullWidth: false,
              color: 'medium',
            },
          ],
    [messageActions, t],
  );

  useEffect(() => {
    setTimeout(() => {
      const element = markdownRef.current;
      if (element) {
        element.querySelectorAll('img').forEach((img) => {
          img.onerror = function () {
            this.style.display = 'none';
            setImageError(true);
          };
        });
      }
    });
  }, []);

  return (
    <Container>
      <ErrorMessage>{imageError ? t('ImageError') : ''}</ErrorMessage>
      <InnerContainer {...{ smallScreen }}>
        <Component {...{ smallScreen }}>{component}</Component>
        <ImageContainer {...{ smallScreen }}>
          <Image>
            <img src={Phone} />
          </Image>
          <PreviewContainer>
            <PreviewTitleContainer {...{ titleBackground }}>
              <PreviewTitle variant='h6'>{caption ?? ''}</PreviewTitle>
            </PreviewTitleContainer>
            <StyledPaper elevation={2}>
              <PreviewDate>
                {created ? formatDate(created) : formatDateObject(new Date())}
              </PreviewDate>
              <Box ref={markdownRef}>
                <StyledReactMarkdown>{prepareMarkdownPreview(markdownData)}</StyledReactMarkdown>
              </Box>
              {actions.map(({ caption, fullWidth, color }) => (
                <PreviewButton
                  key={caption}
                  {...{
                    label: caption,
                    fullWidth,
                    size: 'small',
                    buttonColor: getButtonColor(color),
                  }}
                />
              ))}
            </StyledPaper>
          </PreviewContainer>
        </ImageContainer>
      </InnerContainer>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

const InnerContainer = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ smallScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: smallScreen ? 'column' : 'row',
  width: '100%',
}));

const Component = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ smallScreen }) => ({
  ...(smallScreen ? { width: '100%' } : { flex: 1 }),
}));

const previewWidth = 285;

const PreviewContainer = styled(Box)({
  position: 'absolute',
  top: 55,
  left: 18,
  maxWidth: previewWidth,
  maxHeight: 436,
  overflowX: 'hidden',
  overflowY: 'auto',
  img: {
    maxWidth: '100%',
  },
});

const PreviewTitleContainer = styled(
  Box,
  removeStyledProps('titleBackground'),
)<{ titleBackground: string }>(({ theme, titleBackground }) => {
  const width = `calc(${previewWidth}px - ${theme.spacing(12)})`;

  return {
    width,
    minWidth: width,
    padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(7)}`,
    backgroundColor: titleBackground,
  };
});

const PreviewTitle = styled(Typography)({
  fontSize: '1.05rem',
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
});

const PreviewDate = styled(Typography)(({ theme }) => ({
  fontSize: '0.65rem',
  marginTop: theme.spacing(1),
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: '1.05rem',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.error.main,
  marginBottom: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  marginTop: theme.spacing(-5),
  width: `calc(100% - ${theme.spacing(6)})`,
  borderRadius: borderRadius * 0.65,
}));

const StyledReactMarkdown = styled(ReactMarkdown)({
  fontSize: '0.65rem',
  wordBreak: 'break-word',
});

export const phoneHeight = editorMarkdownHeight + 120;
const imageWidth = 1084;
const imageHeight = 1920;

const ImageContainer = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  marginLeft: smallScreen ? 0 : theme.spacing(2),
  marginTop: smallScreen ? theme.spacing(2) : 0,
  position: 'relative',
  height: phoneHeight,
  minHeight: phoneHeight,
  maxHeight: phoneHeight,
  width: (imageWidth / imageHeight) * phoneHeight,
}));

const Image = styled(Box)({
  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
});

const PreviewButton = styled(
  Button,
  removeStyledProps('buttonColor'),
)<{ buttonColor: { color: string; backgroundColor: string } }>(
  ({ theme, fullWidth, buttonColor }) => ({
    fontSize: '0.7rem !important',
    marginTop: theme.spacing(1),
    ...buttonColor,
    ...(!fullWidth ? { marginRight: theme.spacing(1) } : {}),
    '&:hover': {
      ...buttonColor,
    },
  }),
);
