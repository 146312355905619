import { useMessageAnswerExport, useMessageAnswerList } from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';

export const MessagesAnswers = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.MessagesAnswers,
        translationKey: 'MessagesAnswers',
        useApiList: useMessageAnswerList,
        useApiExport: useMessageAnswerExport,
        columns: [
          { dataKey: 'id', label: 'Id', type: TableColumnType.Number },
          { dataKey: 'messageCaption', label: 'Message' },
          { dataKey: 'datePreview', label: 'DatePreview', type: TableColumnType.DateTime },
          { dataKey: 'dateRead', label: 'DateRead', type: TableColumnType.DateTime },
          { dataKey: 'dateAnswer', label: 'DateAnswer', type: TableColumnType.DateTime },
          {
            dataKey: 'answerName',
            label: 'AnswerName',
            type: TableColumnType.Enum,
            enumName: 'MessagesAnswersName',
          },
          { dataKey: 'answerValues', label: 'AnswerValues' },
        ],
      }}
    />
  );
};
