import * as Yup from 'yup';
import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(
    ({
      t,
      isNumberRequired,
      isStringEvRequired,
      isStringLength,
      isStringMaxLength,
      isStringMaxLengthOptional,
      isCurrencyMin,
      isNumberMinMax,
      isCurrencyMinMaxOptional,
      isDate,
    }) => ({
      code: isStringLength(8),
      regionId: isNumberRequired(),
      name: isStringMaxLength(100),
      city: isStringMaxLength(100),
      balanceDay: isNumberMinMax(1, 31),
      payLimitPercentage: isNumberMinMax(0, 100),
      payLimitAmount: isCurrencyMin(0),
      note: isStringMaxLengthOptional(2048),
      firstCompanyFee: isCurrencyMin(0),
      firstWorkerFee: isCurrencyMin(0),
      secondCompanyFee: isCurrencyMin(0),
      secondWorkerFee: isCurrencyMin(0),
      thirdCompanyFee: isCurrencyMin(0),
      thirdWorkerFee: isCurrencyMin(0),
      fourthPlusCompanyFee: isCurrencyMin(0),
      fourthPlusWorkerFee: isCurrencyMin(0),
      paymentCardCompanyFee: isCurrencyMin(0),
      paymentCardWorkerFee: isCurrencyMin(0),
      bonus: isCurrencyMinMaxOptional(0, 1000).test(
        'BonusFromAndToFilled',
        t('BonusFromAndToFilled'),
        function (bonus) {
          return !!(bonus || (!this.parent.bonusFrom && !this.parent.bonusTo));
        },
      ),
      bonusFrom: isDate().test('BonusToIsFilled', t('BonusToIsFilled'), function (bonusFrom) {
        return !!(bonusFrom || !this.parent.bonusTo);
      }),
      bonusTo: isDate().test(
        'BonusToGreaterThanBonusFrom',
        t('BonusToGreaterThanBonusFrom'),
        function (bonusTo) {
          const bonusFrom = this.parent.bonusFrom;
          return !bonusFrom || (bonusTo && bonusFrom && new Date(bonusTo) >= new Date(bonusFrom));
        },
      ),
      firstVerification: Yup.object({
        caption: isStringMaxLength(200),
        toolTip: isStringMaxLength(200),
        example: isStringMaxLength(200),
        format: isStringMaxLength(200),
      }),
      secondVerification: Yup.object({
        caption: isStringMaxLengthOptional(200),
        toolTip: isStringMaxLengthOptional(200),
        example: isStringMaxLengthOptional(200),
        format: isStringMaxLengthOptional(200),
      }).nullable(),
      debtorName: isStringEvRequired('excludeFromBatch'),
      debtorBic: isStringEvRequired('excludeFromBatch'),
      debtorIban: isStringEvRequired('excludeFromBatch'),
      debtorOrgId: isStringEvRequired('excludeFromBatch'),
    }),
  );
};
