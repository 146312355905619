import { useCompanyLookupsList } from '@/api';
import { CardStatus } from '@/api/base/model';
import { useCardList } from '@/api/methods/cards';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';

export const Cards = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Cards,
        translationKey: 'Cards',
        useApiList: useCardList,
        sort: 'id',
        isSortAscending: false,
        columns: [
          {
            dataKey: 'id',
            label: 'Identifier',
          },
          {
            dataKey: 'worker.name',
            filterKey: 'workerName',
            label: 'Worker',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
          {
            dataKey: 'cardIdentifier',
            filterKey: 'cardIdentifier',
            label: 'CardIdentifier',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
          {
            dataKey: 'mcid',
            filterKey: 'mCID',
            label: 'MCID',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
          {
            dataKey: 'pan',
            filterKey: 'pAN',
            label: 'PAN',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
          {
            dataKey: 'status',
            filterKey: 'status',
            label: 'Status',
            type: TableColumnType.Enum,
            enumName: 'CardStatus',
            enumObject: CardStatus,
            filterEnabled: true,
          },
          {
            dataKey: 'company.name',
            filterKey: 'companyId',
            label: 'Company',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'activationDate',
            label: 'ActivationDate',
            type: TableColumnType.DateTime,
            filterEnabled: false,
          },
        ],
      }}
    />
  );
};
