import { ApiListOptions, FormValues } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { RegionsApi } from '../base';
import { params, fileParams, downloadFileFromResponse, dto } from '../utils';

const api = new RegionsApi();

export const useRegionList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Regions, options], async () => {
    const response = await api.apiV1AdminRegionsGet(params(options));

    return response.data;
  });
};

export const useRegionLookupsList = () => {
  return useQuery([ApiKey.Regions], async () => {
    const response = await api.apiV1AdminRegionsLookupsGet(params());

    return response.data;
  });
};

export const useRegionExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminRegionsXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useRegionGet = (id: number) => {
  return useQuery([ApiKey.Regions, id], async () => {
    const response = await api.apiV1AdminRegionsIdGet(params({ id }));

    return response.data.data;
  });
};
