// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum CardStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    TemporaryBlocked = 'TemporaryBlocked',
    Fraud = 'Fraud',
    Closed = 'Closed',
    Lost = 'Lost',
    Stolen = 'Stolen'
}



