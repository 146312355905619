import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { omit } from 'lodash/fp';
import {
  getItemId,
  getItemUrl,
  getNewBulkItemUrl,
  getNewItemUrl,
  isNewBulkItem,
  isNewItem,
} from './utils';
import { TableItem } from '../Table';
import { ModuleContext as TModuleContext, ModuleOptions } from './types';
import { ModuleContext } from './ModuleContext';
import { useAppContext } from '@/context';
import { useQueryClient } from 'react-query';
import {
  AddCircle,
  Cached,
  DownloadForOffline,
  Handshake,
  LibraryAdd,
  Payments,
  QueryStats,
  SaveAlt,
  VerifiedUser,
} from '@mui/icons-material';
import { TableAction } from './enums';
import { useTranslation } from 'react-i18next';
import { useActiveRoute } from '@/utils';
import { EXPORT_LIMIT } from '@/constants';

const defaultModuleOptions = {
  apiKey: undefined,
  active: false,
  create: false,
  createBulk: false,
  formDisabled: false,
  exportCallback: undefined,
  generateFilesCallback: undefined,
  connectCallback: undefined,
  batchProcessCallback: undefined,
  reconciliatePaymentsCallback: undefined,
  pollBatchesCallback: undefined,
  directDebitsCallback: undefined,
};

export const ModuleProvider = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasPermission } = useAppContext();
  const { t } = useTranslation('Module');

  const [
    {
      apiKey,
      active,
      create,
      createBulk,
      formDisabled,
      exportCallback,
      generateFilesCallback,
      connectCallback,
      batchProcessCallback,
      reconciliatePaymentsCallback,
      pollBatchesCallback,
      directDebitsCallback,
    },
    setModuleOptions,
  ] = useState<ModuleOptions>(defaultModuleOptions);

  useEffect(() => {
    setModuleOptions(defaultModuleOptions);
  }, [location.pathname]);

  const updateModuleOptions = useCallback((newModuleOptions: Partial<ModuleOptions>) => {
    setModuleOptions((moduleOptions) => ({ ...moduleOptions, ...newModuleOptions }));
  }, []);

  const newItem = useMemo(() => isNewItem(location), [location]);
  const newItemBulk = useMemo(() => isNewBulkItem(location), [location]);
  const itemId = useMemo(() => getItemId(location), [location]);
  const formOpen = useMemo(
    () => !!(newItem || newItemBulk || itemId),
    [newItem, newItemBulk, itemId],
  );
  const activeRoute = useActiveRoute();
  const currentModule = useMemo(() => (active ? activeRoute : undefined), [active, activeRoute]);
  const editEnabled = useMemo(
    () =>
      !!(
        currentModule &&
        currentModule.editPermission &&
        hasPermission(currentModule.editPermission)
      ),
    [currentModule, hasPermission],
  );
  const createEnabled = useMemo(() => !!(editEnabled && create), [editEnabled, create]);
  const createBulkEnabled = useMemo(() => !!(editEnabled && createBulk), [editEnabled, createBulk]);
  const exportEnabled = useMemo(() => !!exportCallback, [exportCallback]);
  const generateFilesEnabled = useMemo(() => !!generateFilesCallback, [generateFilesCallback]);
  const connectEnabled = useMemo(() => !!connectCallback, [connectCallback]);
  const batchProcessEnabled = useMemo(() => !!batchProcessCallback, [batchProcessCallback]);
  const directDebitsEnabled = useMemo(() => !!directDebitsCallback, [directDebitsCallback]);
  const reconciliatePaymentsEnabled = useMemo(
    () => !!reconciliatePaymentsCallback,
    [reconciliatePaymentsCallback],
  );
  const pollBatchesEnabled = useMemo(() => !!pollBatchesCallback, [pollBatchesCallback]);

  const onReload = useCallback(() => {
    queryClient.invalidateQueries(apiKey);
  }, [queryClient, apiKey]);

  const onOpenNewItemForm = useCallback(() => {
    navigate(getNewItemUrl(location.pathname));
  }, [navigate, location.pathname]);

  const onOpenNewBulkItemForm = useCallback(() => {
    navigate(getNewBulkItemUrl(location.pathname));
  }, [navigate, location.pathname]);

  const navigateToItem = useCallback(
    (id: number) => navigate(getItemUrl(location.pathname, id)),
    [navigate, location.pathname],
  );

  const onTableRowClick = useCallback(
    (item: TableItem) => {
      navigateToItem(item.id);
    },
    [navigateToItem],
  );

  const onFormClose = useCallback(() => {
    navigate(location.pathname);
  }, [navigate, location.pathname]);

  const onExport = useCallback(() => {
    exportCallback?.();
  }, [exportCallback]);

  const onGenerateFiles = useCallback(() => {
    generateFilesCallback?.();
  }, [generateFilesCallback]);

  useEffect(() => {
    if (formDisabled || (newItem && !createEnabled)) {
      onFormClose();
    }
  }, [formDisabled, newItem, createEnabled, onFormClose]);

  const onVerifyConnection = useCallback(() => {
    connectCallback?.();
  }, [connectCallback]);

  const onBatchProcess = useCallback(() => {
    batchProcessCallback?.();
  }, [batchProcessCallback]);

  const onDirectDebits = useCallback(() => {
    directDebitsCallback?.();
  }, [directDebitsCallback]);

  const onReconciliatePayments = useCallback(() => {
    reconciliatePaymentsCallback?.();
  }, [reconciliatePaymentsCallback]);

  const onPollBatches = useCallback(() => {
    pollBatchesCallback?.();
  }, [pollBatchesCallback]);

  const tableActions = useMemo(
    () =>
      [
        { key: TableAction.Reload, label: t('Reload'), icon: Cached, onClick: onReload },
        {
          key: TableAction.Export,
          label: t('Export'),
          text: t('ExportConfirmText', { value: EXPORT_LIMIT }),
          icon: DownloadForOffline,
          onClick: onExport,
          visible: exportEnabled,
        },
        {
          key: TableAction.GenerateFiles,
          label: t('GenerateFiles'),
          text: t('GenerateFilesConfirmText'),
          icon: SaveAlt,
          onClick: onGenerateFiles,
          visible: generateFilesEnabled,
        },
        {
          key: TableAction.Add,
          label: t('New'),
          icon: AddCircle,
          onClick: onOpenNewItemForm,
          visible: createEnabled,
        },
        {
          key: TableAction.AddBulk,
          label: t('NewBulk'),
          icon: LibraryAdd,
          onClick: onOpenNewBulkItemForm,
          visible: createBulkEnabled,
        },
        {
          key: TableAction.VerifyConnection,
          label: t('VerifyConnection'),
          icon: VerifiedUser,
          onClick: onVerifyConnection,
          visible: connectEnabled,
        },
        {
          key: TableAction.BatchProcess,
          label: t('BatchProcess'),
          icon: Payments,
          onClick: onBatchProcess,
          visible: batchProcessEnabled,
        },
        {
          key: TableAction.DirectDebits,
          label: t('DirectDebits'),
          icon: Payments,
          onClick: onDirectDebits,
          visible: directDebitsEnabled,
        },
        {
          key: TableAction.ReconciliatePayments,
          label: t('ReconciliatePayments'),
          icon: Handshake,
          onClick: onReconciliatePayments,
          visible: reconciliatePaymentsEnabled,
        },
        {
          key: TableAction.PollBatches,
          label: t('PollBatches'),
          icon: QueryStats,
          onClick: onPollBatches,
          visible: pollBatchesEnabled,
        },
      ]
        .filter(({ visible }) => visible === undefined || visible)
        .map(omit('visible')),
    [
      t,
      onReload,
      onExport,
      exportEnabled,
      onGenerateFiles,
      generateFilesEnabled,
      onOpenNewItemForm,
      createEnabled,
      onOpenNewBulkItemForm,
      createBulkEnabled,
      onVerifyConnection,
      connectEnabled,
      onBatchProcess,
      batchProcessEnabled,
      onReconciliatePayments,
      reconciliatePaymentsEnabled,
      onPollBatches,
      pollBatchesEnabled,
      onDirectDebits,
    ],
  );

  const context: TModuleContext = useMemo(
    () => ({
      active,
      formOpen,
      newItem,
      newItemBulk,
      itemId,
      editEnabled,
      tableActions,
      onTableRowClick,
      onFormClose,
      navigateToItem,
      updateModuleOptions,
    }),
    [
      active,
      formOpen,
      newItem,
      newItemBulk,
      itemId,
      editEnabled,
      tableActions,
      onTableRowClick,
      onFormClose,
      navigateToItem,
      updateModuleOptions,
    ],
  );

  return <ModuleContext.Provider value={context}>{children}</ModuleContext.Provider>;
};
