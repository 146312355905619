import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isStringRequired }) => ({
    username: isStringRequired(),
    password: isStringRequired(),
  }));
};
