import { useEffect, useState } from 'react';
import { Box, Tabs as MuiTabs, Tab, styled } from '@mui/material';
import { Props } from './types';
import { removeStyledProps, useSmallScreen } from '@/utils';

export const Tabs = ({
  items,
  rightComponent,
  contentHeight,
  activeTab,
  setActiveTab,
  ...props
}: Props) => {
  const smallScreen = useSmallScreen();
  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setActiveTab?.(newValue);
  };

  useEffect(() => {
    if (activeTab !== undefined && value !== activeTab) {
      setValue(activeTab);
    }
  }, [activeTab, value]);

  return (
    <>
      <Container>
        <StyledTabs
          {...{
            ...props,
            variant: 'scrollable',
            scrollButtons: 'auto',
            value,
            onChange: handleChange,
          }}
        >
          {items.map(({ label, width = 150, error, disabled }) => (
            <StyledTab
              key={label}
              {...{ label, width, error, disabled, 'data-cy': `tabs-${label}` }}
            />
          ))}
        </StyledTabs>
        {rightComponent}
      </Container>
      {items.map(({ label, component, smallScreenNoPadding }, index) => {
        return (
          <div key={label}>
            {index === value && (
              <Content
                {...{ ...(smallScreen ? { smallScreenNoPadding } : { height: contentHeight }) }}
              >
                {component}
              </Content>
            )}
          </div>
        );
      })}
    </>
  );
};

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '100%',
}));

const Content = styled(
  Box,
  removeStyledProps(['height', 'smallScreenNoPadding']),
)<{ height?: number; smallScreenNoPadding?: boolean }>(
  ({ theme, height, smallScreenNoPadding }) => ({
    ...(height ? { height, minHeight: height, maxHeight: height } : {}),
    ...(smallScreenNoPadding
      ? { marginLeft: theme.spacing(-2), marginRight: theme.spacing(-2) }
      : {}),
    flexGrow: 1,
  }),
);

const StyledTabs = styled(MuiTabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(
  Tab,
  removeStyledProps(['width', 'error']),
)<{ width: number; error?: boolean }>(({ theme, width, error }) => ({
  color: error ? theme.palette.error.main : theme.palette.grey[900],
  fontWeight: theme.typography.fontWeightLight,
  minWidth: width,
  '&.Mui-selected': {
    color: error ? theme.palette.error.main : theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightBold,
  },
  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
}));
