export const Workers = {
  Id: 'Identifier',
  AccountBankCode: 'Bank code',
  AccountIban: 'IBAN',
  AccountNumber: 'Account number',
  Active: 'Active',
  Code: 'Code',
  Company: 'Company',
  CompanyCode: 'Company code',
  CurrentAvailable: 'Available',
  CurrentBudget: 'Budget',
  CurrentMonth: 'Current month',
  CurrentRealized: 'Withdrawn',
  CurrentTotalHours: 'Hours',
  DateLastLogin: 'Last login',
  DateLastPayment: 'Last request',
  DateLastRefresh: 'Last refresh',
  DateRegistered: 'Registered',
  DismissDate: 'Dismiss date',
  InExecution: 'Foreclosure',
  InInsolvency: 'Insolvency',
  FirstName: 'First name',
  HourSalaryAvg: 'Avg. hour salary',
  Idwa: 'IDWA',
  Idws: 'IDWS',
  LastName: 'Last name',
  MonthSalary: 'Month salary',
  PayLimitPercentage: 'Limit %',
  ResetPin: 'Reset PIN',
  ResetPinConfirmText:
    'Are you sure you want to reset the PIN so that the worker has to re-enter it when logging in?',
  ResetPinSuccess: 'PIN reset',
  WageDeduction: 'Wage deduction',
  Worker: 'Worker',
  WorkingTime: 'Working time',
  ReferralCode: 'Referral code',
  RefreshWorker: 'Refresh worker',
  RefreshWorkerConfirmText: 'Are you sure you want to refresh worker (personal data, attendance)?',
  RefreshWorkerSuccess: 'Worker refreshed',
  ExcludedPeriod: 'Excluded period',
  ExcludedPeriodText: 'A user receives no income from the excluded period',
  ExcludedPeriodFrom: 'From (including)',
  ExcludedPeriodTo: 'Till (including)',
  DownloadAgreement: 'Download the Salary Deduction Agreement',
  DownloadAgreementText: 'Do you wish to download the PDF file?',
  EnableWorker: 'Activate worker',
  EnableWorkerConfirmText: 'Are you sure you want to activate the worker?',
  EnableWorkerSuccess: 'Worker activated',
  DisableWorker: 'Deactivate worker',
  DisableWorkerConfirmText: 'Are you sure you want to deactivate the worker?',
  DisableWorkerSuccess: 'Worker activated',
  State: 'State',
};
