import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import cs from './cs';
import en from './en';
import sk from './sk';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      cs,
      en,
      sk,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export { languages } from './enums';
export type { Language } from './types';
export * from './LocalizationProvider';
export * from './TranslationProvider';
export * from './utils';
export default i18n;
