export const Validation = {
  Required: 'Povinné',
  MinLength: 'Musí mít alespoň {{value}} znaků',
  MaxLength: 'Nesmí mít více než {{value}} znaků',
  Length: 'Musí mít {{value}} znaků',
  MinValue: 'Musí být větší nebo rovno {{value}}',
  MaxValue: 'Musí být menší nebo rovno {{value}}',
  CurrencyValue: 'Nesmí mít více než {{value}} číslic za desetinnou čárkou',
  ValidDate: 'Musí být platné datum',
  PasswordsNotSame: 'Zadaná hesla nejsou stejná',
  BonusToIsFilled: 'Musí být vyplněno, když je vyplněné pole "Trvání akce do"',
  BonusToGreaterThanBonusFrom: 'Musí být pozdější datum než "Trvání akce od"',
  BonusFromAndToFilled:
    'Musí být větší než 0, když jsou vyplněna pole "Trvání akce od" a "Trvání akce do"',
  DateValidToGreaterThanDateValidFrom: 'Musí být stejný nebo pozdější datum než "Platnost od"',
  DateValidFromGreaterThanToday: 'Musí být pozdější datum než dnes',
  CompanyAdmin: 'Company admin musí mít přidělenou společnost',
};
