import { Currency } from '@/enums';

export const getCurrencySymbol = (currency?: Currency) => {
  switch (currency) {
    case Currency.CZK:
      return 'Kč';
    case Currency.EUR:
      return '€';
    default:
      return '';
  }
};
