export const Dashboard = {
  Amount: 'Amount',
  Available: 'Available',
  Company: 'Company',
  Count: 'Count',
  GlobalOverview: 'Global overview',
  Home: 'Welcome',
  LoggedIn: 'Logged in',
  Month: 'Month',
  NumberOfIndividualWithdrawals: 'Number of individual withdrawals',
  NumbersOfWorkers: 'Numbers of workers',
  NoData: 'No data to display',
  PaymentRequested: 'Requested payment',
  Realized: 'Realized',
  Registered: 'Registered',
  TotalAmountOfWithdrawals: 'Total amount of withdrawals',
  TotalBudget: 'Total budget',
};
