import { Checkbox, CheckboxProps } from '../../Checkbox';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { ChangeEvent, useCallback } from 'react';
import { useFormikContext } from 'formik';

export const CheckboxComponent = (props: CheckboxProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <Checkbox {...{ ...props, ...field, onChange: handleChange }} />;
};

export const CheckboxField = (fieldProps: FormFieldProps<Omit<CheckboxProps, 'value'>>) => {
  const restProps = splitProps(fieldProps, [
    'className',
    'disabled',
    'tooltipText',
  ]) as CheckboxProps;

  return (
    <Field {...{ ...fieldProps, includeError: false }}>
      <CheckboxComponent {...restProps} />
    </Field>
  );
};
