import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { SelectItem } from '@/components';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { ApiSelect, ApiSelectProps } from '../../ApiSelect';

export const ApiSelectComponent = (props: ApiSelectProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: SelectItem) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <ApiSelect {...{ ...props, ...field, onChange: handleChange }} />;
};

export const ApiSelectField = (fieldProps: FormFieldProps<Omit<ApiSelectProps, 'value'>>) => {
  const restProps = splitProps(fieldProps, [
    'className',
    'disabled',
    'getOptionValue',
    'getOptionLabel',
    'useApiList',
    'params',
    'selectFirst',
  ]) as ApiSelectProps;

  return (
    <Field {...fieldProps}>
      <ApiSelectComponent {...restProps} />
    </Field>
  );
};
