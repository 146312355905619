import { ApiListOptions, FormValues } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { WorkersApi } from '../base';
import { params, fileParams, downloadFileFromResponse, dto } from '../utils';
import { UpdateWorkerDto } from '../base/model';

const api = new WorkersApi();

export const useWorkerList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Workers, options], async () => {
    const response = await api.apiV1AdminWorkersGet(params(options));

    return response.data;
  });
};

export const useWorkerExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminWorkersXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useWorkerUpdate = () => {
  return useMutation((values: FormValues) => {
    if (values?.excludedPeriod && values.excludedPeriod.from === null) {
      delete values.excludedPeriod;
    }

    return api.apiV1AdminWorkersIdPut(
      params({
        id: values.id,
        updateWorkerDto: dto(UpdateWorkerDto, values),
      }),
    );
  });
};

export const useWorkerGet = (id: number) => {
  return useQuery([ApiKey.Workers, id], async () => {
    const response = await api.apiV1AdminWorkersIdGet(params({ id }));

    return response.data.data;
  });
};

export const usePinReset = () => {
  return useMutation(async (id: number) => api.apiV1AdminWorkersIdPinResetPost(params({ id })));
};

export const useWorkerRefresh = () => {
  return useMutation(async (id: number) => api.apiV1AdminWorkersIdRefreshPut(params({ id })));
};

export const useWorkerAgreementExport = () => {
  return useMutation(async (id: number) => {
    const response = await api.apiV1AdminWorkersIdSalaryDeductionAgreementGet(fileParams({ id }));
    downloadFileFromResponse(response);
  });
};

export const useWorkerEnable = () => {
  return useMutation(async (id: number) => {
    await api.apiV1AdminWorkersIdEnablePut(params({ id }));
    await api.apiV1AdminWorkersIdRefreshPut(params({ id }));
  });
};

export const useWorkerDisable = () => {
  return useMutation(async (id: number) => {
    await api.apiV1AdminWorkersIdDisablePut(params({ id }));
    await api.apiV1AdminWorkersIdRefreshPut(params({ id }));
  });
};
