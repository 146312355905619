// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { AnswerMessageDto, ApiErrorResponse, ApiResponse, WorkerMessageDetailDtoApiDataResponse, WorkerMessageViewDtoListApiDataResponse } from "../model";

export class WorkerMessagesApi extends APIHandler {
    static urls: APIURLTypes.WorkerMessagesApi = {
        apiV1ClientWorkerMessagesGet: "/api/v1/client/worker-messages",
        apiV1ClientWorkerMessagesIdGet: "/api/v1/client/worker-messages/{id}",
        apiV1ClientWorkerMessagesIdPut: "/api/v1/client/worker-messages/{id}"
    };
    
    constructor() {
        super("WorkerMessagesApi");
    }
    
    /**
     * Get messages mobile view.
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1ClientWorkerMessagesGet(params: APIParams & {
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<WorkerMessageViewDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(WorkerMessagesApi.urls.apiV1ClientWorkerMessagesGet, null);
        params.options = this.initOptions(params.options, { offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<WorkerMessageViewDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientWorkerMessagesGet", params.options, body, "apiV1ClientWorkerMessagesGet");
    }

    /**
     * Get message detail mobile view.
     * @param id 
     */
    public apiV1ClientWorkerMessagesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<WorkerMessageDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(WorkerMessagesApi.urls.apiV1ClientWorkerMessagesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<WorkerMessageDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientWorkerMessagesIdGet", params.options, body, "apiV1ClientWorkerMessagesIdGet");
    }

    /**
     * Answer message.
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. 
     * @param id 
     * @param answerMessageDto 
     */
    public apiV1ClientWorkerMessagesIdPut(params: APIParams & {
        id: number;
        answerMessageDto?: AnswerMessageDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(WorkerMessagesApi.urls.apiV1ClientWorkerMessagesIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.answerMessageDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1ClientWorkerMessagesIdPut", params.options, body, "apiV1ClientWorkerMessagesIdPut");
    }

}
