import { Card, CardContent, Box, styled } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Props } from './types';
import { FOOTER_PART_1, FOOTER_PART_2 } from '@/constants';
import { removeStyledProps, removeStyledSmallScreenProp, useSmallScreen } from '@/utils';
import { appHeaderHeight } from '@/styles';

export const Content = ({ children, fullWidth, collapseEnabled, toggleFullWidth }: Props) => {
  const Icon = fullWidth ? ArrowRight : ArrowLeft;
  const smallScreen = useSmallScreen();
  const compact = !collapseEnabled;
  const smallScreenOrCompact = smallScreen || compact;

  return (
    <Wrapper {...{ smallScreen }}>
      {smallScreen && <HeaderExtender />}
      <InnerWrapper {...{ smallScreen }}>
        <Container {...{ raised: true, smallScreen, compact }}>
          {!smallScreenOrCompact && (
            <CollapseArrow onClick={toggleFullWidth} data-cy='sidebar-toggle'>
              <Icon />
            </CollapseArrow>
          )}
          <ContainerContent {...{ smallScreenOrCompact }}>{children}</ContainerContent>
        </Container>
        {smallScreenOrCompact && (
          <Footer {...{ smallScreen }}>
            {FOOTER_PART_1} {FOOTER_PART_2}
          </Footer>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const HeaderExtender = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: appHeaderHeight,
}));

const InnerWrapper = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) =>
  smallScreen
    ? {
        position: 'absolute',
        top: 0,
        left: 0,
        width: `calc(100% - ${theme.spacing(4)})`,
        height: 'fit-content',
        minHeight: `calc(100% - ${theme.spacing(1)})`,
        padding: `0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }
    : {
        width: '100%',
        height: '100%',
      },
);

const Container = styled(
  Card,
  removeStyledProps(['smallScreen', 'compact']),
)<{ smallScreen: boolean; compact: boolean }>(({ theme, smallScreen, compact }) => {
  const height = '100%';
  const compactHeight = 'none';
  const width = `calc(100% - ${theme.spacing(3)})`;

  return smallScreen
    ? compact
      ? { maxWidth: 500, margin: '0 auto' }
      : { width: '100%' }
    : compact
    ? {
        position: 'absolute',
        left: '50%',
        top: `-${theme.spacing(2)}`,
        transform: 'translateX(-50%)',
        width: 'auto',
        minWidth: 500,
        maxWidth: width,
        height: compactHeight,
        minHeight: compactHeight,
        maxHeight: compactHeight,
        display: 'flex',
        alignItems: 'stretch',
      }
    : {
        position: 'absolute',
        left: 0,
        top: `-${theme.spacing(2)}`,
        width,
        height,
        minHeight: height,
        maxHeight: height,
        display: 'flex',
        alignItems: 'stretch',
      };
});

const ContainerContent = styled(
  CardContent,
  removeStyledProps('smallScreenOrCompact'),
)<{ smallScreenOrCompact: boolean }>(({ theme, smallScreenOrCompact }) => {
  const width = `calc(100% - ${theme.spacing(smallScreenOrCompact ? 4 : 5)})`;
  const height = `calc(100% - ${theme.spacing(2)})`;

  return {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(
      smallScreenOrCompact ? 2 : 1,
    )} !important`,
    width,
    minWidth: width,
    maxWidth: width,
    height,
    minHeight: height,
    maxHeight: height,
    ...(smallScreenOrCompact ? { minHeight: 200, maxHeight: 'none', overflowY: 'visible' } : {}),
  };
});

const CollapseArrow = styled(Box)(({ theme }) => ({
  width: theme.spacing(2),
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Footer = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  fontSize: '0.8em',
  ...(smallScreen
    ? {
        padding: `${theme.spacing(3)} 0 ${theme.spacing(2)}`,
        width: '100%',
        textAlign: 'center',
      }
    : {
        position: 'absolute',
        left: '50%',
        bottom: theme.spacing(4),
        transform: 'translateX(-50%)',
      }),
}));
