// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, OpenAiAnswer, OpenAiQuestion, Stream } from "../model";

export class OpenAiApi extends APIHandler {
    static urls: APIURLTypes.OpenAiApi = {
        apiV1SharedOpenAiAskPost: "/api/v1/shared/open-ai/ask",
        apiV1SharedOpenAiAskStreamPost: "/api/v1/shared/open-ai/ask-stream"
    };
    
    constructor() {
        super("OpenAiApi");
    }
    
    /**
     * @param openAiQuestion 
     */
    public apiV1SharedOpenAiAskPost(params: APIParams & {
        openAiQuestion?: OpenAiQuestion;
    } = {}): AxiosPromise<OpenAiAnswer> {
        const apiURL: string = this.initAPIURL(OpenAiApi.urls.apiV1SharedOpenAiAskPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.openAiQuestion;
        return this.makeCall<OpenAiAnswer>("POST".toLowerCase(), apiURL, "apiV1SharedOpenAiAskPost", params.options, body, "apiV1SharedOpenAiAskPost");
    }

    /**
     * @param openAiQuestion 
     */
    public apiV1SharedOpenAiAskStreamPost(params: APIParams & {
        openAiQuestion?: OpenAiQuestion;
    } = {}): AxiosPromise<Stream> {
        const apiURL: string = this.initAPIURL(OpenAiApi.urls.apiV1SharedOpenAiAskStreamPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.openAiQuestion;
        return this.makeCall<Stream>("POST".toLowerCase(), apiURL, "apiV1SharedOpenAiAskStreamPost", params.options, body, "apiV1SharedOpenAiAskStreamPost");
    }

}
