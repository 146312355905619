export const Users = {
  Company: 'Company',
  FirstName: 'First name',
  LastName: 'Last name',
  Username: 'Username',
  Identifier: 'Identifier',
  CompanyName: 'Company',
  CompanyCode: 'Company Code',
  User: 'User',
  Password: 'Password',
  NewPassword: 'New password',
  ConfirmNewPassword: 'Confirm new password',
  Roles: 'Roles',
  Warning: 'Warning',
  WarningText:
    'If you edit your roles, saving will log you out and you may lose some access to the application.',
};
