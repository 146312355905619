import { useCallback, useState } from 'react';
import { TableCellComponent, TableColumnType } from '../Table';
import {
  ActionComponentProps,
  ActionComponentWithHookProps,
  ActionProps,
  ActionsProps,
} from './types';
import { ConfirmButton, useLoading } from '@/components';
import { Box, styled } from '@mui/material';
import { useAppContext } from '@/context';

export const createActions = ({ actions, onSuccess, t, dataKey }: ActionsProps) => {
  if (actions) {
    return [
      {
        dataKey: dataKey || 'actions',
        label: '',
        sortEnabled: false,
        type: TableColumnType.Component,
        component: (({ item }) => {
          return (
            <Actions>
              {actions.map(({ key, label, text, message, isEnabled = () => true, ...action }) => {
                const isLabelString = typeof label === 'string';
                const actionKey = key ?? (isLabelString ? label : '');
                const actionLabel = isLabelString ? t(label) : label(item, t);

                return (
                  <Action
                    key={actionKey}
                    {...{
                      ...action,
                      item,
                      label: actionLabel,
                      text: t(text),
                      enabled: isEnabled(item),
                      afterCallback: () => (message ? onSuccess(t(message)) : null),
                      'data-cy': `module-action-${actionKey}`,
                    }}
                  />
                );
              })}
            </Actions>
          );
        }) as TableCellComponent,
      },
    ];
  }

  return [];
};

const Action = ({ useAction, onClick, ...props }: ActionProps) => {
  return useAction ? (
    <ActionComponentWithHook {...{ ...props, useAction }} />
  ) : onClick ? (
    <ActionComponent {...{ ...props, onClick, enableLoading: false }} />
  ) : (
    <></>
  );
};

const ActionComponentWithHook = ({ useAction, ...props }: ActionComponentWithHookProps) => {
  const { mutateAsync } = useAction();

  return <ActionComponent {...{ ...props, onClick: mutateAsync }} />;
};

const ActionComponent = ({
  onClick,
  afterCallback,
  item: { id },
  enabled,
  permission,
  text,
  createContent,
  enableLoading = true,
  visibleOnSuccess = true,
  ...props
}: ActionComponentProps) => {
  const { showLoading, hideLoading } = useLoading();
  const { hasPermission } = useAppContext();
  const [success, setSuccess] = useState(false);

  const handleClick = useCallback(async () => {
    if (enableLoading) {
      showLoading();
    }
    await onClick(id);
    if (enableLoading) {
      hideLoading();
    }
    afterCallback();
    setSuccess(true);
  }, [id, enableLoading, onClick, afterCallback, showLoading, hideLoading]);

  return enabled && hasPermission(permission) && (!success || visibleOnSuccess) ? (
    <StyledConfirmButton
      {...{
        ...props,
        text,
        confirmDisabled: !text && !createContent,
        onClick: handleClick,
        Content: createContent ? createContent(id) : undefined,
      }}
    />
  ) : (
    <></>
  );
};

const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledConfirmButton = styled(ConfirmButton)(({ theme }) => ({
  color: theme.palette.success.main,
  marginRight: theme.spacing(1),
}));
