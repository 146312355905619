// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class QuestionResponse extends APIModel {
    caption: string;
    example: string;
    format: string;
    toolTip: string;

    constructor(options?: APIModelOptions) {
        super(QuestionResponse.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "caption", type: "string" },
            { name: "example", type: "string" },
            { name: "format", type: "string" },
            { name: "toolTip", type: "string" }
        ];
    }
}




