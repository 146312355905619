import {
  useConnectorCreate,
  useConnectorExport,
  useConnectorGet,
  useConnectorList,
  useConnectorUpdate,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { getConnectorStateStyle } from '@/styles';
import { FormBasic, formBasicFields } from './FormBasic';
import { FormScript, formScriptFields } from './FormScript';
import { useValidationSchema } from './validation';

export const Connectors = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Connectors,
        translationKey: 'Connectors',
        useApiList: useConnectorList,
        useApiGet: useConnectorGet,
        useApiCreate: useConnectorCreate,
        useApiUpdate: useConnectorUpdate,
        useApiExport: useConnectorExport,
        useValidationSchema,
        formComponentHeight: 550,
        columns: [
          { dataKey: 'id', label: 'Id', type: TableColumnType.Number },
          { dataKey: 'name', label: 'Name' },
          { dataKey: 'code', label: 'Code' },
          { dataKey: 'identifier', label: 'Identifier' },
          { dataKey: 'active', label: 'Active', type: TableColumnType.Boolean },
          { dataKey: 'version', label: 'Version' },
          {
            dataKey: 'state',
            label: 'State',
            type: TableColumnType.Enum,
            enumName: 'ConnectorState',
            getStyle: getConnectorStateStyle,
          },
        ],
        initialValues: {
          code: '',
          name: '',
          version: '',
          description: '',
          script: '',
          active: false,
          company: null,
        },
        formComponents: [
          {
            label: 'Connector',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'Script',
            component: FormScript,
            fields: formScriptFields,
          },
        ],
      }}
    />
  );
};
