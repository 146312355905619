import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';

export const usePreviousNotEmpty = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    if (value && !isEmpty(value)) {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
};
