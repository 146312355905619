import { removeStyledProps } from '@/utils';
import { MoreHoriz } from '@mui/icons-material';
import {
  Box,
  styled,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { identity, isEmpty, pickBy } from 'lodash';
import { useCallback, useState } from 'react';
import { Order } from './enums';
import { TableFilter } from './TableFilter';
import { TableHeadProps } from './types';
import {
  chooseDataKey,
  createRangeFromKey,
  createRangeToKey,
  isNumberType,
  isRangeType,
} from './utils';

type FilterActive = { [key: string]: boolean };

export const TableHead = ({
  columns,
  isSortAscending,
  filter,
  onFilter,
  sortBy,
  onSort,
}: TableHeadProps) => {
  const [filterActive, setFilterActive] = useState<FilterActive>({});

  const updateFilterActive = useCallback(
    (key: string) => {
      setFilterActive((filterActive) => ({ ...filterActive, [key]: !filterActive[key] }));
      const column = columns.find(
        ({ filterKey, dataKey }) => key === chooseDataKey(filterKey, dataKey),
      );
      if (column && isRangeType(column.type)) {
        onFilter(createRangeFromKey(key), undefined);
        onFilter(createRangeToKey(key), undefined);
      } else {
        onFilter(key, undefined);
      }
    },
    [columns, onFilter],
  );

  const direction = isSortAscending ? Order.asc : Order.desc;

  return (
    <MuiTableHead>
      <TableRow>
        {columns.map(
          ({ dataKey, sortKey, filterKey, type, label, filterEnabled, sortEnabled = true }) => {
            const currentSortKey = chooseDataKey(sortKey, dataKey);
            const currentFilterKey = chooseDataKey(filterKey, dataKey);
            const isSortActive = sortBy === currentSortKey;
            const isRight = isNumberType(type);

            const content = <TableCellContent>{label}</TableCellContent>;

            const sortContent = sortEnabled ? (
              <TableSortLabel
                active={isSortActive}
                direction={isSortActive ? direction : Order.desc}
                onClick={() => onSort(currentSortKey, isSortActive ? !isSortAscending : false)}
                data-cy={`table-sort-${currentSortKey}`}
              >
                {content}
              </TableSortLabel>
            ) : (
              content
            );

            return (
              <StyledTableCell
                key={dataKey}
                sortDirection={isSortActive ? direction : false}
                align={isRight ? 'right' : 'left'}
              >
                <TableCellContainer
                  component='span'
                  display={isRight ? 'flex' : 'inline-flex'}
                  flexDirection={isRight ? 'row-reverse' : 'row'}
                >
                  {sortContent}
                  {filterEnabled ? (
                    <FilterIcon
                      {...{
                        active: !!filterActive[currentFilterKey],
                        onClick: () => updateFilterActive(filterKey ?? currentFilterKey),
                        'data-cy': `table-filter-${currentFilterKey}`,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </TableCellContainer>
              </StyledTableCell>
            );
          },
        )}
      </TableRow>
      {!isEmpty(pickBy(filterActive, identity)) && (
        <TableRow>
          {columns.map(({ dataKey: defaultDataKey, filterKey, type, filterType, ...rest }) => {
            const dataKey = chooseDataKey(filterKey, defaultDataKey);
            const active = filterActive[dataKey];

            return (
              <FilterTableCell key={dataKey}>
                {active ? (
                  <TableFilter
                    {...{
                      ...rest,
                      type: filterType ?? type,
                      dataKey,
                      filter,
                      onChange: onFilter,
                    }}
                  />
                ) : (
                  <></>
                )}
              </FilterTableCell>
            );
          })}
        </TableRow>
      )}
    </MuiTableHead>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomWidth: 0,
  '& svg': {
    color: `${theme.palette.common.black} !important`,
  },
}));

const FilterTableCell = styled(StyledTableCell)(({ theme }) => ({
  padding: `0 ${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)}`,
  verticalAlign: 'bottom',
}));

const TableCellContainer = styled(Box)({
  alignItems: 'center',
});

const FilterIcon = styled(
  MoreHoriz,
  removeStyledProps('active'),
)<{ active: boolean }>(({ theme, active }) => ({
  cursor: 'pointer',
  ...(active ? { color: theme.palette.secondary.main } : {}),
}));

const TableCellContent = styled(Box)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: theme.typography.fontWeightBold,
}));
