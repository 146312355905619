import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppContext as TAppContext } from './types';
import { AppContext } from './AppContext';
import { getParsedToken, isTokenValid, parseToken, storage } from '@/utils';
import { Permission, StorageKey } from '@/enums';
import { LoggedUserDto } from '@/api/base/model';

export const AppContextProvider = ({ children }: React.PropsWithChildren) => {
  const [initialized, setInitialized] = useState(false);
  const [sessionId, setSessionId] = useState<number | null>(null);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const loggedIn = !!sessionId;

  const updateLoggedUser = useCallback(
    (loggedUser: LoggedUserDto) => {
      const { token, companyId, permissions } = loggedUser;
      storage.setItem(StorageKey.Token, token);
      storage.setItem(StorageKey.CompanyId, companyId);
      storage.setJSON(StorageKey.Permissions, permissions);
      const parsed = parseToken(token);
      setSessionId(parsed.sessionId);
      setCompanyId(companyId);
      setPermissions(permissions as Permission[]);
    },
    [setSessionId],
  );

  const removeLoggedUser = useCallback(() => {
    storage.removeItem(StorageKey.Token);
    storage.removeItem(StorageKey.CompanyId);
    storage.removeItem(StorageKey.Permissions);
    setSessionId(null);
    setCompanyId(null);
    setPermissions([]);
  }, [setSessionId]);

  const hasPermission = useCallback(
    (permission: Permission | undefined) => {
      return !permission || permissions.includes(permission);
    },
    [permissions],
  );

  const hasAnyPermission = useCallback(
    (permissionsToCheck: Permission[] | undefined) => {
      return !permissionsToCheck || !!permissionsToCheck.find(hasPermission);
    },
    [hasPermission],
  );

  useEffect(() => {
    const checkToken = () => setSessionId((sessionId) => (isTokenValid() ? sessionId : null));

    if (isTokenValid()) {
      const token = getParsedToken();

      if (token) {
        const companyIdString = storage.getItem(StorageKey.CompanyId);
        const permissions: Permission[] | null = storage.getJSON(StorageKey.Permissions);

        if (companyIdString && permissions && permissions.length) {
          const companyId = companyIdString === 'null' ? null : Number(companyIdString);

          if (companyId === null || !isNaN(companyId)) {
            setSessionId(token.sessionId);
            setCompanyId(companyId);
            setPermissions(permissions);
          }
        }
      }
    }

    window.addEventListener('storage', checkToken);
    setInitialized(true);

    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, []);

  const context: TAppContext = useMemo(
    () => ({
      initialized,
      loggedIn,
      companyId,
      permissions,
      updateLoggedUser,
      removeLoggedUser,
      hasPermission,
      hasAnyPermission,
    }),
    [
      initialized,
      loggedIn,
      companyId,
      permissions,
      updateLoggedUser,
      removeLoggedUser,
      hasPermission,
      hasAnyPermission,
    ],
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
