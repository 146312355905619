import { UseApiList } from '@/api';
import { usePreviousNotEmpty } from '@/utils';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiListData<T, D extends Array<any>>(useApiList: UseApiList<T, D>, options: T) {
  const rawApiList = useApiList(options);

  const apiList = useMemo(
    () => ({
      ...rawApiList,
      data: {
        data: [],
        message: '',
        ...(rawApiList.data ?? {}),
        metadata: {
          totalItems: 0,
          ...(rawApiList.data ? rawApiList.data.metadata : {}),
        },
      },
    }),
    [rawApiList],
  );

  const prevData = usePreviousNotEmpty(apiList.data);

  return prevData && !apiList.data.data.length && apiList.isLoading
    ? {
        ...apiList,
        data: prevData,
      }
    : apiList;
}
