import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isStringRequired, isStringMaxLength }) => ({
    type: isStringMaxLength(200),
    fileName: isStringMaxLength(512),
    fileType: isStringMaxLength(255),
    fileData: isStringRequired(),
  }));
};
