export enum TableAction {
  Reload = 'reload',
  Export = 'export',
  GenerateFiles = 'generateFiles',
  Add = 'add',
  AddBulk = 'addBulk',
  VerifyConnection = 'verifyConnection',
  BatchProcess = 'batchProcess',
  ReconciliatePayments = 'reconciliatePayments',
  PollBatches = 'pollBatches',
  DirectDebits = 'directDebits',
}
