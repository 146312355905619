// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { KeyValuePairDto } from './key-value-pair-dto';
import { PaymentState } from './payment-state';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class PaymentDto extends APIModel {
    /**
    * Payment identifier
    */
    id: number;
    /**
    * Pay month sequence
    */
    payMonthSequence: number;
    /**
    * Currency
    */
    currency: string;
    /**
    * Payment amount
    */
    amount: number;
    /**
    * Amount paid
    */
    paid: number;
    /**
    * Payment advance
    */
    advance: number;
    /**
    * Company fee
    */
    companyFee: number;
    /**
    * Worker fee
    */
    workerFee: number;
    /**
    * Bonus amount
    */
    bonus: number;
    /**
    * Demo
    */
    demo: boolean;
    state: PaymentState;
    /**
    * Pay month
    */
    payMonth: string;
    /**
    * Date when payment was requested
    */
    dateRequested: string;
    /**
    * Date when payment was verified
    */
    dateVerified: string;
    /**
    * Date when payment was processed
    */
    dateProcessed: string;
    worker: KeyValuePairDto;
    company: KeyValuePairDto;
    /**
    * Referral bonus of payment
    */
    referralBonus: number;
    /**
    * Hunter referral code
    */
    hunterReferralCode: string;
    /**
    * Direct debit id
    */
    directDebitId: number;
    /**
    * The account number the payment will be sent to
    */
    account: string;

    constructor(options?: APIModelOptions) {
        super(PaymentDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "payMonthSequence", type: "number" },
            { name: "currency", type: "string" },
            { name: "amount", type: "number" },
            { name: "paid", type: "number" },
            { name: "advance", type: "number" },
            { name: "companyFee", type: "number" },
            { name: "workerFee", type: "number" },
            { name: "bonus", type: "number" },
            { name: "demo", type: "boolean" },
            { name: "state", type: "PaymentState" },
            { name: "payMonth", type: "string" },
            { name: "dateRequested", type: "string" },
            { name: "dateVerified", type: "string" },
            { name: "dateProcessed", type: "string" },
            { name: "worker", type: "KeyValuePairDto" },
            { name: "company", type: "KeyValuePairDto" },
            { name: "referralBonus", type: "number" },
            { name: "hunterReferralCode", type: "string" },
            { name: "directDebitId", type: "number" },
            { name: "account", type: "string" }
        ];
    }
}




