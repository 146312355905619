// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, BalanceDetailDtoApiDataResponse, BalanceDtoListApiDataResponse } from "../model";

export class BalancesApi extends APIHandler {
    static urls: APIURLTypes.BalancesApi = {
        apiV1AdminBalancesGet: "/api/v1/admin/balances",
        apiV1AdminBalancesIdErpGet: "/api/v1/admin/balances/{id}/erp",
        apiV1AdminBalancesIdGet: "/api/v1/admin/balances/{id}",
        apiV1AdminBalancesIdXlsxPaymentsGet: "/api/v1/admin/balances/{id}/xlsx-payments",
        apiV1AdminBalancesXlsxGet: "/api/v1/admin/balances/xlsx"
    };
    
    constructor() {
        super("BalancesApi");
    }
    
    /**
     * Get balances
     * @param companyId 
     * @param payMonthFrom 
     * @param payMonthTo 
     * @param regionId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminBalancesGet(params: APIParams & {
        companyId?: number;
        payMonthFrom?: string;
        payMonthTo?: string;
        regionId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<BalanceDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(BalancesApi.urls.apiV1AdminBalancesGet, null);
        params.options = this.initOptions(params.options, { companyId: params.companyId, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, regionId: params.regionId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<BalanceDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminBalancesGet", params.options, body, "apiV1AdminBalancesGet");
    }

    /**
     * Get balance ERP export in XML or XLSX format.
     *  ### Error names for HTTP Status 400 BadRequest: + **COMPANY_HAS_NO_ERP_SYSTEM** - Company has no ERP system. + **UNKNOWN_ERP_SYSTEM** - Company has unknown ERP system. 
     * @param id 
     */
    public apiV1AdminBalancesIdErpGet(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(BalancesApi.urls.apiV1AdminBalancesIdErpGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminBalancesIdErpGet", params.options, body, "apiV1AdminBalancesIdErpGet");
    }

    /**
     * Get balance detail
     * @param id 
     */
    public apiV1AdminBalancesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<BalanceDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(BalancesApi.urls.apiV1AdminBalancesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<BalanceDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminBalancesIdGet", params.options, body, "apiV1AdminBalancesIdGet");
    }

    /**
     * Get balance payments export in XLSX format.
     * @param id 
     */
    public apiV1AdminBalancesIdXlsxPaymentsGet(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(BalancesApi.urls.apiV1AdminBalancesIdXlsxPaymentsGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminBalancesIdXlsxPaymentsGet", params.options, body, "apiV1AdminBalancesIdXlsxPaymentsGet");
    }

    /**
     * Get balance export in XLSX format.
     * @param companyId 
     * @param payMonthFrom 
     * @param payMonthTo 
     * @param regionId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminBalancesXlsxGet(params: APIParams & {
        companyId?: number;
        payMonthFrom?: string;
        payMonthTo?: string;
        regionId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(BalancesApi.urls.apiV1AdminBalancesXlsxGet, null);
        params.options = this.initOptions(params.options, { companyId: params.companyId, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, regionId: params.regionId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminBalancesXlsxGet", params.options, body, "apiV1AdminBalancesXlsxGet");
    }

}
