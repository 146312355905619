export const Dashboard = {
  Amount: 'Částka',
  Available: 'K čerpání',
  Company: 'Společnost',
  Count: 'Počet',
  GlobalOverview: 'Globální přehled',
  Home: 'Vítejte',
  LoggedIn: 'Přihlášeno',
  Month: 'Měsíc',
  NoData: 'Žádná data k zobrazení',
  NumberOfIndividualWithdrawals: 'Počet jednotlivých výběrů',
  NumbersOfWorkers: 'Počty pracovníků',
  PaymentRequested: 'Požádalo',
  Realized: 'Vyčerpáno',
  Registered: 'Registrováno',
  TotalAmountOfWithdrawals: 'Celková částka výběrů',
  TotalBudget: 'Celkový budget',
};
