export const Connectors = {
  Id: 'Identifier',
  Active: 'Active',
  Code: 'Code',
  Company: 'Company',
  Connector: 'Connector',
  Description: 'Description',
  Identifier: 'Identifier UID',
  Name: 'Name',
  Script: 'Script',
  State: 'State',
  Version: 'Version',
};
