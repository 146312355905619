import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Props } from './types';
import { useState } from 'react';

export const ConfirmModal = ({ open, label, text, Content, onClick, onClose }: Props) => {
  const { t } = useTranslation('Common');
  const [disabled, setDisabled] = useState(!!Content);

  return (
    <Modal {...{ open, maxWidth: 'xs', onClick: (e) => e.stopPropagation() }}>
      <Container>
        <Title variant='h6'>{label}</Title>
        {text && <p>{text}</p>}
        {Content ? <Content onInit={() => setDisabled(false)} /> : <></>}
        <Actions>
          <StyledButton
            {...{
              label: t('Yes'),
              disabled,
              onClick: () => {
                onClose();
                onClick();
              },
              'data-cy': 'confirm-button-submit',
            }}
          />
          <StyledButton {...{ label: t('No'), onClick: onClose, color: 'secondary' }} />
        </Actions>
      </Container>
    </Modal>
  );
};

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));
