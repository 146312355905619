// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, RoleDtoListApiDataResponse } from "../model";

export class RolesApi extends APIHandler {
    static urls: APIURLTypes.RolesApi = {
        apiV1AdminRolesGet: "/api/v1/admin/roles"
    };
    
    constructor() {
        super("RolesApi");
    }
    
    /**
     * Get roles
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminRolesGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<RoleDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(RolesApi.urls.apiV1AdminRolesGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<RoleDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminRolesGet", params.options, body, "apiV1AdminRolesGet");
    }

}
