import { useSnackbar as useNotistackSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useSnackbar = () => {
  const { enqueueSnackbar, ...rest } = useNotistackSnackbar();

  const showSnackbar = useCallback(
    (message: string) => enqueueSnackbar(message),
    [enqueueSnackbar],
  );

  const showSuccessSnackbar = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: 'success', autoHideDuration: 3000 }),
    [enqueueSnackbar],
  );

  const showErrorSnackbar = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 }),
    [enqueueSnackbar],
  );

  const showWarningSnackbar = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: 'warning', autoHideDuration: 10000 }),
    [enqueueSnackbar],
  );

  return { showSnackbar, showSuccessSnackbar, showErrorSnackbar, showWarningSnackbar, ...rest };
};
