export const DirectDebits = {
  Amount: 'Čiastka',
  Created: 'Vytvorené',
  DirectDebits: 'Inkasá',
  DirectDebit: 'Inkaso',
  DirectDebitRequest: 'Zadatné do banky',
  DirectDebitRequestConfirmText: 'Označit inkaso jako zadané do banky?',
  DirectDebitRequestSuccess: 'Inkaso bylo označeno jako zadané do banky.',
  DirectDebitProcess: 'Spracovať',
  DirectDebitProcessConfirmText: 'Naozaj je inkaso odoslané?',
  DirectDebitProcessSuccess: 'Inkaso bolo spracované',
  Identifier: 'Identifikátor',
  CompanyCode: 'Kód spoločnosti',
  CompanyName: 'Názov spoločnosti',
  DownloadXlsx: 'Stiahnuť XLSX',
  DownloadXlsxText: 'Prajete si vygenerovať XLSX súbor?',
  DownloadCompleted: 'Sťahovanie dokončené',
  Status: 'Stav',
  PayMonth: 'Na vyplatenie',
};
