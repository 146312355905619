import {
  getImagePublicUrl,
  useImageDelete,
  useImageExport,
  useImageGet,
  useImageList,
  useImageUpdate,
} from '@/api';
import { Module, TableCellComponent, TableColumnType } from '@/components';
import { ApiKey, Permission } from '@/enums';
import { tableCellHeight } from '@/styles';
import { toClipboard } from '@/utils';
import { CopyAll, Delete } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { createDeleteConfirm } from './DeleteConfirm';
import { FormBasic, formBasicFields } from './FormBasic';
import { useValidationSchema } from './validation';

export const Images = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Images,
        translationKey: 'Images',
        useApiList: useImageList,
        useApiGet: useImageGet,
        useApiUpdate: useImageUpdate,
        useApiExport: useImageExport,
        useValidationSchema,
        formComponentHeight: 450,
        refreshComponentColumnAfterSave: true,
        columns: [
          { dataKey: 'id', label: 'Id', type: TableColumnType.Number },
          { dataKey: 'created', label: 'Created', type: TableColumnType.DateTime },
          { dataKey: 'lastModified', label: 'LastModified', type: TableColumnType.DateTime },
          { dataKey: 'type', label: 'Type' },
          { dataKey: 'fileName', label: 'FileName' },
          { dataKey: 'fileSize', label: 'FileSize' },
          {
            dataKey: 'preview',
            label: '',
            sortEnabled: false,
            type: TableColumnType.Component,
            component: (({ item, componentKey }) => {
              return (
                <Image>
                  <img src={`${getImagePublicUrl(item.id)}?${componentKey}`} />
                </Image>
              );
            }) as TableCellComponent,
          },
        ],
        formComponents: [
          {
            label: 'Image',
            component: FormBasic,
            fields: formBasicFields,
          },
        ],
        actions: [
          {
            label: 'CopyUrl',
            message: 'UrlCopied',
            onClick: (id: number) => toClipboard(getImagePublicUrl(id)),
            icon: CopyAll,
          },
          {
            label: 'Delete',
            message: 'DeleteSuccess',
            useAction: useImageDelete,
            icon: Delete,
            permission: Permission.MessagesSave,
            createContent: createDeleteConfirm,
            visibleOnSuccess: false,
          },
        ],
      }}
    />
  );
};

const Image = styled(Box)({
  height: tableCellHeight,
  minHeight: tableCellHeight,
  maxHeight: tableCellHeight,
  img: {
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
});
