import { ListItemIcon, ListItemText, Menu as MuiMenu, MenuItem, styled } from '@mui/material';
import { Props } from './types';

export const Menu = ({ items, anchorEl, onClose }: Props) => {
  return (
    <StyledMenu
      {...{
        anchorEl,
        open: !!anchorEl,
        onClose,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
    >
      {items.map(({ label, onClick, icon: Icon, ...item }) => (
        <MenuItem
          key={label}
          onClick={() => {
            onClick();
            onClose();
          }}
          {...item}
        >
          {Icon && (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

const StyledMenu = styled(MuiMenu)({
  '& .MuiPaper-root': {
    borderRadius: 24,
  },
});
