import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(
    ({ isStringRequired, isStringMaxLength, isStringMaxLengthOptional, isEntity }) => ({
      name: isStringMaxLength(200),
      code: isStringMaxLength(50),
      description: isStringMaxLengthOptional(500),
      version: isStringMaxLength(100),
      script: isStringRequired(),
      company: isEntity('Company'),
    }),
  );
};
