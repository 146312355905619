export const Balances = {
  Id: 'Identifikátor',
  Balance: 'Závěrka',
  PayMonth: 'Měsíc',
  CompanyCode: 'Kód společnosti',
  CompanyName: 'Název společnosti',
  DownloadErpSystem: 'Stáhnout pro {{erpSystem}}',
  DownloadErpSystemText: 'Přejete si vygenerovat soubor pro import do mzdového systému?',
  DownloadXls: 'Stáhnout XLS',
  DownloadXlsText: 'Přejete si vygenerovat XLS soubor?',
  DownloadCompleted: 'Stahování dokončeno',
  TotalPaid: 'Suma plateb',
  TotalBonus: 'Suma bonusů',
  TotalFee: 'Suma poplatků',
  TotalAdvance: 'Suma záloh',
  TotalCompanyFee: 'Suma poplatků hrazených firmou',
  TotalWorkerFee: 'Suma poplatků hrazených zaměstnancem',
  TotalPaymentsCount: 'Počet plateb',
  TotalWorkersCount: 'Počet zaměstnanců',
  TotalWorkersRegistered: 'Počet registrovaných',
  TotalWorkersActive: 'Počet aktivních',
  TotalWorkersPaid: 'Vybrali',
  DateTimeCalculated: 'Datum závěrky',
  DateTimeDownloaded: 'Datum exportu',
};
