// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class VerifyQuestionRegistrationDto extends APIModel {
    /**
    * First verification
    */
    firstQuestionInput: string;
    /**
    * Second verification
    */
    secondQuestionInput: string;
    /**
    * Referral code of the Hunter
    */
    hunterReferralCode: string;

    constructor(options?: APIModelOptions) {
        super(VerifyQuestionRegistrationDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "firstQuestionInput", type: "string" },
            { name: "secondQuestionInput", type: "string" },
            { name: "hunterReferralCode", type: "string" }
        ];
    }
}




