export const Cards = {
  Identifier: 'Identifikátor',
  Worker: 'Pracovník',
  CardIdentifier: 'Identifikátor karty',
  MCID: 'MPTS ID',
  PAN: 'Číslo karty',
  Status: 'Stav',
  Company: 'Společnost',
  ActivationDate: 'Datum aktivace',
};
