import { FormValues, ApiListOptions } from '@/components';
import { useQuery, useMutation } from 'react-query';
import { ApiKey } from '@/enums';
import { CompaniesApi } from '../base';
import { RegistrationType, SaveCompanyDto } from '../base/model';
import { params, dto, fileParams, downloadFileFromResponse } from '../utils';

const api = new CompaniesApi();

interface UseCompanyLookupdListOptions {
  search?: string;
  limit?: number;
  demo?: boolean;
}

export const useCompanyList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Companies, options], async () => {
    const response = await api.apiV1AdminCompaniesGet(params(options));

    return response.data;
  });
};

export const useCompanyExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminCompaniesXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useCompanyLookupsList = (options: UseCompanyLookupdListOptions) => {
  return useQuery([ApiKey.CompaniesLookups, options], async () => {
    const response = await api.apiV1AdminCompaniesLookupsGet(params(options));

    return response.data;
  });
};

export const useCompanyGet = (id: number) => {
  return useQuery([ApiKey.Companies, id], async () => {
    const response = await api.apiV1AdminCompaniesIdGet(params({ id }));

    return response.data.data;
  });
};

export const useCompanyCreate = () => {
  return useMutation((values: FormValues) => {
    if (!values.registrationType) {
      values.registrationType = RegistrationType.Default;
    }
    return api.apiV1AdminCompaniesPost(
      params({
        saveCompanyDto: dto(SaveCompanyDto, values),
      }),
    );
  });
};

export const useCompanyUpdate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminCompaniesIdPut(
      params({
        id: values.id,
        saveCompanyDto: dto(SaveCompanyDto, values),
      }),
    );
  });
};

export const useCompanyRefresh = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminCompaniesRefreshPut(params({ requestBody: [id] }));
  });
};

export const useCompanyRefreshDemoData = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminCompaniesRefreshDemoDataPost(params({ requestBody: [id] }));
  });
};

export const useCompanyMonthOverview = (id: number, month: string) => {
  return useQuery([ApiKey.CompanyMonthOverview, id, month], async () => {
    const response = await api.apiV1AdminCompaniesIdMonthOverviewMonthGet(params({ id, month }));

    return response.data.data;
  });
};

export const useCompanyCurrentBudget = (id: number) => {
  return useQuery([ApiKey.CompanyCurrentBudget, id], async () => {
    const response = await api.apiV1AdminCompaniesIdCurrentBudgetGet(params({ id }));

    return response.data.data;
  });
};

export const useCompanyCurrentWorkers = (id: number) => {
  return useQuery([ApiKey.CompanyCurrentWorkers, id], async () => {
    const response = await api.apiV1AdminCompaniesIdCurrentWorkersGet(params({ id }));

    return response.data.data;
  });
};

export const useCompanyBalanceCreate = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminCompaniesBalancesPost(params({ requestBody: [id] }));
  });
};

export const useCompanyActiveThemeGet = (id: number) => {
  return useQuery([ApiKey.Companies, id, 'active-theme'], async () => {
    const response = await api.apiV1ClientCompaniesIdActiveThemeGet(params({ id }));

    return response.data.data;
  });
};

export const useEnrollCompanyReferralProgram = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminCompaniesIdReferralEnrollPost(params({ id }));
  });
};

export const useRemoveCompanyReferralProgram = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminCompaniesIdReferralRemovePost(params({ id }));
  });
};
