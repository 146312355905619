export const Form = {
  Close: 'Zavrieť',
  Errors: 'Skontrolujte políčka formulára',
  FailedToUploadImage: 'Nepodarilo sa nahrať obrázok',
  Save: 'Uložiť',
  Saved: 'Uložené',
  SaveAndContinue: 'Uložiť a pokračovať',
  UploadImage: 'Nahrať obrázok',
  UploadImageDropzone: 'Pretiahnite sem súbor s obrázkom alebo kliknutím vyberte súbor',
};
