import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface TWindowSizeContext {
  width: number;
  height: number;
}

const WindowSizeContext = createContext<TWindowSizeContext>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const WindowSizeProvider = ({ children }: React.PropsWithChildren) => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const onResize = () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const context: TWindowSizeContext = useMemo(() => windowSize, [windowSize]);

  return <WindowSizeContext.Provider value={context}>{children}</WindowSizeContext.Provider>;
};

export const useWindowSize = () => useContext(WindowSizeContext);
