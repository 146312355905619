// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CompanyThemeDetailDtoApiDataResponse, CompanyThemeDtoListApiDataResponse, CreatedEntityDtoApiDataResponse, SaveCompanyThemeDto } from "../model";

export class CompanyThemesApi extends APIHandler {
    static urls: APIURLTypes.CompanyThemesApi = {
        apiV1AdminCompanyThemesGet: "/api/v1/admin/company-themes",
        apiV1AdminCompanyThemesIdGet: "/api/v1/admin/company-themes/{id}",
        apiV1AdminCompanyThemesIdPut: "/api/v1/admin/company-themes/{id}",
        apiV1AdminCompanyThemesPost: "/api/v1/admin/company-themes",
        apiV1AdminCompanyThemesXlsxGet: "/api/v1/admin/company-themes/xlsx"
    };
    
    constructor() {
        super("CompanyThemesApi");
    }
    
    /**
     * Get company themes
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminCompanyThemesGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<CompanyThemeDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompanyThemesApi.urls.apiV1AdminCompanyThemesGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyThemeDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompanyThemesGet", params.options, body, "apiV1AdminCompanyThemesGet");
    }

    /**
     * Get company theme detail
     * @param id 
     */
    public apiV1AdminCompanyThemesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<CompanyThemeDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompanyThemesApi.urls.apiV1AdminCompanyThemesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyThemeDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompanyThemesIdGet", params.options, body, "apiV1AdminCompanyThemesIdGet");
    }

    /**
     * Update company theme
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param id 
     * @param saveCompanyThemeDto 
     */
    public apiV1AdminCompanyThemesIdPut(params: APIParams & {
        id: number;
        saveCompanyThemeDto?: SaveCompanyThemeDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompanyThemesApi.urls.apiV1AdminCompanyThemesIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveCompanyThemeDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminCompanyThemesIdPut", params.options, body, "apiV1AdminCompanyThemesIdPut");
    }

    /**
     * Create company theme
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_INVALID** - Company is invalid. 
     * @param saveCompanyThemeDto 
     */
    public apiV1AdminCompanyThemesPost(params: APIParams & {
        saveCompanyThemeDto?: SaveCompanyThemeDto;
    } = {}): AxiosPromise<CreatedEntityDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompanyThemesApi.urls.apiV1AdminCompanyThemesPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveCompanyThemeDto;
        return this.makeCall<CreatedEntityDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompanyThemesPost", params.options, body, "apiV1AdminCompanyThemesPost");
    }

    /**
     * Get company theme export in XLSX format.
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminCompanyThemesXlsxGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CompanyThemesApi.urls.apiV1AdminCompanyThemesXlsxGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminCompanyThemesXlsxGet", params.options, body, "apiV1AdminCompanyThemesXlsxGet");
    }

}
