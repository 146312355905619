import { Balances } from './balances';
import { Common } from './common';
import { Companies } from './companies';
import { CompanyThemes } from './companyThemes';
import { Connectors } from './connectors';
import { Dashboard } from './dashboard';
import { Editor } from './editor';
import { Errors } from './errors';
import { Enums } from './enums';
import { Form } from './form';
import { Images } from './images';
import { Languages } from './languages';
import { Login } from './login';
import { Messages } from './messages';
import { MessagesAnswers } from './messagesAnswers';
import { Module } from './module';
import { PasswordChange } from './passwordChange';
import { Payments } from './payments';
import { Routes } from './routes';
import { Setting } from './setting';
import { UserAccount } from './userAccount';
import { Validation } from './validation';
import { Users } from './users';
import { Workers } from './workers';
import { Regions } from './regions';
import { DirectDebits } from './directDebits';
import { BankAuthorizations } from './bankAuthorizations';
import { Cards } from './cards';
import { CardTransactions } from './cardTransactions';

export default {
  ...Enums,
  Balances,
  BankAuthorizations,
  Cards,
  CardTransactions,
  Companies,
  CompanyThemes,
  Connectors,
  Dashboard,
  DirectDebits,
  Editor,
  Images,
  Messages,
  MessagesAnswers,
  Payments,
  Setting,
  Workers,
  Common,
  Errors,
  Form,
  Languages,
  Login,
  Module,
  PasswordChange,
  Regions,
  Routes,
  Users,
  UserAccount,
  Validation,
};
