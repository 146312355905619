// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, PaymentBatchIEnumerableApiDataResponse, UrlModelApiDataResponse, UrlsModelApiDataResponse } from "../model";

export class CeskaSporitelnaApi extends APIHandler {
    static urls: APIURLTypes.CeskaSporitelnaApi = {
        apiV1AdminCeskaSporitelnaAuthenticationUrlGet: "/api/v1/admin/ceska-sporitelna/authentication-url",
        apiV1AdminCeskaSporitelnaAuthorizationLinkPost: "/api/v1/admin/ceska-sporitelna/authorization-link",
        apiV1AdminCeskaSporitelnaBatchPost: "/api/v1/admin/ceska-sporitelna/batch",
        apiV1AdminCeskaSporitelnaBatchStateBatchIdGet: "/api/v1/admin/ceska-sporitelna/batch-state/{batchId}",
        apiV1AdminCeskaSporitelnaCompleteAuthenticationPost: "/api/v1/admin/ceska-sporitelna/complete-authentication",
        apiV1AdminCeskaSporitelnaDirectDebitPost: "/api/v1/admin/ceska-sporitelna/direct-debit",
        apiV1AdminCeskaSporitelnaPaymentBatchesGet: "/api/v1/admin/ceska-sporitelna/payment-batches",
        apiV1AdminCeskaSporitelnaPaymentStatePaymentIdGet: "/api/v1/admin/ceska-sporitelna/payment-state/{paymentId}",
        apiV1AdminCeskaSporitelnaPollBatchesPost: "/api/v1/admin/ceska-sporitelna/poll-batches",
        apiV1AdminCeskaSporitelnaVerifyConnectionGet: "/api/v1/admin/ceska-sporitelna/verify-connection"
    };
    
    constructor() {
        super("CeskaSporitelnaApi");
    }
    
    /**
     */
    public apiV1AdminCeskaSporitelnaAuthenticationUrlGet(params: APIParams & {
        
    } = {}): AxiosPromise<UrlModelApiDataResponse> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaAuthenticationUrlGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<UrlModelApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaAuthenticationUrlGet", params.options, body, "apiV1AdminCeskaSporitelnaAuthenticationUrlGet");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **PAYMENTS_ALREADY_AUTHORIZED** - Payment(s) have been already authorized in bank. 
     * @param authorizationId 
     * @param isBatchPayment 
     */
    public apiV1AdminCeskaSporitelnaAuthorizationLinkPost(params: APIParams & {
        authorizationId?: string;
        isBatchPayment?: boolean;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaAuthorizationLinkPost, null);
        params.options = this.initOptions(params.options, { authorizationId: params.authorizationId, isBatchPayment: params.isBatchPayment }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaAuthorizationLinkPost", params.options, body, "apiV1AdminCeskaSporitelnaAuthorizationLinkPost");
    }

    /**
     */
    public apiV1AdminCeskaSporitelnaBatchPost(params: APIParams & {
        
    } = {}): AxiosPromise<UrlsModelApiDataResponse> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaBatchPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<UrlsModelApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaBatchPost", params.options, body, "apiV1AdminCeskaSporitelnaBatchPost");
    }

    /**
     * @param batchId 
     */
    public apiV1AdminCeskaSporitelnaBatchStateBatchIdGet(params: APIParams & {
        batchId: string;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaBatchStateBatchIdGet, { batchId: params.batchId } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaBatchStateBatchIdGet", params.options, body, "apiV1AdminCeskaSporitelnaBatchStateBatchIdGet");
    }

    /**
     * @param code 
     */
    public apiV1AdminCeskaSporitelnaCompleteAuthenticationPost(params: APIParams & {
        code?: string;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaCompleteAuthenticationPost, null);
        params.options = this.initOptions(params.options, { code: params.code }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaCompleteAuthenticationPost", params.options, body, "apiV1AdminCeskaSporitelnaCompleteAuthenticationPost");
    }

    /**
     */
    public apiV1AdminCeskaSporitelnaDirectDebitPost(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaDirectDebitPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaDirectDebitPost", params.options, body, "apiV1AdminCeskaSporitelnaDirectDebitPost");
    }

    /**
     */
    public apiV1AdminCeskaSporitelnaPaymentBatchesGet(params: APIParams & {
        
    } = {}): AxiosPromise<PaymentBatchIEnumerableApiDataResponse> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaPaymentBatchesGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<PaymentBatchIEnumerableApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaPaymentBatchesGet", params.options, body, "apiV1AdminCeskaSporitelnaPaymentBatchesGet");
    }

    /**
     * @param paymentId 
     */
    public apiV1AdminCeskaSporitelnaPaymentStatePaymentIdGet(params: APIParams & {
        paymentId: string;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaPaymentStatePaymentIdGet, { paymentId: params.paymentId } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaPaymentStatePaymentIdGet", params.options, body, "apiV1AdminCeskaSporitelnaPaymentStatePaymentIdGet");
    }

    /**
     */
    public apiV1AdminCeskaSporitelnaPollBatchesPost(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaPollBatchesPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaPollBatchesPost", params.options, body, "apiV1AdminCeskaSporitelnaPollBatchesPost");
    }

    /**
     */
    public apiV1AdminCeskaSporitelnaVerifyConnectionGet(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CeskaSporitelnaApi.urls.apiV1AdminCeskaSporitelnaVerifyConnectionGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminCeskaSporitelnaVerifyConnectionGet", params.options, body, "apiV1AdminCeskaSporitelnaVerifyConnectionGet");
    }

}
