import { ApiListOptions, FormValues } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { UsersApi } from '../base';
import { params, fileParams, downloadFileFromResponse, dto, withCompanyId } from '../utils';
import { SaveUserDto } from '../base/model';

const api = new UsersApi();

export const useAdminUserList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Users, options], async () => {
    const response = await api.apiV1AdminUsersAdminGet(params(options));

    return response.data;
  });
};

export const useAdminUserExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminUsersAdminXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useOtherUserList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Users, options], async () => {
    const response = await api.apiV1AdminUsersOtherGet(params(options));

    return response.data;
  });
};

export const useOtherUserExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminUsersOtherXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useUserGet = (id: number) => {
  return useQuery([ApiKey.Users, id], async () => {
    const response = await api.apiV1AdminUsersIdGet(params({ id }));

    return response.data.data;
  });
};

export const useUserUpdate = () => {
  return useMutation((values: FormValues) => {
    const roleIds = undefined;
    values = { ...values, roleId: values.role?.id, roleIds };

    return api.apiV1AdminUsersIdPut(
      params({
        id: values.id,
        saveUserDto: createSaveDto(values),
      }),
    );
  });
};

export const useAdminUserCreate = () => {
  return useMutation((values: FormValues) => {
    const roleIds = undefined;
    values = { ...values, roleId: values.role?.id, roleIds };

    return api.apiV1AdminUsersPost(
      params({
        saveUserDto: createSaveDto(values),
      }),
    );
  });
};

const createSaveDto = (values: FormValues) => dto(SaveUserDto, withCompanyId(values));
