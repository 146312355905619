import { useValidation } from '@/components';

export const useValidationSchema = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(({ isStringMaxLength }) => ({
    firstName: isStringMaxLength(100),
    lastName: isStringMaxLength(100),
    username: isStringMaxLength(100),
  }));
};
