export const Regions = {
  Id: 'Identifikátor',
  Region: 'Region',
  Name: 'Název',
  Code: 'Kód',
  MinimalNetSalary: 'Minimální čístá mzda',
  DirectDebitLimit: 'Limit inkasa',
  Delete: 'Smazat',
  DeleteSuccess: 'Region smazán',
  DeleteConfirmText: 'Opravdu chcete smazat region?',
  HunterReferralBonus: 'Referal bonus - Lovec',
  TargetReferralBonus: 'Referal bonus - Cíl',
};
