export const DirectDebits = {
  Amount: 'Amount',
  Created: 'Created',
  DirectDebits: 'Direct debits',
  DirectDebit: 'Direct debit',
  DirectDebitRequest: 'Mark as requested',
  DirectDebitRequestConfirmText: 'Mark direct debit as requested?',
  DirectDebitRequestSuccess: 'Direct debit marked as requested.',
  DirectDebitProcess: 'Process',
  DirectDebitProcessConfirmText: 'Has the direct debit really been sent?',
  DirectDebitProcessSuccess: 'Direct debit processed',
  Identifier: 'Identifier',
  CompanyCode: 'Company code',
  CompanyName: 'Company name',
  DownloadXlsx: 'Download XLSX',
  DownloadXlsxText: 'Do you wish to generate an XLSX file?',
  DownloadCompleted: 'Download completed',
  Status: 'State',
  PayMonth: 'Pay month',
};
