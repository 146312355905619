import {
  useCompanyLookupsList,
  useOtherUserExport,
  useUserGet,
  useOtherUserList,
  useUserUpdate,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { useValidationSchema } from './validation';
import { FormBasic, formBasicFields } from './FormBasic';

export const OtherUsers = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Users,
        translationKey: 'Users',
        useApiList: useOtherUserList,
        useApiGet: useUserGet,
        useApiUpdate: useUserUpdate,
        useApiExport: useOtherUserExport,
        formComponentHeight: 230,
        useValidationSchema,
        columns: [
          { dataKey: 'id', label: 'Identifier', type: TableColumnType.Number },
          {
            dataKey: 'lastName',
            label: 'LastName',
            filterEnabled: true,
            type: TableColumnType.Text,
          },
          {
            dataKey: 'firstName',
            label: 'FirstName',
          },
          {
            dataKey: 'username',
            label: 'Username',
            filterEnabled: true,
            type: TableColumnType.Text,
          },
          {
            dataKey: 'companyName',
            label: 'CompanyName',
            filterKey: 'companyId',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'companyCode',
            label: 'CompanyCode',
          },
        ],
        formComponents: [
          {
            label: 'User',
            component: FormBasic,
            fields: formBasicFields,
          },
        ],
        actions: [],
      }}
    />
  );
};
