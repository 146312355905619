import { EditorJson, EditorJsonProps } from '../../EditorJson';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';

export const EditorJsonComponent = (props: EditorJsonProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const field = useField();
  const { value, name } = field;

  const onChange = useCallback(
    (value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue, name],
  );

  useEffect(() => {
    if (!value) {
      onChange('{}');
      setFieldTouched(name, false);
    }
  }, [value, name, onChange, setFieldTouched]);

  return <EditorJson {...{ ...props, ...field, onChange }} />;
};

export const EditorJsonField = (fieldProps: FormFieldProps<EditorJsonProps>) => {
  const restProps = splitProps(fieldProps, ['className', 'disabled']) as EditorJsonProps;

  return (
    <Field {...fieldProps}>
      <EditorJsonComponent {...restProps} />
    </Field>
  );
};
