import LogoSvg from '@/assets/images/logo.svg';
import { removeStyledProps } from '@/utils';
import { Box, styled } from '@mui/material';
import { LogoProps } from './types';

export const Logo = (props: LogoProps) => {
  return (
    <Wrapper {...props}>
      <img src={LogoSvg} alt='logo' />
    </Wrapper>
  );
};

const Wrapper = styled(
  Box,
  removeStyledProps('visible'),
)<LogoProps>(({ visible = true }) => ({
  opacity: visible ? 1 : 0,
  transition: 'opacity 300ms',
}));
