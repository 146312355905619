import { useApiListData } from '@/utils';
import { useEffect, useMemo } from 'react';
import { Select, SelectItem } from '../Select';
import { Props } from './types';

export const ApiSelect = ({
  useApiList,
  onChange,
  selectFirst,
  value,
  params = {},
  getOptionValue = (item: SelectItem) => item.id,
  getOptionLabel = (item: SelectItem) => item.name,
  ...props
}: Props) => {
  const { data } = useApiListData(useApiList, { ...params });

  const items = useMemo(() => {
    return (data?.data as SelectItem[]) ?? [];
  }, [data?.data]);

  useEffect(() => {
    if (!value && items.length && onChange && selectFirst) {
      onChange(`${items[0].id}`);
    }
  }, [selectFirst, value, items, onChange]);

  return (
    <Select
      {...{
        ...props,
        value: `${value}`,
        items,
        getOptionValue,
        getOptionLabel,
        onChange,
      }}
    />
  );
};
