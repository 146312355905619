// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, NewWorkerQueryDto, WorkerDetailsApiDataResponse, WorkerDumpResponseApiDataResponse, WorkerQueryDto } from "../model";

export class ConnectorDiagnosticsApi extends APIHandler {
    static urls: APIURLTypes.ConnectorDiagnosticsApi = {
        apiV1AdminConnectorDiagnosticsNewWorkerDetailsPost: "/api/v1/admin/connector-diagnostics/new-worker-details",
        apiV1AdminConnectorDiagnosticsWorkerDetailsPost: "/api/v1/admin/connector-diagnostics/worker-details",
        apiV1AdminConnectorDiagnosticsWorkerDumpPost: "/api/v1/admin/connector-diagnostics/worker-dump"
    };
    
    constructor() {
        super("ConnectorDiagnosticsApi");
    }
    
    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. 
     * @param newWorkerQueryDto 
     */
    public apiV1AdminConnectorDiagnosticsNewWorkerDetailsPost(params: APIParams & {
        newWorkerQueryDto?: NewWorkerQueryDto;
    } = {}): AxiosPromise<WorkerDetailsApiDataResponse> {
        const apiURL: string = this.initAPIURL(ConnectorDiagnosticsApi.urls.apiV1AdminConnectorDiagnosticsNewWorkerDetailsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.newWorkerQueryDto;
        return this.makeCall<WorkerDetailsApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminConnectorDiagnosticsNewWorkerDetailsPost", params.options, body, "apiV1AdminConnectorDiagnosticsNewWorkerDetailsPost");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. 
     * @param workerQueryDto 
     */
    public apiV1AdminConnectorDiagnosticsWorkerDetailsPost(params: APIParams & {
        workerQueryDto?: WorkerQueryDto;
    } = {}): AxiosPromise<WorkerDetailsApiDataResponse> {
        const apiURL: string = this.initAPIURL(ConnectorDiagnosticsApi.urls.apiV1AdminConnectorDiagnosticsWorkerDetailsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.workerQueryDto;
        return this.makeCall<WorkerDetailsApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminConnectorDiagnosticsWorkerDetailsPost", params.options, body, "apiV1AdminConnectorDiagnosticsWorkerDetailsPost");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. 
     * @param workerQueryDto 
     */
    public apiV1AdminConnectorDiagnosticsWorkerDumpPost(params: APIParams & {
        workerQueryDto?: WorkerQueryDto;
    } = {}): AxiosPromise<WorkerDumpResponseApiDataResponse> {
        const apiURL: string = this.initAPIURL(ConnectorDiagnosticsApi.urls.apiV1AdminConnectorDiagnosticsWorkerDumpPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.workerQueryDto;
        return this.makeCall<WorkerDumpResponseApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminConnectorDiagnosticsWorkerDumpPost", params.options, body, "apiV1AdminConnectorDiagnosticsWorkerDumpPost");
    }

}
