export const Connectors = {
  Id: 'Identifikátor',
  Active: 'Aktivní',
  Code: 'Kód',
  Company: 'Společnost',
  Connector: 'Konektor',
  Description: 'Popis',
  Identifier: 'Identifikátor UID',
  Name: 'Název',
  Script: 'Skript',
  State: 'Stav',
  Version: 'Verze',
};
