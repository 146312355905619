import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';
import { Props } from './types';

export const Breadcrumbs = ({ items }: Props) => {
  return (
    <MuiBreadcrumbs>
      {items.map(({ label, path }) =>
        path ? (
          <StyledLink key={label} to={path}>
            {label}
          </StyledLink>
        ) : (
          <Typography key={label} color='text.primary'>
            {label}
          </Typography>
        ),
      )}
    </MuiBreadcrumbs>
  );
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
