import { useQuery } from 'react-query';
import { CardsApi } from '../base';
import { params } from '../utils';
import { ApiKey } from '@/enums';
import { ApiListOptions } from '@/components';

const api = new CardsApi();

export const useCardList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Cards, options], async () => {
    const response = await api.apiV1AdminCardsGet(params(options));

    return response.data;
  });
};
