// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { KeyValuePairDto } from './key-value-pair-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CompanyThemeDto extends APIModel {
    /**
    * Theme identifier
    */
    id: number;
    /**
    * Theme is active
    */
    active: boolean;
    /**
    * Version
    */
    version: string;
    company: KeyValuePairDto;
    /**
    * Size of metadata
    */
    size: number;

    constructor(options?: APIModelOptions) {
        super(CompanyThemeDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "active", type: "boolean" },
            { name: "version", type: "string" },
            { name: "company", type: "KeyValuePairDto" },
            { name: "size", type: "number" }
        ];
    }
}




