import { useQuery } from 'react-query';
import { CardTransactionsApi } from '../base';
import { params } from '../utils';
import { ApiKey } from '@/enums';
import { ApiListOptions } from '@/components';

const api = new CardTransactionsApi();

export const useCardTransactionList = (options: ApiListOptions) => {
  return useQuery([ApiKey.CardTransactions, options], async () => {
    const response = await api.apiV1AdminCardTransactionsGet(params(options));

    return response.data;
  });
};
