import { useApiListData } from '@/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteItem } from '../Autocomplete';
import { Props } from './types';

export const ApiAutocomplete = ({
  useApiList,
  onChange,
  selectFirst,
  value,
  multiple,
  params = {},
  defaultItem,
  getOptionValue = (item: AutocompleteItem) => item.id,
  getOptionLabel = (item: AutocompleteItem) => item.name,
  ...props
}: Props) => {
  const [search, setSearch] = useState('');

  const { data } = useApiListData(useApiList, { ...params, search });

  const items = useMemo(() => {
    const items = data?.data ?? [];
    return [
      ...(defaultItem &&
      (!items.length ||
        !items.find((item) => getOptionValue(item) === getOptionValue(defaultItem))) &&
      (!search || getOptionLabel(defaultItem).toLowerCase().includes(search.toLowerCase()))
        ? [defaultItem]
        : []),
      ...items,
    ];
  }, [data?.data, defaultItem, search, getOptionValue, getOptionLabel]);

  useEffect(() => {
    if (selectFirst && !value && items.length) {
      onChange(items[0]);
    }
  }, [selectFirst, value, items, onChange]);

  const handleInputChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  return (
    <Autocomplete
      {...{
        ...props,
        value,
        multiple,
        items,
        getOptionValue,
        getOptionLabel,
        filterOptions: (items) => items,
        onChange,
        onInputChange: handleInputChange,
      }}
    />
  );
};
