import { Box, IconButton, styled } from '@mui/material';
import { Menu, Person } from '@mui/icons-material';
import { ConfirmButton, Dropdown, LanguageDropdown, TableAction, useModule } from '@/components';
import { appHeaderHeight } from '@/styles';
import { useAppContext } from '@/context';
import { useTranslation } from 'react-i18next';
import { removeStyledSmallScreenProp, useSmallScreen } from '@/utils';
import { Props } from './types';
import { useUserAccount } from '../useUserAccount';
import { Logo } from '../Logo';

export const Header = ({ sidebarOpen, toggleSidebar }: Props) => {
  const { t } = useTranslation('UserAccount');
  const { loggedIn } = useAppContext();
  const { active: moduleActive, formOpen, tableActions } = useModule();
  const smallScreen = useSmallScreen();
  const userAccountItems = useUserAccount();

  return (
    <Container>
      {smallScreen &&
        (loggedIn ? (
          <StyledIconButton {...{ onClick: toggleSidebar }}>
            <Menu />
          </StyledIconButton>
        ) : (
          <div />
        ))}
      <Logo visible={!smallScreen || !sidebarOpen} />
      <Right {...{ smallScreen }}>
        {loggedIn &&
          (moduleActive && (!formOpen || !smallScreen) ? (
            tableActions
              .filter(({ key }) => !smallScreen || key === TableAction.Reload)
              .map(({ key, icon: Icon, ...rest }) => {
                const props = {
                  ...rest,
                  'data-cy': `header-button-${key}`,
                };

                return (
                  <StyledConfirmButton
                    key={key}
                    {...{
                      iconButton: true,
                      icon: Icon,
                      confirmDisabled: ![TableAction.Export, TableAction.GenerateFiles].includes(
                        key,
                      ),
                      ...props,
                    }}
                  />
                );
              })
          ) : smallScreen ? (
            <IconPlaceholder />
          ) : (
            <></>
          ))}
        {!smallScreen && (
          <>
            <StyledLanguageDropdown />
            {loggedIn && (
              <StyledDropdown
                data-cy='user-account'
                label={t('UserAccount')}
                startIcon={<Person />}
                items={userAccountItems}
              />
            )}
          </>
        )}
      </Right>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  height: appHeaderHeight,
  width: `calc(100% - ${theme.spacing(8)})`,
  background: theme.palette.primary.main,
  padding: `0 ${theme.spacing(4)}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Right = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  display: 'flex',
  ...(!smallScreen ? { paddingBottom: theme.spacing(1) } : {}),
}));

const StyledDropdown = styled(Dropdown)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const StyledLanguageDropdown = styled(LanguageDropdown)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.common.black,
}));

const StyledConfirmButton = styled(ConfirmButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const IconPlaceholder = styled(IconButton)(() => ({
  width: 40,
}));
