import { escapeRegExp } from 'lodash';
import { Location } from 'react-router-dom';

const NEW_ITEM = '?newItem';
const NEW_BULK_ITEM = '?newBulkItem';
const ITEM = '?item=';

const itemRegExp = escapeRegExp(ITEM);

export const isNewItem = (location: Location) => location.search === NEW_ITEM;
export const isNewBulkItem = (location: Location) => location.search === NEW_BULK_ITEM;
export const isItem = (location: Location) => new RegExp(`${itemRegExp}\\d+`).test(location.search);
export const getItemId = (location: Location) =>
  isItem(location) ? Number(location.search.replace(new RegExp(itemRegExp), '')) : null;
export const getNewItemUrl = (url: string) => `${url}${NEW_ITEM}`;
export const getNewBulkItemUrl = (url: string) => `${url}${NEW_BULK_ITEM}`;
export const getItemUrl = (url: string, id: number) => `${url}${ITEM}${id}`;

export const formatDate = (date: Date): string => {
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getLocalDatePart = (isoString: string) => {
  const localDate = new Date(isoString);
  // get local date without time zone
  localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
  return localDate.toISOString().split('T')[0];
};
