export const Images = {
  Id: 'Identifikátor',
  CopyUrl: 'Zkopírovat URL',
  FileName: 'Název souboru',
  FileSize: 'Velikost',
  Image: 'Obrázek',
  Created: 'Vytvořeno',
  LastModified: 'Editováno',
  Type: 'Typ',
  UrlCopied: 'URL zkopírována',
  Delete: 'Smazat',
  DeleteConfirmText: 'Opravdu chcete smazat obrázek?',
  DeleteConfirmTextEmpty: 'Obrázek není nikde používaný, je možné jej bezpečně smazat.',
  DeleteConfirmTextUsed: 'Obrázek je používán v následujících zprávách:',
  DeleteSuccess: 'Obrázek smazán',
};
