import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Props } from './types';

export const Select = ({
  id,
  label,
  value: outterValue,
  items,
  error,
  helperText,
  placeholder,
  onChange,
  getOptionValue = (item) => item.id,
  getOptionLabel = (item) => item.name,
  ...props
}: Props) => {
  const labelId = useMemo(() => `${id}-label`, [id]);
  const value = useMemo(
    () =>
      outterValue
        ? typeof outterValue === 'string'
          ? outterValue
          : getOptionValue(outterValue)
        : undefined,
    [outterValue, getOptionValue],
  );

  const findItem = useCallback(
    (value: unknown) => items.find((item) => getOptionValue(item) === value),
    [items, getOptionValue],
  );

  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const item = findItem(e.target.value);

      if (item) {
        onChange?.(getOptionValue(item));
      }
    },
    [findItem, onChange, getOptionValue],
  );

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId} error={error}>
        {label}
      </InputLabel>
      <MuiSelect
        {...{
          id,
          labelId,
          label,
          value,
          onChange: handleChange,
          error,
          displayEmpty: !!placeholder,
          renderValue: (selected) => {
            if (placeholder && !selected) {
              return <Placeholder>{placeholder}</Placeholder>;
            }

            const item = findItem(selected);

            return item ? <>{getOptionLabel(item)}</> : <></>;
          },
          ...props,
        }}
      >
        {items.map((item) => {
          const value = getOptionValue(item);
          const label = getOptionLabel(item);
          return (
            <MenuItem key={value} value={value} data-cy={`select-${value}`}>
              {label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText ? <FormHelperText {...{ error }}>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
};

const Placeholder = styled(Box)(({ theme }) => ({ color: theme.palette.grey[500] }));
