// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ConnectorState } from './connector-state';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class ConnectorDto extends APIModel {
    /**
    * Connector identifier
    */
    id: number;
    /**
    * Connector name
    */
    name: string;
    /**
    * Connector code
    */
    code: string;
    /**
    * Connector version
    */
    version: string;
    /**
    * Connector is active
    */
    active: boolean;
    /**
    * Connector identifier
    */
    identifier: string;
    state: ConnectorState;
    companyId: number;

    constructor(options?: APIModelOptions) {
        super(ConnectorDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "name", type: "string" },
            { name: "code", type: "string" },
            { name: "version", type: "string" },
            { name: "active", type: "boolean" },
            { name: "identifier", type: "string" },
            { name: "state", type: "ConnectorState" },
            { name: "companyId", type: "number" }
        ];
    }
}




