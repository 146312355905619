import { MouseEventHandler, useState } from 'react';
import { IconButton, styled, useTheme } from '@mui/material';
import { Button } from '../Button';
import { Tooltip } from '../Tooltip';
import { Props } from './types';
import { ConfirmModal } from '../ConfirmModal';

export const ConfirmButton = ({
  label,
  text,
  onClick,
  icon: Icon,
  disabled,
  confirmDisabled,
  iconButton,
  Content,
  ...props
}: Props) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const buttonProps = {
    ...props,
    disabled,
    label,
    onClick: ((e) => {
      if (!disabled) {
        e.stopPropagation();
        if (confirmDisabled) {
          onClick();
        } else {
          onOpen();
        }
      }
    }) as MouseEventHandler,
  };

  const tooltipProps = { title: label };

  return (
    <>
      {Icon ? (
        iconButton ? (
          <Tooltip {...tooltipProps}>
            <StyledIconButton {...buttonProps}>
              <Icon />
            </StyledIconButton>
          </Tooltip>
        ) : (
          <Tooltip {...tooltipProps}>
            <Icon
              {...{
                ...buttonProps,
                style: disabled
                  ? { color: theme.palette.grey[700], opacity: 0.15 }
                  : { cursor: 'pointer' },
              }}
            />
          </Tooltip>
        )
      ) : (
        <Button {...buttonProps} />
      )}
      <ConfirmModal {...{ open, label, text, Content, onClick, onClose }} />
    </>
  );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));
