// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, ChangePasswordDto, CompanyThemeViewDtoApiDataResponse, EmailModel, LoggedUserDtoApiDataResponse, LoggedWorkerDtoApiDataResponse, LoginUserDto, LoginWorkerDto, PasswordAndOTPModel, WorkerOverviewDtoApiDataResponse } from "../model";

export class AuthApi extends APIHandler {
    static urls: APIURLTypes.AuthApi = {
        apiV1AdminAuthLoginPost: "/api/v1/admin/auth/login",
        apiV1AdminAuthLogoutPut: "/api/v1/admin/auth/logout",
        apiV1AdminAuthPasswordPut: "/api/v1/admin/auth/password",
        apiV1ClientAuthActiveThemeGet: "/api/v1/client/auth/active-theme",
        apiV1ClientAuthCreateUsernameAndPasswordOtpPost: "/api/v1/client/auth/create-username-and-password-otp",
        apiV1ClientAuthCreateUsernameAndPasswordPost: "/api/v1/client/auth/create-username-and-password",
        apiV1ClientAuthLoginPost: "/api/v1/client/auth/login",
        apiV1ClientAuthLogoutPut: "/api/v1/client/auth/logout",
        apiV1ClientAuthOverviewGet: "/api/v1/client/auth/overview",
        apiV1ClientAuthSalaryDeductionAgreementGet: "/api/v1/client/auth/salary-deduction-agreement"
    };
    
    constructor() {
        super("AuthApi");
    }
    
    /**
     * Login admin user
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **USER_IDENTIFIER_OR_PIN_INVALID** - Identifier or PIN is invalid. 
     * @param loginUserDto 
     */
    public apiV1AdminAuthLoginPost(params: APIParams & {
        loginUserDto?: LoginUserDto;
    } = {}): AxiosPromise<LoggedUserDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1AdminAuthLoginPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.loginUserDto;
        return this.makeCall<LoggedUserDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminAuthLoginPost", params.options, body, "apiV1AdminAuthLoginPost");
    }

    /**
     * Logout admin user
     */
    public apiV1AdminAuthLogoutPut(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1AdminAuthLogoutPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("PUT".toLowerCase(), apiURL, "apiV1AdminAuthLogoutPut", params.options, body, "apiV1AdminAuthLogoutPut");
    }

    /**
     * Change password for admin user
     * @param changePasswordDto 
     */
    public apiV1AdminAuthPasswordPut(params: APIParams & {
        changePasswordDto?: ChangePasswordDto;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1AdminAuthPasswordPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.changePasswordDto;
        return this.makeCall<any>("PUT".toLowerCase(), apiURL, "apiV1AdminAuthPasswordPut", params.options, body, "apiV1AdminAuthPasswordPut");
    }

    /**
     * Get active company theme.
     */
    public apiV1ClientAuthActiveThemeGet(params: APIParams & {
        
    } = {}): AxiosPromise<CompanyThemeViewDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthActiveThemeGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyThemeViewDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientAuthActiveThemeGet", params.options, body, "apiV1ClientAuthActiveThemeGet");
    }

    /**
     * @param emailModel 
     */
    public apiV1ClientAuthCreateUsernameAndPasswordOtpPost(params: APIParams & {
        emailModel?: EmailModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthCreateUsernameAndPasswordOtpPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.emailModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientAuthCreateUsernameAndPasswordOtpPost", params.options, body, "apiV1ClientAuthCreateUsernameAndPasswordOtpPost");
    }

    /**
     * @param passwordAndOTPModel 
     */
    public apiV1ClientAuthCreateUsernameAndPasswordPost(params: APIParams & {
        passwordAndOTPModel?: PasswordAndOTPModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthCreateUsernameAndPasswordPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.passwordAndOTPModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientAuthCreateUsernameAndPasswordPost", params.options, body, "apiV1ClientAuthCreateUsernameAndPasswordPost");
    }

    /**
     * Login worker
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **USER_IDENTIFIER_OR_PIN_INVALID** - Identifier or PIN is invalid. 
     * @param loginWorkerDto 
     */
    public apiV1ClientAuthLoginPost(params: APIParams & {
        loginWorkerDto?: LoginWorkerDto;
    } = {}): AxiosPromise<LoggedWorkerDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthLoginPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.loginWorkerDto;
        return this.makeCall<LoggedWorkerDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1ClientAuthLoginPost", params.options, body, "apiV1ClientAuthLoginPost");
    }

    /**
     * Logout worker
     */
    public apiV1ClientAuthLogoutPut(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthLogoutPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("PUT".toLowerCase(), apiURL, "apiV1ClientAuthLogoutPut", params.options, body, "apiV1ClientAuthLogoutPut");
    }

    /**
     * Get overview mobile view.
     */
    public apiV1ClientAuthOverviewGet(params: APIParams & {
        
    } = {}): AxiosPromise<WorkerOverviewDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthOverviewGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<WorkerOverviewDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientAuthOverviewGet", params.options, body, "apiV1ClientAuthOverviewGet");
    }

    /**
     * Get salary deduction agreement for current worker in PDF format.
     */
    public apiV1ClientAuthSalaryDeductionAgreementGet(params: APIParams & {
        
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(AuthApi.urls.apiV1ClientAuthSalaryDeductionAgreementGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1ClientAuthSalaryDeductionAgreementGet", params.options, body, "apiV1ClientAuthSalaryDeductionAgreementGet");
    }

}
