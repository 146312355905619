import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar } from '../Snackbar';
import { Table } from './Table';
import { Form } from './Form';
import { Props } from './types';
import { useModule } from './useModule';
import { useSmallScreen } from '@/utils';

export const Module = ({
  apiKey,
  columns,
  sort,
  formComponents,
  actions,
  refreshComponentColumnAfterSave,
  useApiList,
  useApiGet,
  useApiCreate,
  useApiCreateBulk,
  useApiUpdate,
  useApiExport,
  useValidationSchema,
  useValidationSchemaBulk,
  isEditEnabled,
  ...props
}: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [componentKey, setComponentKey] = useState(false);
  const queryClient = useQueryClient();
  const { showSuccessSnackbar } = useSnackbar();
  const { formOpen, updateModuleOptions } = useModule();
  const smallScreen = useSmallScreen();

  const formEnabled = !!(useApiGet && formComponents);

  const onSuccess = useCallback(
    (message: string, edit?: boolean) => {
      setTimeout(() => {
        queryClient.invalidateQueries(apiKey);
        if (edit && refreshComponentColumnAfterSave) {
          setComponentKey((componentKey) => !componentKey);
        }
        showSuccessSnackbar(message);
      });
    },
    [queryClient, apiKey, refreshComponentColumnAfterSave, showSuccessSnackbar],
  );

  useEffect(() => {
    setTimeout(() => {
      updateModuleOptions({
        apiKey,
        active: true,
        formDisabled: !formEnabled,
        create: !!useApiCreate,
        createBulk: !!useApiCreateBulk,
      });
      setInitialized(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialized ? (
    <>
      {(!formOpen || !smallScreen) && (
        <Table
          {...{
            columns,
            actions,
            sort,
            useApiList,
            useApiExport,
            onSuccess,
            rowClickEnabled: formEnabled,
            componentKey: `${componentKey}`,
            ...props,
          }}
        />
      )}
      {formEnabled && (
        <Form
          {...{
            formComponents,
            useApiGet,
            useApiCreate,
            useApiCreateBulk,
            useApiUpdate,
            useValidationSchema,
            useValidationSchemaBulk,
            isEditEnabled,
            onSuccess,
            ...props,
          }}
        />
      )}
    </>
  ) : (
    <></>
  );
};
