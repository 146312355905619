import { useAppContext } from '@/context';
import { Permission } from '@/enums';
import { DashboardView } from './DashboardView';
import { HomeView } from './HomeView';

export const Dashboard = () => {
  const { hasPermission } = useAppContext();

  return hasPermission(Permission.DashboardView) ? <DashboardView /> : <HomeView />;
};
