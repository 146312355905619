// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, RegionDetailDtoApiDataResponse, RegionDtoListApiDataResponse, RegionLookupDtoListApiDataResponse } from "../model";

export class RegionsApi extends APIHandler {
    static urls: APIURLTypes.RegionsApi = {
        apiV1AdminRegionsGet: "/api/v1/admin/regions",
        apiV1AdminRegionsIdGet: "/api/v1/admin/regions/{id}",
        apiV1AdminRegionsLookupsGet: "/api/v1/admin/regions/lookups",
        apiV1AdminRegionsXlsxGet: "/api/v1/admin/regions/xlsx"
    };
    
    constructor() {
        super("RegionsApi");
    }
    
    /**
     * Get regions
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminRegionsGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<RegionDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(RegionsApi.urls.apiV1AdminRegionsGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<RegionDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminRegionsGet", params.options, body, "apiV1AdminRegionsGet");
    }

    /**
     * Get region detail
     * @param id 
     */
    public apiV1AdminRegionsIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<RegionDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(RegionsApi.urls.apiV1AdminRegionsIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<RegionDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminRegionsIdGet", params.options, body, "apiV1AdminRegionsIdGet");
    }

    /**
     * Region lookups
     */
    public apiV1AdminRegionsLookupsGet(params: APIParams & {
        
    } = {}): AxiosPromise<RegionLookupDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(RegionsApi.urls.apiV1AdminRegionsLookupsGet, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<RegionLookupDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminRegionsLookupsGet", params.options, body, "apiV1AdminRegionsLookupsGet");
    }

    /**
     * Get region export in XLSX format.
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminRegionsXlsxGet(params: APIParams & {
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(RegionsApi.urls.apiV1AdminRegionsXlsxGet, null);
        params.options = this.initOptions(params.options, { sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminRegionsXlsxGet", params.options, body, "apiV1AdminRegionsXlsxGet");
    }

}
