import { removeStyledProps } from '@/utils';
import { Box, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataLabelProps } from './types';

export const NoDataWrapper = (props: PropsWithChildren) => {
  return <Wrapper {...props} />;
};

export const NoDataLabel = ({ data, offsetX = 0, offsetY = 0 }: NoDataLabelProps) => {
  const { t } = useTranslation('Dashboard');

  if (data.find(({ value }) => value)) {
    return <></>;
  }

  return <Label {...{ offsetX, offsetY }}>{t('NoData')}</Label>;
};

const Wrapper = styled(Box)({
  position: 'relative',
  width: 'fit-content',
});

const Label = styled(
  Box,
  removeStyledProps(['offsetX', 'offsetY']),
)<{ offsetX: number; offsetY: number }>(({ offsetX, offsetY }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`,
}));
