import {
  useCompanyLookupsList,
  useAdminUserExport,
  useUserGet,
  useAdminUserList,
  useUserUpdate,
  useAdminUserCreate,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { ApiKey } from '@/enums';
import { FormBasic, formBasicFields } from './FormBasic';
import { FormPassword, formPasswordFields } from './FormPassword';
import { FormRoles } from './FormRoles';
import { useValidationSchema } from './validation';

export const Users = () => {
  return (
    <Module
      {...{
        apiKey: ApiKey.Users,
        translationKey: 'Users',
        useApiList: useAdminUserList,
        useApiGet: useUserGet,
        useApiUpdate: useUserUpdate,
        useApiExport: useAdminUserExport,
        useApiCreate: useAdminUserCreate,
        formComponentHeight: 230,
        useValidationSchema,
        columns: [
          { dataKey: 'id', label: 'Identifier', type: TableColumnType.Number },
          {
            dataKey: 'lastName',
            label: 'LastName',
            filterEnabled: true,
            type: TableColumnType.Text,
          },
          {
            dataKey: 'firstName',
            label: 'FirstName',
          },
          {
            dataKey: 'username',
            label: 'Username',
            filterEnabled: true,
            type: TableColumnType.Text,
          },
          {
            dataKey: 'companyName',
            label: 'CompanyName',
            filterKey: 'companyId',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'companyCode',
            label: 'CompanyCode',
          },
        ],
        initialValues: {
          lastName: '',
          firstName: '',
          username: '',
          userRoles: [],
          password: 0,
          company: null,
        },
        formComponents: [
          {
            label: 'User',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'Roles',
            component: FormRoles,
          },
          {
            label: 'Password',
            component: FormPassword,
            fields: formPasswordFields,
          },
        ],
        actions: [],
      }}
    />
  );
};
