import { Box, styled, Tooltip as MuiTooltip } from '@mui/material';
import { Props } from './types';

export const Tooltip = ({ title, ...props }: Props) => {
  return (
    <MuiTooltip
      {...{
        ...props,
        title: <TooltipTitle onClick={(e) => e.stopPropagation()}>{title}</TooltipTitle>,
      }}
    />
  );
};

const TooltipTitle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '0.8rem',
}));
