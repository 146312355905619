import { InputField, Grid, FormComponentProps } from '@/components';

export const formDebtorFields = [
  {
    name: 'debtorName',
    label: 'DebtorName',
  },
  {
    name: 'debtorBic',
    label: 'DebtorBic',
  },
  {
    name: 'debtorIban',
    label: 'DebtorIban',
  },
  {
    name: 'debtorOrgId',
    label: 'DebtorOrgId',
  },
];

export const FormDebtor = ({ disabled, t, values }: FormComponentProps) => {
  return (
    <Grid container>
      {formDebtorFields.map(({ ...field }) => (
        <Grid key={field.name} item xs={12} sm={6}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              type: 'text',
              disabled,
              required: values.excludeFromBatch,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
