import { CSSProperties } from 'react';
import { CheckCircle, Close } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableColumnType } from './enums';
import { TableCellValueProps } from './types';
import { useDateFormat, getCurrencySymbol, removeStyledProps } from '@/utils';
import { Tooltip } from '../Tooltip';
import { tableCellHeight } from '@/styles';
import { isNumberType } from './utils';

export const TableCellValue = ({
  type,
  value,
  item,
  max,
  enumName,
  componentKey,
  component,
  getStyle = () => ({}),
}: TableCellValueProps) => {
  return (
    <TableCellContent {...{ type, style: getStyle(value, item) }}>
      {(() => {
        switch (type) {
          case TableColumnType.Boolean:
            return <BooleanCellValue {...{ value }} />;
          case TableColumnType.Component:
            return <ComponentCellValue {...{ item, componentKey, component }} />;
          case TableColumnType.Date:
            return <DateCellValue {...{ value }} />;
          case TableColumnType.DateTime:
            return <DateTimeCellValue {...{ value }} />;
          case TableColumnType.Month:
            return <MonthCellValue {...{ value }} />;
          case TableColumnType.Enum:
            return <EnumCellValue {...{ value, enumName }} />;
          case TableColumnType.Currency:
            return <CurrencyCellValue {...{ value, item }} />;
          default:
            return <TextCellValue {...{ value, max }} />;
        }
      })()}
    </TableCellContent>
  );
};

const BooleanCellValue = ({ value }: Pick<TableCellValueProps, 'value'>) => {
  return value ? <StyledCheckCircle /> : <StyledClose />;
};

const ComponentCellValue = ({
  item,
  componentKey = '',
  component: Component,
}: Pick<TableCellValueProps, 'item' | 'component' | 'componentKey'>) => {
  return Component ? <Component {...{ item, componentKey }} /> : <></>;
};

const DateCellValue = ({ value }: Pick<TableCellValueProps, 'value'>) => {
  const { formatDate } = useDateFormat();

  return <>{formatDate(value)}</>;
};

const DateTimeCellValue = ({ value }: Pick<TableCellValueProps, 'value'>) => {
  const { formatDateTime } = useDateFormat();

  return <>{formatDateTime(value)}</>;
};

const MonthCellValue = ({ value }: Pick<TableCellValueProps, 'value'>) => {
  const { formatMonth } = useDateFormat();

  return <>{formatMonth(value)}</>;
};

const EnumCellValue = ({ value, enumName }: Pick<TableCellValueProps, 'value' | 'enumName'>) => {
  const { t } = useTranslation(enumName);

  return <>{t(value)}</>;
};

const CurrencyCellValue = ({ value, item }: Pick<TableCellValueProps, 'value' | 'item'>) => {
  const symbol = getCurrencySymbol(item.currency);
  return (
    <>
      {value} {symbol}
    </>
  );
};

const TextCellValue = ({ value, max }: Pick<TableCellValueProps, 'value' | 'max'>) => {
  return typeof value === 'string' && max && value.substring(0, max).length < value.length ? (
    <Tooltip title={value}>
      <Box>{`${value.substring(0, max)}...`}</Box>
    </Tooltip>
  ) : (
    value
  );
};

const StyledCheckCircle = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const StyledClose = styled(Close)(({ theme }) => ({ color: theme.palette.error.main }));

const TableCellContent = styled(
  Box,
  removeStyledProps('style'),
)<{
  type?: TableColumnType;
  style?: CSSProperties;
}>(({ theme, type, style }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: theme.palette.grey[100],
  height: tableCellHeight,
  padding: `0 ${theme.spacing(2)}`,
  marginBottom: 4,
  width: `calc(100% - ${theme.spacing(4)})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: isNumberType(type) ? 'flex-end' : 'flex-start',
  ...(style ?? {}),
}));
