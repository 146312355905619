import { createTheme } from '@mui/material/styles';
import {
  borderRadius,
  colorBlack,
  colorGreen,
  colorGreenLight,
  colorGrey,
  colorGreyDark,
  colorGreyLight,
  colorPeach,
  colorRed,
  fontFamily,
} from './constants';
import * as locales from '@mui/material/locale';
import { useMemo } from 'react';
import { toLocale, useLanguage } from '@/translation';

export const useTheme = () => {
  const language = useLanguage();

  const theme = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentLocale = (locales as any)[toLocale(language)];

    return createTheme(
      {
        typography: {
          fontFamily,
        },
        shape: {
          borderRadius,
        },
        palette: {
          primary: {
            main: colorGreen,
          },
          secondary: {
            main: colorPeach,
          },
          success: {
            main: colorGreenLight,
          },
          error: {
            main: colorRed,
          },
          common: {
            black: colorBlack,
          },
          grey: {
            '100': colorGreyLight,
            '500': colorGrey,
            '700': colorGreyDark,
          },
        },
      },
      currentLocale,
    );
  }, [language]);

  return theme;
};
