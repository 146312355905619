import { StorageKey } from '@/enums';

const { localStorage } = window;

export const storage = {
  getItem: (key: StorageKey) => localStorage.getItem(key),
  setItem: (key: StorageKey, value: string | number) => localStorage.setItem(key, `${value}`),
  getJSON: (key: StorageKey) => {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  },
  setJSON: (key: StorageKey, value: Record<string, string> | Array<string>) =>
    localStorage.setItem(key, JSON.stringify(value)),
  removeItem: (key: StorageKey) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};
