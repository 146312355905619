// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { RegistrationType } from './registration-type';
import { VerificationQuestionDto } from './verification-question-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class RegistrationVerificationDto extends APIModel {
    /**
    * Registration identifier
    */
    registrationId: number;
    /**
    * Company identifier
    */
    companyId: number;
    /**
    * Company code
    */
    companyCode: string;
    /**
    * Company name
    */
    companyName: string;
    /**
    * Company city
    */
    companyCity: string;
    /**
    * Company currency code using ISO 4217
    */
    companyCurrency: string;
    /**
    * Company country code using ISO 3166
    */
    companyLegislation: string;
    /**
    * Link prefix to legislation documents stored online.
    */
    legislationLinkPrefix: string;
    /**
    * Indicates that company is participating in referral program and registration step 2 can have referral code.
    */
    enrolledInReferralProgram: boolean;
    firstVerification: VerificationQuestionDto;
    secondVerification: VerificationQuestionDto;
    registrationType: RegistrationType;

    constructor(options?: APIModelOptions) {
        super(RegistrationVerificationDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "registrationId", type: "number" },
            { name: "companyId", type: "number" },
            { name: "companyCode", type: "string" },
            { name: "companyName", type: "string" },
            { name: "companyCity", type: "string" },
            { name: "companyCurrency", type: "string" },
            { name: "companyLegislation", type: "string" },
            { name: "legislationLinkPrefix", type: "string" },
            { name: "enrolledInReferralProgram", type: "boolean" },
            { name: "firstVerification", type: "VerificationQuestionDto" },
            { name: "secondVerification", type: "VerificationQuestionDto" },
            { name: "registrationType", type: "RegistrationType" }
        ];
    }
}




