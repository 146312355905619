import { useMemo } from 'react';
import { EditorMarkdownField, Grid, FormComponentProps } from '@/components';
import { Preview } from './Preview';
import { useImageCreate } from '@/api';
import { DEFAULT_IMAGE_TYPE } from './constants';

const editor = { name: 'markdownData', required: true };

export const formContentFields = [editor];

export const FormContent = ({ disabled, values }: FormComponentProps) => {
  const { mutateAsync } = useImageCreate();
  const companyId = values.company?.id;

  const tEditor = useMemo(
    () => ({
      ...editor,
      disabled,
      onImageSave: companyId
        ? async (fileData: string, file: File) => {
            const response = await mutateAsync({
              fileData,
              fileName: file.name,
              fileType: file.type,
              type: DEFAULT_IMAGE_TYPE,
              companyId,
            });
            return response.data.data.id;
          }
        : undefined,
    }),
    [disabled, companyId, mutateAsync],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Preview {...{ values, component: <EditorMarkdownField {...tEditor} /> }} />
      </Grid>
    </Grid>
  );
};
