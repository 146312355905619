import { ApiAutocomplete, ApiAutocompleteProps } from '../../ApiAutocomplete';
import { FormFieldProps } from '../types';
import { splitProps } from '@/utils';
import { Field, useField } from '../Field';
import { splitPropsList } from './AutocompleteField';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { AutocompleteItem } from '@/components';

export const ApiAutocompleteComponent = (props: ApiAutocompleteProps) => {
  const field = useField();
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: AutocompleteItem) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name],
  );

  return <ApiAutocomplete {...{ ...props, ...field, onChange: handleChange }} />;
};

export const ApiAutocompleteField = (fieldProps: FormFieldProps<ApiAutocompleteProps>) => {
  const restProps = splitProps(fieldProps, [
    'useApiList',
    'params',
    'defaultItem',
    'multiple',
    ...splitPropsList,
  ]) as ApiAutocompleteProps;

  return (
    <Field {...fieldProps}>
      <ApiAutocompleteComponent {...restProps} />
    </Field>
  );
};
