import 'brace';
import 'brace/mode/json';
import 'brace/theme/textmate';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor/dist/jsoneditor.min.css';
import 'jsoneditor-react/es/editor.min.css';
import { Box, FormControl, FormHelperText, styled } from '@mui/material';
import { Props } from './types';
import { useCallback, useMemo, useState } from 'react';

const defaultValue = {};

export const EditorJson = ({
  value: outterValue,
  disabled,
  error,
  helperText,
  onChange,
  ...props
}: Props) => {
  const [stateKey, setStateKey] = useState(false);

  const value = useMemo(() => {
    if (!outterValue) {
      return defaultValue;
    }
    try {
      return JSON.parse(outterValue);
    } catch {
      return defaultValue;
    }
  }, [outterValue]);

  const handleChange = useCallback(
    (value: object) => {
      if (!disabled) {
        onChange?.(value ? JSON.stringify(value) : '');
      }
    },
    [disabled, onChange],
  );

  const handleChangeText = useCallback(() => {
    if (disabled) {
      setStateKey((stateKey) => !stateKey);
    }
  }, [disabled]);

  return (
    <FormControl fullWidth>
      <Container key={`${stateKey}`}>
        <JsonEditor
          {...{
            ...props,
            value,
            history: true,
            mode: 'code',
            theme: 'ace/theme/textmate',
            allowedModes: ['tree', 'view', 'form', 'code', 'text'],
            onChange: handleChange,
            onChangeText: handleChangeText,
          }}
        />
      </Container>
      {helperText ? <FormHelperText {...{ error }}>{helperText}</FormHelperText> : <></>}
    </FormControl>
  );
};

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  border: '1px solid #f0f0f0',
  '& .jsoneditor': {
    height: 500,
    border: `1px solid ${theme.palette.common.black}`,
  },
  '& .jsoneditor-menu': {
    backgroundColor: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  '& .jsoneditor-contextmenu .jsoneditor-menu button': {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}));
