import { useMemo } from 'react';
import { EditorJsonField, Grid, FormComponentProps } from '@/components';

const editor = { name: 'metadata', label: 'Metadata', required: true };

export const formMetadataFields = [editor];

export const FormMetadata = ({ disabled, t }: FormComponentProps) => {
  const tEditor = useMemo(() => ({ ...editor, label: t(editor.label), disabled }), [t, disabled]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <EditorJsonField {...tEditor} />
      </Grid>
    </Grid>
  );
};
