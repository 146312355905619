export const Validation = {
  Required: 'Povinné',
  MinLength: 'Musí mať aspoň {{value}} znakov',
  MaxLength: 'Nesmiete mať viac ako {{value}} znakov',
  Length: 'Musí mať {{value}} znakov',
  MinValue: 'Musí mať viac alebo rovnako {{value}}',
  MaxValue: 'Musí mať menej alebo rovnako {{value}}',
  CurrencyValue: 'Nesmie mať viac ako {{value}} číslic za desatinnou čiarkou',
  ValidDate: 'Musí byť platný dátum',
  PasswordsNotSame: 'Zadané heslá nie sú rovnaké',
  BonusToIsFilled: 'Musí byť vyplnené, keď je vyplnené pole "Trvanie akcie do"',
  BonusToGreaterThanBonusFrom: 'Musí byť neskorší dátum ako "Trvanie akcie od"',
  BonusFromAndToFilled:
    'Musí mať viac  ako 0, keď sú vyplnené polia "Trvanie akcie od" a "Trvanie akcie do".',
  DateValidToGreaterThanDateValidFrom: 'Musí byť rovnaký alebo neskorší dátum ako "Platnosť od"',
  DateValidFromGreaterThanToday: 'Musí byť neskorší dátum ako dnes',
  CompanyAdmin: 'Company admin musí mať pridelenú spoločnosť',
};
