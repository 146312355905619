import { InputField, Grid, FormComponentProps } from '@/components';

export const formBasicFields = [
  {
    name: 'name',
    label: 'Name',
    required: true,
  },
  {
    name: 'code',
    label: 'Code',
    required: true,
  },
  {
    name: 'minimalNetSalary',
    label: 'MinimalNetSalary',
    type: 'number',
    required: true,
  },
  {
    name: 'directDebitLimit',
    label: 'DirectDebitLimit',
    type: 'number',
    required: true,
  },
  {
    name: 'hunterReferralBonus',
    label: 'HunterReferralBonus',
    type: 'number',
    required: true,
  },
  {
    name: 'targetReferralBonus',
    label: 'TargetReferralBonus',
    type: 'number',
    required: true,
  },
];

export const FormBasic = ({ disabled, t }: FormComponentProps) => {
  return (
    <Grid container>
      {formBasicFields.map((field) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 6 }}>
          <InputField {...{ ...field, label: t(field.label), disabled }} />
        </Grid>
      ))}
    </Grid>
  );
};
