// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class LoggedUserDto extends APIModel {
    /**
    * JWT Token
    */
    token: string;
    /**
    * Identifier of current user\'s company
    */
    companyId: number;
    /**
    * List of user permissions.
    */
    permissions: Array<string>;

    constructor(options?: APIModelOptions) {
        super(LoggedUserDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "token", type: "string" },
            { name: "companyId", type: "number" },
            { name: "permissions", type: "Array<string>" }
        ];
    }
}




