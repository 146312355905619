export const CardTransactions = {
  Identifier: 'Identifier',
  TransactionProcessingDate: 'Transaction Date',
  Amount: 'Amount',
  Status: 'Status',
  Worker: 'Worker',
  Company: 'Company',
  PayMonth: 'Pay month',
  Region: 'Region',
  CashbackAmount: 'Cashback',
  CashbackPayoutId: 'Cashback payout',
  CashbackPayoutDate: 'Cashback payout date',
  Debited: 'Debited',
  CardNumber: 'Card number',
};
