import { useMemo } from 'react';
import { CheckboxField, DatePickerField, InputField, Grid, FormComponentProps } from '@/components';
import { getCurrencySymbol } from '@/utils';

const datePickers = [
  { name: 'bonusFrom', label: 'BonusFrom', nonTouchedError: true },
  { name: 'bonusTo', label: 'BonusTo', nonTouchedError: true },
];
const input = {
  name: 'bonus',
  label: 'Bonus',
  type: 'number',
  nonTouchedError: true,
};
const checkbox = { name: 'oneTime', label: 'OneTime' };

export const formBonusActionFields = [...datePickers, input, checkbox];

export const FormBonusAction = ({ disabled, t, values }: FormComponentProps) => {
  const tInput = useMemo(
    () => ({
      ...input,
      label: t(input.label),
      disabled,
      endAdornmentString: getCurrencySymbol(values.currency),
    }),
    [t, disabled, values.currency],
  );
  const tCheckbox = useMemo(
    () => ({ ...checkbox, label: t(checkbox.label), disabled }),
    [t, disabled],
  );

  return (
    <Grid container>
      {datePickers.map((field) => (
        <Grid key={field.name} item xs={12} sm={6} md={3}>
          <DatePickerField
            {...{
              ...field,
              label: t(field.label),
              disabled,
              dateOnly: true,
            }}
          />
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={3}>
        <InputField {...tInput} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CheckboxField {...tCheckbox} />
      </Grid>
    </Grid>
  );
};
