export const Messages = {
  Id: 'Identifikátor',
  AddButton: 'Přidat tlačítko',
  Button: 'Tlačítko',
  Buttons: 'Tlačítka',
  ButtonLabel: 'Text tlačítka',
  Cancel: 'Zavřít',
  Caption: 'Nadpis',
  Color: 'Barva',
  Company: 'Společnost',
  CompanyCode: 'Kód společnosti',
  Content: 'Obsah',
  Created: 'Vytvořeno',
  DateValidFrom: 'Platnost od',
  DateValidTo: 'Platnost do',
  EditValues: 'Hodnoty',
  FullWidth: 'Na celou šířku',
  ImageError: 'Obsahem zprávy je nejspíše smazaný obrázek!',
  Message: 'Zpráva',
  Name: 'Název',
  NoActionsText: 'Jsou zobrazena výchozí tlačítka.',
  Preview: 'Náhled',
  Read: 'Přečteno',
  Type: 'Typ',
};
