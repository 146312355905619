// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SaveMessageBaseDto } from './save-message-base-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class SaveBulkMessageDto extends APIModel {
    companyIds: Array<number>;
    message: SaveMessageBaseDto;

    constructor(options?: APIModelOptions) {
        super(SaveBulkMessageDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "companyIds", type: "Array<number>" },
            { name: "message", type: "SaveMessageBaseDto" }
        ];
    }
}




