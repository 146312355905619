export const Images = {
  Id: 'Identifier',
  CopyUrl: 'Copy URL',
  FileName: 'Filename',
  FileSize: 'Size',
  Image: 'Image',
  Created: 'Created',
  LastModified: 'Edited',
  Type: 'Type',
  UrlCopied: 'URL copied',
  Delete: 'Delete',
  DeleteConfirmText: 'Are you sure you want to delete image?',
  DeleteConfirmTextEmpty: 'The image is not used anywhere, it can be safely deleted.',
  DeleteConfirmTextUsed: 'The image is used in the following messages:',
  DeleteSuccess: 'Image deleted',
};
