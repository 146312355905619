import { ApiListOptions, FormValues } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { MessagesApi } from '../base';
import { SaveBulkMessageDto, SaveMessageDto } from '../base/model';
import { dto, withCompanyId, params, fileParams, downloadFileFromResponse } from '../utils';

const api = new MessagesApi();

export const useMessageList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Messages, options], async () => {
    const response = await api.apiV1AdminMessagesGet(params(options));

    return response.data;
  });
};

export const useMessageExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminMessagesXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useMessageAnswerList = (options: ApiListOptions) => {
  return useQuery([ApiKey.MessagesAnswers, options], async () => {
    const response = await api.apiV1AdminMessagesAnswersGet(params(options));

    return response.data;
  });
};

export const useMessageAnswerExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminMessagesAnswersXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useMessageGet = (id: number) => {
  return useQuery([ApiKey.Messages, id], async () => {
    const response = await api.apiV1AdminMessagesIdGet(params({ id }));

    return response.data.data;
  });
};

export const useMessageCreate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminMessagesPost(
      params({
        saveMessageDto: createSaveDto(values),
      }),
    );
  });
};

export const useMessageCreateBulk = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminMessagesBulkPost(
      params({
        saveBulkMessageDto: createSaveBulkDto(values),
      }),
    );
  });
};

export const useMessageUpdate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminMessagesIdPut(
      params({
        id: values.id,
        saveMessageDto: createSaveDto(values),
      }),
    );
  });
};

const createSaveDto = (values: FormValues) => dto(SaveMessageDto, withCompanyId(values));

export const withCompanyIdsForBulkMessage = ({ company, ...values }: FormValues) => ({
  message: { ...values },
  companyIds: company.map((c: any) => c.id),
});

const createSaveBulkDto = (values: FormValues) =>
  dto(SaveBulkMessageDto, withCompanyIdsForBulkMessage(values));
