export const Routes = {
  Dashboard: 'Dashboard',
  Workers: 'Workers',
  AttendanceCurrent: 'Current attendance',
  AttendanceAll: 'All attendance',
  AbsenceCurrent: 'Current absence',
  AbsenceAll: 'All absence',
  Payments: 'Requests',
  PaymentsDemo: 'Demo requests',
  PaymentsVerifiedCzech: 'Verified requests in Czech Republic',
  PaymentsVerifiedSlovak: 'Verified requests in Slovak Republic',
  Messages: 'Messages',
  Actions: 'Actions',
  Images: 'Images',
  Answers: 'Answers',
  Companies: 'Companies',
  Themes: 'Themes',
  Connectors: 'Connectors',
  Balances: 'Balances',
  Users: 'Users',
  AdminUsers: 'Admin users',
  OtherUsers: 'Application users',
  Roles: 'Roles',
  UserRoles: 'User roles',
  UserViews: 'User views',
  Helper: 'Helper',
  LogToday: 'Log today',
  LogFull: 'Log full',
  ActiveUser: 'Active user',
  Help: 'Help',
  News: 'News',
  PasswordChange: 'Change password',
  Setting: 'Setting',
  Regions: 'Regions',
  DirectDebits: 'Direct debits',
  BankAuthorizations: 'CZ payments authorizations',
  Cards: 'Payment cards',
  CardTransactions: 'Card transactions',
};
