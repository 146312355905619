export const Module = {
  Export: 'Export',
  ExportConfirmText: 'Bude exportované maximálne {{value}} záznamov. Chcete pokračovať v exporte?',
  GenerateFiles: 'Generovať súbory',
  GenerateFilesConfirmText: 'Budú generované súbory. Chcete pokračovať?',
  New: 'Nová položka',
  NewBulk: 'Nová hromadná položka',
  Reload: 'Znovu načítať',
  VerifyConnection: 'Verifikovať spojenie',
  BatchProcess: 'Spracovať všetky položky naraz',
  ReconciliatePayments: 'Rekonciliácia platieb',
  PollBatches: 'Zkontrolovat stav plateb v bance',
  From: 'od',
  To: 'do',
};
