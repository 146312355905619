import { TableColumnType } from './enums';

export const chooseDataKey = (sortFilterKey: string | undefined, dataKey: string) =>
  sortFilterKey ?? dataKey;

export const createRangeFromKey = (key: string) => `${key}From`;

export const createRangeToKey = (key: string) => `${key}To`;

export const isRangeType = (type: TableColumnType | undefined) =>
  type === TableColumnType.Date ||
  type === TableColumnType.DateTime ||
  type === TableColumnType.Month;

export const isNumberType = (type: TableColumnType | undefined) =>
  type === TableColumnType.Number || type === TableColumnType.Currency;

export const toUTCDateTime = (value: string, isTo: boolean): string => {
  const localDate = new Date(value);
  if (isTo) {
    localDate.setHours(23, 59, 59, 999);
  } else {
    localDate.setHours(0, 0, 0, 0);
  }
  return localDate.toISOString();
};
