import { useEffect } from 'react';
import { TableGenerateFilesProps } from './types';
import { useModule } from './useModule';

export const TableGenerateFiles = ({
  useApiGenerateFiles,
  apiOptions,
}: TableGenerateFilesProps) => {
  const { mutateAsync } = useApiGenerateFiles();
  const { updateModuleOptions } = useModule();

  useEffect(() => {
    updateModuleOptions({
      generateFilesCallback: () => mutateAsync({ ...apiOptions }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateAsync, apiOptions]);

  return <></>;
};
