import { Formik, Form as FormikForm } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../Loading';
import { FormValues, Props } from './types';

export function Form<T extends FormValues>({
  children: Component,
  onSubmit,
  withLoading = true,
  afterSubmitRoute,
  ...props
}: Props<T>) {
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();

  return (
    <Formik
      {...props}
      onSubmit={async (values, { resetForm }) => {
        if (withLoading) {
          showLoading();
        }
        await onSubmit(values);
        if (withLoading) {
          hideLoading();
        }
        resetForm();
        if (afterSubmitRoute) {
          navigate(afterSubmitRoute);
        }
      }}
    >
      {(formProps) => (
        <FormikForm>
          <Component {...formProps} />
        </FormikForm>
      )}
    </Formik>
  );
}
