import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Form as FormComponent, FormValues } from '../Form';
import {
  FormBaseProps,
  FormDetailProps,
  FormEditableDetailProps,
  FormNewProps,
  FormProps,
} from './types';
import { Box, IconButton, styled } from '@mui/material';
import { Button } from '../Button';
import { Tabs } from '../Tabs';
import { Close } from '@mui/icons-material';
import { useModule } from './useModule';
import { get } from 'lodash';
import { removeStyledSmallScreenProp, useSmallScreen } from '@/utils';
import { Toolbar } from './Toolbar';
import { Spinner } from '../Loading';

const nsForm = { ns: 'Form' };

export const Form = ({
  useApiCreate,
  useApiCreateBulk,
  useApiUpdate,
  useValidationSchema,
  useValidationSchemaBulk,
  ...props
}: FormProps) => {
  const { formOpen, itemId, newItemBulk } = useModule();
  const smallScreen = useSmallScreen();

  const content = formOpen ? (
    itemId ? (
      useApiUpdate && useValidationSchema ? (
        <FormEditableDetail {...{ ...props, itemId, useApiUpdate, useValidationSchema }} />
      ) : (
        <FormDetail {...{ ...props, itemId }} />
      )
    ) : !newItemBulk && useApiCreate && useValidationSchema ? (
      <FormNew {...{ ...props, newItemBulk, useApiCreate, useValidationSchema }} />
    ) : newItemBulk && useApiCreateBulk && useValidationSchemaBulk ? (
      <FormNew
        {...{
          ...props,
          newItemBulk,
          useApiCreate: useApiCreateBulk,
          useValidationSchema: useValidationSchemaBulk,
        }}
      />
    ) : (
      <></>
    )
  ) : (
    <></>
  );

  return formOpen ? (
    smallScreen ? (
      content
    ) : (
      <Modal {...{ open: true, maxWidth: 'lg' }}>{content}</Modal>
    )
  ) : (
    <></>
  );
};

const FormNew = ({ useApiCreate, useValidationSchema, ...props }: FormNewProps) => {
  const { mutateAsync } = useApiCreate();
  const { editEnabled, newItemBulk } = useModule();
  const validationSchema = useValidationSchema();

  return (
    <FormBase
      {...{
        ...props,
        formComponents:
          newItemBulk && props.formComponentsBulk ? props.formComponentsBulk : props.formComponents,
        initialValues: newItemBulk ? props.initialValuesBulk : props.initialValues,
        mutateAsync,
        editEnabled,
        validationSchema,
      }}
    />
  );
};

const FormEditableDetail = ({
  useApiUpdate,
  useValidationSchema,
  ...props
}: FormEditableDetailProps) => {
  const { mutateAsync } = useApiUpdate();
  const validationSchema = useValidationSchema();

  return (
    <FormDetail
      {...{
        ...props,
        mutateAsync,
        validationSchema,
      }}
    />
  );
};

const FormDetail = ({
  itemId,
  useApiGet,
  isEditEnabled = () => true,
  ...props
}: FormDetailProps) => {
  const { data, isRefetching, isStale, isLoading } = useApiGet(itemId);
  const { editEnabled } = useModule();

  return isLoading ? (
    <Spinner fullSizeBox />
  ) : data && (!isStale || !isRefetching) ? (
    <FormBase
      {...{
        ...props,
        data,
        initialValues: data,
        editEnabled: editEnabled && isEditEnabled(data),
      }}
    />
  ) : (
    <></>
  );
};

const FormBase = ({
  translationKey,
  formComponents,
  formComponentHeight,
  editEnabled,
  nonCloseSubmit,
  initialValues = {},
  mutateAsync,
  onSuccess,
  ...props
}: FormBaseProps) => {
  const { t } = useTranslation([translationKey, 'Form']);
  const { newItem, onFormClose, navigateToItem } = useModule();
  const smallScreen = useSmallScreen();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (mutateAsync) {
        const response = await mutateAsync(values);
        onSuccess(t('Saved', nsForm), true);
        return response;
      }
    },
    [mutateAsync, onSuccess, t],
  );

  const onSubmitAndClose = useCallback(
    async (values: FormValues) => {
      onSubmit(values);
      onFormClose();
    },
    [onSubmit, onFormClose],
  );

  const onSubmitAndContinue = useCallback(
    async (values: FormValues) => {
      const response = await onSubmit(values);

      if (newItem) {
        const id = response?.data?.data?.id;

        if (id) {
          navigateToItem(id);
        } else {
          onFormClose();
        }
      }
    },
    [newItem, onSubmit, navigateToItem, onFormClose],
  );

  return (
    <FormComponent {...{ onSubmit: onSubmitAndClose, initialValues, ...props }}>
      {({ errors, touched, values }) => {
        const isInvalid = !!formComponents.find(({ fields }) =>
          (fields ?? []).find(({ name, nonTouchedError }) =>
            nonTouchedError || get(touched, name) ? get(errors, name) : false,
          ),
        );

        return (
          <Container {...{ smallScreen }}>
            {smallScreen && <Toolbar />}
            <Tabs
              {...{
                contentHeight: formComponentHeight,
                items: formComponents.map(
                  ({ label, fields, component: Component, tabContentDisabled, ...rest }) => ({
                    label: t(label),
                    error: !!fields?.find(({ name, nonTouchedError }) =>
                      nonTouchedError || get(touched, name) ? get(errors, name) : false,
                    ),
                    component: (
                      <Component
                        {...{
                          initialValues,
                          values,
                          disabled: !editEnabled || tabContentDisabled || false,
                          t,
                        }}
                      />
                    ),
                    disabled: formComponents.length < 2,
                    ...rest,
                  }),
                ),
                rightComponent: (
                  <IconButton onClick={onFormClose} data-cy='module-form-close'>
                    <Close />
                  </IconButton>
                ),
              }}
            />
            <Actions {...{ smallScreen }}>
              {editEnabled && (
                <>
                  <StyledButton
                    type='submit'
                    label={t('Save', nsForm)}
                    disabled={isInvalid}
                    data-cy='form-submit'
                  />
                  {nonCloseSubmit && (
                    <StyledButton
                      label={t('SaveAndContinue', nsForm)}
                      disabled={isInvalid}
                      onClick={() => onSubmitAndContinue(values)}
                      data-cy='form-submit-continue'
                    />
                  )}
                </>
              )}
              {isInvalid && <Error>{t('Errors', nsForm)}</Error>}
            </Actions>
          </Container>
        );
      }}
    </FormComponent>
  );
};

const Container = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  width: `calc(100% - ${theme.spacing(smallScreen ? 2 : 8)})`,
  padding: theme.spacing(smallScreen ? 1 : 4),
}));

const Actions = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(smallScreen ? { paddingTop: theme.spacing(2) } : {}),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const Error = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
  color: theme.palette.error.main,
  fontSize: '0.8em',
  textAlign: 'center',
}));
