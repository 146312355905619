import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidDateOptional } from '@/utils';
import { Currency } from '@/enums';

export const useValidation = () => {
  const translation = useTranslation();

  const t = (key: string, options: Record<string, string | number> = {}) =>
    translation.t(key, { ...options, ns: 'Validation' });

  const v = (key: string, value: number) => t(key, { value });

  const required = t('Required');
  const length = (value: number) => v('Length', value);
  const minLength = (value: number) => v('MinLength', value);
  const maxLength = (value: number) => v('MaxLength', value);
  const minValue = (value: number) => v('MinValue', value);
  const maxValue = (value: number) => v('MaxValue', value);

  const isString = () => Yup.string().nullable();
  const isStringRequired = () => Yup.string().required(required);

  const isNumber = () => Yup.number();
  const isNumberRequired = () => isNumber().required(required);

  const isDate = () => Yup.date().nullable().test('ValidDate', t('ValidDate'), isValidDateOptional);
  const isDateRequired = () =>
    isDate().test('Required', required, function (value) {
      return !!value;
    });

  const isStringLength = (value: number) => isStringRequired().length(value, length(value));
  const isStringMinLength = (value: number) => isStringRequired().min(value, minLength(value));
  const isStringMaxLength = (value: number) => isStringRequired().max(value, maxLength(value));
  const isStringMinMaxLength = (min: number, max: number) =>
    isStringRequired().min(min, minLength(min)).max(max, maxLength(max));

  const isStringLengthOptional = (value: number) => isString().length(value, length(value));
  const isStringMinLengthOptional = (value: number) => isString().min(value, minLength(value));
  const isStringMaxLengthOptional = (value: number) => isString().max(value, maxLength(value));
  const isStringMinMaxLengthOptional = (min: number, max: number) =>
    isString().min(min, minLength(min)).max(max, maxLength(max));

  const isStringEvRequired = (dependsOnField: string) =>
    isString().when(dependsOnField, {
      is: true,
      then: isStringRequired(),
    });

  const isNumberMin = (value: number) => isNumberRequired().min(value, minValue(value));
  const isNumberMinMax = (min: number, max: number) =>
    isNumberRequired().min(min, minValue(min)).max(max, maxValue(max));

  const isNumberMinOptional = (value: number) => isNumber().min(value, minValue(value));
  const isNumberMinMaxOptional = (min: number, max: number) =>
    isNumber().min(min, minValue(min)).max(max, maxValue(max));

  const isCurrencyMin = (min: number) =>
    isNumberMin(min).when('currency', {
      is: (currency: string) => currency === Currency.CZK,
      then: checkCurrencyPatternForDigitsAfterComma(0),
      otherwise: checkCurrencyPatternForDigitsAfterComma(2),
    });

  const isCurrencyMinMaxOptional = (min: number, max: number) =>
    isNumberMinMaxOptional(min, max).when('currency', {
      is: (currency: string) => currency === Currency.CZK,
      then: checkCurrencyPatternForDigitsAfterComma(0),
      otherwise: checkCurrencyPatternForDigitsAfterComma(2),
    });

  const checkCurrencyPatternForDigitsAfterComma = (numberOfDigits: number) =>
    Yup.number().test('CurrencyValue', v('CurrencyValue', numberOfDigits), (value) => {
      if (value != undefined) {
        const patternXDigitsAfterComma = new RegExp(`^\\d+(\\.\\d{0,${numberOfDigits}})?$`);
        return patternXDigitsAfterComma.test(value.toString());
      }
      return true;
    });

  const isEntity = (name: string) =>
    Yup.object()
      .nullable()
      .required(required)
      .test(name, required, function (entity) {
        return !!entity?.id;
      });

  const isEntityOptional = () => Yup.object().nullable();

  const isArray = () => Yup.array().nullable();

  const isArrayNotEmpty = () => Yup.array().min(1);

  const callbackProps = {
    t,
    isString,
    isStringRequired,
    isStringEvRequired,
    isStringLength,
    isStringMinLength,
    isStringMaxLength,
    isStringMinMaxLength,
    isStringLengthOptional,
    isStringMinLengthOptional,
    isStringMaxLengthOptional,
    isStringMinMaxLengthOptional,
    isCurrencyMin,
    isCurrencyMinMaxOptional,
    isNumber,
    isNumberRequired,
    isNumberMin,
    isNumberMinMax,
    isNumberMinOptional,
    isNumberMinMaxOptional,
    isDate,
    isDateRequired,
    isEntity,
    isEntityOptional,
    isArray,
    isArrayNotEmpty,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type Callback = (props: typeof callbackProps) => Record<string, any>;

  const createValidationSchema = (callback: Callback) => {
    return Yup.object().shape(callback(callbackProps));
  };

  return createValidationSchema;
};
