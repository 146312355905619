export const Users = {
  Company: 'Společnost',
  FirstName: 'Jméno',
  LastName: 'Příjmení',
  Username: 'Uživatelské jméno',
  Identifier: 'Identifikátor',
  CompanyName: 'Společnost',
  CompanyCode: 'Kód společnosti',
  User: 'Uživatel',
  Password: 'Heslo',
  NewPassword: 'Nové heslo',
  ConfirmNewPassword: 'Potvrďte nové heslo',
  Roles: 'Role',
  Warning: 'Varování',
  WarningText:
    'Pokud editujete své role, tak uložením dojde k odhlášení a můžete přijít o nějaké přístupy v aplikaci.',
};
