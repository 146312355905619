export const Form = {
  Close: 'Zavřít',
  Errors: 'Zkontrolujte formulářová pole',
  FailedToUploadImage: 'Nepodařilo se nahrát obrázek',
  Save: 'Uložit',
  Saved: 'Uloženo',
  SaveAndContinue: 'Uložit a pokračovat',
  UploadImage: 'Nahrát obrázek',
  UploadImageDropzone: 'Přetáhněte sem soubor s obrázkem nebo kliknutím vyberte soubor',
};
