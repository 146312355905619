// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class Stream extends APIModel {
    canRead: boolean;
    canWrite: boolean;
    canSeek: boolean;
    canTimeout: boolean;
    length: number;
    position: number;
    readTimeout: number;
    writeTimeout: number;

    constructor(options?: APIModelOptions) {
        super(Stream.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "canRead", type: "boolean" },
            { name: "canWrite", type: "boolean" },
            { name: "canSeek", type: "boolean" },
            { name: "canTimeout", type: "boolean" },
            { name: "length", type: "number" },
            { name: "position", type: "number" },
            { name: "readTimeout", type: "number" },
            { name: "writeTimeout", type: "number" }
        ];
    }
}




