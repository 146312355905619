export const Module = {
  Export: 'Export',
  ExportConfirmText:
    'A maximum of {{value}} records will be exported. Do you want to continue exporting?',
  GenerateFiles: 'Generate files',
  GenerateFilesConfirmText: 'Files will be generated. Do you want to continue?',
  New: 'New item',
  NewBulk: 'New bulk item',
  Reload: 'Reload',
  VerifyConnection: 'Verify connection',
  BatchProcess: 'Batch process all items',
  ReconciliatePayments: 'Reconciliate payments',
  PollBatches: 'Verify payment states in bank',
  From: 'from',
  To: 'to',
};
