import { useImageUsed } from '@/api';
import { CreateActionContent, getModuleItemUrl, Spinner } from '@/components';
import { ConfirmModalContentProps } from '@/components/ConfirmModal/types';
import { Route } from '@/enums';
import { usePrevious } from '@/utils';
import { Box, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const createDeleteConfirm: CreateActionContent = (id: number) => {
  // eslint-disable-next-line react/display-name
  return (props: ConfirmModalContentProps) => <DeleteConfirm {...{ ...props, id }} />;
};

const DeleteConfirm = ({ id, onInit }: ConfirmModalContentProps & { id: number }) => {
  const { t } = useTranslation('Images');
  const { data, isLoading } = useImageUsed(id);

  const isLoadingPrev = usePrevious(isLoading);

  useEffect(() => {
    if (isLoadingPrev && !isLoading) {
      onInit();
    }
  }, [isLoadingPrev, isLoading, onInit]);

  return (
    <Container>
      {isLoading ? (
        <Spinner fullSizeBox />
      ) : (
        <>
          <ContentContainer>
            {data ? (
              data.messages.length ? (
                <ListContainer>
                  <InfoTextUsed>{t('DeleteConfirmTextUsed')}</InfoTextUsed>
                  {data.messages.map(({ id, name }) => (
                    <StyledLink
                      key={id}
                      to={getModuleItemUrl(Route.Messages, id)}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Typography>{name}</Typography>
                    </StyledLink>
                  ))}
                </ListContainer>
              ) : (
                <InfoText>{t('DeleteConfirmTextEmpty')}</InfoText>
              )
            ) : (
              <></>
            )}
          </ContentContainer>
          <Typography>{t('DeleteConfirmText')}</Typography>
        </>
      )}
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
});

const ContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(1),
}));

const ListContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(1),
}));

const InfoText = styled(Typography)({
  fontWeight: 'bold',
});

const InfoTextUsed = styled(InfoText)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
