import { omit } from 'lodash';
import { Breadcrumbs, useModule } from '@/components';
import { useActiveRoute } from '@/utils';
import { Box, styled } from '@mui/material';
import { ToolbarProps } from './types';
import { useTranslation } from 'react-i18next';

export const Toolbar = ({ action, ...props }: ToolbarProps) => {
  const activeRoute = useActiveRoute();
  const { formOpen } = useModule();
  const { t } = useTranslation('Routes');

  if (!activeRoute) {
    return <></>;
  }

  const lastItem = formOpen ? activeRoute : omit(activeRoute, 'path');

  return (
    <Container {...props}>
      {action ? <IconPlaceholder /> : <div />}
      <Breadcrumbs
        items={[
          ...(activeRoute.parent && activeRoute.parent !== lastItem.label
            ? [{ label: t(activeRoute.parent) }]
            : []),
          { ...lastItem, label: t(lastItem.label) },
        ]}
      />
      {action ?? <div />}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

const IconPlaceholder = styled(Box)(() => ({
  width: 24,
}));
