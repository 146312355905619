import { ConfirmModalContentProps } from '@/components/ConfirmModal/types';
import { Box, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import { CreateDateRangeActionContent } from './types';
import { ReconciliatePaymentsParams } from './TableVerifiedConnect';
import { DatePicker } from '../DatePicker';
import { useTranslation } from 'react-i18next';

export const createDateRangeConfirm: CreateDateRangeActionContent = (
  range: ReconciliatePaymentsParams,
  onChange: (dataKey: string, value: string) => void,
  confirmText: string,
) => {
  // eslint-disable-next-line react/display-name
  return (props: ConfirmModalContentProps) => (
    <DateRangeConfirm {...{ ...props, range, onChange, confirmText }} />
  );
};

const DateRangeConfirm = ({
  onInit,
  range,
  onChange,
  confirmText,
}: ConfirmModalContentProps & {
  range: ReconciliatePaymentsParams;
  onChange: (dataKey: string, value: string) => void;
  confirmText: string;
}) => {
  const { t } = useTranslation('Module');

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <Container>
      <ContentContainer>
        <Typography>{`${t('From')}:`}</Typography>
        <DatePicker
          {...{
            dateOnly: true,
            disableMaskedInput: true,
            value: range.startDate,
            onChange: (value) => onChange('startDate', value as string),
          }}
        />
        <Typography>{`${t('To')}:`}</Typography>
        <DatePicker
          {...{
            dateOnly: true,
            disableMaskedInput: true,
            value: range.endDate,
            onChange: (value) => onChange('endDate', value as string),
          }}
        />
      </ContentContainer>
      <ConfirmTextContainer>
        <Typography>{confirmText}</Typography>
      </ConfirmTextContainer>
    </Container>
  );
};

const Container = styled(Box)({
  width: '100%',
});

const ContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const ConfirmTextContainer = styled(Box)({
  marginTop: '2em',
  marginBottom: '1em',
});
