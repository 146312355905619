export const Messages = {
  Id: 'Identifier',
  AddButton: 'Add button',
  Button: 'Button',
  Buttons: 'Buttons',
  ButtonLabel: 'Button label',
  Cancel: 'Cancel',
  Caption: 'Caption',
  Color: 'Color',
  Company: 'Company',
  CompanyCode: 'Company code',
  Content: 'Content',
  Created: 'Created',
  DateValidFrom: 'Valid from',
  DateValidTo: 'Valid to',
  EditValues: 'Values',
  FullWidth: 'Full width',
  ImageError: 'The content of the message is most likely a deleted image!',
  Message: 'Message',
  Name: 'Name',
  NoActionsText: 'Default buttons are displayed.',
  Preview: 'Preview',
  Read: 'Read',
  Type: 'Type',
};
