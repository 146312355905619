import React from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { IconButton, styled } from '@mui/material';
import { Close } from '@mui/icons-material';

export const SnackbarProvider = ({ children }: React.PropsWithChildren) => {
  const notistackRef = React.createRef<NotistackSnackbarProvider>();

  return (
    <NotistackSnackbarProvider
      ref={notistackRef}
      preventDuplicate={true}
      maxSnack={3}
      action={(key) => (
        <IconButton onClick={() => notistackRef.current?.closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

const CloseIcon = styled(Close)({
  color: '#FFF',
});
