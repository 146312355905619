import { useCompanyCurrentWorkers } from '@/api';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { NoDataLabel, NoDataWrapper } from './NoData';
import { BarChartProps } from './types';
import { getColor } from './utils';

export const BarChart = ({ companyId }: BarChartProps) => {
  const { t } = useTranslation('Dashboard');
  const { data } = useCompanyCurrentWorkers(companyId);

  const counts = [
    { name: t('Registered'), value: data?.registered },
    { name: t('LoggedIn'), value: data?.loggedIn },
    { name: t('PaymentRequested'), value: data?.paymentRequested },
  ];

  return (
    <NoDataWrapper>
      <RechartsBarChart
        data={counts}
        width={550}
        height={300}
        margin={{
          top: 20,
        }}
      >
        <CartesianGrid strokeDasharray='3' />
        <XAxis dataKey='name' />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Bar dataKey='value'>
          {counts.map(({ name }, index) => (
            <Cell key={name} fill={getColor(index)} />
          ))}
        </Bar>
      </RechartsBarChart>
      <NoDataLabel data={counts} offsetX={35} offsetY={10} />
    </NoDataWrapper>
  );
};
