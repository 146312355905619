import { useCompanyCurrentBudget } from '@/api';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart as RechartsPieChart, Tooltip } from 'recharts';
import { NoDataLabel, NoDataWrapper } from './NoData';
import { PieChartProps } from './types';
import { getColor } from './utils';

export const PieChart = ({ companyId }: PieChartProps) => {
  const { t } = useTranslation('Dashboard');

  const { data } = useCompanyCurrentBudget(companyId);

  const totalBudget = [
    { name: t('Available'), value: data?.currentAvailable },
    { name: t('Realized'), value: data?.currentRealized },
  ];

  return (
    <NoDataWrapper>
      <RechartsPieChart width={300} height={300}>
        <Pie
          data={totalBudget}
          dataKey='value'
          isAnimationActive={false}
          cx='50%'
          cy='50%'
          innerRadius={40}
          outerRadius={80}
          label
        >
          {totalBudget.map(({ name }, index) => (
            <Cell key={name} fill={getColor(index)} />
          ))}
        </Pie>
        <Tooltip />
        <Legend height={36} />
      </RechartsPieChart>
      <NoDataLabel data={totalBudget} offsetX={-10} />
    </NoDataWrapper>
  );
};
