import { useCompanyLookupsList } from '@/api';
import { InputField, Grid, FormComponentProps, ApiAutocompleteField } from '@/components';
import { isCompanyAdmin } from '@/utils/user';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

const userFields = [
  { name: 'firstName', label: 'FirstName', required: true },
  { name: 'lastName', label: 'LastName', required: true },
  { name: 'username', label: 'Username', required: true },
];

export const companyField = {
  name: 'company',
  label: 'Company',
  required: false,
  params: { demo: false },
};

export const formBasicFields = [...userFields, companyField];

export const FormBasic = ({ disabled, t, initialValues, values }: FormComponentProps) => {
  const tCompany = useMemo(
    () => ({
      ...companyField,
      label: t(companyField.label),
      disabled,
      defaultItem: initialValues.company,
      useApiList: useCompanyLookupsList,
    }),
    [t, disabled, initialValues.company],
  );

  const showCompanyField = useMemo(() => {
    return isCompanyAdmin(values?.role ? [values.role] : []);
  }, [values?.role]);

  return (
    <Grid container>
      {userFields.map((field) => (
        <Grid key={field.name} {...{ item: true, xs: 12, sm: 12, md: 6 }}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              disabled,
            }}
          />
        </Grid>
      ))}
      {showCompanyField && (
        <Grid item xs={12} sm={12} md={6}>
          <ApiAutocompleteField key={initialValues.company?.id} {...tCompany} />
        </Grid>
      )}
    </Grid>
  );
};
