// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ApiMetadata } from './api-metadata';
import { CompanyThemeDto } from './company-theme-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CompanyThemeDtoListApiDataResponse extends APIModel {
    message: string;
    metadata: ApiMetadata;
    data: Array<CompanyThemeDto>;

    constructor(options?: APIModelOptions) {
        super(CompanyThemeDtoListApiDataResponse.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "message", type: "string" },
            { name: "metadata", type: "ApiMetadata" },
            { name: "data", type: "Array<CompanyThemeDto>" }
        ];
    }
}




