import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { GlobalStyles } from './GlobalStyles';
import { useTheme } from './theme';

export const ThemeProvider = ({ children }: React.PropsWithChildren) => {
  const theme = useTheme();

  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </MuiThemeProvider>
  );
};
