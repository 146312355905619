// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PaymentState } from './payment-state';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class PaymentViewDto extends APIModel {
    /**
    * Payment identifier
    */
    id: number;
    /**
    * Payment amount
    */
    amount: number;
    /**
    * Payment fee for company
    */
    companyFee: number;
    /**
    * Payment fee for worker
    */
    workerFee: number;
    /**
    * Bonus for withdrawal
    */
    bonus: number;
    state: PaymentState;
    /**
    * Date when payment was requested
    */
    dateRequested: string;

    constructor(options?: APIModelOptions) {
        super(PaymentViewDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "amount", type: "number" },
            { name: "companyFee", type: "number" },
            { name: "workerFee", type: "number" },
            { name: "bonus", type: "number" },
            { name: "state", type: "PaymentState" },
            { name: "dateRequested", type: "string" }
        ];
    }
}




