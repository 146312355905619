export const Enums = {
  ConnectorState: {
    Unknown: 'Neznámý',
    Active: 'Aktivní',
    Connecting: 'Připojuje se',
    Disabled: 'Zakázaný',
    CompilationError: 'Chyba kompilace',
  },
  PaymentState: {
    None: 'Neznámý',
    Requested: 'Požadavek',
    Verified: 'Ověřena',
    Processed: 'Zpracována',
    Error: 'Chyba',
    InBankPendingAuth: 'V bance - čeká na autorizaci',
    InBankLocked: 'V bance - uzamčeno',
    InBankAuthExpired: 'V bance - vypršela platnost',
  },
  Currencies: {
    CZK: 'Česká koruna',
    EUR: 'Euro',
  },
  MessagesAnswersName: {
    read: 'Přečteno',
  },
  RegistrationType: {
    Default: 'výchozí',
    OneTimeEmailCode: 'emailovým kódem',
  },
  WorkerState: {
    Active: 'Aktivní',
    Inactive: 'Neaktivní',
    Disabled: 'Vypnutý',
    NotFound: 'Nenalezen',
    RefreshFailure: 'Obnovení selhalo',
  },
  DirectDebitStatus: {
    Pending: 'Nové',
    Collected: 'Proplaceno',
    Requested: 'V bance',
  },
  ExternalPaymentState: {
    AUTHORIZATION_NOT_STARTED: 'Autorizace nebyla zahájena',
    DONE: 'Potvrzeno',
    PROCESSING: 'Zpracovává se',
    REJECTED: 'Zamítnuto',
    EXPIRED: 'Autorizace vypršela',
    ORDER_PROCESSING_FAILED: 'Chyba zpracování',
  },
  CardStatus: {
    Active: 'Aktivní',
    Inactive: 'Neaktivní',
    TemporaryBlocked: 'Dočasná blokace',
    Fraud: 'Fraud',
    Closed: 'Uzavřena',
    Lost: 'Ztracena',
    Stolen: 'Ukradena',
  },
  CardTransactionStatus: {
    Pending: 'Čekající',
    Authorized: 'Schváleno',
    Reversed: 'Vráceno',
    Error: 'Chyba',
  },
};
