import { StorageKey } from '@/enums';
import { Token } from '@/types';
import jwtDecode from 'jwt-decode';
import { storage } from './storage';

export const parseToken = (token: string) => jwtDecode<Token>(token);

export const getParsedToken = () => {
  const token = storage.getItem(StorageKey.Token);

  if (token) {
    return parseToken(token);
  }

  return null;
};

export const isTokenValid = () => {
  const token = getParsedToken();
  return !!token && token.exp > Date.now() / 1000;
};
