import { useCallback } from 'react';
import { useChangePassword } from '@/api';
import { Button, Form, InputField, useSnackbar } from '@/components';
import { useValidationSchema } from './validation';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { removeStyledSmallScreenProp, useSmallScreen } from '@/utils';

const initialValues = { oldPassword: '', newPassword: '', newPasswordConfirm: '' };

type FormValues = typeof initialValues;

export const PasswordChange = () => {
  const { t } = useTranslation('PasswordChange');
  const { mutateAsync } = useChangePassword();
  const { showSuccessSnackbar } = useSnackbar();
  const validationSchema = useValidationSchema();
  const smallScreen = useSmallScreen();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      await mutateAsync(values);

      showSuccessSnackbar(t('PasswordChanged'));
    },
    [mutateAsync, showSuccessSnackbar, t],
  );

  return (
    <Form {...{ initialValues, validationSchema, onSubmit }}>
      {({ isValid }) => (
        <Container {...{ smallScreen }}>
          <Title variant='h1'>{t('Title')}</Title>
          {[
            { name: 'oldPassword', label: 'CurrentPassword' },
            { name: 'newPassword', label: 'NewPassword' },
            { name: 'newPasswordConfirm', label: 'ConfirmNewPassword' },
          ].map((field) => (
            <StyledInputField key={field.name} {...field} label={t(field.label)} type='password' />
          ))}
          <StyledButton
            type='submit'
            label={t('Submit')}
            disabled={!isValid}
            data-cy='form-submit'
          />
        </Container>
      )}
    </Form>
  );
};

const Container = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  padding: smallScreen ? `${theme.spacing(6)} ${theme.spacing(2)}` : theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 500,
  margin: '0 auto',
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '1.1em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
}));

const StyledInputField = styled(InputField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));
