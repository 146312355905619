// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CardStatus } from './card-status';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CardResponse extends APIModel {
    id: number;
    cardIdentifier: string;
    status: CardStatus;

    constructor(options?: APIModelOptions) {
        super(CardResponse.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "cardIdentifier", type: "string" },
            { name: "status", type: "CardStatus" }
        ];
    }
}




