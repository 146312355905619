// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { AnswerQuestionsModel, AnswerQuestionsWithEmailCodeModel, ApiErrorResponse, ApiResponse, EmailModel, InitialRegistrationResponse, LoginResponse, PasswordAndOTPModel, PhoneNumberModel, PushNotificationsRegistrationModel, RegistrationStartModel } from "../model";

export class RegistrationApi extends APIHandler {
    static urls: APIURLTypes.RegistrationApi = {
        apiV1ClientRegistrationPushNotificationsAndroidPost: "/api/v1/client/registration/push-notifications/android",
        apiV1ClientRegistrationPushNotificationsApplePost: "/api/v1/client/registration/push-notifications/apple",
        apiV1ClientRegistrationRegistrationTokenRequestEmailOtpPost: "/api/v1/client/registration/{registrationToken}/request-email-otp",
        apiV1ClientRegistrationRegistrationTokenStep2ControlQuestionPost: "/api/v1/client/registration/{registrationToken}/step-2/control-question",
        apiV1ClientRegistrationRegistrationTokenStep2EmailOtpPost: "/api/v1/client/registration/{registrationToken}/step-2/email-otp",
        apiV1ClientRegistrationRegistrationTokenStep3Post: "/api/v1/client/registration/{registrationToken}/step-3",
        apiV1ClientRegistrationRegistrationTokenStep4Post: "/api/v1/client/registration/{registrationToken}/step-4",
        apiV1ClientRegistrationStep1Post: "/api/v1/client/registration/step-1"
    };
    
    constructor() {
        super("RegistrationApi");
    }
    
    /**
     * @param pushNotificationsRegistrationModel 
     */
    public apiV1ClientRegistrationPushNotificationsAndroidPost(params: APIParams & {
        pushNotificationsRegistrationModel?: PushNotificationsRegistrationModel;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationPushNotificationsAndroidPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.pushNotificationsRegistrationModel;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationPushNotificationsAndroidPost", params.options, body, "apiV1ClientRegistrationPushNotificationsAndroidPost");
    }

    /**
     * @param pushNotificationsRegistrationModel 
     */
    public apiV1ClientRegistrationPushNotificationsApplePost(params: APIParams & {
        pushNotificationsRegistrationModel?: PushNotificationsRegistrationModel;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationPushNotificationsApplePost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.pushNotificationsRegistrationModel;
        return this.makeCall<any>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationPushNotificationsApplePost", params.options, body, "apiV1ClientRegistrationPushNotificationsApplePost");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **EMAIL_DELIVERY_FAILED** - Email notification delivery has failed. 
     * @param registrationToken 
     * @param emailModel 
     */
    public apiV1ClientRegistrationRegistrationTokenRequestEmailOtpPost(params: APIParams & {
        registrationToken: string;
        emailModel?: EmailModel;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationRegistrationTokenRequestEmailOtpPost, { registrationToken: params.registrationToken } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.emailModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationRegistrationTokenRequestEmailOtpPost", params.options, body, "apiV1ClientRegistrationRegistrationTokenRequestEmailOtpPost");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **REGISTRATION_STATE_INVALID** - Registration must be \&#39;IN_PROGRESS\&#39; or \&#39;FAILED\&#39; state. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. + **CONNECTOR_NO_CONNECTION** - There is no connection to any of the employer\&#39;s servers. Please try again later. + **CONNECTOR_NO_REPLY** - The employer\&#39;s server did not respond in a timely manner. Please try again later. + **REGISTRATION_FAILED** - Registration failed. 
     * @param registrationToken 
     * @param answerQuestionsModel 
     */
    public apiV1ClientRegistrationRegistrationTokenStep2ControlQuestionPost(params: APIParams & {
        registrationToken: string;
        answerQuestionsModel?: AnswerQuestionsModel;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationRegistrationTokenStep2ControlQuestionPost, { registrationToken: params.registrationToken } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.answerQuestionsModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationRegistrationTokenStep2ControlQuestionPost", params.options, body, "apiV1ClientRegistrationRegistrationTokenStep2ControlQuestionPost");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **REGISTRATION_STATE_INVALID** - Registration must be \&#39;IN_PROGRESS\&#39; or \&#39;FAILED\&#39; state. + **CONECTOR_REQUEST_RECORD_NOT_FOUND** - Connector request failed. Record not found. Did you enter the data correctly?. + **CONNECTOR_NO_CONNECTION** - There is no connection to any of the employer\&#39;s servers. Please try again later. + **CONNECTOR_NO_REPLY** - The employer\&#39;s server did not respond in a timely manner. Please try again later. + **REGISTRATION_FAILED** - Registration failed. 
     * @param registrationToken 
     * @param answerQuestionsWithEmailCodeModel 
     */
    public apiV1ClientRegistrationRegistrationTokenStep2EmailOtpPost(params: APIParams & {
        registrationToken: string;
        answerQuestionsWithEmailCodeModel?: AnswerQuestionsWithEmailCodeModel;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationRegistrationTokenStep2EmailOtpPost, { registrationToken: params.registrationToken } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.answerQuestionsWithEmailCodeModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationRegistrationTokenStep2EmailOtpPost", params.options, body, "apiV1ClientRegistrationRegistrationTokenStep2EmailOtpPost");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **REGISTRATION_STATE_INVALID** - Registration must be \&#39;IN_PROGRESS\&#39; or \&#39;FAILED\&#39; state. + **REGISTRATION_FAILED** - Registration failed. + **USERNAME_TAKEN** - User with provided email already exists. 
     * @param registrationToken 
     * @param passwordAndOTPModel 
     */
    public apiV1ClientRegistrationRegistrationTokenStep3Post(params: APIParams & {
        registrationToken: string;
        passwordAndOTPModel?: PasswordAndOTPModel;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationRegistrationTokenStep3Post, { registrationToken: params.registrationToken } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.passwordAndOTPModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationRegistrationTokenStep3Post", params.options, body, "apiV1ClientRegistrationRegistrationTokenStep3Post");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **REGISTRATION_STATE_INVALID** - Registration must be \&#39;IN_PROGRESS\&#39; or \&#39;FAILED\&#39; state. + **REGISTRATION_FAILED** - Registration failed. 
     * @param registrationToken 
     * @param phoneNumberModel 
     */
    public apiV1ClientRegistrationRegistrationTokenStep4Post(params: APIParams & {
        registrationToken: string;
        phoneNumberModel?: PhoneNumberModel;
    }): AxiosPromise<LoginResponse> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationRegistrationTokenStep4Post, { registrationToken: params.registrationToken } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.phoneNumberModel;
        return this.makeCall<LoginResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationRegistrationTokenStep4Post", params.options, body, "apiV1ClientRegistrationRegistrationTokenStep4Post");
    }

    /**
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_CODE_INVALID** - Company code was not found. 
     * @param registrationStartModel 
     */
    public apiV1ClientRegistrationStep1Post(params: APIParams & {
        registrationStartModel?: RegistrationStartModel;
    } = {}): AxiosPromise<InitialRegistrationResponse> {
        const apiURL: string = this.initAPIURL(RegistrationApi.urls.apiV1ClientRegistrationStep1Post, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.registrationStartModel;
        return this.makeCall<InitialRegistrationResponse>("POST".toLowerCase(), apiURL, "apiV1ClientRegistrationStep1Post", params.options, body, "apiV1ClientRegistrationStep1Post");
    }

}
