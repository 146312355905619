// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CardStatus } from './card-status';
import { ReferralBonusDetails } from './referral-bonus-details';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class OverviewResponse extends APIModel {
    firstName: string;
    lastName: string;
    bankAccount: string;
    cashbackBankAccount: string;
    withdrawRealized: number;
    withdrawAvailable: number;
    currentMonth: string;
    code: string;
    referralCode: string;
    active: boolean;
    companyName: string;
    companyCode: string;
    companyCurrency: string;
    companyLegislation: string;
    balanceDay: number;
    firstCompanyFee: number;
    firstWorkerFee: number;
    secondCompanyFee: number;
    secondWorkerFee: number;
    thirdCompanyFee: number;
    thirdWorkerFee: number;
    fourthPlusCompanyFee: number;
    fourthPlusWorkerFee: number;
    paymentCardCompanyFee: number;
    paymentCardWorkerFee: number;
    unreadMessagesCount: number;
    /**
    * Id of the newest unread message
    */
    newestUnreadMessageId: number;
    /**
    * Preview of the newest unread message
    */
    newestUnreadMessagePreview: string;
    nextMonthPaymentSequence: number;
    bonus: number;
    bonusMessage: string;
    enrolledInReferralProgram: boolean;
    referralBonus: number;
    referral: ReferralBonusDetails;
    payrollConsolidationInProcess: boolean;
    isNew: boolean;
    enrolledInCardProgram: boolean;
    /**
    * Total amount of all accumulated cashback across all worker\'s cards
    */
    cashback: number;
    /**
    * Total amount of all cashback not yet paid to the worker across all worker\'s cards
    */
    unpaidCashback: number;
    isCashbackAccountSet: boolean;
    phoneNumber: string;
    isMigratedToUserNameAndPassword: boolean;
    paymentCardStatus: CardStatus;
    passwordChangeRequired: boolean;
    withdrawalLowerLimit: number;

    constructor(options?: APIModelOptions) {
        super(OverviewResponse.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "bankAccount", type: "string" },
            { name: "cashbackBankAccount", type: "string" },
            { name: "withdrawRealized", type: "number" },
            { name: "withdrawAvailable", type: "number" },
            { name: "currentMonth", type: "string" },
            { name: "code", type: "string" },
            { name: "referralCode", type: "string" },
            { name: "active", type: "boolean" },
            { name: "companyName", type: "string" },
            { name: "companyCode", type: "string" },
            { name: "companyCurrency", type: "string" },
            { name: "companyLegislation", type: "string" },
            { name: "balanceDay", type: "number" },
            { name: "firstCompanyFee", type: "number" },
            { name: "firstWorkerFee", type: "number" },
            { name: "secondCompanyFee", type: "number" },
            { name: "secondWorkerFee", type: "number" },
            { name: "thirdCompanyFee", type: "number" },
            { name: "thirdWorkerFee", type: "number" },
            { name: "fourthPlusCompanyFee", type: "number" },
            { name: "fourthPlusWorkerFee", type: "number" },
            { name: "paymentCardCompanyFee", type: "number" },
            { name: "paymentCardWorkerFee", type: "number" },
            { name: "unreadMessagesCount", type: "number" },
            { name: "newestUnreadMessageId", type: "number" },
            { name: "newestUnreadMessagePreview", type: "string" },
            { name: "nextMonthPaymentSequence", type: "number" },
            { name: "bonus", type: "number" },
            { name: "bonusMessage", type: "string" },
            { name: "enrolledInReferralProgram", type: "boolean" },
            { name: "referralBonus", type: "number" },
            { name: "referral", type: "ReferralBonusDetails" },
            { name: "payrollConsolidationInProcess", type: "boolean" },
            { name: "isNew", type: "boolean" },
            { name: "enrolledInCardProgram", type: "boolean" },
            { name: "cashback", type: "number" },
            { name: "unpaidCashback", type: "number" },
            { name: "isCashbackAccountSet", type: "boolean" },
            { name: "phoneNumber", type: "string" },
            { name: "isMigratedToUserNameAndPassword", type: "boolean" },
            { name: "paymentCardStatus", type: "CardStatus" },
            { name: "passwordChangeRequired", type: "boolean" },
            { name: "withdrawalLowerLimit", type: "number" }
        ];
    }
}




