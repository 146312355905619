// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CompanyDetailDtoApiDataResponse, CompanyDtoListApiDataResponse, CompanyLookupDtoListApiDataResponse, CompanyThemeViewDtoApiDataResponse, CreatedEntityDtoApiDataResponse, CurrentBudgetDtoApiDataResponse, CurrentWorkersDtoApiDataResponse, MonthOverviewDtoListApiDataResponse, SaveCompanyDto } from "../model";

export class CompaniesApi extends APIHandler {
    static urls: APIURLTypes.CompaniesApi = {
        apiV1AdminCompaniesBalancesPost: "/api/v1/admin/companies/balances",
        apiV1AdminCompaniesDirectDebitPost: "/api/v1/admin/companies/direct-debit",
        apiV1AdminCompaniesGet: "/api/v1/admin/companies",
        apiV1AdminCompaniesIdCurrentBudgetGet: "/api/v1/admin/companies/{id}/current-budget",
        apiV1AdminCompaniesIdCurrentWorkersGet: "/api/v1/admin/companies/{id}/current-workers",
        apiV1AdminCompaniesIdDelete: "/api/v1/admin/companies/{id}",
        apiV1AdminCompaniesIdGet: "/api/v1/admin/companies/{id}",
        apiV1AdminCompaniesIdMonthOverviewMonthGet: "/api/v1/admin/companies/{id}/month-overview/{month}",
        apiV1AdminCompaniesIdPut: "/api/v1/admin/companies/{id}",
        apiV1AdminCompaniesIdReferralEnrollPost: "/api/v1/admin/companies/{id}/referral/enroll",
        apiV1AdminCompaniesIdReferralRemovePost: "/api/v1/admin/companies/{id}/referral/remove",
        apiV1AdminCompaniesLookupsGet: "/api/v1/admin/companies/lookups",
        apiV1AdminCompaniesPost: "/api/v1/admin/companies",
        apiV1AdminCompaniesRefreshDemoDataPost: "/api/v1/admin/companies/refresh-demo-data",
        apiV1AdminCompaniesRefreshPut: "/api/v1/admin/companies/refresh",
        apiV1AdminCompaniesSalaryDeductionAgreementIdGet: "/api/v1/admin/companies/salary-deduction-agreement/{id}",
        apiV1AdminCompaniesXlsxGet: "/api/v1/admin/companies/xlsx",
        apiV1ClientCompaniesIdActiveThemeGet: "/api/v1/client/companies/{id}/active-theme"
    };
    
    constructor() {
        super("CompaniesApi");
    }
    
    /**
     * Create balances for the given companies or all of them non provided.
     * @param requestBody 
     */
    public apiV1AdminCompaniesBalancesPost(params: APIParams & {
        requestBody?: Array<number>;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesBalancesPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.requestBody;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompaniesBalancesPost", params.options, body, "apiV1AdminCompaniesBalancesPost");
    }

    /**
     * Create balances for the given companies or all of them non provided.
     * @param requestBody 
     */
    public apiV1AdminCompaniesDirectDebitPost(params: APIParams & {
        requestBody?: Array<number>;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesDirectDebitPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.requestBody;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompaniesDirectDebitPost", params.options, body, "apiV1AdminCompaniesDirectDebitPost");
    }

    /**
     * Get companies
     * @param companyName 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminCompaniesGet(params: APIParams & {
        companyName?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<CompanyDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesGet, null);
        params.options = this.initOptions(params.options, { companyName: params.companyName, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesGet", params.options, body, "apiV1AdminCompaniesGet");
    }

    /**
     * Get current budget
     * @param id 
     */
    public apiV1AdminCompaniesIdCurrentBudgetGet(params: APIParams & {
        id: number;
    }): AxiosPromise<CurrentBudgetDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdCurrentBudgetGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CurrentBudgetDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesIdCurrentBudgetGet", params.options, body, "apiV1AdminCompaniesIdCurrentBudgetGet");
    }

    /**
     * Get current workers counts
     * @param id 
     */
    public apiV1AdminCompaniesIdCurrentWorkersGet(params: APIParams & {
        id: number;
    }): AxiosPromise<CurrentWorkersDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdCurrentWorkersGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CurrentWorkersDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesIdCurrentWorkersGet", params.options, body, "apiV1AdminCompaniesIdCurrentWorkersGet");
    }

    /**
     * Delete company
     * @param id 
     */
    public apiV1AdminCompaniesIdDelete(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdDelete, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("DELETE".toLowerCase(), apiURL, "apiV1AdminCompaniesIdDelete", params.options, body, "apiV1AdminCompaniesIdDelete");
    }

    /**
     * Get company detail
     * @param id 
     */
    public apiV1AdminCompaniesIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<CompanyDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesIdGet", params.options, body, "apiV1AdminCompaniesIdGet");
    }

    /**
     * Get month overview
     *  ### Error names for HTTP Status 400 BadRequest: + **INVALID_DATE** - Invalid date. 
     * @param id 
     * @param month 
     */
    public apiV1AdminCompaniesIdMonthOverviewMonthGet(params: APIParams & {
        id: number;
        month: string;
    }): AxiosPromise<MonthOverviewDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdMonthOverviewMonthGet, { id: params.id, month: params.month } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<MonthOverviewDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesIdMonthOverviewMonthGet", params.options, body, "apiV1AdminCompaniesIdMonthOverviewMonthGet");
    }

    /**
     * Update company  todo: company update should be split in parts that may or may not trigger company refresh.
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_CODE_EXISTS** - Company with provided code already exists. + **INVALID_BALANCE_DAY** - Company balance day has invalid value 
     * @param id 
     * @param saveCompanyDto 
     */
    public apiV1AdminCompaniesIdPut(params: APIParams & {
        id: number;
        saveCompanyDto?: SaveCompanyDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveCompanyDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminCompaniesIdPut", params.options, body, "apiV1AdminCompaniesIdPut");
    }

    /**
     * Get company detail
     * @param id 
     */
    public apiV1AdminCompaniesIdReferralEnrollPost(params: APIParams & {
        id: number;
    }): AxiosPromise<CompanyDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdReferralEnrollPost, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyDetailDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompaniesIdReferralEnrollPost", params.options, body, "apiV1AdminCompaniesIdReferralEnrollPost");
    }

    /**
     * Get company detail
     * @param id 
     */
    public apiV1AdminCompaniesIdReferralRemovePost(params: APIParams & {
        id: number;
    }): AxiosPromise<CompanyDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesIdReferralRemovePost, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyDetailDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompaniesIdReferralRemovePost", params.options, body, "apiV1AdminCompaniesIdReferralRemovePost");
    }

    /**
     * Companies lookups
     * @param search Filter by name
     * @param limit Page Size
     * @param demo Include demo companies
     */
    public apiV1AdminCompaniesLookupsGet(params: APIParams & {
        search?: string;
        limit?: number;
        demo?: boolean;
    } = {}): AxiosPromise<CompanyLookupDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesLookupsGet, null);
        params.options = this.initOptions(params.options, { search: params.search, limit: params.limit, demo: params.demo }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyLookupDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesLookupsGet", params.options, body, "apiV1AdminCompaniesLookupsGet");
    }

    /**
     * Create company
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. + **COMPANY_CODE_EXISTS** - Company with provided code already exists. + **INVALID_BALANCE_DAY** - Company balance day has invalid value 
     * @param saveCompanyDto 
     */
    public apiV1AdminCompaniesPost(params: APIParams & {
        saveCompanyDto?: SaveCompanyDto;
    } = {}): AxiosPromise<CreatedEntityDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.saveCompanyDto;
        return this.makeCall<CreatedEntityDtoApiDataResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompaniesPost", params.options, body, "apiV1AdminCompaniesPost");
    }

    /**
     * Refresh demo company data
     * @param requestBody 
     */
    public apiV1AdminCompaniesRefreshDemoDataPost(params: APIParams & {
        requestBody?: Array<number>;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesRefreshDemoDataPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.requestBody;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCompaniesRefreshDemoDataPost", params.options, body, "apiV1AdminCompaniesRefreshDemoDataPost");
    }

    /**
     * Refresh given companies or all of them when non provided.
     *  ### Error names for HTTP Status 400 BadRequest: + **CONNECTOR_NO_CONNECTION** - There is no connection to any of the employer\&#39;s servers. Please try again later. 
     * @param requestBody 
     */
    public apiV1AdminCompaniesRefreshPut(params: APIParams & {
        requestBody?: Array<number>;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesRefreshPut, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.requestBody;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminCompaniesRefreshPut", params.options, body, "apiV1AdminCompaniesRefreshPut");
    }

    /**
     * Get salary deduction agreement for company
     * @param id 
     */
    public apiV1AdminCompaniesSalaryDeductionAgreementIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesSalaryDeductionAgreementIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesSalaryDeductionAgreementIdGet", params.options, body, "apiV1AdminCompaniesSalaryDeductionAgreementIdGet");
    }

    /**
     * Get company export in XLSX format.
     * @param companyName 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminCompaniesXlsxGet(params: APIParams & {
        companyName?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1AdminCompaniesXlsxGet, null);
        params.options = this.initOptions(params.options, { companyName: params.companyName, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminCompaniesXlsxGet", params.options, body, "apiV1AdminCompaniesXlsxGet");
    }

    /**
     * Get active company theme view.
     * @param id 
     */
    public apiV1ClientCompaniesIdActiveThemeGet(params: APIParams & {
        id: number;
    }): AxiosPromise<CompanyThemeViewDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(CompaniesApi.urls.apiV1ClientCompaniesIdActiveThemeGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CompanyThemeViewDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1ClientCompaniesIdActiveThemeGet", params.options, body, "apiV1ClientCompaniesIdActiveThemeGet");
    }

}
