import { InputField, Grid, FormComponentProps } from '@/components';
import { getCurrencySymbol } from '@/utils';

export const formFeesFields = [
  {
    name: 'firstCompanyFee',
    label: 'FirstCompanyFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'firstWorkerFee',
    label: 'FirstWorkerFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'secondCompanyFee',
    label: 'SecondCompanyFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'secondWorkerFee',
    label: 'SecondWorkerFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'thirdCompanyFee',
    label: 'ThirdCompanyFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'thirdWorkerFee',
    label: 'ThirdWorkerFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'fourthPlusCompanyFee',
    label: 'FourthPlusCompanyFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'fourthPlusWorkerFee',
    label: 'FourthPlusWorkerFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'paymentCardCompanyFee',
    label: 'PaymentCardCompanyFee',
    getEndAdornment: getCurrencySymbol,
  },
  {
    name: 'paymentCardWorkerFee',
    label: 'PaymentCardWorkerFee',
    getEndAdornment: getCurrencySymbol,
  },
];

export const FormFees = ({ disabled, t, values }: FormComponentProps) => {
  return (
    <Grid container>
      {formFeesFields.map(({ getEndAdornment = () => undefined, ...field }) => (
        <Grid key={field.name} item xs={12} sm={6}>
          <InputField
            {...{
              ...field,
              label: t(field.label),
              type: 'number',
              disabled,
              required: true,
              endAdornmentString: getEndAdornment(values.currency),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
