export const Payments = {
  Id: 'Identifier',
  Account: 'Account number',
  Advance: 'Advance',
  Amount: 'Amount',
  Authorization: 'Authorization',
  AuthorizeOpen: 'Send verified payments to bank for authorization?',
  Bonus: 'Bonus',
  Company: 'Company',
  CompanyFee: 'Company fee',
  DateProcessed: 'Processing date',
  DateRequested: 'Date of request',
  Debited: 'Debited',
  Error: 'Error',
  Paid: 'Paid',
  Payment: 'Request',
  PaymentProcess: 'Process',
  PaymentProcessConfirmText: 'Has the request really been sent?',
  PaymentProcessSuccess: 'Request processed',
  PayMonth: 'Pay month',
  PayMonthSequence: 'Pay month sequence',
  ReferralBonus: 'Referral bonus',
  HunterReferralCode: 'Referral code - Hunter',
  State: 'State',
  Worker: 'Worker',
  WorkerFee: 'Worker fee',
  ReconciliatePayments: 'Reconciliate requests',
  ReconciliatePaymentsConfirm: 'Execute requests reconciliation?',
  ReconciliatePaymentsOK: 'Requests reconciliation started',
};
