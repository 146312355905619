// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, UpdateWorkerDto, WorkerDetailDtoApiDataResponse, WorkerDtoListApiDataResponse, WorkerState } from "../model";

export class WorkersApi extends APIHandler {
    static urls: APIURLTypes.WorkersApi = {
        apiV1AdminWorkersGet: "/api/v1/admin/workers",
        apiV1AdminWorkersIdDisablePut: "/api/v1/admin/workers/{id}/disable",
        apiV1AdminWorkersIdEnablePut: "/api/v1/admin/workers/{id}/enable",
        apiV1AdminWorkersIdGet: "/api/v1/admin/workers/{id}",
        apiV1AdminWorkersIdPinResetPost: "/api/v1/admin/workers/{id}/pin-reset",
        apiV1AdminWorkersIdPut: "/api/v1/admin/workers/{id}",
        apiV1AdminWorkersIdRefreshPut: "/api/v1/admin/workers/{id}/refresh",
        apiV1AdminWorkersIdSalaryDeductionAgreementGet: "/api/v1/admin/workers/{id}/salary-deduction-agreement",
        apiV1AdminWorkersXlsxGet: "/api/v1/admin/workers/xlsx"
    };
    
    constructor() {
        super("WorkersApi");
    }
    
    /**
     * Get workers
     * @param workerId 
     * @param workerState 
     * @param accountNumber 
     * @param firstName 
     * @param lastName 
     * @param companyId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminWorkersGet(params: APIParams & {
        workerId?: number;
        workerState?: WorkerState;
        accountNumber?: string;
        firstName?: string;
        lastName?: string;
        companyId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<WorkerDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersGet, null);
        params.options = this.initOptions(params.options, { workerId: params.workerId, workerState: params.workerState, accountNumber: params.accountNumber, firstName: params.firstName, lastName: params.lastName, companyId: params.companyId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<WorkerDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminWorkersGet", params.options, body, "apiV1AdminWorkersGet");
    }

    /**
     * Disable worker
     * @param id 
     */
    public apiV1AdminWorkersIdDisablePut(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdDisablePut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminWorkersIdDisablePut", params.options, body, "apiV1AdminWorkersIdDisablePut");
    }

    /**
     * Enable worker
     * @param id 
     */
    public apiV1AdminWorkersIdEnablePut(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdEnablePut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminWorkersIdEnablePut", params.options, body, "apiV1AdminWorkersIdEnablePut");
    }

    /**
     * Get worker detail
     * @param id 
     */
    public apiV1AdminWorkersIdGet(params: APIParams & {
        id: number;
    }): AxiosPromise<WorkerDetailDtoApiDataResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<WorkerDetailDtoApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminWorkersIdGet", params.options, body, "apiV1AdminWorkersIdGet");
    }

    /**
     * Reset PIN
     * @param id 
     */
    public apiV1AdminWorkersIdPinResetPost(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdPinResetPost, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminWorkersIdPinResetPost", params.options, body, "apiV1AdminWorkersIdPinResetPost");
    }

    /**
     * Update worker
     *  ### Error names for HTTP Status 400 BadRequest: + **VALIDATION_FAILED** - Validation Failed. See \&#39;errorName\&#39; or \&#39;errors\&#39; for more details. 
     * @param id 
     * @param updateWorkerDto 
     */
    public apiV1AdminWorkersIdPut(params: APIParams & {
        id: number;
        updateWorkerDto?: UpdateWorkerDto;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.updateWorkerDto;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminWorkersIdPut", params.options, body, "apiV1AdminWorkersIdPut");
    }

    /**
     * Refresh given worker.
     *  ### Error names for HTTP Status 400 BadRequest: + **CONNECTOR_NO_CONNECTION** - There is no connection to any of the employer\&#39;s servers. Please try again later. 
     * @param id 
     */
    public apiV1AdminWorkersIdRefreshPut(params: APIParams & {
        id: number;
    }): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdRefreshPut, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("PUT".toLowerCase(), apiURL, "apiV1AdminWorkersIdRefreshPut", params.options, body, "apiV1AdminWorkersIdRefreshPut");
    }

    /**
     * Get salary deduction agreement for given worker in PDF format.
     * @param id 
     */
    public apiV1AdminWorkersIdSalaryDeductionAgreementGet(params: APIParams & {
        id: number;
    }): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersIdSalaryDeductionAgreementGet, { id: params.id } );
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminWorkersIdSalaryDeductionAgreementGet", params.options, body, "apiV1AdminWorkersIdSalaryDeductionAgreementGet");
    }

    /**
     * Get worker export in XLSX format.
     * @param workerId 
     * @param workerState 
     * @param accountNumber 
     * @param firstName 
     * @param lastName 
     * @param companyId 
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminWorkersXlsxGet(params: APIParams & {
        workerId?: number;
        workerState?: WorkerState;
        accountNumber?: string;
        firstName?: string;
        lastName?: string;
        companyId?: number;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<any> {
        const apiURL: string = this.initAPIURL(WorkersApi.urls.apiV1AdminWorkersXlsxGet, null);
        params.options = this.initOptions(params.options, { workerId: params.workerId, workerState: params.workerState, accountNumber: params.accountNumber, firstName: params.firstName, lastName: params.lastName, companyId: params.companyId, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<any>("GET".toLowerCase(), apiURL, "apiV1AdminWorkersXlsxGet", params.options, body, "apiV1AdminWorkersXlsxGet");
    }

}
