import { FormValues } from '@/components';
import { StorageKey } from '@/enums';
import { downloadFile, storage } from '@/utils';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { set } from 'lodash';
import { APIModel, APIModelOptions, AttributeMapItem } from './base/base';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../package.json');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModelClass = any;

export function dto(Model: ModelClass, options?: APIModelOptions) {
  const model = new Model();

  Model.attributeTypeMap.map((item: AttributeMapItem) => {
    const key = item.name;

    if (options && options instanceof Object) {
      const optionName: string | undefined = Object.keys(options).find((name: string) => {
        return key.toLowerCase() === name.toLowerCase();
      });

      if (optionName) {
        set(model, key, options[optionName]);
      } else {
        set(model, key, APIModel.initAsPimitiveType(item, options));
      }
    } else {
      set(model, key, APIModel.initAsPimitiveType(item, options));
    }
  });

  return model;
}

type Params<T> = T & { options?: AxiosRequestConfig };

export function params<T>(data?: T, options?: AxiosRequestConfig) {
  const language = storage.getItem(StorageKey.Language);
  const token = storage.getItem(StorageKey.Token);

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...((data as any) ?? {}),
    options: {
      ...(options ?? {}),
      headers: {
        'Content-Type': 'application/json',
        ClientVersion: `${process.env.CLIENT_NAME}-${version}`,
        ClientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(language ? { 'Accept-Language': language } : {}),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(options?.headers ?? {}),
      },
    },
  } as Params<T>;
}

export function fileParams<T>(data?: T) {
  return params<T>(data, { responseType: 'blob' });
}

export function downloadFileFromResponse(response: AxiosResponse) {
  const contentDisposition = response.headers['content-disposition'];
  downloadFile(
    response.data,
    contentDisposition
      ? contentDisposition
          .replace(/^.*filename=/, '')
          .replace(/;.*$/, '')
          .replace(/"/g, '')
      : '',
  );
}

export const withCompanyId = ({ company, ...values }: FormValues) => ({
  ...values,
  companyId: company?.id,
});
