export const Payments = {
  Id: 'Identifikátor',
  Account: 'Číslo účtu',
  Advance: 'Do zálohy',
  Amount: 'Čiastka',
  Authorization: 'Autorizácia',
  AuthorizeOpen: 'Zadat verifikované platby do banky k autorizaci?',
  Bonus: 'Bonus',
  Company: 'Spoločnosť',
  CompanyFee: 'Poplatok spoločnosť',
  DateProcessed: 'Dátum spracovania',
  DateRequested: 'Dátum požiadavky',
  Debited: 'Inkasované',
  Error: 'Chyba',
  Paid: 'Vyplatené',
  Payment: 'Žiadost',
  PaymentProcess: 'Spracovať',
  PaymentProcessConfirmText: 'Naozaj je žiadost odoslaná?',
  PaymentProcessSuccess: 'Žiadost bola spracovaná',
  PayMonth: 'Na vyplatenie',
  PayMonthSequence: 'Poradie v mesiaci',
  ReferralBonus: 'Referal bonus',
  HunterReferralCode: 'Referal kód - Lovec',
  State: 'Stav',
  Worker: 'Pracovník',
  WorkerFee: 'Poplatok zamestnanec',
  ReconciliatePayments: 'Rekonciliácia platieb',
  ReconciliatePaymentsConfirm: 'Vykonať rekonciliáciu platieb?',
  ReconciliatePaymentsOK: 'Prebieha rekonciliace plateb',
};
