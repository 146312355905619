export const Payments = {
  Id: 'Identifikátor',
  Account: 'Číslo účtu',
  Advance: 'Do záloh',
  Amount: 'Částka',
  Authorization: 'Autorizace',
  AuthorizeOpen: 'Zadat verifikované platby do banky k autorizaci?',
  Bonus: 'Bonus',
  Company: 'Společnost',
  CompanyFee: 'Poplatek firma',
  DateProcessed: 'Datum zpracování',
  DateRequested: 'Datum požadavku',
  Debited: 'Inkasováno',
  Error: 'Chyba',
  Paid: 'Vyplaceno',
  Payment: 'Žádost',
  PaymentProcess: 'Zpracovat',
  PaymentProcessConfirmText: 'Opravdu je žádost odeslána?',
  PaymentProcessSuccess: 'Žádost byla zpracována',
  PayMonth: 'K výplatě',
  PayMonthSequence: 'Pořadí v měsíci',
  ReferralBonus: 'Referal bonus',
  HunterReferralCode: 'Referal kód - Lovec',
  State: 'Stav',
  Worker: 'Pracovník',
  WorkerFee: 'Poplatek zaměstnanec',
  ReconciliatePayments: 'Rekonciliace plateb',
  ReconciliatePaymentsConfirm: 'Provést rekonciliaci plateb?',
  ReconciliatePaymentsOK: 'Probíhá rekonciliace plateb',
};
