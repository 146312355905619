// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse } from "../model";

export class CashbacksApi extends APIHandler {
    static urls: APIURLTypes.CashbacksApi = {
        apiV1AdminCashbacksGeneratePayoutsPost: "/api/v1/admin/cashbacks/generate-payouts"
    };
    
    constructor() {
        super("CashbacksApi");
    }
    
    /**
     */
    public apiV1AdminCashbacksGeneratePayoutsPost(params: APIParams & {
        
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CashbacksApi.urls.apiV1AdminCashbacksGeneratePayoutsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1AdminCashbacksGeneratePayoutsPost", params.options, body, "apiV1AdminCashbacksGeneratePayoutsPost");
    }

}
