export const Enums = {
  ConnectorState: {
    Unknown: 'Unknown',
    Active: 'Active',
    Connecting: 'Connecting',
    Disabled: 'Disabled',
    CompilationError: 'CompilationError',
  },
  PaymentState: {
    None: 'None',
    Requested: 'Requested',
    Verified: 'Verified',
    Processed: 'Processed',
    Error: 'Error',
    InBankPendingAuth: 'In bank - pending authorization',
    InBankLocked: 'In bank - locked',
    InBankAuthExpired: 'In bank - authorization expired',
  },
  Currencies: {
    CZK: 'CZK',
    EUR: 'EUR',
  },
  MessagesAnswersName: {
    read: 'Read',
  },
  RegistrationType: {
    Default: 'default',
    OneTimeEmailCode: 'email code',
  },
  WorkerState: {
    Active: 'Active',
    Inactive: 'Inactive',
    Disabled: 'Disabled',
    NotFound: 'Not Found',
    RefreshFailure: 'Refresh Failure',
  },
  DirectDebitStatus: {
    Pending: 'Pending',
    Collected: 'Collected',
    Requested: 'Requested',
  },
  ExternalPaymentState: {
    AUTHORIZATION_NOT_STARTED: 'Autorization pending',
    DONE: 'Authorized',
    PROCESSING: 'Processing',
    REJECTED: 'Rejected',
    EXPIRED: 'Autorization expired',
    ORDER_PROCESSING_FAILED: 'Authorization error',
  },
  CardStatus: {
    Active: 'Active',
    Inactive: 'Inactive',
    TemporaryBlocked: 'Temporarily blocked',
    Fraud: 'Fraud',
    Closed: 'Closed',
    Lost: 'Lost',
    Stolen: 'Stolen',
  },
  CardTransactionStatus: {
    Pending: 'Pending',
    Authorized: 'Authorized',
    Reversed: 'Reversed',
    Error: 'Error',
  },
};
