// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class UserDto extends APIModel {
    /**
    * User id
    */
    id: number;
    /**
    * Username
    */
    username: string;
    /**
    * First name
    */
    firstName: string;
    /**
    * Last name
    */
    lastName: string;
    /**
    * Code of company
    */
    companyCode: string;
    /**
    * Name of company
    */
    companyName: string;
    /**
    * User identifier
    */
    identifier: string;

    constructor(options?: APIModelOptions) {
        super(UserDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "username", type: "string" },
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "companyCode", type: "string" },
            { name: "companyName", type: "string" },
            { name: "identifier", type: "string" }
        ];
    }
}




