import { ApiListOptions, FormValues } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { ImagesApi } from '../base';
import { defaultBasePath } from '../base/base';
import { CreateImageDto, UpdateImageDto } from '../base/model';
import { downloadFileFromResponse, dto, fileParams, params } from '../utils';

const api = new ImagesApi();

export const useImageList = (options: ApiListOptions) => {
  return useQuery([ApiKey.Images, options], async () => {
    const response = await api.apiV1AdminImagesGet(params(options));

    return response.data;
  });
};

export const useImageExport = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminImagesXlsxGet(fileParams(options));
    downloadFileFromResponse(response);
  });
};

export const useImageGet = (id: number) => {
  return useQuery([ApiKey.Images, id], async () => {
    const response = await api.apiV1AdminImagesIdGet(params({ id }));

    return response.data.data;
  });
};

export const useImageUsed = (id: number) => {
  return useQuery([ApiKey.Images, id, 'used'], async () => {
    const response = await api.apiV1AdminImagesIdUsedGet(params({ id }));

    return response.data.data;
  });
};

export const useImageCreate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminImagesPost(
      params({
        createImageDto: dto(CreateImageDto, values),
      }),
    );
  });
};

export const useImageUpdate = () => {
  return useMutation((values: FormValues) => {
    return api.apiV1AdminImagesIdPut(
      params({
        id: values.id,
        updateImageDto: dto(UpdateImageDto, values),
      }),
    );
  });
};

export const useImageDelete = () => {
  return useMutation((id: number) => {
    return api.apiV1AdminImagesIdDelete(params({ id }));
  });
};

export const imagePublicUrlPath = '/api/v1/client/images';

export const getImagePublicUrl = (id: number) => `${defaultBasePath}${imagePublicUrlPath}/${id}`;
