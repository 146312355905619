import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { Props } from './types';

export const Input = ({ value, onChange, endAdornmentString, ...props }: Props) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e, e.target.value);
    },
    [onChange],
  );

  return (
    <TextField
      {...{
        variant: 'outlined',
        fullWidth: true,
        value: value === null ? '' : value,
        onChange: handleChange,
        ...(endAdornmentString
          ? {
              InputProps: {
                endAdornment: <InputAdornment position='end'>{endAdornmentString}</InputAdornment>,
              },
            }
          : {}),
        ...props,
      }}
    />
  );
};
