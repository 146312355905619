import { omit } from 'lodash';

export function splitProps<T, K extends (keyof T)[]>(
  original: T,
  splitKeys: K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return splitKeys.reduce((props, key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const originalAny = original as any;
    props[key] = originalAny[key];
    omit(originalAny, [key]);

    return props;
  }, {} as T);
}
