// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CardTransactionStatus } from './card-transaction-status';
import { KeyValuePairDto } from './key-value-pair-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CardTransactionDto extends APIModel {
    /**
    * Card id within PalmApp
    */
    id: number;
    /**
    * Card number (masked)
    */
    cardNumber: string;
    status: CardTransactionStatus;
    /**
    * Transaction amount
    */
    amount: number;
    /**
    * Transaction currency
    */
    currency: string;
    /**
    * The amount of cashback gained by the transaction
    */
    cashbackAmount: number;
    /**
    * Transaction processing date
    */
    transactionProcessingDate: string;
    /**
    * The month for which the transaction is paid
    */
    payMonth: string;
    /**
    * Id of the cashback payout (if paid)
    */
    cashbackPayoutId: number;
    /**
    * Date of the cashback payout (if paid)
    */
    cashbackPayoutDate: string;
    /**
    * Id of the direct debit used to collect the transaction amount (if debited)
    */
    directDebitId: number;
    /**
    * Region code where the transaction was made
    */
    regionCode: string;
    worker: KeyValuePairDto;
    company: KeyValuePairDto;

    constructor(options?: APIModelOptions) {
        super(CardTransactionDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "cardNumber", type: "string" },
            { name: "status", type: "CardTransactionStatus" },
            { name: "amount", type: "number" },
            { name: "currency", type: "string" },
            { name: "cashbackAmount", type: "number" },
            { name: "transactionProcessingDate", type: "string" },
            { name: "payMonth", type: "string" },
            { name: "cashbackPayoutId", type: "number" },
            { name: "cashbackPayoutDate", type: "string" },
            { name: "directDebitId", type: "number" },
            { name: "regionCode", type: "string" },
            { name: "worker", type: "KeyValuePairDto" },
            { name: "company", type: "KeyValuePairDto" }
        ];
    }
}




