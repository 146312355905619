import { ApiListOptions } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { ApiKey } from '@/enums';
import { DirectDebitsApi } from '../base';
import { params, fileParams, downloadFileFromResponse } from '../utils';

const api = new DirectDebitsApi();

export const useDirectDebitList = (options: ApiListOptions) => {
  return useQuery([ApiKey.DirectDebits, options], async () => {
    const response = await api.apiV1AdminDirectDebitsGet(params(options));

    return response.data;
  });
};

export const useDirectDebitPaymentsExport = () => {
  return useMutation(async (id: number) => {
    const response = await api.apiV1AdminDirectDebitsIdXlsxPaymentsGet(fileParams({ id }));
    downloadFileFromResponse(response);
  });
};

export const useDirectDebitRequest = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminDirectDebitsIdRequestPost(params({ id }));
  });
};

export const useDirectDebitProcess = () => {
  return useMutation(async (id: number) => {
    return api.apiV1AdminDirectDebitsIdPerformPost(params({ id }));
  });
};

export const useDirectDebitGet = (id: number) => {
  return useQuery([ApiKey.DirectDebits, id], async () => {
    const response = await api.apiV1AdminDirectDebitsIdGet(params({ id }));

    return response.data.data;
  });
};

export const useDirectDebitsRequestSk = () => {
  return useMutation(async (options: ApiListOptions) => {
    const response = await api.apiV1AdminDirectDebitsRequestSkPost(fileParams({ ...options }));
    downloadFileFromResponse(response);
  });
};
