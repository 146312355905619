export const Module = {
  Export: 'Export',
  ExportConfirmText: 'Bude exportováno maximálně {{value}} záznamů. Chcete pokračovat v exportu?',
  GenerateFiles: 'Generovat soubory',
  GenerateFilesConfirmText: 'Budou generovány soubory. Chcete pokračovat?',
  New: 'Nová položka',
  NewBulk: 'Nová hromadná položka',
  Reload: 'Znovu načíst',
  VerifyConnection: 'Verifikovat spojení',
  BatchProcess: 'Zpracovat všechny položky najednou',
  ReconciliatePayments: 'Rekonciliace plateb',
  DirectDebits: 'Zpracovat CZ inkasa',
  PollBatches: 'Zkontrolovat stav plateb v bance',
  From: 'od',
  To: 'do',
};
