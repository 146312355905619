import { ApiKey } from '@/enums';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { TableDirectDebitProps } from './types';
import { useModule } from './useModule';
import { useLoading } from '../Loading';
import { ConfirmModal } from '../ConfirmModal';

export const TableDirectDebit = ({
  t,
  useApiVerifyConnection,
  useApiVerifiedConnectionConnect,
  useApiVerifiedConnectionDirectDebit,
}: TableDirectDebitProps) => {
  const { updateModuleOptions } = useModule();
  const queryClient = useQueryClient();
  const { mutateAsync: connect } = useApiVerifiedConnectionConnect();
  const { data, isLoading } = useApiVerifyConnection();
  const { mutateAsync: directDebit } = useApiVerifiedConnectionDirectDebit();
  const loadingContext = useLoading();
  const [directDebitsModalOpen, setDirectDebitsModalOpen] = useState(false);

  useEffect(() => {
    queryClient.invalidateQueries([ApiKey.VerifyConnectionGet]);
  }, [queryClient]);

  useEffect(() => {
    updateModuleOptions({
      connectCallback: !data?.isConnected && !isLoading ? () => connect({}) : undefined,
      directDebitsCallback:
        data?.isConnected && !isLoading
          ? () => {
              setDirectDebitsModalOpen(true);
            }
          : undefined,
    });
  }, [connect, directDebit, data, isLoading, updateModuleOptions, loadingContext]);

  return (
    <>
      <ConfirmModal
        {...{
          label: 'CZ inkasa',
          text: 'Vytvořit inkasa pro CZ?',
          open: directDebitsModalOpen,
          onClick: () => {
            directDebit(loadingContext);
          },
          onClose: () => {
            setDirectDebitsModalOpen(false);
          },
        }}
      />
    </>
  );
};
