// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class MessageAnswerDto extends APIModel {
    /**
    * Message answer identifier
    */
    id: number;
    /**
    * Message caption
    */
    messageCaption: string;
    /**
    * Message answer name
    */
    answerName: string;
    /**
    * Answered values
    */
    answerValues: string;
    /**
    * Date of message preview
    */
    datePreview: string;
    /**
    * Date when message was read
    */
    dateRead: string;
    /**
    * Date when message was answered
    */
    dateAnswer: string;

    constructor(options?: APIModelOptions) {
        super(MessageAnswerDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "messageCaption", type: "string" },
            { name: "answerName", type: "string" },
            { name: "answerValues", type: "string" },
            { name: "datePreview", type: "string" },
            { name: "dateRead", type: "string" },
            { name: "dateAnswer", type: "string" }
        ];
    }
}




