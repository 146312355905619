export enum ApiKey {
  Balances = 'Balances',
  Companies = 'Companies',
  CompaniesExport = 'CompaniesExport',
  CompaniesLookups = 'CompaniesLookups',
  CompanyCurrentBudget = 'CompanyCurrentBudget',
  CompanyCurrentWorkers = 'CompanyCurrentWorkers',
  CompanyMonthOverview = 'CompanyMonthOverview',
  CompanyThemes = 'CompanyThemes',
  Connectors = 'Connectors',
  DirectDebits = 'DirectDebits',
  Images = 'Images',
  Messages = 'Messages',
  MessagesAnswers = 'MessagesAnswers',
  Payments = 'Payments',
  PaymentsVerified = 'PaymentsVerified',
  Regions = 'Regions',
  RegistrationTypes = 'RegistrationTypes',
  Roles = 'Roles',
  Setting = 'Setting',
  Users = 'Users',
  VerifyConnectionGet = 'VerifyConnectionGet',
  VerifyConnectionAuthenticationUrl = 'VerifyConnectionAuthenticationUrl',
  Workers = 'Workers',
  BankAuthorizations = 'BankAuthorizations',
  BatchAuthorizationUrl = 'BatchAuthorizationUrl',
  Cards = 'Cards',
  CardTransactions = 'CardTransactions',
}
