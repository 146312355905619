import { useValidation } from '@/components';

export const useValidationSchemaBulk = () => {
  const createValidationSchema = useValidation();

  return createValidationSchema(
    ({
      t,
      isArrayNotEmpty,
      isDateRequired,
      isStringRequired,
      isStringMaxLength,
      isStringMaxLengthOptional,
    }) => ({
      caption: isStringMaxLength(200),
      type: isStringMaxLengthOptional(100),
      preview: isStringMaxLengthOptional(1024),
      markdownData: isStringRequired(),
      company: isArrayNotEmpty(),
      dateValidFrom: isDateRequired(),
      dateValidTo: isDateRequired().test(
        'DateValidToGreaterThanDateValidFrom',
        t('DateValidToGreaterThanDateValidFrom'),
        function (dateValidTo) {
          const dateValidFrom = this.parent.dateValidFrom;
          return !dateValidTo || !dateValidFrom || new Date(dateValidTo) >= new Date(dateValidFrom);
        },
      ),
    }),
  );
};
