import { Box, CircularProgress, styled, alpha } from '@mui/material';
import { Props } from './types';

export const Spinner = ({
  visible = true,
  fullScreen = false,
  fullSizeBox = false,
  fullSize = false,
}: Props) => {
  if (!visible) {
    return <></>;
  }

  return fullScreen ? (
    <FullScreenSpinner />
  ) : fullSizeBox ? (
    <FullSizeBoxSpinner />
  ) : fullSize ? (
    <FullSizeSpinner />
  ) : (
    <Base />
  );
};

const FullScreenSpinner = () => {
  return (
    <FullScreenContainer>
      <Base />
    </FullScreenContainer>
  );
};

const FullSizeBoxSpinner = () => {
  return (
    <FullSizeBoxContainer>
      <Base />
    </FullSizeBoxContainer>
  );
};

const FullSizeSpinner = () => {
  return (
    <FullSizeContainer>
      <Base />
    </FullSizeContainer>
  );
};

const Base = () => {
  return <CircularProgress />;
};

const FullSizeBoxContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 75,
});

const FullSizeContainer = styled(FullSizeBoxContainer)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1250,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha('#FFF', 0.75),
});

const FullScreenContainer = styled(FullSizeContainer)({
  zIndex: 1350,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
});
